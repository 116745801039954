import { ChangeEvent, useState } from 'react'
import Button from '../FormComponents/Button'
import TextArea from '../TextArea'
import XsType from '../interface/text/XsType'
import ModalWindow from '../window/ModalWindow'

interface FeedbackModalProps {
	isOpen: boolean
	onClose: () => void
}

export default function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
	const [feedback, setFeedback] = useState('')
	const [status, setStatus] = useState<
		'idle' | 'sending' | 'success' | 'error'
	>('idle')

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault()
		setStatus('sending')

		try {
			const token = localStorage.getItem('token')
			const response = await fetch('/api/send-feedback', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ feedback }),
			})

			if (!response.ok) {
				const data = await response.json()
				throw new Error(data.message || 'Failed to send feedback')
			}

			setStatus('success')
			setTimeout(() => {
				onClose()
				setFeedback('')
				setStatus('idle')
			}, 2000)
		} catch (error) {
			console.error('Failed to send feedback:', error)
			setStatus('error')
		}
	}

	const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setFeedback(e.target.value)
	}

	return (
		<ModalWindow
			id='feedback-modal'
			title='Send Feedback'
			isOpen={isOpen}
			onClose={onClose}
			size='small'
			bodyClassName='px-6 pb-6 pt-0'
		>
			<form onSubmit={handleSubmit} className='space-y-4'>
				<p className='text-sm text-gray-600'>
					Found a bug in the dungeon? Want to recite a poem about a new feature?
					The quest log awaits your tales of mis-adventure!
				</p>

				<TextArea
					value={feedback}
					onChange={handleChange}
					placeholder="What's on your mind, adventurer?"
					minRows={4}
					required
					isOnDark={false}
				/>

				<div className='flex flex-col items-center space-y-2'>
					<Button type='submit' disabled={status === 'sending'}>
						{status === 'sending'
							? 'Sending...'
							: status === 'success'
							? 'Sent!'
							: 'Send Message'}
					</Button>
					{status === 'error' && (
						<XsType className='text-red-500'>
							Failed to send feedback. Please try again.
						</XsType>
					)}
				</div>
			</form>
		</ModalWindow>
	)
}
