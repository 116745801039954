import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { IGameCard } from '../../../../shared/types/game'
import ToolButton from '../interface/toolbar/ToolButton'
import GameMenuContent from './GameMenuContent'

type Props = {
	game: IGameCard
	refetch?: () => void
	children: React.ReactElement
	show: boolean
	onOpen?: () => void
	onClose?: () => void
}

const GameMenu = ({
	game,
	refetch,
	children,
	show,
	onOpen,
	onClose,
}: Props) => {
	const [isOpen, setIsOpen] = React.useState(false)

	return (
		<DropdownMenu.Root
			onOpenChange={open => {
				setIsOpen(open)
				if (open) {
					onOpen?.()
				} else {
					onClose?.()
				}
			}}
		>
			<DropdownMenu.Trigger
				asChild
				className={twMerge(
					'absolute top-4 right-4 z-20 transition-opacity duration-300',
					show || isOpen ? 'opacity-100' : 'opacity-0',
				)}
			>
				<ToolButton isOpen={isOpen} as='div'>
					{children}
				</ToolButton>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<GameMenuContent game={game} refetch={refetch} />
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

export default GameMenu
