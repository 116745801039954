/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useReducer } from 'react'
import { ISavedWindow } from '../../../../shared/types/game'
import GameContext from '../../contexts/game'
import WindowsContext from '../../contexts/windows'
import WindowReducer from '../../reducers/windows'

interface IProps {
	children: React.ReactNode
}

const Windows: React.FC<IProps> = ({ children }) => {
	const { game } = useContext(GameContext)

	const [windowsState, dispatchWindows] = useReducer(WindowReducer, {
		// array of stored window rect; order determines z-index
		windows: JSON.parse(
			window.localStorage.getItem(`${game._id}.windows`) || '[]',
		) as ISavedWindow[],
		// start position of next unstored window to open
		position: { x: 0, y: 0 },
	})

	const saveWindowsLocally = () => {
		window.localStorage.setItem(
			`${game._id}.windows`,
			JSON.stringify(windowsState.windows),
		)
	}

	useEffect(saveWindowsLocally, [
		game._id,
		JSON.stringify(windowsState.windows),
	])

	return (
		<WindowsContext.Provider value={{ windowsState, dispatchWindows }}>
			{children}
		</WindowsContext.Provider>
	)
}

export default Windows
