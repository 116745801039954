import { useContext, useMemo, useState } from 'react'
import { IDocument, TDocumentModes } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import useGetCollectionName from '../../../hooks/useGetCollectionName'
import Menu from '../../documentmenu/Menu'
import Window from '../../window/Window'
import WindowModeToggle from '../../WindowModeToggle'
import DocumentWindowMenuButton from '../MenuButton'
import DocumentWindowTitle from '../Title'
import HandoutEditor from './HandoutEditor'

interface HandoutWindowProps {
	documentId: string
	onClose: () => void
}

const emptyDocument: IDocument = {
	_id: '',
	type: '',
	creator: '',
	access: 'private',
	accessList: [],
	values: {},
} as IDocument

const HandoutWindow = ({
	documentId,
	onClose = () => {},
}: HandoutWindowProps) => {
	const { game } = useContext(GameContext)
	const [documentMode, setDocumentMode] = useState<TDocumentModes>('view')
	const document = useMemo(
		() => game.documents.byId[documentId] || emptyDocument,
		[game.documents, game.assets, documentId],
	)
	const collection =
		game.system?.collections?.find(t => t.type === document.type) || null
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`

	if (!document._id) return null

	return (
		<Window
			key={documentId}
			documentId={documentId}
			title={DocumentWindowTitle({ title, singularName, document })}
			open={true}
			onClose={onClose}
			bodyClassName='p-0'
			size={collection?.windowSize || 'medium'}
			headerIcon={
				<Menu document={document}>
					<DocumentWindowMenuButton />
				</Menu>
			}
			headerChildren={
				<>
					{collection?.hasEditMode === 'true' && (
						<WindowModeToggle
							documentMode={documentMode}
							setDocumentMode={setDocumentMode}
						/>
					)}
				</>
			}
		>
			{documentMode === 'edit' ? (
				<HandoutEditor doc={document} />
			) : (
				<div className='flex items-center justify-center p-4'>
					{document.values.asset && (
						<img
							src={game.assets.byId[document.values.asset]?.fileurl}
							alt={document.values.name || 'Handout'}
							className='max-h-full max-w-full object-contain'
						/>
					)}
				</div>
			)}
		</Window>
	)
}

export default HandoutWindow
