interface PenWithColorProps {
	color?: string
	lineColor?: string
	className?: string
}

const PenWithColor = ({
	color = 'currentColor',
	lineColor = '#ff69b4',
	className,
}: PenWithColorProps) => {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			stroke={color}
			strokeWidth='1.8'
			strokeLinecap='round'
			strokeLinejoin='round'
			className={className}
		>
			<path d='M12 20h9' stroke={lineColor} />
			<path d='M16.376 3.622a1 1 0 0 1 3.002 3.002L7.368 18.635a2 2 0 0 1-.855.506l-2.872.838a.5.5 0 0 1-.62-.62l.838-2.872a2 2 0 0 1 .506-.854z' />
			<path d='m15 5 3 3' />
		</svg>
	)
}

export default PenWithColor
