import { FC, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BookResource } from '../../../../../shared/types/resources'
import useResourceMetadataOptions from '../../../hooks/useResourceMetadataOptions'
import useUpdateResource from '../../../hooks/useUpdateResource'
import ModalWindow from '../../window/ModalWindow'
import FilterDropdown from './elements/FilterDropdown'

interface Props {
	id: string
	resource: BookResource
	isOpen: boolean
	onClose: () => void
}

const BookEditor: FC<Props> = ({ id, resource, isOpen, onClose }) => {
	const { updateResource, loading } = useUpdateResource()
	const [saveError, setSaveError] = useState<string | null>(null)
	const { games, ruleSystems } = useResourceMetadataOptions(resource)

	const methods = useForm({
		defaultValues: {
			title: resource.metadata?.title || '',
			description: resource.metadata?.description || '',
			game: resource.metadata?.game || '',
			ruleSystem: resource.metadata?.ruleSystem || '',
			authors: resource.metadata?.authors?.join(', ') || '',
			contributingArtists:
				resource.metadata?.contributingArtists?.join(', ') || '',
			publisher: resource.metadata?.publisher || '',
			year: resource.metadata?.year || '',
			edition: resource.metadata?.edition || '',
			isbn: resource.metadata?.isbn || '',
			language: resource.metadata?.language || '',
			series: resource.metadata?.series || '',
			seriesNumber: resource.metadata?.seriesNumber || undefined,
			tags: resource.metadata?.tags?.join(', ') || '',
			contentWarnings: resource.metadata?.contentWarnings?.join(', ') || '',
		},
	})

	const handleClose = () => {
		if (methods.formState.isDirty) {
			const confirmClose = window.confirm(
				'You have unsaved changes. Are you sure you want to close?',
			)
			if (!confirmClose) {
				return
			}
		}
		setSaveError(null)
		onClose()
	}

	const splitAndTrim = (str: string) =>
		str
			? str
					.split(',')
					.map(s => s.trim())
					.filter(Boolean)
			: []

	const onSubmit = async (data: any) => {
		try {
			setSaveError(null)

			// Convert comma-separated strings to arrays
			const metadata = {
				...resource.metadata,
				...data,
				authors: splitAndTrim(data.authors),
				contributingArtists: splitAndTrim(data.contributingArtists),
				tags: splitAndTrim(data.tags),
				contentWarnings: splitAndTrim(data.contentWarnings),
				// Ensure seriesNumber is a number or undefined
				seriesNumber: data.seriesNumber ? Number(data.seriesNumber) : undefined,
			}

			await updateResource(resource._id, metadata)
			onClose()
		} catch (err) {
			console.error('Failed to update resource:', err)
			setSaveError(
				err instanceof Error ? err.message : 'Failed to update resource',
			)
		}
	}

	return (
		<ModalWindow
			id={id}
			title={`Edit ${resource.metadata?.title || resource.name}`}
			isOpen={isOpen}
			onClose={handleClose}
			size='medium'
			enableClickOutside={true}
			headerChildren={
				<div className='mr-2 flex space-x-4'>
					<button
						onClick={handleClose}
						disabled={loading}
						className='text-gray-400 hover:text-gray-300 disabled:opacity-50'
					>
						Cancel
					</button>
					<button
						onClick={methods.handleSubmit(onSubmit)}
						disabled={loading || !methods.formState.isDirty}
						className='text-blue-500 hover:text-blue-400 disabled:opacity-50'
					>
						{loading ? 'Saving...' : 'Save'}
					</button>
				</div>
			}
		>
			<FormProvider {...methods}>
				<form className='space-y-6 p-6'>
					{saveError && (
						<div className='rounded-md bg-red-900/50 p-3 text-sm text-red-200'>
							{saveError}
						</div>
					)}

					{/* Basic Information */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Basic Information
						</h3>
						<div>
							<label className='block text-sm text-gray-400'>Title</label>
							<input
								{...methods.register('title')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								placeholder='Book title'
							/>
						</div>
						<div>
							<label className='block text-sm text-gray-400'>Description</label>
							<textarea
								{...methods.register('description')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								rows={3}
								placeholder='Book description'
							/>
						</div>
					</div>

					{/* Game Information */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Game Information
						</h3>
						<div className='grid grid-cols-2 gap-4'>
							<div>
								<label className='block text-sm text-gray-400'>Game</label>
								<FilterDropdown
									title='Select Game'
									items={games}
									selectedItems={
										methods.watch('game') ? [methods.watch('game')] : []
									}
									onItemToggle={gameId => {
										methods.setValue('game', gameId, { shouldDirty: true })
									}}
								/>
							</div>
							<div>
								<label className='block text-sm text-gray-400'>
									Rule System
								</label>
								<FilterDropdown
									title='Select Rule System'
									items={ruleSystems}
									selectedItems={
										methods.watch('ruleSystem')
											? [methods.watch('ruleSystem')]
											: []
									}
									onItemToggle={systemId => {
										methods.setValue('ruleSystem', systemId, {
											shouldDirty: true,
										})
									}}
								/>
							</div>
						</div>
					</div>

					{/* Publication Information */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Publication Information
						</h3>
						<div className='grid grid-cols-2 gap-4'>
							<div>
								<label className='block text-sm text-gray-400'>Publisher</label>
								<input
									{...methods.register('publisher')}
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='Publisher'
								/>
							</div>
							<div>
								<label className='block text-sm text-gray-400'>Year</label>
								<input
									{...methods.register('year')}
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='Publication year'
								/>
							</div>
							<div>
								<label className='block text-sm text-gray-400'>Edition</label>
								<input
									{...methods.register('edition')}
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='Edition'
								/>
							</div>
							<div>
								<label className='block text-sm text-gray-400'>ISBN</label>
								<input
									{...methods.register('isbn')}
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='ISBN'
								/>
							</div>
						</div>
					</div>

					{/* Series Information */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Series Information
						</h3>
						<div className='grid grid-cols-2 gap-4'>
							<div>
								<label className='block text-sm text-gray-400'>Series</label>
								<input
									{...methods.register('series')}
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='Series name'
								/>
							</div>
							<div>
								<label className='block text-sm text-gray-400'>
									Series Number
								</label>
								<input
									{...methods.register('seriesNumber', { valueAsNumber: true })}
									type='number'
									className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
									placeholder='Series number'
								/>
							</div>
						</div>
					</div>

					{/* Credits */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>Credits</h3>
						<div>
							<label className='block text-sm text-gray-400'>Authors</label>
							<input
								{...methods.register('authors')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								placeholder='Authors (comma separated)'
							/>
						</div>
						<div>
							<label className='block text-sm text-gray-400'>
								Contributing Artists
							</label>
							<input
								{...methods.register('contributingArtists')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								placeholder='Artists (comma separated)'
							/>
						</div>
					</div>

					{/* Tags and Warnings */}
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Tags and Warnings
						</h3>
						<div>
							<label className='block text-sm text-gray-400'>Tags</label>
							<input
								{...methods.register('tags')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								placeholder='Tags (comma separated)'
							/>
						</div>
						<div>
							<label className='block text-sm text-gray-400'>
								Content Warnings
							</label>
							<input
								{...methods.register('contentWarnings')}
								className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400'
								placeholder='Content warnings (comma separated)'
							/>
						</div>
					</div>
				</form>
			</FormProvider>
		</ModalWindow>
	)
}

export default BookEditor
