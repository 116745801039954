interface IProps {
	children: React.ReactNode
}

const Footer: React.FC<IProps> = ({ children }) => {
	return (
		<div className='border-top items-center border-t border-gray-200 bg-gray-50 px-2 py-2 text-gray-900'>
			{children}
		</div>
	)
}

export default Footer
