import { Pointer } from 'lucide-react'
import { forwardRef, useContext } from 'react'
import ToolsContext from '../../contexts/tools'
import ToolButton from '../interface/toolbar/ToolButton'

const PointerButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { toolsState, dispatchTools } = useContext(ToolsContext)
		const { mode } = toolsState

		const handleClick = () => {
			dispatchTools({ type: 'SET_MODE', payload: 'select' })
		}

		return (
			<ToolButton
				tooltip='Select'
				isOpen={mode === 'select'}
				onClick={handleClick}
				ref={ref}
			>
				<Pointer aria-label='Selection tool' />
			</ToolButton>
		)
	},
)

PointerButton.displayName = 'PointerButton'

export default PointerButton
