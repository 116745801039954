import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import menuContentClasses from './menuContentClasses'

type Props = { children: React.ReactNode; className?: string }

const MenuContent = forwardRef<HTMLDivElement, Props>(
	({ children, className }, ref) => {
		return (
			<DropdownMenu.Content
				ref={ref}
				sideOffset={5}
				collisionPadding={20}
				className={twMerge(menuContentClasses, className)}
				style={{
					width: 'max-content',
					minWidth: '10rem',
					boxShadow: INTERFACE_DROPSHADOW,
				}}
			>
				{children}
			</DropdownMenu.Content>
		)
	},
)

MenuContent.displayName = 'MenuContent'

export default MenuContent
