import { debounce } from 'lodash'
import { AnnotationEditorType, AnnotationMode, getDocument } from 'pdfjs-dist'
import { PDFViewerOptions } from 'pdfjs-dist/types/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'
import {
	EventBus,
	PDFLinkService,
	PDFScriptingManager,
	PDFViewer as PDFViewerComponent,
} from 'pdfjs-dist/web/pdf_viewer.mjs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { usePdfModifier } from '../../hooks/usePdfModifier'
import { configurePdfWorker } from '../../utils/pdfConfig'
import { useHighlightAnnotations } from './hooks/useHighlightAnnotations'
import { usePdfKeyboardNavigation } from './hooks/usePdfKeyboardNavigation'
import PDFOutline from './PDFOutline'
import PDFToolbar from './pdftoolbar/PDFToolbar'
import './PDFViewer.css'
import {
	PDFViewerProps,
	ScaleMode,
	ScaleModeType,
	SpreadMode,
	SpreadModeType,
	TextLayerMode,
} from './types/pdfViewerTypes'
import { useCustomImageAnnotations } from './useCustomImageAnnotations'
import useEventBusDebugger from './useEventBusDebugger'

configurePdfWorker()

const DEBUG = false

// UI Constants
const TRANSITION_DURATION = 200 // milliseconds
const RESCALE_DELAY = TRANSITION_DURATION + 50 // Wait for transition to complete before rescaling
const STORAGE_KEY_PREFIX = 'pdf-viewer-settings-'

interface PDFViewerSettings {
	outlineWidth: number
	showOutline: boolean
	spreadMode: SpreadModeType
}

// Add viewer preferences type
interface ViewerPreferences {
	FitWindow?: boolean
	CenterWindow?: boolean
	DisplayDocTitle?: boolean
	HideMenubar?: boolean
	HideToolbar?: boolean
	HideWindowUI?: boolean
	Direction?: 'L2R' | 'R2L'
	[key: string]: any // for any other properties we might encounter
}

export const PDFViewer = ({
	resource,
	page = '1',
	isGame = false,
	showTools = true,
	formValues,
	onFormChange,
	enableAnnotations = true,
	initialSpreadMode,
	initialScaleMode,
	annotationMode = AnnotationMode.ENABLE_FORMS | AnnotationMode.ENABLE_STORAGE,
	storePageInUrl = false,
	onClose,
}: PDFViewerProps) => {
	false && console.log('isGame', isGame)
	// Get a stable identifier for the resource
	const resourceIdentifier = useMemo(() => {
		if ('fileurl' in resource) {
			return resource.fileurl
		}
		return ''
	}, [resource])

	// Initialize settings
	const initialSettings = useMemo(() => {
		const savedSettings = localStorage.getItem(
			STORAGE_KEY_PREFIX + resourceIdentifier,
		)
		if (savedSettings) {
			try {
				const parsed = JSON.parse(savedSettings) as PDFViewerSettings
				return parsed
			} catch (e) {
				console.error('Failed to parse saved PDF viewer settings:', e)
			}
		}
		return {
			outlineWidth: 256,
			showOutline: false,
			spreadMode: initialSpreadMode ?? SpreadMode.ODD,
		}
	}, [resourceIdentifier, initialSpreadMode])

	const [outlineWidth, setOutlineWidth] = useState(initialSettings.outlineWidth)
	const [showOutline, setShowOutline] = useState(initialSettings.showOutline)
	const [spreadMode, setSpreadMode] = useState<SpreadModeType>(
		initialSettings.spreadMode,
	)

	// Current page is now only updated via the viewer's pagechanging event
	const [currentPage, setCurrentPage] = useState(() => {
		const urlPage = parseInt(page)
		if (!isNaN(urlPage) && urlPage > 0) {
			return urlPage
		}
		return 1
	})

	// Debounced URL update
	const updateURL = useMemo(
		() =>
			debounce((page: number) => {
				if (typeof window === 'undefined' || !page || !storePageInUrl) return
				try {
					const url = new URL(window.location.href)
					url.searchParams.set('page', page.toString())
					window.history.replaceState({}, '', url.toString())
				} catch (e) {
					console.error('Failed to update URL:', e)
				}
			}, 500),
		[storePageInUrl],
	)

	// Debounced localStorage update
	const updateLocalStorage = useMemo(
		() =>
			debounce((settings: PDFViewerSettings) => {
				if (!resourceIdentifier || typeof window === 'undefined') return
				localStorage.setItem(
					STORAGE_KEY_PREFIX + resourceIdentifier,
					JSON.stringify(settings),
				)
			}, 500),
		[resourceIdentifier],
	)

	useEffect(() => {
		updateURL(currentPage)
		return () => updateURL.cancel()
	}, [currentPage, updateURL])

	useEffect(() => {
		const settings: PDFViewerSettings = {
			outlineWidth,
			showOutline,
			spreadMode,
		}
		updateLocalStorage(settings)
		return () => updateLocalStorage.cancel()
	}, [outlineWidth, showOutline, spreadMode, updateLocalStorage])

	const { modifyPdfWithFormData } = usePdfModifier()
	const containerRef = useRef<HTMLDivElement>(null)
	const viewerRef = useRef<HTMLDivElement>(null)
	const pdfViewerRef = useRef<PDFViewerComponent | null>(null)
	const eventBusRef = useRef<EventBus | null>(null)
	const scriptingManagerRef = useRef<PDFScriptingManager | null>(null)
	const fieldMapRef = useRef<Map<string, string>>(new Map())
	const [pageCount, setPageCount] = useState(0)
	const [scale, setScale] = useState(1)
	const [scaleMode, setScaleMode] = useState<ScaleModeType>(
		initialScaleMode ?? ScaleMode.AUTO,
	)
	const [outline, setOutline] = useState<any[] | null>(null)
	const [error, setError] = useState<Error | null>(null)
	const isLoadingRef = useRef(false)
	const [isLoading, setIsLoading] = useState(true) // Add state for loading
	const [loadingProgress, setLoadingProgress] = useState(0)

	useEventBusDebugger(eventBusRef, isLoadingRef.current, DEBUG)

	const createFieldMap = async () => {
		const fieldMap = new Map<string, string>()
		const pdfDoc = pdfViewerRef.current?.pdfDocument
		if (!pdfDoc) return fieldMap

		for (let i = 1; i <= pdfDoc.numPages; i++) {
			const page = await pdfDoc.getPage(i)
			const annotations = await page.getAnnotations()
			annotations.forEach(annotation => {
				if (annotation.fieldName) {
					fieldMap.set(annotation.id, annotation.fieldName)
				}
			})
		}

		return fieldMap
	}

	const { isHighlighting, handleHighlightToggle } = useHighlightAnnotations(
		eventBusRef,
		pdfViewerRef,
		resourceIdentifier,
	)

	const loadPdf = async () => {
		if (isLoadingRef.current) return
		isLoadingRef.current = true
		setLoadingProgress(0)
		try {
			if (!containerRef.current || !viewerRef.current) return

			// Ensure we have a valid URL before proceeding
			if (!resource?.fileurl) {
				throw new Error('No file URL provided')
			}

			const finalPdfUrl = await modifyPdfWithFormData(
				resource.fileurl,
				formValues,
			)

			const eventBus = new EventBus()
			eventBusRef.current = eventBus

			const linkService = new PDFLinkService({ eventBus })

			const scriptingManager = new PDFScriptingManager({
				eventBus,
				sandboxBundleSrc: '/pdf.sandbox.min.mjs',
			})
			scriptingManagerRef.current = scriptingManager

			const viewerSettings: PDFViewerOptions = {
				container: containerRef.current,
				viewer: viewerRef.current,
				eventBus,
				linkService,
				scriptingManager,
				textLayerMode: TextLayerMode.ENABLED,
				annotationMode: annotationMode,
				annotationEditorMode: enableAnnotations
					? AnnotationEditorType.NONE
					: AnnotationEditorType.DISABLE,
				removePageBorders: true,
				enableHWA: true,
				annotationEditorHighlightColors: [
					'yellow=#ffeb3b',
					'green=#a5d6a7',
					'blue=#90caf9',
					'pink=#f48fb1',
				].join(','),
			}

			const viewer = new PDFViewerComponent(viewerSettings)
			pdfViewerRef.current = viewer
			linkService.setViewer(viewer)
			viewer.linkService = linkService

			// Debug all events
			eventBus.on('*', (evt: any) => {
				console.log('PDF Event:', evt.type, evt)
			})

			const loadingTask = getDocument({
				url: finalPdfUrl,
				rangeChunkSize: 65536, // 64KB chunks
				disableRange: false,
				disableStream: false,
			})

			loadingTask.onProgress = (data: { loaded: number; total: number }) => {
				const progress = data.total
					? Math.round((data.loaded / data.total) * 100)
					: 0
				setLoadingProgress(progress)
			}

			const pdfDocument = await loadingTask.promise

			// Wait for the first page to be rendered before hiding the loading state
			const pageRendered = new Promise<void>(resolve => {
				eventBus.on('pagerendered', () => resolve(), { once: true })
			})

			// Set up the document
			linkService.setDocument(pdfDocument)
			scriptingManager.setViewer(viewer)
			viewer.setDocument(pdfDocument)

			// Set up navigation handlers after document is loaded
			console.log('Setting up navigation handlers')

			const handleNavigate = (evt: any) => {
				console.log('Navigation event received:', evt)
				const pageNumber =
					evt.pageNumber ||
					evt.dest?.[0]?.num ||
					(evt.dest && typeof evt.dest === 'number' ? evt.dest : null)
				console.log('Extracted page number:', pageNumber)
				if (!pageNumber || !pdfViewerRef.current) return

				pdfViewerRef.current.currentPageNumber = pageNumber
			}

			// Listen for all possible navigation-related events
			eventBus.on('navigateto', handleNavigate)
			eventBus.on('linkclick', handleNavigate)
			eventBus.on('namedaction', (evt: any) => {
				console.log('Named action event:', evt)
			})
			eventBus.on('documentinit', () => {
				console.log('Document initialized')
			})
			eventBus.on('outlineloaded', (evt: any) => {
				console.log('Outline loaded:', evt)
			})
			eventBus.on('internallinksloaded', () => {
				console.log('Internal links loaded')
			})

			// Wait for both the pages promise and the first page render
			await Promise.all([viewer.pagesPromise, pageRendered])

			// Now it's safe to hide the loading state
			setIsLoading(false)
			isLoadingRef.current = false

			// Read all PDF viewer settings using proper API methods
			const [layout, viewerPrefs] = (await Promise.all([
				pdfDocument.getPageLayout().catch(() => null),
				pdfDocument.getViewerPreferences().catch(() => null),
			])) as [string | null, ViewerPreferences | null]

			// Apply spread mode in order of precedence:
			// 1. localStorage settings (if exists)
			// 2. Props (if provided)
			// 3. PDF layout (if exists)
			// 4. Default value
			const finalSpreadMode =
				spreadMode ?? // localStorage settings
				initialSpreadMode ?? // props
				(layout ? convertLayoutToSpreadMode(layout) : SpreadMode.ODD) // PDF layout or default

			// Apply scale mode in order of precedence:
			// 1. localStorage settings (if exists)
			// 2. Props (if provided)
			// 3. PDF preferences (if FitWindow is true)
			// 4. Default value
			const finalScaleMode =
				scaleMode ?? // localStorage settings
				initialScaleMode ?? // props
				(viewerPrefs?.FitWindow ? ScaleMode.PAGE_FIT : ScaleMode.AUTO) // PDF preferences or default

			viewer.spreadMode = finalSpreadMode
			viewer.currentScaleValue = finalScaleMode

			// Update the spread mode state to match the viewer
			setSpreadMode(finalSpreadMode)

			eventBus.on('pagechanging', evt => {
				setCurrentPage(evt.pageNumber)

				// Scroll to the new page
				requestAnimationFrame(() => {
					const pageView = pdfViewerRef.current?.getPageView(evt.pageNumber - 1)
					if (pageView?.div) {
						pageView.div.scrollIntoView({ block: 'start', behavior: 'auto' })
					}
				})
			})

			eventBus.on('annotationlayerrendered', async () => {
				try {
					fieldMapRef.current = await createFieldMap()
				} catch (error) {
					console.error('Error creating field map:', error)
				}
			})

			// Set initial page without calling setCurrentPage
			const pageNumber = parseInt(page)
			if (
				!isNaN(pageNumber) &&
				pageNumber > 0 &&
				pageNumber <= pdfDocument.numPages
			) {
				viewer.currentPageNumber = pageNumber
			}

			setPageCount(pdfDocument.numPages)

			try {
				const docOutline = await pdfDocument.getOutline()
				if (docOutline) {
					setOutline(docOutline)
				}
			} catch (error) {
				console.error('[PDFViewer] Error getting outline:', error)
			}

			eventBusRef.current.on('dispatcheventinsandbox', handleSandboxDispatch, {
				external: true,
			})
		} catch (error) {
			console.error('[PDFViewer] Error loading PDF:', error)
			setError(error instanceof Error ? error : new Error('Failed to load PDF'))
		} finally {
			isLoadingRef.current = false
			setIsLoading(false)
		}
	}

	// Helper function to convert PDF layout to spread mode
	function convertLayoutToSpreadMode(layout: string): SpreadModeType {
		switch (layout?.toLowerCase()) {
			case 'singlepage':
			case 'onecolumn':
				return SpreadMode.NONE
			case 'twocolumnleft':
			case 'twopageleft':
				return SpreadMode.ODD
			case 'twocolumnright':
			case 'twopageright':
				return SpreadMode.EVEN
			default:
				return SpreadMode.ODD
		}
	}

	useEffect(() => {
		loadPdf()

		// Cleanup function
		return () => {
			// Store a reference to eventBus before cleanup
			const eventBus = eventBusRef.current

			if (pdfViewerRef.current) {
				// Close the document and cleanup
				pdfViewerRef.current.cleanup()
				pdfViewerRef.current = null
			}

			if (eventBus) {
				// Remove all event listeners using the stored reference
				eventBus.off('dispatcheventinsandbox', handleSandboxDispatch)
				eventBus.off('annotationchange', handleAnnotationChange)
				eventBus.off('switchannotationeditormode', () => {})
				eventBusRef.current = null
			}

			if (scriptingManagerRef.current) {
				scriptingManagerRef.current = null
			}

			// Clear any remaining references
			fieldMapRef.current.clear()
		}
	}, [])

	const getAllFieldValues = async () => {
		const fieldValues = {}
		for (let i = 1; i <= pdfViewerRef.current?.pdfDocument?.numPages; i++) {
			const page = await pdfViewerRef.current?.pdfDocument?.getPage(i)
			const annotations = await page?.getAnnotations()
			annotations.forEach(annotation => {
				if (annotation.fieldName) {
					fieldValues[annotation.fieldName] = annotation.fieldValue
				}
			})
		}
		return fieldValues
	}

	const getAllAnnotations = async () => {
		const annotationStorage =
			pdfViewerRef.current?.pdfDocument?.annotationStorage.getAll()
		const changedValues = {}

		for (const [id, value] of Object.entries(annotationStorage)) {
			const fieldName = fieldMapRef.current.get(id)
			changedValues[fieldName] = value.value
		}

		return changedValues
	}

	const handleAnnotationChange = async () => {
		const fieldValues = await getAllFieldValues()
		const changedAnnotations = await getAllAnnotations()
		const allAnnotations = {
			...fieldValues,
			...changedAnnotations,
		}

		onFormChange?.(allAnnotations)
	}

	const handleSandboxDispatch = (event: any) => {
		if (!event.detail.willCommit) return
		handleAnnotationChange()
	}

	useEffect(() => {
		if (!pdfViewerRef.current || !eventBusRef.current) return
		const eventBus = eventBusRef.current
		eventBus.on('annotationchange', handleAnnotationChange)
		return () => {
			if (eventBus) {
				eventBus.off('annotationchange', handleAnnotationChange)
			}
		}
	}, [pdfViewerRef.current, eventBusRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current || !eventBusRef.current) return
		const eventBus = eventBusRef.current
		const handleEditorModeChange = (event: { mode: number }) => {
			if (!pdfViewerRef.current) return
			pdfViewerRef.current.annotationEditorMode = {
				mode: event.mode,
			}
		}

		eventBus.on('switchannotationeditormode', handleEditorModeChange)

		return () => {
			if (eventBus) {
				eventBus.off('switchannotationeditormode', handleEditorModeChange)
			}
		}
	}, [pdfViewerRef.current, eventBusRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		pdfViewerRef.current.currentScaleValue = scaleMode
	}, [scaleMode])

	const { findImageAnnotations, portals } = useCustomImageAnnotations(
		pdfViewerRef,
		handleAnnotationChange,
	)

	// When the user changes pages from the toolbar or keyboard navigation,
	// set the page on the viewer directly. The `pagechanging` event will update currentPage state.
	const handlePageChange = useCallback(
		(newPage: number, isAbsolute = false) => {
			if (!pdfViewerRef.current) return

			if (isAbsolute) {
				pdfViewerRef.current.currentPageNumber = newPage
				requestAnimationFrame(() => {
					const pageView = pdfViewerRef.current?.getPageView(newPage - 1)
					if (pageView?.div) {
						pageView.div.scrollIntoView({
							block: 'start',
							behavior: 'auto',
							inline: 'nearest',
						})
					}
				})
				return
			}

			const currentPageNum = pdfViewerRef.current.currentPageNumber
			const direction = newPage > currentPageNum ? 'next' : 'prev'

			if (direction === 'next') {
				pdfViewerRef.current.nextPage()
			} else {
				pdfViewerRef.current.previousPage()
			}

			requestAnimationFrame(() => {
				const targetPage = pdfViewerRef.current?.currentPageNumber
				if (targetPage) {
					const pageView = pdfViewerRef.current?.getPageView(targetPage - 1)
					if (pageView?.div) {
						pageView.div.scrollIntoView({
							block: 'start',
							behavior: 'auto',
							inline: 'nearest',
						})
					}
				}
			})
		},
		[],
	)

	const handleZoomIn = () => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		const newScale = viewer.currentScale * 1.1
		viewer.currentScale = Math.min(newScale, 10.0)
		setScale(viewer.currentScale)
	}

	const handleZoomOut = () => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		const newScale = viewer.currentScale / 1.1
		viewer.currentScale = Math.max(newScale, 0.1)
		setScale(viewer.currentScale)
	}

	usePdfKeyboardNavigation({
		currentPage,
		pageCount,
		spreadMode,
		handlePageChange,
		handleZoomIn,
		handleZoomOut,
		setScaleMode,
		setShowOutline,
		showOutline,
	})

	useEffect(() => {
		if (!enableAnnotations) return
		if (isLoadingRef.current) return
		findImageAnnotations()
	}, [findImageAnnotations, isLoadingRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		eventBusRef.current.on('annotationchange', handleAnnotationChange)
		return () => {
			if (eventBusRef.current) {
				eventBusRef.current.off('annotationchange', handleAnnotationChange)
			}
		}
	}, [pdfViewerRef.current, eventBusRef.current])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		viewer.spreadMode = spreadMode
		return () => {
			// No cleanup needed for this effect
		}
	}, [spreadMode])

	useEffect(() => {
		if (!containerRef.current || !pdfViewerRef.current) return
		const viewer = pdfViewerRef.current

		const debouncedResize = debounce(() => {
			if (!viewer) return
			requestAnimationFrame(() => {
				if (viewer) {
					viewer.currentScaleValue = scaleMode
				}
			})
		}, 100)

		const observer = new ResizeObserver(debouncedResize)
		observer.observe(containerRef.current)

		return () => {
			debouncedResize.cancel()
			observer.disconnect()
		}
	}, [containerRef.current, pdfViewerRef.current, scaleMode])

	useEffect(() => {
		if (!pdfViewerRef.current) return
		const viewer = pdfViewerRef.current
		const timer = setTimeout(() => {
			if (viewer) {
				viewer.currentScaleValue = scaleMode
			}
		}, RESCALE_DELAY)

		return () => clearTimeout(timer)
	}, [showOutline, scaleMode, outlineWidth])

	const handleClearAnnotations = useCallback(() => {
		// Remove viewer settings from localStorage
		localStorage.removeItem(STORAGE_KEY_PREFIX + resourceIdentifier)
		// Remove annotations from localStorage
		localStorage.removeItem('pdf_annotations_' + resourceIdentifier)
		// Refresh the whole browser
		window.location.reload()
	}, [resourceIdentifier])

	return (
		<div className='pdfViewer absolute inset-0 flex flex-col'>
			{error && (
				<div className='absolute inset-0 flex items-center justify-center bg-white/50'>
					<div className='text-lg text-red-600'>{error.message}</div>
				</div>
			)}
			{showTools && (
				<PDFToolbar
					pageNumber={currentPage}
					pagesCount={pageCount}
					scale={scale}
					scaleMode={scaleMode}
					spreadMode={spreadMode}
					onPageChange={handlePageChange}
					onZoomIn={handleZoomIn}
					onZoomOut={handleZoomOut}
					onScaleModeChange={setScaleMode}
					onSpreadModeChange={setSpreadMode}
					onOutlineToggle={() => setShowOutline(!showOutline)}
					showOutline={showOutline}
					eventBus={eventBusRef.current}
					title={resource.metadata?.title}
					isGame={isGame}
					isLoading={isLoading}
					loadingProgress={loadingProgress}
					onClose={onClose}
					isHighlighting={isHighlighting}
					onHighlightToggle={handleHighlightToggle}
					onClearAnnotations={handleClearAnnotations}
				/>
			)}
			{showOutline && outline && (
				<PDFOutline
					outline={outline}
					pdfViewer={pdfViewerRef.current}
					onPageChange={handlePageChange}
					currentPage={currentPage}
					onWidthChange={setOutlineWidth}
					onRequestHide={() => setShowOutline(false)}
					resourceId={resourceIdentifier}
					width={outlineWidth}
				/>
			)}
			<div
				ref={containerRef}
				className={twMerge(
					'pdfContainer absolute inset-0 overflow-auto transition-[left]',
					showTools && 'top-16',
					`duration-${TRANSITION_DURATION} ease-out`,
				)}
				style={{ left: showOutline && showTools ? `${outlineWidth}px` : 0 }}
			>
				<div ref={viewerRef} className='pdfViewer' />
			</div>
			{portals}
		</div>
	)
}
export default PDFViewer
