import { yupResolver } from '@hookform/resolvers/yup'
import { useContext } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import request from 'superagent'
import * as yup from 'yup'
import AuthContext from '../../contexts/auth'
import { UserLevel } from '../../interfaces/auth'
import {
	FormButton,
	FormError,
	FormInput,
	FormLabel,
} from '../FormComponents/auth'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	email: string
	password: string
}

const schema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required()

const AuthPage = () => {
	const navigate = useNavigate()
	const { authState, authDispatch } = useContext(AuthContext)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const loginHandler = (data: FieldValues) => {
		const { email, password } = data

		request
			.post('/api/login')
			.send({
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					alert('Something went wrong.')
				}
				if (res.status === 401) {
					alert('Signing in failed.')
				}
				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)

				authDispatch({
					type: 'UPDATE_ALL',
					payload: {
						userId: res.body.user._id,
						token: res.body.token,
						userDetails: {
							name: res.body.user.name,
							avatar: res.body.user.avatar || '',
							email: res.body.user.email,
							userLevel: res.body.user.userLevel || UserLevel.ADVENTURER,
						},
					},
				})

				navigate('/profile')
			})
			.catch(error => {
				alert(error)
				console.log('sign in error', error)
			})
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Sign In' className='text-center' />
			<div className='mb-12 text-center'>
				<NavLink
					to='/signup'
					className='text-gray-400 underline hover:text-gray-300'
				>
					Sign Up Instead?
				</NavLink>
			</div>

			<form
				onSubmit={handleSubmit(loginHandler)}
				className='mx-auto max-w-sm space-y-6'
			>
				<div>
					<FormLabel htmlFor='email'>Email</FormLabel>
					<FormInput
						type='email'
						id='email'
						placeholder='Email address...'
						{...register('email', { required: true })}
					/>
					<FormError message={errors.email?.message} />
				</div>

				<div>
					<FormLabel htmlFor='password'>Password</FormLabel>
					<FormInput
						type='password'
						id='password'
						placeholder='Password...'
						{...register('password', { required: true })}
					/>
					<FormError message={errors.password?.message} />
				</div>

				<div className='flex items-center justify-between'>
					<div className='text-sm'>
						<Link
							to='/forgot-password'
							className='font-medium text-gray-400 hover:text-gray-300'
						>
							Forgot password?
						</Link>
					</div>
				</div>

				<FormButton type='submit'>Sign In</FormButton>
			</form>
		</Card>
	)
}

export default AuthPage
