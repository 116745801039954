import { animated, useTransition } from '@react-spring/web'
import { useRef } from 'react'
import Toolbar from '../interface/toolbar/Toolbar'
import ClearDrawingsButton from './ClearDrawingsButton'
import RedoButton from './RedoButton'
import UndoButton from './UndoButton'

export default function DrawTools() {
	const clearDrawingsAnchorRef = useRef<HTMLButtonElement>(null)

	const drawingTools = [
		{ key: 'undo', Component: UndoButton },
		{ key: 'redo', Component: RedoButton },
		{
			key: 'clear',
			Component: ClearDrawingsButton,
			ref: clearDrawingsAnchorRef,
		},
	]

	const transitions = useTransition(drawingTools, {
		keys: item => item.key,
		from: { width: 0, scale: 0, opacity: 0 },
		enter: { width: 58, scale: 1, opacity: 1 },
		leave: { width: 0, scale: 0, opacity: 0 },
		trail: 100,
		config: { tension: 300, friction: 20 },
	})

	return (
		<div className='pointer-events-auto'>
			<Toolbar>
				{transitions((style, item) => (
					<animated.div
						style={{
							width: style.width,
							overflow: 'hidden',
						}}
					>
						<animated.div
							style={{
								transform: style.scale.to(s => `scale(${s})`),
								opacity: style.opacity,
								width: 58, // Fixed width for the button
								height: 58, // Fixed height for the button
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<item.Component ref={item.ref} />
						</animated.div>
					</animated.div>
				))}
			</Toolbar>
		</div>
	)
}
