import { animated, useTransition } from '@react-spring/web'
import Toolbar from '../interface/toolbar/Toolbar'
import FogAllButton from './FogAllButton'
import FogNoneButton from './FogNoneButton'
import FogPolygonAddButton from './FogPolygonAddButton'
import FogPolygonRemoveButton from './FogPolygonRemoveButton'
import RedoButton from './RedoButton'
import UndoButton from './UndoButton'

export default function FogTools() {
	const fogTools = [
		{ key: 'undo', Component: UndoButton },
		{ key: 'redo', Component: RedoButton },
		{ key: 'polygonAdd', Component: FogPolygonAddButton },
		{ key: 'polygonRemove', Component: FogPolygonRemoveButton },
		{ key: 'fogAll', Component: FogAllButton },
		{ key: 'fogNone', Component: FogNoneButton },
	]

	const transitions = useTransition(fogTools, {
		keys: item => item.key,
		from: { width: 0, scale: 0, opacity: 0 },
		enter: { width: 58, scale: 1, opacity: 1 },
		leave: { width: 0, scale: 0, opacity: 0 },
		trail: 100,
		config: { tension: 300, friction: 20 },
	})

	return (
		<div className='pointer-events-auto'>
			<Toolbar>
				{transitions((style, item) => (
					<animated.div
						style={{
							width: style.width,
							overflow: 'hidden',
						}}
					>
						<animated.div
							style={{
								transform: style.scale.to(s => `scale(${s})`),
								opacity: style.opacity,
								width: 58, // Fixed width for the button
								height: 58, // Fixed height for the button
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<item.Component />
						</animated.div>
					</animated.div>
				))}
			</Toolbar>
		</div>
	)
}
