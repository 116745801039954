import Slider from '../../../interface/Slider'

interface MapGridSizeProps {
	size: number
	onChange: (size: number) => void
}

const MapGridSize = ({ size, onChange }: MapGridSizeProps) => {
	return (
		<div className='center flex align-middle'>
			<Slider
				value={size}
				onChange={onChange}
				label='Size'
				min={0.1}
				max={10}
				step={0.01}
				className='w-full'
			/>
		</div>
	)
}

export default MapGridSize
