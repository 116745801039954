import { useQuery } from '@apollo/client'
import { useContext, useEffect, useRef, useState } from 'react'
import { IGameCard } from '../../../../shared/types/game'
import MetaTags from '../../components/MetaTags'
import AuthContext from '../../contexts/auth'
import { GAMES_BY_USER } from '../../graphql/games'
import NewGameModal from '../modals/NewGameModal'
import ButtonText from './elements/ButtonText'
import Card from './elements/Card'
import GamesList from './sections/GamesList'
import PageHeader from './sections/PageHeader'

interface GamesByUserResponse {
	gamesByUser: {
		_id: string
		name: string
		email: string
		avatar: string
		createdGames: IGameCard[]
		joinedGames: IGameCard[]
	}
}

const ProfilePage = () => {
	const { authState } = useContext(AuthContext)
	const { name } = authState.userDetails
	const { loading, data, refetch } =
		useQuery<GamesByUserResponse>(GAMES_BY_USER)
	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const [isNewGameModalOpen, setIsNewGameModalOpen] = useState(false)

	useEffect(() => {
		refetch()

		intervalRef.current = setInterval(refetch, 3000)

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current)
			}
		}
	}, [authState, refetch])

	const allGames = data?.gamesByUser
		? [...data.gamesByUser.createdGames, ...data.gamesByUser.joinedGames]
		: []

	return (
		<>
			<MetaTags
				title={`${name}'s Profile`}
				description={`${name}'s profile on Realms Inc`}
				type='profile'
			/>
			{data && (
				<Card className='mt-0' innerClassName='max-w-7xl'>
					<PageHeader title='Games' className='mb-0'>
						<ButtonText
							className='flex-1 text-right'
							onClick={() => setIsNewGameModalOpen(true)}
						>
							Create Game
						</ButtonText>
					</PageHeader>

					<GamesList
						games={allGames}
						createdGameIds={data.gamesByUser.createdGames.map(
							(g: any) => g._id,
						)}
						refetch={refetch}
						loading={loading}
					/>
				</Card>
			)}

			<NewGameModal
				isOpen={isNewGameModalOpen}
				onClose={() => setIsNewGameModalOpen(false)}
			/>
		</>
	)
}

export default ProfilePage
