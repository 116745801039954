import { ISavedWindow } from '../../../../shared/types/game'
import { MIN_WINDOW_HEIGHT, MIN_WINDOW_WIDTH } from './windowConfig'

export const fitWindowToViewport = (
	windowRect: ISavedWindow,
	margin = 20,
): ISavedWindow => {
	const newWindowRect = { ...windowRect }
	const viewportWidth = window.innerWidth
	const viewportHeight = window.innerHeight

	if (newWindowRect.x + newWindowRect.width > viewportWidth - margin) {
		newWindowRect.width = viewportWidth - newWindowRect.x - margin
	}

	if (newWindowRect.y + newWindowRect.height > viewportHeight - margin) {
		newWindowRect.height = viewportHeight - newWindowRect.y - margin
	}

	newWindowRect.x = Math.min(
		newWindowRect.x,
		viewportWidth - newWindowRect.width - margin,
	)

	newWindowRect.y = Math.min(
		newWindowRect.y,
		viewportHeight - newWindowRect.height - margin,
	)

	return newWindowRect
}

export const clampDraggedWindow = (
	newX: number,
	newY: number,
	newW: number,
	newH: number,
	margin = 100,
): { x: number; y: number; width: number; height: number } => {
	if (newX + newW < margin) {
		newX = -(newW - margin)
	}
	if (newX > window.innerWidth - margin) {
		newX = window.innerWidth - margin
	}
	if (newY > window.innerHeight - margin) {
		newY = window.innerHeight - margin
	}

	return {
		x: newX,
		y: Math.max(0, newY),
		width: Math.max(newW, MIN_WINDOW_WIDTH),
		height: Math.max(newH, MIN_WINDOW_HEIGHT),
	}
}

export const snapWindowToEdges = (
	x: number,
	y: number,
	width: number,
	snapThreshold = 10,
	isMoving = false,
): { x: number } => {
	const winWidth = window.innerWidth
	const rightEdge = x + width

	// Snap to left edge
	if (x >= -snapThreshold && x <= snapThreshold) {
		return { x: 0 }
	}

	// Snap to right edge (when moving)
	if (
		isMoving &&
		rightEdge >= winWidth - snapThreshold &&
		rightEdge <= winWidth + snapThreshold
	) {
		return { x: winWidth - width }
	}

	return { x }
}
