import { Container, Graphics } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext, useEffect, useState } from 'react'
import { FogOperationType, IFogPolygon } from '../../../../shared/types/fog'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import { generateUUID } from '../../utils/generateUUID'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../interface/constants'

// Convert hex color to number for PIXI
const GOLD_COLOR = parseInt(INTERFACE_BRIGHT_GOLD_COLOR.replace('#', '0x'))

interface FogEditorProps {
	viewport: PixiViewport
}

const FogEditor = ({ viewport }: FogEditorProps) => {
	const [points, setPoints] = useState<{ x: number; y: number }[]>([])
	const { toolsState } = useContext(ToolsContext)
	const { dispatch } = useContext(GameContext)
	const scene = useGetActiveScene()
	const { userId } = useUser()
	const [lastClickTime, setLastClickTime] = useState(0)
	const [mousePos, setMousePos] = useState<{ x: number; y: number } | null>(
		null,
	)
	const [dragStartPos, setDragStartPos] = useState<{
		x: number
		y: number
	} | null>(null)
	const DOUBLE_CLICK_TIME = 300 // ms between clicks to count as double-click
	const SCREEN_SNAP_DISTANCE = 20 // Increased from 10 to 20 pixels
	const VERTEX_RADIUS = 3 // Base radius for vertex points
	const DRAG_THRESHOLD = 10 // Screen pixels to consider a drag

	// Debug logging for tool state changes
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				setPoints([])
				setMousePos(null)
				setDragStartPos(null)
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			console.log('FogEditor unmounting - fog mode turned off')
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [toolsState.mode, toolsState.fog.tool])

	// Convert fog tool to operation type
	const operationType: FogOperationType =
		toolsState.fog.tool === 'polygonAdd' ? 'hide' : 'show'

	const getScaledSnapDistance = useCallback(() => {
		return SCREEN_SNAP_DISTANCE / viewport.scale.x
	}, [viewport.scale.x])

	const getScaledVertexRadius = useCallback(() => {
		return VERTEX_RADIUS / viewport.scale.x
	}, [viewport.scale.x])

	const isNearFirstPoint = useCallback(
		(pos: { x: number; y: number }) => {
			if (points.length === 0) return false
			const firstPoint = points[0]
			const dx = pos.x - firstPoint.x
			const dy = pos.y - firstPoint.y
			return Math.sqrt(dx * dx + dy * dy) < getScaledSnapDistance()
		},
		[points, getScaledSnapDistance],
	)

	const handlePointerMove = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			const worldPos = viewport.toWorld(e.global)

			setMousePos(worldPos)

			// Check for drag beyond threshold
			if (dragStartPos) {
				const dx = e.global.x - dragStartPos.x
				const dy = e.global.y - dragStartPos.y
				const dragDistance = Math.sqrt(dx * dx + dy * dy)

				if (dragDistance > DRAG_THRESHOLD) {
					// Remove the last point if we have any
					if (points.length > 0) {
						console.log('Drag threshold exceeded, removing last point')
						setPoints(prev => prev.slice(0, -1))
					}
					setDragStartPos(null)
				}
			}
		},
		[viewport, dragStartPos, points.length],
	)

	const completePolygon = useCallback(() => {
		if (points.length >= 3 && scene) {
			// Create new fog polygon
			const newOperation: IFogPolygon = {
				_id: generateUUID(),
				type: operationType,
				points: [...points], // Points are already in the correct format
				userId,
			}

			// Get current fog operations or initialize empty array
			const currentFog = Array.isArray(scene.values.fog) ? scene.values.fog : []

			console.log('Saving operation:', {
				type: operationType,
				points: points.map(p => ({ x: p.x.toFixed(2), y: p.y.toFixed(2) })),
			})

			// Dispatch update
			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...scene,
						version: scene.version + 1,
						values: {
							...scene.values,
							fog: [...currentFog, newOperation],
						},
					},
				},
			})

			// Clear current polygon
			setPoints([])
			setMousePos(null)
		}
	}, [points, operationType, scene, dispatch, userId])

	const handlePointerDown = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			// Store drag start position for left clicks
			if (e.button === 0) {
				setDragStartPos({ x: e.global.x, y: e.global.y })
			}

			// Right click completes the polygon
			if (e.button === 2) {
				if (points.length >= 3) {
					completePolygon()
				}
				// Don't stop propagation, let the event bubble up to close fog mode
				return
			}

			// Left click adds points or completes on double-click
			if (e.button === 0) {
				const now = Date.now()
				const worldPos = viewport.toWorld(e.global)
				console.log('Click:', {
					x: worldPos.x.toFixed(2),
					y: worldPos.y.toFixed(2),
					scale: viewport.scale.x.toFixed(2),
					pointCount: points.length,
				})

				if (points.length >= 2 && isNearFirstPoint(worldPos)) {
					completePolygon()
					return
				}

				if (now - lastClickTime < DOUBLE_CLICK_TIME) {
					// Double click detected
					completePolygon()
					setLastClickTime(0) // Reset timer
					return
				}
				setLastClickTime(now)

				const newPoint = { x: worldPos.x, y: worldPos.y }
				setPoints(prev => [...prev, newPoint])
			}
		},
		[
			viewport,
			operationType,
			points,
			lastClickTime,
			completePolygon,
			isNearFirstPoint,
		],
	)

	const handlePointerUp = useCallback(() => {
		setDragStartPos(null)
	}, [points])

	return (
		<Container
			eventMode='static'
			pointerdown={handlePointerDown}
			pointermove={handlePointerMove}
			pointerup={handlePointerUp}
			hitArea={
				new PIXI.Rectangle(
					-viewport.worldWidth / 4,
					-viewport.worldHeight / 4,
					viewport.worldWidth / 2,
					viewport.worldHeight / 2,
				)
			}
		>
			<Graphics
				draw={g => {
					g.clear()

					// Draw visible border around hit area
					g.lineStyle(1 / viewport.scale.x, 0x666666, 0.5)
					g.drawRect(
						-viewport.worldWidth / 4,
						-viewport.worldHeight / 4,
						viewport.worldWidth / 2,
						viewport.worldHeight / 2,
					)

					// Draw invisible rectangle for hit testing
					g.beginFill(0x000000, 0.0001)
					g.drawRect(
						-viewport.worldWidth / 4,
						-viewport.worldHeight / 4,
						viewport.worldWidth / 2,
						viewport.worldHeight / 2,
					)
					g.endFill()

					g.lineStyle(2 / viewport.scale.x, GOLD_COLOR)

					// Draw the polygon lines
					if (points.length > 0) {
						// Fill with translucent black if we have a valid polygon
						if (points.length >= 3) {
							g.beginFill(0x000000, 0.5)
							g.moveTo(points[0].x, points[0].y)
							points.forEach(point => {
								g.lineTo(point.x, point.y)
							})
							if (mousePos) {
								g.lineTo(mousePos.x, mousePos.y)
								if (isNearFirstPoint(mousePos)) {
									g.lineTo(points[0].x, points[0].y)
								}
							}
							g.endFill()
						}

						// Draw the lines
						g.moveTo(points[0].x, points[0].y)
						points.forEach((point, i) => {
							if (i > 0) {
								g.lineTo(point.x, point.y)
							}
						})
						// Draw line to mouse position when drawing
						if (mousePos) {
							g.lineTo(mousePos.x, mousePos.y)
							// Close the polygon preview if near first point
							if (points.length >= 2 && isNearFirstPoint(mousePos)) {
								g.lineTo(points[0].x, points[0].y)
							}
						}
					}

					// Draw points
					points.forEach((point, index) => {
						// Draw larger snap zone for first point when we have enough points
						if (index === 0 && points.length >= 2) {
							g.lineStyle(2 / viewport.scale.x, GOLD_COLOR, 0.5)
							g.drawCircle(point.x, point.y, getScaledSnapDistance())
						}

						g.beginFill(GOLD_COLOR)
						g.drawCircle(point.x, point.y, getScaledVertexRadius())
						g.endFill()
					})
				}}
			/>
		</Container>
	)
}

export default FogEditor
