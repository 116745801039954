import ObjectID from 'bson-objectid'
import { useContext } from 'react'
import { IDocument } from '../../../shared/types/document'
import { LibraryToken } from '../../../shared/types/tokenLibrary'
import GameContext from '../contexts/game'
import useAssetUploader from './useAssetUploader'
import useUser from './useUser'

const useTokenToDocument = () => {
	const { game } = useContext(GameContext)
	const assetUploader = useAssetUploader()
	const { userId } = useUser()

	const createDocument = async (token: LibraryToken): Promise<IDocument> => {
		// Create a new asset from the token image
		const response = await fetch(token.imageUrl)
		const blob = await response.blob()
		const file = new File([blob], `${token.name}.png`, { type: 'image/png' })
		const asset = await assetUploader(file, game._id)

		return {
			_id: ObjectID().toHexString(),
			type: 'actor',
			version: 1,
			values: {
				name: token.name,
				tokenId: token.id,
				relationship: token.relationship,
				token: asset._id,
				locations: [],
			},
			access: 'private',
			accessList: [],
			parentId: null,
			creator: userId,
		}
	}

	return { createDocument }
}

export default useTokenToDocument
