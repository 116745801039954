import {
	EventBus,
	PDFViewer as PDFViewerComponent,
} from 'pdfjs-dist/web/pdf_viewer.mjs'
import { MutableRefObject, useCallback, useEffect } from 'react'
import {
	AnnotationEventHandlers,
	AnnotationOperations,
	PDFAnnotation,
} from '../types/annotations'
const STORAGE_KEY_PREFIX = 'pdf_annotations_'

export function useAnnotations(
	eventBusRef: MutableRefObject<EventBus | null>,
	pdfViewerRef: MutableRefObject<PDFViewerComponent | null>,
	documentId: string,
): AnnotationOperations {
	const storageKey = `${STORAGE_KEY_PREFIX}${documentId}`

	// Load annotations from storage
	const loadFromStorage = useCallback(async () => {
		try {
			const stored = localStorage.getItem(storageKey)
			if (!stored) return

			console.log('🎨 Loading annotations from storage:', stored)

			const viewer = pdfViewerRef.current
			if (!viewer?.pdfDocument) {
				console.warn('PDF document not ready')
				return
			}

			console.log('Debug - Before loading:', {
				storageSize: viewer.pdfDocument.annotationStorage.size,
				hasSerializableMap:
					!!viewer.pdfDocument.annotationStorage.serializable.map,
			})

			const { annotations } = JSON.parse(stored)
			viewer.pdfDocument.annotationStorage.setAll(annotations)

			console.log('Debug - After loading:', {
				storageSize: viewer.pdfDocument.annotationStorage.size,
				hasSerializableMap:
					!!viewer.pdfDocument.annotationStorage.serializable.map,
			})

			// Wait for pages to be ready
			await new Promise<void>(resolve => {
				if (viewer.pagesPromise) {
					viewer.pagesPromise.then(() => resolve())
				} else {
					resolve()
				}
			})

			// Wait for annotation layers to be ready
			const pageCount = viewer.pdfDocument.numPages
			await new Promise<void>(resolve => {
				const checkLayers = () => {
					const allLayersReady = Array.from({ length: pageCount }, (_, i) => {
						const pageView = viewer.getPageView(i)
						return (pageView as any)?.annotationLayer?.div != null
					}).every(Boolean)

					if (allLayersReady) {
						resolve()
					} else {
						setTimeout(checkLayers, 100)
					}
				}
				checkLayers()
			})

			// Force update to display restored annotations
			const typedViewer = viewer
			typedViewer.update()
		} catch (error) {
			console.error(
				'Failed to load annotations:',
				error instanceof Error ? error.message : String(error),
			)
		}
	}, [storageKey, pdfViewerRef])

	// Save annotations to storage
	const saveToStorage = async () => {
		try {
			await new Promise(resolve => setTimeout(resolve, 1000))

			const viewer = pdfViewerRef.current
			if (!viewer?.pdfDocument?.annotationStorage) return

			const { map, hash } = viewer.pdfDocument.annotationStorage.serializable
			console.log('Debug - serializable state:', {
				hasMap: !!map,
				hash,
				mapSize: map ? map.size : 0,
			})

			if (!map) {
				console.log(
					'Debug - full annotation storage:',
					viewer.pdfDocument.annotationStorage,
				)
				return
			}

			const serialized = {
				annotations: Object.fromEntries(map.entries()),
				hash,
			}

			console.log('🎨 Saving annotations to storage:', serialized.annotations)

			localStorage.setItem(storageKey, JSON.stringify(serialized))

			// localStorage.setItem(storageKey, JSON.stringify(annotations))
		} catch (error) {
			console.error('Failed to save annotations:', error)
		}
	}

	// Serialize all annotations
	const serialize = useCallback(async () => {
		const viewer = pdfViewerRef.current
		if (!viewer?.pdfDocument?.annotationStorage) return null

		// Get all annotations from storage
		const annotationStorage = viewer.pdfDocument.annotationStorage.getAll()
		if (!annotationStorage) return null

		// Convert storage data to a more usable format
		const serialized = Object.entries(annotationStorage).reduce(
			(acc, [key, value]) => {
				acc[key] = {
					id: key,
					...value,
				} as PDFAnnotation
				return acc
			},
			{} as Record<string, PDFAnnotation>,
		)

		return serialized
	}, [pdfViewerRef])

	// Set up event listeners for annotation changes and load initial annotations
	useEffect(() => {
		const eventBus = eventBusRef.current
		const viewer = pdfViewerRef.current
		if (!eventBus || !viewer) return

		// Load initial annotations when pages are ready
		const handlePagesReady = () => {
			if (viewer.pdfDocument) {
				loadFromStorage()
			}
		}

		// Try loading immediately if document is already ready
		if (viewer.pdfDocument) {
			loadFromStorage()
		}

		eventBus.on('pagesloaded', handlePagesReady)

		const handlers: AnnotationEventHandlers = {
			annotationeditorstateschanged: () => {
				console.log('Debug - editor states changed:', {
					storageSize:
						pdfViewerRef.current?.pdfDocument?.annotationStorage.size,
					hasSerializableMap:
						!!pdfViewerRef.current?.pdfDocument?.annotationStorage.serializable
							.map,
				})
				saveToStorage()
			},
			annotationeditorparamschanged: event => {
				console.log('🎨 Annotation editor params changed')
				if (event.details?.length > 0) {
					saveToStorage()
				}
			},
			annotationupdated: () => {
				console.log('🎨 Annotation updated')
				saveToStorage()
			},
			switchannotationeditormode: () => {},
			switchannotationeditorparams: () => {},
		}

		// Add all event listeners
		Object.entries(handlers).forEach(([event, handler]) => {
			eventBus.on(event as keyof AnnotationEventHandlers, handler)
		})

		// Cleanup
		return () => {
			eventBus.off('pagesloaded', handlePagesReady)
			Object.entries(handlers).forEach(([event, handler]) => {
				eventBus.off(event as keyof AnnotationEventHandlers, handler)
			})
		}
	}, [eventBusRef.current, pdfViewerRef.current, loadFromStorage])

	return {
		serialize,
		deserialize: async () => {}, // Not needed anymore since we use setAll
		copyToClipboard: async () => {}, // TODO: Implement these operations
		pasteFromClipboard: async () => {},
		undo: async () => {},
		redo: async () => {},
	}
}
