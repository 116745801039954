import ObjectID from 'bson-objectid'
import { useContext } from 'react'
import { IMessage } from '../../../shared/types/log'
import AuthContext from '../contexts/auth'
import EventContext from '../contexts/event'
import GameContext from '../contexts/game'
import { useNotifications } from '../contexts/notifications'
import UsersContext from '../contexts/users'

const useMessageHandler = () => {
	const { game, dispatch } = useContext(GameContext)
	const { dispatchEvent } = useContext(EventContext)
	const { authState } = useContext(AuthContext)
	const { usersState } = useContext(UsersContext)
	const { addMessage } = useNotifications()

	const handleRollMessage = (msg: string) => {
		const notation = msg.replace(/^\/r\s+|^\/roll\s+/, '').trim()
		if (!notation) return

		dispatchEvent({
			type: 'ADD_EVENT',
			payload: {
				_id: ObjectID().toHexString(),
				timestamp: new Date(),
				name: 'roll',
				data: notation,
			},
		})
	}

	const handleNewMessage = (msg: string) => {
		console.log('[MessageHandler] Starting to handle new message:', msg)
		if (!msg) return

		try {
			const isRoll = msg.startsWith('/roll') || msg.startsWith('/r')
			const userId = authState.userId

			if (isRoll) {
				handleRollMessage(msg)
				return
			}

			const { assumedCharacterId } = game.userSettings[userId]
			const user = usersState.users.find(u => u.userId === userId)
			if (!user) throw new Error('User not found')

			const { name, avatar } = user?.userProfile || {}
			console.log('[MessageHandler] User info:', {
				name,
				userId,
				assumedCharacterId,
			})

			// Find the asset ID that corresponds to the avatar URL
			let fallbackAvatarId = null
			if (avatar) {
				const assetEntry = Object.entries(game.assets.byId).find(
					([_, asset]) => asset.fileurl === avatar,
				)
				if (assetEntry) {
					fallbackAvatarId = assetEntry[0] // The asset ID
				}
			}

			const now = Date.now()
			const payload: IMessage = {
				_id: ObjectID().toHexString(),
				sender: userId,
				type: 'message',
				message: msg.trimEnd(),
				access: 'public',
				accessList: [],
				assumedCharacterId: assumedCharacterId,
				fallbackName: name,
				fallbackAvatarId,
				createdAt: now,
				updatedAt: now,
			}

			console.log('[MessageHandler] Created message payload:', payload)

			dispatch({
				type: 'ADD_MESSAGE',
				payload,
			})

			// Show notification if chat panel is closed
			console.log(
				'[MessageHandler] Message added to game state, preparing notification:',
				{
					message: payload.message,
					sender: name,
					assumedCharacterId,
					timestamp: new Date(now).toISOString(),
				},
			)

			const notificationText = name
				? `${name}: ${payload.message}`
				: payload.message
			console.log(
				'[MessageHandler] Attempting to add notification:',
				notificationText,
			)
			addMessage(notificationText)
			console.log('[MessageHandler] Notification added')
		} catch (error) {
			console.error('Error handling new message:', error)
		}
	}

	return handleNewMessage
}

export default useMessageHandler
