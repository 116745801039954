import * as PIXI from 'pixi.js'
import { useGrid } from '../contexts/grid'

export function useSnapToGrid() {
	const { grid, squareGrid, hexGrid, gridDimensions } = useGrid()

	return (x: number, y: number) => {
		if (!grid.enabled) return { x, y }

		// adjust input for grid being 3x map (to allow offsetting)
		const offsetX = gridDimensions.width / 3 - grid.x
		const offsetY = gridDimensions.height / 3 - grid.y
		const localX = x + offsetX
		const localY = y + offsetY

		if (grid.type === 'square') {
			const square = squareGrid?.pointToSquare(new PIXI.Point(localX, localY))
			if (!square) return { x, y }
			// convert back to map coordinates
			return {
				x: square.x - offsetX,
				y: square.y - offsetY,
			}
		} else {
			if (!hexGrid) {
				return { x, y }
			}

			const point = { x: localX, y: localY }
			const nearestHex = hexGrid.pointToHex(point)
			if (!nearestHex) {
				return { x, y }
			}

			return {
				x: nearestHex.x - offsetX,
				y: nearestHex.y - offsetY,
			}
		}
	}
}
