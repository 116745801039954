import ExitButton from '../ExitButton'
import FeedbackButton from '../FeedbackButton'
import SettingsButton from '../toolbar/SettingsButton'
import Toolbar from './toolbar/Toolbar'

const LeftTools = () => {
	return (
		<Toolbar>
			<ExitButton />
			<SettingsButton />
			<FeedbackButton />
		</Toolbar>
	)
}

export default LeftTools
