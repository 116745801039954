import React, { forwardRef, useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { IDocument } from '../../../../shared/types/document'
import GameContext from '../../contexts/game'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import Menu from '../documentmenu/Menu'
import BaseType from '../interface/text/BaseType'
import AccessDots from './AccessDots'
import ItemThumbnail from './ItemThumbnail'
import DocumentWindowMenuButton from './MenuButton'

type Props = {
	document: IDocument
	className?: string
	style?: React.CSSProperties
	showMenuButton?: boolean
}

const ItemInformation = forwardRef<HTMLDivElement, Props>(
	({ document, className, showMenuButton = true }, ref) => {
		const { game } = useContext(GameContext)
		const collection = game.system?.collections?.find(
			c => c.type === document.type,
		)
		const { name } = document.values
		const { singularName } = useGetCollectionName(document)
		const isCharacter = collection?.canAssumeAsCharacter === 'true' || false

		return (
			<div
				ref={ref}
				className={twMerge(
					'relative flex flex-auto cursor-pointer space-x-1 overflow-hidden py-1.5',
					className,
				)}
			>
				<ItemThumbnail id={document._id} round={isCharacter} />

				<div className='flex flex-1 overflow-hidden'>
					<div className='mt-0.5 flex min-w-0 flex-1'>
						<BaseType className='truncate'>
							{name ? (
								name
							) : (
								<span className='italic text-gray-500'>Unnamed</span>
							)}
							{singularName !== 'Folder' && <AccessDots document={document} />}
						</BaseType>
					</div>
				</div>
				<div className='relative z-10 flex items-center'>
					<Menu document={document}>
						<DocumentWindowMenuButton show={showMenuButton} />
					</Menu>
				</div>
			</div>
		)
	},
)

export default ItemInformation
