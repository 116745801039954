import { EventBus } from 'pdfjs-dist/web/pdf_viewer.mjs'

// Scale Mode Constants
export const ScaleMode = {
	AUTO: 'auto',
	PAGE_ACTUAL: 'page-actual',
	PAGE_WIDTH: 'page-width',
	PAGE_FIT: 'page-fit',
} as const

export const TextLayerMode = {
	DISABLED: 0,
	ENABLED: 1,
} as const

export const SpreadMode = {
	NONE: 0,
	EVEN: 1,
	ODD: 2,
} as const

export type TextLayerModeType =
	(typeof TextLayerMode)[keyof typeof TextLayerMode]
export type ScaleModeType = (typeof ScaleMode)[keyof typeof ScaleMode]
export type SpreadModeType = (typeof SpreadMode)[keyof typeof SpreadMode]

// Props Types
export interface PDFViewerProps {
	resource: any
	page?: string
	isGame?: boolean
	showTools?: boolean
	formValues?: any
	onFormChange?: (values: any) => void
	enableAnnotations?: boolean
	initialSpreadMode?: SpreadModeType
	initialScaleMode?: ScaleModeType
	annotationMode?: number
	storePageInUrl?: boolean
	onClose?: () => void
}

// Event Bus Type Extension
export interface PDFEventBus extends EventBus {
	dispatch: (eventName: string, data?: any) => void
}

export interface PDFOutlineItem {
	title: string
	bold: boolean
	italic: boolean
	color: Uint8ClampedArray
	dest: any
	url: string | null
	unsafeUrl: string | null
	newWindow: boolean
	count: number
	items: PDFOutlineItem[]
}
