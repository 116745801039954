import { ButtonHTMLAttributes } from 'react'

const FormButton = ({
	className = '',
	children,
	...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
	return (
		<button
			className={`flex w-full justify-center rounded-md border-0 bg-white px-4 py-2 
            text-sm font-medium text-gray-900 shadow-sm transition-colors duration-200 
            hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 
            focus:ring-offset-gray-900 ${className}`}
			{...props}
		>
			{children}
		</button>
	)
}

export default FormButton
