import * as PIXI from 'pixi.js'
import { useCallback, useRef } from 'react'
import { useGrid } from '../../../contexts/grid'
import {
	createHexGrid,
	distanceBetweenPoints,
	toWorldCoords,
} from '../../../utils/gridUtils'
import { defineGrid } from '../grid/square/core/Grid'

interface ResizeState {
	resizing: boolean
	startDist: number
	startSize: number
	pointerDownLocalX: number
	pointerDownLocalY: number
	pointerDownWorldX: number
	pointerDownWorldY: number
	anchorCornerLocal: PIXI.Point | null
	dragCornerLocal: PIXI.Point | null
	anchorCornerWorld: PIXI.Point | null
	dragCornerWorld: PIXI.Point | null
}

export function useResize() {
	const {
		grid,
		viewport,
		gridDimensions,
		hexGrid,
		squareGrid,
		dragHex,
		anchorCorner,
	} = useGrid()

	const resizeRef = useRef<ResizeState>({
		resizing: false,
		startDist: 0,
		startSize: 0,
		pointerDownLocalX: 0,
		pointerDownLocalY: 0,
		pointerDownWorldX: 0,
		pointerDownWorldY: 0,
		anchorCornerLocal: null,
		dragCornerLocal: null,
		anchorCornerWorld: null,
		dragCornerWorld: null,
	})

	const startResize = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			if (!anchorCorner) return

			const pointerDownWorld = toWorldCoords(e, viewport)
			if (!pointerDownWorld) return

			const gridOriginOffset = {
				x: gridDimensions.width / 3,
				y: gridDimensions.height / 3,
			}

			const pointerDownLocal = {
				x: pointerDownWorld.x - grid.x + gridOriginOffset.x,
				y: pointerDownWorld.y - grid.y + gridOriginOffset.y,
			}

			resizeRef.current.pointerDownWorldX = pointerDownWorld.x
			resizeRef.current.pointerDownWorldY = pointerDownWorld.y
			resizeRef.current.pointerDownLocalX = pointerDownLocal.x
			resizeRef.current.pointerDownLocalY = pointerDownLocal.y

			let dragCornerLocal: PIXI.Point | null = null
			let anchorCornerLocal: PIXI.Point | null = null

			if (grid.type === 'square' && squareGrid) {
				const pointerSquare = squareGrid.pointToSquare(
					new PIXI.Point(pointerDownLocal.x, pointerDownLocal.y),
				)
				dragHex.current = pointerSquare
					? { col: pointerSquare.col, row: pointerSquare.row }
					: null

				const dragSquare = pointerSquare
				const anchorSquare = squareGrid.getSquare({
					col: anchorCorner.col,
					row: anchorCorner.row,
				})
				if (!dragSquare || !anchorSquare) return

				const anchorCornerPoint = anchorSquare.corners[anchorCorner.index]
				const dragCornerPoint = dragSquare.corners[anchorCorner.index]
				if (!anchorCornerPoint || !dragCornerPoint) return

				anchorCornerLocal = new PIXI.Point(
					anchorCornerPoint.x,
					anchorCornerPoint.y,
				)
				dragCornerLocal = new PIXI.Point(dragCornerPoint.x, dragCornerPoint.y)
			} else if (hexGrid) {
				const pointerHex = hexGrid.pointToHex(pointerDownLocal)
				dragHex.current = { col: pointerHex.col, row: pointerHex.row }

				const dragHexObj = pointerHex
				const anchorObj = hexGrid.getHex({
					col: anchorCorner.col,
					row: anchorCorner.row,
				})
				if (!dragHexObj || !anchorObj) return

				const anchorCornerPoint = anchorObj.corners[anchorCorner.index]
				const dragCornerPoint = dragHexObj.corners[anchorCorner.index]
				if (!anchorCornerPoint || !dragCornerPoint) return

				anchorCornerLocal = new PIXI.Point(
					anchorCornerPoint.x,
					anchorCornerPoint.y,
				)
				dragCornerLocal = new PIXI.Point(dragCornerPoint.x, dragCornerPoint.y)
			}

			if (!anchorCornerLocal || !dragCornerLocal) return

			const oldOffsetX = grid.x
			const oldOffsetY = grid.y

			const anchorCornerWorld = new PIXI.Point(
				oldOffsetX + anchorCornerLocal.x,
				oldOffsetY + anchorCornerLocal.y,
			)
			const dragCornerWorld = new PIXI.Point(
				oldOffsetX + dragCornerLocal.x,
				oldOffsetY + dragCornerLocal.y,
			)

			const dist = Math.max(
				1,
				distanceBetweenPoints(anchorCornerWorld, dragCornerWorld),
			)
			resizeRef.current = {
				...resizeRef.current,
				resizing: true,
				startDist: dist,
				startSize: grid.size,
				anchorCornerLocal: new PIXI.Point(
					anchorCornerLocal.x,
					anchorCornerLocal.y,
				),
				dragCornerLocal: new PIXI.Point(dragCornerLocal.x, dragCornerLocal.y),
				anchorCornerWorld,
				dragCornerWorld,
			}
		},
		[grid, hexGrid, squareGrid, anchorCorner, dragHex, viewport],
	)

	const moveResize = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			if (!resizeRef.current.resizing) return

			const pointerWorldCurrentPos = toWorldCoords(e, viewport)
			if (!pointerWorldCurrentPos) return

			const pointerWorldOriginPos = new PIXI.Point(
				resizeRef.current.pointerDownWorldX,
				resizeRef.current.pointerDownWorldY,
			)
			const pointerWorldDelta = new PIXI.Point(
				pointerWorldCurrentPos.x - pointerWorldOriginPos.x,
				pointerWorldCurrentPos.y - pointerWorldOriginPos.y,
			)

			const { dragCornerWorld, anchorCornerWorld, startDist, startSize } =
				resizeRef.current
			if (!dragCornerWorld || !anchorCornerWorld) return

			const newDragCornerWorld = new PIXI.Point(
				dragCornerWorld.x + pointerWorldDelta.x,
				dragCornerWorld.y + pointerWorldDelta.y,
			)
			const newDist = Math.max(
				1,
				distanceBetweenPoints(anchorCornerWorld, newDragCornerWorld),
			)
			const scale = newDist / startDist
			const newSize = startSize * scale

			if (newSize > 20) return

			if (grid.type === 'square') {
				const recalcGrid = defineGrid({ size: newSize })

				const anchorSquare = recalcGrid.getSquare({
					col: anchorCorner.col,
					row: anchorCorner.row,
				})
				const dragSquare = dragHex.current
					? recalcGrid.getSquare(dragHex.current)
					: null
				if (!anchorSquare || !dragSquare || !anchorCorner) return

				const anchorCornerNew = anchorSquare.corners[anchorCorner.index]
				if (!anchorCornerNew) return

				const newOffsetX = anchorCornerWorld.x - anchorCornerNew.x
				const newOffsetY = anchorCornerWorld.y - anchorCornerNew.y

				return {
					size: newSize,
					x: newOffsetX,
					y: newOffsetY,
				}
			} else {
				const { grid: recalcGrid } = createHexGrid(
					newSize,
					grid.type,
					gridDimensions,
				)
				const anchorObjNew = recalcGrid.getHex({
					col: anchorCorner.col,
					row: anchorCorner.row,
				})
				const dragObjNew = dragHex.current
					? recalcGrid.getHex(dragHex.current)
					: null
				if (!anchorObjNew || !dragObjNew || !anchorCorner) return

				const anchorCornerNew = anchorObjNew.corners[anchorCorner.index]
				if (!anchorCornerNew) return

				const newOffsetX = anchorCornerWorld.x - anchorCornerNew.x
				const newOffsetY = anchorCornerWorld.y - anchorCornerNew.y

				return {
					size: newSize,
					x: newOffsetX,
					y: newOffsetY,
				}
			}
		},
		[gridDimensions, dragHex, anchorCorner, grid.type, viewport, squareGrid],
	)

	const endResize = useCallback(() => {
		resizeRef.current.resizing = false
	}, [])

	return { startResize, moveResize, endResize }
}
