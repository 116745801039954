import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'

interface Props {
	width: number
	height: number
	maskRef: React.MutableRefObject<PIXI.Graphics | null>
}

const MapMask = ({ width, height, maskRef }: Props) => {
	const drawMask = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()
			g.beginFill(0xffffff, 1)
			g.drawRect(0, 0, width, height)
			g.endFill()

			maskRef.current = g
		},
		[width, height, maskRef],
	)

	return <Graphics draw={drawMask} />
}

export default MapMask
