import { Settings as SettingsIcon } from 'lucide-react'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'
import Settings from '../settings/Settings'

export default function SettingsButton() {
	const { isGM } = useUser()
	const [isOpen, setIsOpen] = useState(false)
	const { game } = useGame()
	const { isTemplate } = game
	if (!isGM) return null

	return (
		<>
			<ToolButton
				tooltip={isTemplate ? 'Settings (This game is a template)' : 'Settings'}
				isOpen={isOpen}
				onClick={() => setIsOpen(true)}
				className={isTemplate ? 'bg-[#D09A3A] text-white' : undefined}
				noHover={isTemplate}
			>
				<SettingsIcon
					aria-hidden='true'
					className={`transition-transform duration-1000 ${
						isOpen ? 'rotate-180' : ''
					}`}
				/>
			</ToolButton>

			{createPortal(
				<Settings onClose={() => setIsOpen(false)} isOpen={isOpen} />,
				document.body,
			)}
		</>
	)
}
