import React from 'react'
import { twMerge } from 'tailwind-merge'
import { useGuide } from '../../contexts/guideContext'
import useGetUserById from '../../hooks/useGetUserById'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import Avatar from '../Avatar'
import SmallUppercase from './text/SmallUppercase'

const GuideNotification: React.FC = () => {
	const { guidingUserId, isFollowing, setIsFollowing } = useGuide()
	const { user: guidingUser } = useGetUserById(guidingUserId)
	const { userId } = useUser()
	const { primary } = useTheme()
	const isGuiding = guidingUserId === userId

	if (!guidingUserId || !guidingUser) {
		return null
	}

	const handleFollowToggle = () => {
		setIsFollowing(true)
	}

	const ActionButton = () => {
		if (isGuiding || isFollowing) {
			return null
		}

		return (
			<>
				<style>
					{`
						@keyframes pulse {
							0%, 100% { transform: scale(1); }
							50% { transform: scale(0.95); }
						}
					`}
				</style>
				<SmallUppercase
					className='flex animate-[pulse_2s_ease-in-out_infinite] cursor-pointer items-center gap-2 rounded-full border border-black bg-black px-4 py-1 pr-1 transition-transform duration-1000 ease-in-out hover:animate-none'
					style={{
						color: primary,
					}}
					onClick={handleFollowToggle}
				>
					<span>Follow</span>
					<Avatar
						src={guidingUser.userProfile?.avatar}
						userName={guidingUser.userProfile?.name}
						userId={guidingUser.userId}
						color={guidingUser.userSettings?.color}
						size='sm'
					/>
				</SmallUppercase>
			</>
		)
	}

	let copy = isFollowing ? (
		<div className='-mr-1 flex items-center gap-2'>
			<span>Map is Following</span>
			<Avatar
				src={guidingUser.userProfile?.avatar}
				userName={guidingUser.userProfile?.name}
				userId={guidingUser.userId}
				color={guidingUser.userSettings?.color}
				size='sm'
			/>
		</div>
	) : (
		''
	)

	copy = isGuiding ? `Sharing Map View` : copy

	return (
		<div
			className={twMerge(
				'flex h-10 flex-row items-center justify-between rounded-full p-1',
				(isGuiding || isFollowing) && 'px-3',
			)}
			style={{ backgroundColor: primary }}
		>
			<SmallUppercase
				style={{
					color: 'black',
				}}
			>
				{copy}
			</SmallUppercase>
			<ActionButton />
		</div>
	)
}

export default GuideNotification
