import { GameProgress } from '../../../shared/types/game'

export async function updateGameProgress(
	gameId: string,
	progress: Partial<GameProgress>,
) {
	const response = await fetch(`/api/games/${gameId}/progress`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(progress),
	})

	if (!response.ok) {
		throw new Error('Failed to update game progress')
	}

	return response.json()
}
