import { FormProvider, useForm } from 'react-hook-form'
import SectionDivider from '../SectionDivider'
import Sidebar from '../Sidebar'
import AddDocumentButton from './AddDocumentButton'
import DocumentTypeList from './DocumentTypeList'

const Header = () => (
	<div className='flex flex-col gap-2'>
		<SectionDivider label='Documents'>
			<AddDocumentButton />
		</SectionDivider>
		<DocumentTypeList />
	</div>
)

const Content = () => <Sidebar />

export const DocumentsProvider = ({
	children,
}: {
	children: React.ReactNode
}) => {
	const form = useForm({
		defaultValues: {
			selection: 'All',
		},
	})

	return <FormProvider {...form}>{children}</FormProvider>
}

export const DocumentsHeader = () => <Header />
export const DocumentsContent = () => <Content />
