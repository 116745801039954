import { useMutation, useQuery } from '@apollo/client'
import { animated, useSpring } from '@react-spring/web'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import AuthContext from '../../contexts/auth'
import { socket } from '../../contexts/socket'
import {
	ADD_PLAYER_WITH_TOKEN,
	GAME_USERS,
	HAS_ACCESS_TO_GAME,
	VALIDATE_INVITE_TOKEN,
} from '../../graphql/games'
import { IUserProfile } from '../../interfaces/users'
import Avatar from '../Avatar'
import { FormButton } from '../FormComponents/auth'
import { INTERFACE_DROPSHADOW } from '../interface/constants'
import Overlapper from '../Overlapper'
import GameCard from './elements/GameCard'
import InviteSignIn from './InviteSignIn'
import InviteSignUp from './InviteSignUp'
import Logo from './sections/Logo'

const StaticGameCard = ({ game }) => {
	const { gameId } = useParams()
	const { data: usersData, loading: usersLoading } = useQuery(GAME_USERS, {
		variables: {
			gameId,
		},
		pollInterval: 5000,
		fetchPolicy: 'cache-and-network',
		skip: !gameId,
	})

	const springProps = useSpring({
		scale: 1,
		rotateY: '0deg',
		config: {
			tension: 300,
			friction: 20,
		},
	})

	return (
		<div className={twMerge('rounded-xl')}>
			<div className='relative z-10'>
				<animated.div
					style={{
						...springProps,
						transformOrigin: 'center center',
					}}
				>
					<GameCard game={game} style={{ boxShadow: INTERFACE_DROPSHADOW }} />
				</animated.div>
			</div>

			<div className='relative z-0 flex flex-row items-center justify-between py-1 pl-3 pr-1'>
				<div className='flex min-w-0 flex-1 flex-col'>
					<p className='mt-2 truncate font-medium text-white'>{game.title}</p>
				</div>

				<Overlapper className='w-1/3' align='right'>
					{usersLoading && (
						<div className='flex items-center justify-center p-3'>loading</div>
					)}
					{usersData?.gameUsers?.map((user: IUserProfile) => (
						<Avatar
							key={user._id}
							src={user.avatar}
							userId={user._id}
							userName={user.name}
							className='mr-2'
							noBorder
						/>
					))}
				</Overlapper>
			</div>
		</div>
	)
}

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

const JoinFromInvite = () => {
	const { authState } = useContext(AuthContext)
	const { userDetails } = authState
	const { email } = userDetails
	const { gameId } = useParams()
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const [isSignIn, setIsSignIn] = useState(false)
	const navigate = useNavigate()
	const [joining, setJoining] = useState(false)
	const [game, setGame] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchGameInfo = async () => {
			try {
				const response = await fetch(`/api/games/${gameId}/invite-info`)
				if (!response.ok) {
					throw new Error('Failed to fetch game info')
				}
				const data = await response.json()
				setGame(data)
			} catch (error) {
				console.error('Error fetching game info:', error)
			} finally {
				setLoading(false)
			}
		}

		if (gameId) {
			fetchGameInfo()
		}
	}, [gameId])

	const [addPlayerWithToken] = useMutation(ADD_PLAYER_WITH_TOKEN, {
		refetchQueries: [{ query: HAS_ACCESS_TO_GAME, variables: { gameId } }],
		onCompleted: () => {
			socket.emit('userDataUpdated', { gameId })
			navigate(`/game/${gameId}`)
		},
		onError: error => {
			console.error('ADD_PLAYER_WITH_TOKEN.onError', error)
			setJoining(false)
		},
	})

	const { loading: tokenLoading, data: tokenData } = useQuery(
		VALIDATE_INVITE_TOKEN,
		{
			variables: {
				gameId,
				inviteToken,
			},
		},
	)

	const {
		loading: accessLoading,
		error: accessError,
		data: accessData,
	} = useQuery(HAS_ACCESS_TO_GAME, {
		variables: { gameId },
	})

	const handleJoinGame = () => {
		setJoining(true)
		addPlayerWithToken({
			variables: {
				gameId,
				email,
				inviteToken,
			},
		})
	}

	if (loading || tokenLoading) {
		return <div>Loading...</div>
	}

	if (!tokenData?.validateInviteToken) {
		alert(
			'The token on this invite is invalid. Please contact the game owner to get a new invite.',
		)
		navigate('/')
	}

	if (accessLoading) {
		return <div>Checking access to game...</div>
	}

	if (accessError) {
		console.error(accessError)
		return <div>Error...</div>
	}

	if (accessData?.hasAccessToGame) {
		navigate(`/game/${gameId}`)
		return <div>Redirecting to game...</div>
	}

	const renderAuthForm = () =>
		isSignIn ? (
			<InviteSignIn setIsSignIn={setIsSignIn} />
		) : (
			<InviteSignUp setIsSignIn={setIsSignIn} />
		)

	const renderJoinConfirmation = () => (
		<div className='rounded-lg bg-gray-800 p-6'>
			<p className='mb-6 text-center text-gray-300'>
				You're signed in as <span className='text-white'>{email}</span>
			</p>
			<FormButton
				onClick={handleJoinGame}
				disabled={joining}
				className='w-full'
			>
				{joining ? 'Joining...' : 'Join Game'}
			</FormButton>
		</div>
	)

	return (
		<div className='min-h-screen'>
			<div className='absolute p-8 md:p-16'>
				<Logo />
			</div>
			<div className='flex min-h-screen items-center justify-center'>
				<div className='mx-auto w-full max-w-lg space-y-8 px-4'>
					{game && (
						<div className='rounded-lg bg-gray-800 p-6'>
							<h2 className='mb-4 text-center text-xl font-semibold text-white'>
								You've been invited to join
							</h2>
							<StaticGameCard game={game} />
							{game.description && (
								<p className='mt-4 text-center text-sm text-gray-300'>
									{game.description}
								</p>
							)}
						</div>
					)}
					{email ? renderJoinConfirmation() : renderAuthForm()}
				</div>
			</div>
		</div>
	)
}

export default JoinFromInvite
