import { AnchorCorner, HexGrid } from '../../../contexts/grid'

/**
 * Returns true if the current corner is in the same position as the anchor corner
 */
export function areCornersAtSamePosition(
	hexGrid: HexGrid,
	currentCorner: AnchorCorner,
	anchorCorner: AnchorCorner,
): boolean {
	if (!hexGrid) return false
	if (!anchorCorner || !currentCorner) return false

	// 1. Get both hexes
	const anchorHex = hexGrid.getHex({
		col: anchorCorner.col,
		row: anchorCorner.row,
	})
	const currentHex = hexGrid.getHex({
		col: currentCorner.col,
		row: currentCorner.row,
	})
	if (!anchorHex || !currentHex) return false

	// 2. Get both corners in pixel coordinates
	const anchorPoint = anchorHex.corners[anchorCorner.index]
	const currentPoint = currentHex.corners[currentCorner.index]

	// 3. Compare positions with epsilon for float comparison
	const EPSILON = 1e-7
	return (
		Math.abs(anchorPoint.x - currentPoint.x) < EPSILON &&
		Math.abs(anchorPoint.y - currentPoint.y) < EPSILON
	)
}
