/**
 * Cross-browser compatible clipboard function
 * Falls back to a temporary input element method if the Clipboard API is not available
 */
export const copyToClipboard = async (text: string): Promise<boolean> => {
	// Try using the Clipboard API first
	if (navigator.clipboard && window.isSecureContext) {
		try {
			await navigator.clipboard.writeText(text)
			return true
		} catch (err) {
			console.warn('Clipboard API failed:', err)
		}
	}

	// Fallback for browsers/devices without Clipboard API
	try {
		const textArea = document.createElement('textarea')
		textArea.value = text

		// Avoid scrolling to bottom
		textArea.style.top = '0'
		textArea.style.left = '0'
		textArea.style.position = 'fixed'
		textArea.style.opacity = '0'

		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()

		const successful = document.execCommand('copy')
		document.body.removeChild(textArea)

		return successful
	} catch (err) {
		console.warn('Fallback clipboard method failed:', err)
		return false
	}
}
