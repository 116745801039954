import { useContext } from 'react'
import { TSetBookAccessPayload } from '../../../shared/types/game'
import GameContext from '../contexts/game'
import useUser from './useUser'

const useHandleShareBookWith = () => {
	const { game, dispatch } = useContext(GameContext)
	const { isGM, userId } = useUser()

	const handleSharePublic = (bookId: string) => {
		const payload: TSetBookAccessPayload = {
			bookId: bookId,
			access: 'public',
			accessList: [],
		}

		share(payload)
	}

	const handleSharePrivate = (bookId: string) => {
		const payload: TSetBookAccessPayload = {
			bookId: bookId,
			access: 'private',
			accessList: isGM ? [] : [userId],
		}

		share(payload)
	}

	const handleShareWith = (
		bookId: string,
		userId: string,
		toggleState = false,
	) => {
		const book = game.books.byId[bookId]
		let accessList = book.accessList || []

		const alreadyShared = accessList.includes(userId)

		if (alreadyShared && toggleState) {
			accessList = accessList.filter(id => id !== userId)
		} else if (!alreadyShared) {
			accessList = [...accessList, userId]
		}

		const payload: TSetBookAccessPayload = {
			bookId: book._id,
			access: 'private',
			accessList: accessList,
		}

		share(payload)
	}

	const share = (payload: TSetBookAccessPayload) => {
		dispatch({
			type: 'SET_BOOK_ACCESS',
			payload,
		})
	}

	return { handleSharePublic, handleSharePrivate, handleShareWith }
}

export default useHandleShareBookWith
