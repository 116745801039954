import * as PIXI from 'pixi.js'
import { useMemo } from 'react'
import { useGrid } from '../contexts/grid'

export default function useTextStyle(scale = 1) {
	const { grid } = useGrid()

	return useMemo(() => {
		return new PIXI.TextStyle({
			trim: true,
			align: 'center',
			fontFamily: 'Inter, sans-serif',
			fontWeight: '600',
			fontSize: 0.18 * grid.size * scale,
			lineHeight: 0.2 * grid.size * scale,
			fill: '#ffffff',
			wordWrap: true,
			wordWrapWidth: 2,
			stroke: '#555555',
			strokeThickness: 0.015 * grid.size * scale,
			dropShadow: false,
			dropShadowColor: '#000000',
			dropShadowAlpha: 0.5,
			dropShadowBlur: 1 * grid.size * scale,
			dropShadowAngle: Math.PI / 3,
			dropShadowDistance: 0.1 * grid.size * scale,
		})
	}, [grid.size, scale])
}
