import { AnnotationEditorParamsType, AnnotationEditorType } from 'pdfjs-dist'
import {
	EventBus,
	PDFViewer as PDFViewerComponent,
} from 'pdfjs-dist/web/pdf_viewer.mjs'
import { MutableRefObject, useCallback, useEffect, useState } from 'react'
import { AnnotationOperations } from '../types/annotations'
import { useAnnotations } from './useAnnotations'

export interface UseHighlightAnnotationsReturn extends AnnotationOperations {
	isHighlighting: boolean
	handleHighlightToggle: () => void
}

export function useHighlightAnnotations(
	eventBusRef: MutableRefObject<EventBus | null>,
	pdfViewerRef: MutableRefObject<PDFViewerComponent | null>,
	documentId: string,
): UseHighlightAnnotationsReturn {
	const [isHighlighting, setIsHighlighting] = useState(false)
	const annotationOps = useAnnotations(eventBusRef, pdfViewerRef, documentId)

	// Monitor annotation changes
	useEffect(() => {
		const eventBus = eventBusRef.current
		if (!eventBus) return

		const handleAnnotationChange = async () => {
			try {
				await annotationOps.serialize()
			} catch (error) {
				console.error('Error serializing highlights:', error)
			}
		}

		eventBus.on('annotationeditorstateschanged', handleAnnotationChange)
		eventBus.on('annotationupdated', handleAnnotationChange)

		return () => {
			eventBus.off('annotationeditorstateschanged', handleAnnotationChange)
			eventBus.off('annotationupdated', handleAnnotationChange)
		}
	}, [annotationOps])

	// Set up default highlight color when UI is ready
	useEffect(() => {
		const eventBus = eventBusRef.current
		const viewer = pdfViewerRef.current
		if (!eventBus || !viewer) return

		const handleUIReady = () => {
			eventBus.dispatch('switchannotationeditorparams', {
				source: viewer,
				type: AnnotationEditorParamsType.HIGHLIGHT_DEFAULT_COLOR,
				value: '#ffeb3b',
			})
		}

		eventBus.on('annotationeditoruiready', handleUIReady)

		return () => {
			eventBus.off('annotationeditoruiready', handleUIReady)
		}
	}, [eventBusRef.current, pdfViewerRef.current])

	const handleHighlightToggle = useCallback(() => {
		if (!pdfViewerRef.current || !eventBusRef.current) return

		setIsHighlighting(prev => {
			const newValue = !prev
			eventBusRef.current.dispatch('switchannotationeditormode', {
				mode: newValue
					? AnnotationEditorType.HIGHLIGHT
					: AnnotationEditorType.NONE,
				editId: null,
				buttonImage: null,
			})
			return newValue
		})
	}, [])

	return {
		...annotationOps,
		isHighlighting,
		handleHighlightToggle,
	}
}
