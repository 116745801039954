import { InputHTMLAttributes, forwardRef } from 'react'

const FormInput = forwardRef<
	HTMLInputElement,
	InputHTMLAttributes<HTMLInputElement>
>(({ className = '', ...props }, ref) => {
	return (
		<input
			ref={ref}
			className={`w-full rounded-md border border-gray-700 bg-gray-900 px-3 py-2 text-sm text-white 
			placeholder-gray-500 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-2 
			focus:ring-gray-500 ${className}`}
			{...props}
		/>
	)
})

FormInput.displayName = 'FormInput'

export default FormInput
