import { useContext, useMemo } from 'react'
import { ResourceContext } from '../contexts/resources'

interface BookMetadata {
	game?: string
	ruleSystem?: string
}

const isBookResource = (
	resource: any,
): resource is { metadata: BookMetadata } => {
	return (
		resource.resourceType === 'book' &&
		resource.metadata &&
		(typeof resource.metadata.game === 'undefined' ||
			typeof resource.metadata.game === 'string') &&
		(typeof resource.metadata.ruleSystem === 'undefined' ||
			typeof resource.metadata.ruleSystem === 'string')
	)
}

const useResourceMetadataOptions = (currentResource?: {
	metadata: BookMetadata
}) => {
	const { resources } = useContext(ResourceContext)

	const options = useMemo(() => {
		const games = new Set<string>()
		const ruleSystems = new Set<string>()

		// Add current resource's values first if they exist
		if (currentResource?.metadata.game) {
			games.add(currentResource.metadata.game)
		}
		if (currentResource?.metadata.ruleSystem) {
			ruleSystems.add(currentResource.metadata.ruleSystem)
		}

		// Then add values from other resources
		Object.values(resources).forEach(resource => {
			if (isBookResource(resource)) {
				if (resource.metadata.game) {
					games.add(resource.metadata.game)
				}
				if (resource.metadata.ruleSystem) {
					ruleSystems.add(resource.metadata.ruleSystem)
				}
			}
		})

		return {
			games: Array.from(games)
				.sort()
				.map(game => ({ id: game, label: game })),
			ruleSystems: Array.from(ruleSystems)
				.sort()
				.map(system => ({
					id: system,
					label: system,
				})),
		}
	}, [resources, currentResource])

	return options
}

export default useResourceMetadataOptions
