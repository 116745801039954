import { Container, Sprite } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useRef } from 'react'
import { IAsset } from '../../../../shared/types/asset'
import { IFogOperation } from '../../../../shared/types/fog'
import { useGrid } from '../../contexts/grid'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import Fog from './Fog'
import Grid from './Grid'
import MapMask from './MapMask'

interface Props {
	texture: PIXI.Texture
	mapAsset: IAsset | null
}

const Map = ({ texture, mapAsset }: Props) => {
	const { mapDimensions } = useGrid()
	const scene = useGetActiveScene()
	const mapContainerRef = useRef<PIXI.Container>(null)
	const maskGraphics = useRef<PIXI.Graphics | null>(null)

	// Convert scene fog polygons to fog operations
	const fogOperations: IFogOperation[] = (scene?.values?.fog || [])
		.filter(p => !p.deleted)
		.map(p => ({
			type: p.type,
			points: p.points,
			alpha: p.type === 'hide' ? 1 : undefined,
		}))

	if (!mapAsset) return null

	return (
		<Container x={0} y={0}>
			<MapMask
				width={mapDimensions.width}
				height={mapDimensions.height}
				maskRef={maskGraphics}
			/>
			<Container ref={mapContainerRef} x={0} y={0} mask={maskGraphics.current}>
				<Sprite
					x={0}
					y={0}
					width={mapDimensions.width}
					height={mapDimensions.height}
					texture={texture}
					eventMode='static'
				/>

				<Grid />

				<Fog
					width={mapDimensions.width}
					height={mapDimensions.height}
					operations={fogOperations}
					resolution={50}
				/>
			</Container>
		</Container>
	)
}

export default Map
