import { useMemo } from 'react'
import {
	FormProvider,
	useForm,
	useFormContext,
	useWatch,
} from 'react-hook-form'
import { initialTokenLibrary } from '../../data/tokenSets'
import ListDivider from '../ListDivider'
import SectionDivider from '../SectionDivider'
import ListMessage from '../views/ListMessage'
import TokenGrid from './TokenGrid'
import TokenSetList from './TokenSetList'

interface TokenLibraryForm {
	selection: string
}

const TokenLibraryContent = () => {
	const { control } = useFormContext<TokenLibraryForm>()

	const selectedSet = useWatch<TokenLibraryForm>({
		control,
		name: 'selection',
		defaultValue: 'All',
	})

	const header = useMemo(
		() => (
			<div className='flex flex-col gap-1'>
				<SectionDivider label='Tokens' />
				<TokenSetList />
			</div>
		),
		[],
	)

	const content = useMemo(() => {
		if (selectedSet === 'All') {
			return (
				<div>
					<ListDivider text='All Tokens' />
					<TokenGrid tokens={Object.values(initialTokenLibrary.tokens.byId)} />
				</div>
			)
		}

		const set = initialTokenLibrary.sets.byId[selectedSet]
		if (!set) return <ListMessage title='No Tokens' />

		return (
			<div>
				<ListDivider text={set.name} />
				{set.description && (
					<p className='mb-2 text-xs text-gray-500'>{set.description}</p>
				)}
				<TokenGrid
					tokens={set.tokenIds.map(id => initialTokenLibrary.tokens.byId[id])}
				/>
			</div>
		)
	}, [selectedSet])

	return (
		<div className='w-[420px] space-y-2'>
			<div className='p-6 pb-0'>{header}</div>
			<div className='h-[calc(100vh-225px)] max-h-[500px] overflow-y-auto px-6 pb-6'>
				{content}
			</div>
		</div>
	)
}

const TokenLibrary = () => {
	const methods = useForm<TokenLibraryForm>({
		defaultValues: {
			selection: 'All',
		},
	})

	return (
		<FormProvider {...methods}>
			<TokenLibraryContent />
		</FormProvider>
	)
}

export default TokenLibrary
