import { FC, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import D10Icon from '../dice-icons/D10Icon'
import D12Icon from '../dice-icons/D12Icon'
import D20Icon from '../dice-icons/D20Icon'
import D4Icon from '../dice-icons/D4Icon'
import D6Icon from '../dice-icons/D6Icon'
import D8Icon from '../dice-icons/D8Icon'
import SectionDivider from '../SectionDivider'

const diceIcons: {
	[key: string]: React.ComponentType<React.SVGProps<SVGSVGElement>>
} = {
	d4: D4Icon,
	d6: D6Icon,
	d8: D8Icon,
	d10: D10Icon,
	d12: D12Icon,
	d20: D20Icon,
}

const rpgDiceConfig = ['d4', 'd6', 'd8', 'd10', 'd12', 'd20', 'd100']

const SettingsDice: FC = () => {
	const { color } = useUser()
	const { game, dispatch } = useGame()
	const [enabledDice, setEnabledDice] = useState<Set<string>>(
		new Set(game.dice?.enabledDice || rpgDiceConfig),
	)

	useEffect(() => {
		dispatch({
			type: 'SET_ENABLED_DICE',
			payload: { enabledDice: Array.from(enabledDice) },
		})
	}, [enabledDice, dispatch])

	const toggleDie = (die: string) => {
		setEnabledDice(prev => {
			const newSet = new Set(prev)
			if (newSet.has(die)) {
				newSet.delete(die)
			} else {
				newSet.add(die)
			}
			return newSet
		})
	}

	return (
		<div>
			<SectionDivider label='Dice Settings' />

			<div className='mt-4 space-y-4'>
				<div className='grid grid-cols-7 gap-2'>
					{rpgDiceConfig.map(die => {
						const isEnabled = enabledDice.has(die)
						const IconComponent =
							die === 'd100' ? diceIcons['d10'] : diceIcons[die]

						if (!IconComponent) return null

						return (
							<button
								key={die}
								onClick={() => toggleDie(die)}
								className={twMerge(
									'flex aspect-square items-center justify-center rounded-lg p-2 transition-all hover:bg-black/5',
									isEnabled ? 'bg-black/10' : 'bg-black/5 opacity-40',
								)}
							>
								{die === 'd100' ? (
									<div className='flex items-center'>
										<IconComponent
											className='h-8 w-8'
											fill={color}
											stroke='white'
										/>
										<IconComponent
											className='-ml-4 h-8 w-8'
											fill={color}
											stroke='white'
										/>
									</div>
								) : (
									<IconComponent
										className='h-8 w-8'
										fill={color}
										stroke='white'
									/>
								)}
							</button>
						)
					})}
				</div>

				<p className='text-center text-sm text-gray-500'>
					Control which dice are shown in the dice bar.
					<br />
					All players will see the same dice enabled.
				</p>
			</div>
		</div>
	)
}

export default SettingsDice
