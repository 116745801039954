import { useLazyQuery } from '@apollo/client'
import { animated, useSpring } from '@react-spring/web'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import useFitText from 'use-fit-text'
import { IGameCard } from '../../../../../shared/types/game'
import { GET_ASSET } from '../../../graphql/games'
import useTheme from '../../../hooks/useTheme'
import GameLogo from '../../interface/GameLogo'
import { INTERFACE_DROPSHADOW } from '../../interface/constants'

export interface IGameCardProps {
	game: IGameCard
	className?: string
	style?: React.CSSProperties
}

const calc = (x: number, y: number, rect: DOMRect) => [
	-(y - rect.top - rect.height / 2) / 20,
	(x - rect.left - rect.width / 2) / 20,
	1.05,
]

const calcLogo = (x: number, y: number, rect: DOMRect) => [
	-(y - rect.top - rect.height / 2) / 15,
	(x - rect.left - rect.width / 2) / 15,
	1,
]

const calcBackground = (x: number, y: number, rect: DOMRect) => [
	50 + ((x - rect.left) / rect.width - 0.5) * 8,
	50 + ((y - rect.top) / rect.height - 0.5) * 8,
]

const trans = (x: number, y: number, s: number) =>
	`perspective(1000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`

const transLogo = (x: number, y: number, s: number) =>
	`translate3d(${y * 0.5}px, ${-x * 0.5}px, 20px) scale(${s})`

const transBackground = (x: number, y: number) => `${x}% ${y}%`

const calcShine = (x: number, y: number, rect: DOMRect) => {
	const mouseXPercent = ((x - rect.left) / rect.width) * 100
	return `${mouseXPercent * 2}% 0`
}

export default function GameCard({ game, className, style }: IGameCardProps) {
	const [getAsset, { data, error }] = useLazyQuery(GET_ASSET, {
		variables: {
			gameId: game._id,
			assetId: game.coverImage,
		},
	})
	const coverImageUrl = data?.getAsset?.fileurl
	const { primary } = useTheme()
	const { fontSize, ref } = useFitText({
		maxFontSize: 500,
	})
	const [props, api] = useSpring(() => ({
		xys: [0, 0, 1],
		config: { mass: 1, tension: 400, friction: 25 },
	}))

	const [logoProps, logoApi] = useSpring(() => ({
		xys: [0, 0, 1],
		config: { mass: 1, tension: 350, friction: 25 },
	}))

	const [bgProps, bgApi] = useSpring(() => ({
		xy: [50, 50],
		config: { mass: 1, tension: 350, friction: 25 },
	}))

	const [shineProps, shineApi] = useSpring(() => ({
		position: '200% 0',
		config: { tension: 350, friction: 25 },
	}))

	useEffect(() => {
		if (error) {
			console.error('Error fetching asset:', error)
		}
	}, [error])

	useEffect(() => {
		if (game.coverImage && !coverImageUrl) {
			getAsset()
		}
	}, [game.coverImage, coverImageUrl, getAsset])

	const backgroundStyle = coverImageUrl
		? {
				backgroundImage: `url(${coverImageUrl})`,
				backgroundSize: 'cover',
				backgroundPosition: bgProps.xy.to(transBackground),
		  }
		: {}

	const scrimStyle =
		coverImageUrl && !game.hideLogo
			? {
					backgroundImage: `
							linear-gradient(160deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0) 70%),
							linear-gradient(160deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))
						`,
			  }
			: {}

	const handleMouseMove = ({
		clientX,
		clientY,
		currentTarget,
	}: React.MouseEvent) => {
		const rect = currentTarget.getBoundingClientRect()
		api.start({ xys: calc(clientX, clientY, rect) })
		logoApi.start({ xys: calcLogo(clientX, clientY, rect) })
		bgApi.start({ xy: calcBackground(clientX, clientY, rect) })
		shineApi.start({ position: calcShine(clientX, clientY, rect) })
	}

	const handleMouseLeave = () => {
		api.start({ xys: [0, 0, 1] })
		logoApi.start({ xys: [0, 0, 1] })
		bgApi.start({ xy: [50, 50] })
		shineApi.start({ position: '200% 0' })
	}

	return (
		<animated.div
			className={twMerge(
				'group relative aspect-[1.65] w-full overflow-hidden rounded-2xl bg-gray-900 text-center shadow-xl',
				className,
			)}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseLeave}
			style={{
				...backgroundStyle,
				...style,
				transform: props.xys.to(trans),
			}}
		>
			<div className='absolute inset-x-3 top-0 h-px rounded-t-2xl bg-gradient-to-r from-white/0 via-white/10 to-white/0' />
			<div
				className='pointer-events-none absolute inset-0 rounded-2xl'
				style={{
					boxShadow: INTERFACE_DROPSHADOW,
					maskImage: 'linear-gradient(to bottom right, white 30%, transparent)',
					maskSize: 'cover',
					maskRepeat: 'no-repeat',
				}}
			/>
			<div className='absolute inset-0' style={scrimStyle} />
			<animated.div
				className='absolute inset-0 rounded-2xl opacity-0 transition-opacity duration-300 group-hover:opacity-100'
				style={{
					background:
						'linear-gradient(105deg, transparent 40%, rgba(255, 255, 255, 0.08) 45%, rgba(255, 255, 255, 0) 50%)',
					backgroundSize: '200% 100%',
					backgroundPosition: shineProps.position,
				}}
			/>
			<animated.div
				id='scrim'
				className='relative z-10 flex h-full flex-col justify-center p-8'
				style={{
					transform: logoProps.xys.to(transLogo),
				}}
			>
				{!game.hideLogo &&
					(game.logo ? (
						<GameLogo
							gameId={game._id}
							assetId={game.logo}
							title={game.title}
						/>
					) : (
						<div
							ref={ref}
							className='max-h-[80%] overflow-hidden font-semibold uppercase tracking-wide text-white'
							style={{
								fontFamily: 'Rubik, sans-serif',
								textShadow: '0 4px 6px rgba(0,0,0,0.5)',
								color: primary,
								fontSize,
							}}
						>
							{/* {game.title} */}
						</div>
					))}
			</animated.div>
		</animated.div>
	)
}
