import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback, useState } from 'react'
import { AnchorCorner, useGrid } from '../../../contexts/grid'

interface Props {
	x: number
	y: number
	index: number
	col: number
	row: number
	onpointerdown: (e: PIXI.FederatedPointerEvent, corner: AnchorCorner) => void
	eventMode: PIXI.EventMode
}

const GridCorner = ({
	x,
	y,
	index,
	col,
	row,
	onpointerdown,
	eventMode,
}: Props) => {
	const [isHovered, setIsHovered] = useState(false)
	const { anchorCorner, interactionMode, grid, hexGrid } = useGrid()

	const CORNER_HIT_AREA = 0.2 * grid.size
	const CORNER_RING_SIZE = 0.2 * grid.size
	const CORNER_LINE_WIDTH = 0.05 * grid.size
	const CORNER_COLOR = 0xff8800
	const CORNER_ALPHA = 1
	const ACTIVE_COLOR = 0xff0000
	const ACTIVE_ALPHA = 1
	const SEGMENTS = 32
	const ARROW_SIZE = 0.3 * grid.size
	const ARROW_ANGLE = Math.PI / 6 // 30 degrees

	const isActive =
		anchorCorner?.col === col &&
		anchorCorner?.row === row &&
		anchorCorner?.index === index

	const hitArea = () => {
		return new PIXI.Circle(0, 0, CORNER_HIT_AREA)
	}

	const drawRing = (g: PIXI.Graphics, radius: number) => {
		g.moveTo(radius, 0)
		for (let i = 0; i <= SEGMENTS; i++) {
			const angle = (i / SEGMENTS) * Math.PI * 2
			g.lineTo(radius * Math.cos(angle), radius * Math.sin(angle))
		}
		g.closePath()
	}

	const drawArrowHead = (
		g: PIXI.Graphics,
		x: number,
		y: number,
		directionX: number,
		directionY: number,
	) => {
		const angle = Math.atan2(directionY, directionX)
		const leftAngle = angle + ARROW_ANGLE
		const rightAngle = angle - ARROW_ANGLE

		g.moveTo(x, y)
		g.lineTo(
			x - ARROW_SIZE * Math.cos(leftAngle),
			y - ARROW_SIZE * Math.sin(leftAngle),
		)
		g.moveTo(x, y)
		g.lineTo(
			x - ARROW_SIZE * Math.cos(rightAngle),
			y - ARROW_SIZE * Math.sin(rightAngle),
		)
	}

	const drawDirectionLine = (g: PIXI.Graphics) => {
		if (!anchorCorner || !hexGrid || isActive) return

		// Get the active hex and its corner
		const activeHex = hexGrid.getHex({
			col: anchorCorner.col,
			row: anchorCorner.row,
		})
		if (!activeHex) return

		const activeCornerPoint = activeHex.corners[anchorCorner.index]
		if (!activeCornerPoint) return

		// Get current hex and its corner
		const currentHex = hexGrid.getHex({ col, row })
		if (!currentHex) return

		const currentCornerPoint = currentHex.corners[index]
		if (!currentCornerPoint) return

		// Calculate direction vector
		const dx = activeCornerPoint.x - currentCornerPoint.x
		const dy = activeCornerPoint.y - currentCornerPoint.y
		const length = Math.sqrt(dx * dx + dy * dy)
		if (length === 0) return

		// Normalize
		const normalizedDx = dx / length
		const normalizedDy = dy / length

		// Calculate end point at anchor's ring
		const endX = dx - normalizedDx * CORNER_RING_SIZE
		const endY = dy - normalizedDy * CORNER_RING_SIZE

		// Draw line from this corner's ring to the anchor corner's ring
		g.moveTo(normalizedDx * CORNER_RING_SIZE, normalizedDy * CORNER_RING_SIZE)
		g.lineTo(endX, endY)

		// Draw arrow head
		drawArrowHead(g, endX, endY, normalizedDx, normalizedDy)
	}

	const drawCorner = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()

			if (isActive) {
				g.lineStyle(CORNER_LINE_WIDTH, ACTIVE_COLOR, ACTIVE_ALPHA)
				drawRing(g, CORNER_RING_SIZE)
			} else if (interactionMode === 'none' && isHovered) {
				// Only show hover effect in 'none' mode
				g.lineStyle(CORNER_LINE_WIDTH, CORNER_COLOR, CORNER_ALPHA)
				drawRing(g, CORNER_RING_SIZE)
				drawDirectionLine(g)
			}
		},
		[
			isHovered,
			isActive,
			interactionMode,
			CORNER_LINE_WIDTH,
			CORNER_RING_SIZE,
			anchorCorner,
			hexGrid,
		],
	)

	const handlePointerDown = (e: PIXI.FederatedPointerEvent) => {
		onpointerdown(e, { col, row, index })
	}

	const handlePointerEnter = () => {
		if (interactionMode === 'none' && !isActive) {
			setIsHovered(true)
		}
	}

	const handlePointerLeave = () => {
		setIsHovered(false)
	}

	return (
		<Graphics
			draw={drawCorner}
			eventMode={eventMode}
			hitArea={hitArea()}
			cursor={isActive ? 'grab' : 'pointer'}
			x={x}
			y={y}
			onpointerdown={handlePointerDown}
			onpointerenter={handlePointerEnter}
			onpointerleave={handlePointerLeave}
		/>
	)
}

export default GridCorner
