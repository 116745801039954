import { useCallback } from 'react'
import { IBook } from '../../../shared/types/book'
import { BookMetadata } from '../../../shared/types/resources'
import RPGBookCategoryDescriptions from '../interfaces/bookcategories'
import useExtractMetaData from './useExtractBookMetadata'

function useAiMetadataExtractor() {
	const { requestMetadataExtraction } = useExtractMetaData()

	const prepareMetadataExtractionPrompt = useCallback(
		(text: string, currentBookFields: Partial<IBook>): string => {
			// Extract first 10 and last 5 pages (assuming 3000 characters per page)
			const firstPages = text.slice(0, 30000)
			const lastPages = text.slice(-15000)
			const sampleText = firstPages + '\n...\n' + lastPages

			const prompt = `
				Analyze the following book sample and fill in the metadata fields:
				
				${sampleText}

				Current book fields:
				${JSON.stringify(currentBookFields, null, 2)}

				Available RPG book categories:
				${JSON.stringify(RPGBookCategoryDescriptions, null, 2)}

				Please fill in or update the following fields based on the sample text and return as JSON. Use the exact field names provided:

				{
					"title": "string",
					"description": "string",
					"categories": ["One or more RPGBookCategory values"],
					"game": "string (e.g. Dungeons & Dragons, Pathfinder, Trail of Cthulhu, Alien, etc.)",
					"ruleSystem": "string (e.g. Dungeons & Dragons 5e, Pathfinder 2e, GUMSHOE, Year Zero Engine, etc.)",
					"authors": ["string"],
					"contributingArtists": ["string"],
					"publisher": "string",
					"year": "string",
					"edition": "string",
					"isbn": "string",
					"pageCount": number,
					"language": "string",
					"copyright": {
						"year": "string",
						"owner": "string"
					},
					"series": "string", // e.g. "The Traveller's Aid Society"
					"seriesNumber": number, // e.g. 1
					"relatedBooks": ["string"], // e.g. ["Traveller's Core", "Traveller's Guide to the Galaxy"]
					"requiredBooks": ["string"], // e.g. ["Traveller's Core", "Traveller's Guide to the Galaxy"]
					"tags": ["string"], // e.g. ["Zine", "The Traveller's Aid Society", "The Traveller's Aid Society 2"]
					"contentWarnings": ["string"], // e.g. [""]
					"playerCount": {
						"min": number,
						"max": number
					},
					"recommendedAgeRange": {
						"min": number,
						"max": number
					},
					"playTime": {
						"min": number,
						"max": number,
					},
					"tableOfContents": [
						{
							"chapterTitle": "string",
							"pageNumber": "string"
						}
					],
					"errata": [
						{
							"version": "string",
							"url": "string",
							"date": "YYYY-MM-DD"
						}
					]
				}
				Return only the JSON object with the filled fields. If you can't determine a field, omit it from the JSON. Do not include fields that are not in this list (e.g., _id, creator, access, accessList, resourceId, lastUpdated).

				Do not include any markdown formatting or code blocks. Return only the raw JSON object.

				Important notes about categories:
				1. Always return categories as an array, even if there's only one category
				2. Never return empty strings as categories
				3. Only use values from the provided RPGBookCategory list in snake_case format (e.g. 'players_guide', not "Player's Guide")
				4. If you can't determine any categories, omit the field entirely
				5. Categories must be one of: ${Object.keys(RPGBookCategoryDescriptions).join(
					', ',
				)}
			`
			return prompt
		},
		[],
	)

	const cleanJsonResponse = (response: string): string => {
		// Remove markdown code blocks if present
		let cleaned = response.replace(/```json\n?|\n?```/g, '')

		// Remove any leading/trailing whitespace
		cleaned = cleaned.trim()

		// Ensure the string starts with { and ends with }
		if (!cleaned.startsWith('{') || !cleaned.endsWith('}')) {
			throw new Error('Invalid JSON response format')
		}

		return cleaned
	}

	const extractMetadataWithAI = useCallback(
		async (
			text: string,
			currentBookFields: Partial<IBook>,
		): Promise<Partial<BookMetadata>> => {
			const prompt = prepareMetadataExtractionPrompt(text, currentBookFields)

			try {
				const response = await requestMetadataExtraction({
					prompt,
				})
				const cleanedResponse = cleanJsonResponse(response.response)
				const extractedMetadata = JSON.parse(cleanedResponse)
				return extractedMetadata
			} catch (error) {
				console.error('Error extracting metadata:', error)
				if (error instanceof Error) {
					console.error('Response cleaning/parsing failed:', error.message)
				}
				throw error
			}
		},
		[prepareMetadataExtractionPrompt, requestMetadataExtraction],
	)

	return {
		extractMetadataWithAI,
	}
}

export default useAiMetadataExtractor
