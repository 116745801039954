import OnlineTabletop from './sections/Features/OnlineTabletop'

const HomePage = () => {
	return (
		<>
			{/* <Main /> */}
			<OnlineTabletop />
			{/* <Library /> */}
			{/* <Dice /> */}
		</>
	)
}

export default HomePage
