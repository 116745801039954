import { Bell, LogOut, User } from 'lucide-react'
import { FC, useContext, useRef, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import AuthContext from '../../../contexts/auth'
import useApi from '../../../hooks/useApi'
import SidebarModalWindow from '../../window/SidebarModalWindow'
import AccountTab from './profile/AccountTab'
import NotificationsTab from './profile/NotificationsTab'

interface IProps {
	onClose: () => void
}

const ProfileEditor: FC<IProps> = props => {
	const { authState, authDispatch } = useContext(AuthContext)
	const { request } = useApi()
	const fileRef = useRef<HTMLInputElement>(null)
	const [isUploading, setIsUploading] = useState(false)
	const [uploadError, setUploadError] = useState<string | null>(null)
	const [previewUrl, setPreviewUrl] = useState<string | null>(null)
	const [isOpen, setIsOpen] = useState(true)

	const { register, getValues, setValue } = useForm<FieldValues>({
		defaultValues: {
			name: authState.userDetails.name,
			email: authState.userDetails.email,
		},
	})

	const handleFieldUpdate = async (field: string) => {
		try {
			const values = getValues()
			const value = values[field]

			await request(`/api/users/me/${field}`, {
				method: 'PATCH',
				body: JSON.stringify({ [field]: value }),
				headers: {
					'Content-Type': 'application/json',
				},
			})

			authDispatch({
				type: 'UPDATE_PROFILE',
				payload: {
					userDetails: {
						...authState.userDetails,
						[field]: value,
					},
				},
			})
		} catch (error) {
			console.error(`Error updating ${field}:`, error)
			alert(`Failed to update ${field}. Please try again.`)
		}
	}

	const handleCancel = () => {
		setIsOpen(false)
		setTimeout(props.onClose, 500)
	}

	const handleDeleteAccount = async () => {
		if (
			!window.confirm(
				'Are you sure you want to delete your account? This will remove you from all games you have joined, and it will delete all your games. This cannot be undone.',
			)
		) {
			return
		}

		try {
			await request('/api/users/me', {
				method: 'DELETE',
			})
			authState.logout()
		} catch (error) {
			console.error('Error deleting account:', error)
			alert('Failed to delete account. Please try again.')
		}
	}

	const handleSignOut = async () => {
		try {
			await request('/api/logout', {
				method: 'POST',
				credentials: 'include',
			})
			authState.logout()
			window.location.href = '/'
		} catch (error) {
			console.error('Error signing out:', error)
			alert('Failed to sign out. Please try again.')
		}
	}

	return (
		<SidebarModalWindow
			id='profile-editor'
			title='Edit Account'
			size='large'
			onClose={handleCancel}
			isOpen={isOpen}
			tabs={[
				{
					id: 'account',
					label: 'Account',
					icon: <User />,
					content: (
						<AccountTab
							handleSubmit={handleFieldUpdate}
							handleDeleteAccount={handleDeleteAccount}
							register={register}
							setValue={setValue}
							fileRef={fileRef}
							isUploading={isUploading}
							uploadError={uploadError}
							previewUrl={previewUrl}
							setPreviewUrl={setPreviewUrl}
							setIsUploading={setIsUploading}
							setUploadError={setUploadError}
						/>
					),
				},
				{
					id: 'notifications',
					label: 'Notifications',
					icon: <Bell />,
					content: <NotificationsTab />,
				},
				{
					id: 'signout',
					label: 'Sign Out',
					icon: <LogOut />,
					content: <div />,
					onClick: handleSignOut,
				},
			]}
		/>
	)
}

export default ProfileEditor
