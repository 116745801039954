import Label from '../../../FormComponents/Label'
import Input from '../../../Input'
import SmType from '../../../interface/text/SmType'

interface MapGridUnitProps {
	unitSize: number
	unit: string
	onChange: (values: { unitSize?: number; unit?: string }) => void
}

const MapGridUnit = ({ unitSize, unit, onChange }: MapGridUnitProps) => {
	return (
		<div className='center relative flex items-center gap-px self-center'>
			<Label className='absolute left-2 mt-0 w-1/2'>
				<SmType>Scale</SmType>
			</Label>
			<Input
				type='number'
				value={unitSize}
				onChange={e => onChange({ unitSize: Number(e.target.value) })}
				isOnDark={false}
				className='w-3/4 pt-0'
				inputClassName='text-right rounded-r-none'
			/>
			<Input
				type='text'
				value={unit}
				onChange={e => onChange({ unit: e.target.value })}
				isOnDark={false}
				className='w-1/4 pt-0'
				inputClassName='rounded-l-none'
			/>
		</div>
	)
}

export default MapGridUnit
