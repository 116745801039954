import { useQuery } from '@apollo/client'
import { Loader2, Trash2 } from 'lucide-react'
import { FC, useState } from 'react'
import { BookResource } from '../../../../shared/types/resources'
import { ICollection } from '../../../../shared/types/system'
import { GET_RESOURCE } from '../../graphql/resources'
import { useGame } from '../../hooks/useGame'
import Button from '../FormComponents/Button'
import PDFSheetConfigModal from './PDFSheetConfigModal'

interface PDFSheetRowProps {
	id: string
	resourceId: string
	collection: ICollection
	onDelete: (id: string) => void
}

interface GetResourceResponse {
	getResource: BookResource
}

const PDFSheetRow: FC<PDFSheetRowProps> = ({
	id,
	resourceId,
	collection,
	onDelete,
}) => {
	const [isEditing, setIsEditing] = useState(false)
	const { dispatch } = useGame()

	const { loading, error, data } = useQuery<GetResourceResponse>(GET_RESOURCE, {
		variables: { resourceId },
		skip: !resourceId,
		onError: error => {
			console.error(`PDFSheetRow [${id}] query error:`, {
				message: error.message,
				graphQLErrors: error.graphQLErrors,
				networkError: error.networkError,
			})
		},
	})

	if (loading) {
		return (
			<div className='flex items-center justify-between rounded bg-gray-800 p-2'>
				<div className='flex items-center gap-2 p-2'>
					<Loader2 className='h-4 w-4 animate-spin' />
					<span className='text-gray-400'>Loading...</span>
				</div>
			</div>
		)
	}

	if (error || !data?.getResource) {
		return (
			<div className='flex items-center justify-between rounded bg-red-900/50 p-2'>
				<div className='flex-grow p-2 text-red-200'>
					Error loading PDF sheet: {error?.message || 'Resource not found'}
				</div>
				<Button
					className='w-12 text-gray-400 hover:text-red-400'
					onClick={() => onDelete(id)}
				>
					<Trash2 size={16} />
				</Button>
			</div>
		)
	}

	const resource = data.getResource

	const handleSave = async (updatedCollection: ICollection) => {
		try {
			const formFields = resource.metadata?.pdf?.formFields
			if (!formFields || formFields.length === 0) {
				throw new Error(
					'This PDF does not contain any form fields. Only form-fillable PDFs can be used as PDF sheets.',
				)
			}

			dispatch({
				type: 'UPDATE_PDF_SHEET',
				payload: {
					id,
					collection: updatedCollection,
				},
			})
			setIsEditing(false)
		} catch (error) {
			console.error('Error updating PDF sheet:', error)
		}
	}

	return (
		<>
			<div className='group flex items-center justify-between rounded-lg bg-black/10'>
				<div
					className='flex-grow cursor-pointer rounded p-3'
					onClick={() => {
						setIsEditing(true)
					}}
				>
					{collection.singularName || resource.name || 'Unnamed'}
				</div>
				<button
					className='mt-0 flex h-full items-center justify-center rounded bg-transparent p-3 text-red-500 hover:bg-red-500 hover:text-white'
					onClick={() => onDelete(id)}
				>
					<Trash2 size={20} />
				</button>
			</div>

			{isEditing && (
				<PDFSheetConfigModal
					onClose={() => setIsEditing(false)}
					onSave={handleSave}
					initialValues={collection}
					resource={resource}
				/>
			)}
		</>
	)
}

export default PDFSheetRow
