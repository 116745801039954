import { Container, Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'

const BALL_RADIUS = 0.4
const PIN_TOP_WIDTH = 0.08
const PIN_BOTTOM_WIDTH = PIN_TOP_WIDTH / 2
const PIN_HEIGHT = 1.3
const PIN_BASE_RADIUS = PIN_BOTTOM_WIDTH
const PIN_LIGHT = 0xcccccc // Light metallic
const PIN_DARK = 0x888888 // Dark metallic

interface PinProps {
	x: number
	y: number
}

// The red ball at the top
const Ball = () => {
	const draw = useCallback((g: PIXI.Graphics) => {
		g.clear()
		g.beginFill(0xff0000)
		g.drawCircle(0, -PIN_HEIGHT, BALL_RADIUS)
		g.endFill()
	}, [])

	return <Graphics draw={draw} />
}

// The pin body with shading
const PinBody = () => {
	const draw = useCallback((g: PIXI.Graphics) => {
		const angle = Math.acos(PIN_BOTTOM_WIDTH / PIN_BASE_RADIUS)
		const tangentY = -PIN_BASE_RADIUS * Math.sin(angle)

		// Main body
		g.clear()
		g.beginFill(PIN_LIGHT)
		g.moveTo(-PIN_TOP_WIDTH, -PIN_HEIGHT)
		g.lineTo(PIN_TOP_WIDTH, -PIN_HEIGHT)
		g.lineTo(PIN_BOTTOM_WIDTH, tangentY)
		g.lineTo(-PIN_BOTTOM_WIDTH, tangentY)
		g.lineTo(-PIN_TOP_WIDTH, -PIN_HEIGHT)
		g.endFill()

		// Shading
		g.beginFill(PIN_DARK, 0.3)
		g.moveTo(-PIN_TOP_WIDTH, -PIN_HEIGHT)
		g.lineTo(0, -PIN_HEIGHT)
		g.lineTo(0, tangentY)
		g.lineTo(-PIN_BOTTOM_WIDTH, tangentY)
		g.lineTo(-PIN_TOP_WIDTH, -PIN_HEIGHT)
		g.endFill()
	}, [])

	return <Graphics draw={draw} />
}

// The circular base with shading
const Base = () => {
	const draw = useCallback((g: PIXI.Graphics) => {
		// Base circle
		g.clear()
		g.beginFill(PIN_LIGHT)
		g.drawCircle(0, 0, PIN_BASE_RADIUS)
		g.endFill()

		// Shading on the left half
		g.beginFill(PIN_DARK, 0.3)
		g.arc(0, 0, PIN_BASE_RADIUS, Math.PI / 2, -Math.PI / 2)
		g.endFill()
	}, [])

	return <Graphics draw={draw} />
}

export default function Pin({ x, y }: PinProps) {
	return (
		<Container x={x} y={y}>
			<Base />
			<PinBody />
			<Ball />
		</Container>
	)
}
