import { useFormContext } from 'react-hook-form'
import { initialTokenLibrary } from '../../data/tokenSets'
import useTheme from '../../hooks/useTheme'
import SmallUppercase from '../interface/text/SmallUppercase'

const TokenSetList = () => {
	const { register } = useFormContext()
	const { primary } = useTheme()
	const selectedSet = useFormContext().watch('selection', 'All')

	const sets = [
		{ id: 'All', name: 'All' },
		...initialTokenLibrary.sets.allIds.map(id => ({
			id,
			name: initialTokenLibrary.sets.byId[id].name,
		})),
	]

	return (
		<div className='mb-4 flex flex-wrap justify-center gap-x-0 gap-y-0.5'>
			{sets.map(({ id, name }) => (
				<li key={id} className='inline-block'>
					<input
						className='hidden'
						type='radio'
						value={id}
						id={id}
						{...register('selection')}
					/>
					<label
						htmlFor={id}
						className='flex cursor-pointer items-center px-1.5 transition-colors duration-100'
					>
						<SmallUppercase
							className='mt-px w-full text-center'
							style={{
								color: selectedSet === id ? 'black' : primary,
							}}
						>
							{name}
						</SmallUppercase>
					</label>
				</li>
			))}
		</div>
	)
}

export default TokenSetList
