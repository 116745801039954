import SoloBanner from '../../../../assets/frontsite/solobanner.png'
import HeroSection from '../HeroSection'

const Solo = () => {
	return (
		<HeroSection
			backgroundImage={SoloBanner}
			// eyebrow='Shared Worlds'
			title='Your Tabletop'
			body={
				<>
					Delightfully simple
					<br />
					dice, sheets and maps.
				</>
			}
			buttonText='Get Started'
			buttonLink='/signup'
			gradientPosition='left'
			reversed
		/>
	)
}

export default Solo
