import { memo } from 'react'
import useItemFilter from '../../hooks/UseItemFilter'
import ListDivider from '../ListDivider'
import List from './List'

interface DocumentGroup {
	[key: string]: any[]
}

interface DocumentListProps {
	selectedType: string
	groupedDocuments: DocumentGroup
	collectionTypes: any[]
	dragIndex: number
	hoverIndex: number
	hoverItem: (dragId: string, hoverId: string, before?: 'up' | 'down') => void
	moveItem: ({ id, targetId, parentId, before }: any) => void
}

const DocumentList = memo(
	({
		selectedType,
		groupedDocuments,
		collectionTypes,
		dragIndex,
		hoverIndex,
		hoverItem,
		moveItem,
	}: DocumentListProps) => {
		const { checkAccess } = useItemFilter()

		if (selectedType === 'All') {
			return (
				<div className='space-y-2'>
					{Object.entries(groupedDocuments)
						.filter(([type, docs]) => {
							if (type === 'Books') return false
							// Only show collections where the user has access to at least one document
							return docs.some(doc => checkAccess(doc))
						})
						.map(([type]) => {
							const collection = collectionTypes.find(c => c.type === type)
							return (
								<div key={type}>
									<ListDivider text={collection?.pluralName || type} />
									<List
										parentId='library'
										dragIndex={dragIndex}
										hoverIndex={hoverIndex}
										hoverItem={hoverItem}
										moveItem={moveItem}
										selectedType={type}
									/>
								</div>
							)
						})}
				</div>
			)
		}

		// When filtering by type, always show the section divider
		const collection = collectionTypes.find(c => c.type === selectedType)
		return (
			<div className='space-y-2'>
				<ListDivider text={collection?.pluralName || selectedType} />
				<List
					parentId='library'
					dragIndex={dragIndex}
					hoverIndex={hoverIndex}
					hoverItem={hoverItem}
					moveItem={moveItem}
					selectedType={selectedType}
				/>
			</div>
		)
	},
)

DocumentList.displayName = 'DocumentList'

export default DocumentList
