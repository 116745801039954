import { ReactNode, useContext } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import AuthContext from '../contexts/auth'
import { UserLevel } from '../interfaces/auth'

interface ProtectedAdminRouteProps {
	children: ReactNode
}

export const ProtectedAdminRoute = ({
	children,
}: ProtectedAdminRouteProps): JSX.Element => {
	const { authState } = useContext(AuthContext)
	const location = useLocation()

	if (!authState.token || authState.userDetails.userLevel !== UserLevel.ADMIN) {
		return <Navigate to='/signin' state={{ from: location }} replace />
	}

	return <>{children}</>
}
