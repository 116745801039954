import RealmsLogo from '../../assets/logo/realmslogo2.png'
import Button from '../FormComponents/Button'
import BaseType from '../interface/text/BaseType'
import MediumUppercase from '../interface/text/MediumUppercase'
import ModalWindow from '../window/ModalWindow'

interface Props {
	isOpen: boolean
	onClose: () => void
	onSetup: () => void
}

const WelcomeCreatorModal = ({ isOpen, onClose, onSetup }: Props) => {
	return (
		<ModalWindow
			id='welcome-creator-modal'
			title=''
			isOpen={isOpen}
			onClose={onClose}
			dimensions={{ width: 425, height: 533 }}
			className='rounded-3xl'
			hideHeader
		>
			<div className='relative flex h-full flex-col items-center justify-center gap-4 overflow-hidden bg-black/5 px-16'>
				<img src={RealmsLogo} alt='Realms Logo' className='mx-auto mb-8 w-24' />

				<div className='relative z-10 space-y-4 text-center'>
					<h1 className='text-4xl font-semibold'>Welcome</h1>
					<BaseType className='text-black/50'>
						Review the game settings,
						<br /> and you're ready to play.
					</BaseType>
				</div>

				<div className='mt-12 flex w-48 flex-col gap-2'>
					<Button
						onClick={onClose}
						className='mt-0 flex-1 rounded-full bg-transparent text-black text-black/50 transition-all duration-300 hover:bg-black/5 hover:text-black'
					>
						<MediumUppercase>Skip</MediumUppercase>
					</Button>
					<Button onClick={onSetup} className='mt-0 flex-1 rounded-full'>
						<MediumUppercase>Setup</MediumUppercase>
					</Button>
				</div>
			</div>
		</ModalWindow>
	)
}

export default WelcomeCreatorModal
