import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import useProcessBook from '../hooks/useProcessBook'
import { generateUUID } from '../utils/generateUUID'
import type { ToastContextType } from './toast'
import { useToast } from './toast'

const getUUID = () => {
	try {
		return crypto.randomUUID()
	} catch (e) {
		return generateUUID()
	}
}

export interface QueueItem {
	file: File
	id: string
	status: 'queued' | 'uploading' | 'processing' | 'completed' | 'error'
	uploadProgress?: number
	processingProgress?: number
	error?: string
	uploadStartTime?: Date
	processingStartTime?: Date
	completionTime?: Date
}

interface QueueContextType {
	addToQueue: (files: File[]) => void
	removeFromQueue: (id: string) => void
	queueLength: number
	items: Record<string, QueueItem>
	clearQueue: () => void
	hasReachedUploadLimit: boolean
}

export const QueueContext = createContext<QueueContextType | undefined>(
	undefined,
)

const showError = (addToast: ToastContextType['addToast'], error: Error) => {
	addToast({
		id: Math.random().toString(36).substring(7),
		message: error.message,
		type: 'error',
		requireDismiss: true,
	})
}

const showSuccess = (
	addToast: ToastContextType['addToast'],
	message: string,
) => {
	addToast({
		id: Math.random().toString(36).substring(7),
		message,
		type: 'success',
	})
}

export function QueueProvider({ children }: { children: ReactNode }) {
	const [queueState, setQueueState] = useState<{
		items: Record<string, QueueItem>
		activeUploads: number
	}>({
		items: {},
		activeUploads: 0,
	})
	const { processBook } = useProcessBook()
	const { addToast } = useToast()

	const addToQueue = useCallback(
		(files: File[]) => {
			try {
				setQueueState(prev => {
					const newItems = files.reduce(
						(acc, file) => ({
							...acc,
							[getUUID()]: {
								file,
								id: getUUID(),
								status: 'queued' as const,
							},
						}),
						{},
					)

					return {
						...prev,
						items: {
							...prev.items,
							...newItems,
						},
					}
				})
				showSuccess(
					addToast,
					`Added ${files.length} file(s) to queue: ${files
						.map(f => f.name)
						.join(', ')}`,
				)
			} catch (error) {
				console.error('Error adding files to queue:', error)
				showError(addToast, error)
			}
		},
		[addToast],
	)

	const removeFromQueue = useCallback((id: string) => {
		setQueueState(prev => {
			const remaining = { ...prev.items }
			delete remaining[id]
			return {
				...prev,
				items: remaining,
			}
		})
	}, [])

	const clearQueue = useCallback(() => {
		setQueueState(prev => ({
			...prev,
			items: Object.fromEntries(
				Object.entries(prev.items).filter(
					([, item]) =>
						item.status === 'uploading' || item.status === 'processing',
				),
			),
		}))
		showSuccess(addToast, 'Queue cleared')
	}, [addToast])

	// Process next item in queue
	const processNext = useCallback(async () => {
		const nextToUpload = Object.entries(queueState.items).find(
			([, item]) => item.status === 'queued',
		)

		if (!nextToUpload || queueState.activeUploads >= 3) return

		const [id, item] = nextToUpload

		setQueueState(prev => ({
			...prev,
			activeUploads: prev.activeUploads + 1,
			items: {
				...prev.items,
				[id]: {
					...item,
					status: 'uploading',
					uploadStartTime: new Date(),
				},
			},
		}))

		try {
			const result = await processBook(
				item.file,
				{
					generateSummary: false,
					resourceType: 'book',
				},
				progress => {
					setQueueState(prev => ({
						...prev,
						items: {
							...prev.items,
							[id]: {
								...prev.items[id],
								uploadProgress: progress,
							},
						},
					}))
				},
			)

			if (result?.resource) {
				setQueueState(prev => {
					const remaining = { ...prev.items }
					delete remaining[id]
					return {
						...prev,
						activeUploads: prev.activeUploads - 1,
						items: remaining,
					}
				})
				showSuccess(addToast, `Successfully uploaded ${item.file.name}`)
			} else {
				throw new Error(`Failed to process ${item.file.name}`)
			}
		} catch (error) {
			console.error('Error processing file:', error)
			// Don't show error toast for user cancellations
			if (!(error instanceof Error && error.name === 'UserCancelled')) {
				const errorMessage =
					error instanceof Error
						? `Error processing ${item.file.name}: ${error.message}`
						: `Failed to process ${item.file.name}`
				showError(addToast, new Error(errorMessage))
			}
			setQueueState(prev => ({
				...prev,
				activeUploads: prev.activeUploads - 1,
				items: {
					...prev.items,
					[id]: {
						...prev.items[id],
						status: 'error',
						error: error instanceof Error ? error.message : 'Unknown error',
					},
				},
			}))
		}
	}, [queueState.items, queueState.activeUploads, processBook, addToast])

	// Monitor queue state and process next items
	useEffect(() => {
		const queuedItems = Object.values(queueState.items).filter(
			item => item.status === 'queued',
		)

		if (queuedItems.length > 0 && queueState.activeUploads < 3) {
			processNext()
		}
	}, [queueState.items, queueState.activeUploads, processNext])

	return (
		<QueueContext.Provider
			value={{
				addToQueue,
				removeFromQueue,
				queueLength: Object.values(queueState.items).filter(
					item => item.status === 'queued',
				).length,
				items: queueState.items,
				clearQueue,
				hasReachedUploadLimit: queueState.activeUploads >= 3,
			}}
		>
			{children}
		</QueueContext.Provider>
	)
}

export function useQueue() {
	const context = useContext(QueueContext)
	if (context === undefined) {
		throw new Error('useQueue must be used within a QueueProvider')
	}
	return context
}

export default QueueProvider
