import { Upload } from 'lucide-react'
import { ChangeEvent, useRef } from 'react'
import { useQueue } from '../../../../contexts/queue'
import Button from '../../../FormComponents/Button'

const AddBookButton = () => {
	const fileInputRef = useRef<HTMLInputElement>(null)
	const { addToQueue, hasReachedUploadLimit } = useQueue()

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		if (files && files.length > 0) {
			try {
				addToQueue(Array.from(files))
			} catch (error) {
				console.error('Error processing file(s):', error)
				alert('Error processing file(s)')
			} finally {
				if (fileInputRef.current) {
					fileInputRef.current.value = ''
				}
			}
		}
	}

	const handleClick = () => {
		if (hasReachedUploadLimit) return
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	return (
		<div>
			<Button
				className='mt-0 w-12 bg-gray-700 transition-all duration-300 hover:bg-gray-600 disabled:cursor-not-allowed disabled:opacity-50'
				onClick={handleClick}
				disabled={hasReachedUploadLimit}
			>
				<Upload size={22} />
			</Button>
			<input
				type='file'
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
				accept='.pdf'
				multiple
			/>
		</div>
	)
}

export default AddBookButton
