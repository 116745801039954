import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface TabButtonProps {
	id: string
	label: string
	icon?: ReactNode
	accessory?: string
	isActive?: boolean
	isActionButton?: boolean
	onClick: () => void
}

const TabButton = ({
	id,
	label,
	icon,
	accessory,
	isActive,
	isActionButton,
	onClick,
}: TabButtonProps) => {
	const baseClasses =
		'-ml-4 flex w-full items-center gap-3 rounded-2xl px-4 py-3 text-left text-sm transition-colors'

	const stateClasses = isActionButton
		? 'text-gray-600 hover:bg-black/5 hover:text-gray-900'
		: isActive
		? 'bg-black/10 text-gray-900'
		: 'text-gray-600 hover:bg-black/5 hover:text-gray-900'

	return (
		<button
			key={id}
			role={isActionButton ? undefined : 'tab'}
			aria-selected={isActionButton ? undefined : isActive}
			aria-controls={isActionButton ? undefined : `tabpanel-${id}`}
			id={isActionButton ? undefined : `tab-${id}`}
			onClick={onClick}
			className={twMerge(baseClasses, stateClasses)}
		>
			<div className='flex flex-1 items-center gap-3'>
				{icon && icon}
				{label}
			</div>
			{accessory && <div className='text-gray-400'>{accessory}</div>}
		</button>
	)
}

export default TabButton
