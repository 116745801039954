import { useContext, useEffect, useRef, useState } from 'react'
import { useDrop } from 'react-dnd'
import GameContext from '../../contexts/game'
import useUser from '../../hooks/useUser'
import { generateUUID } from '../../utils/generateUUID'
import ListDivider from '../ListDivider'
import ItemTypes from '../draganddrop/ItemTypes'
import ListMessage from '../views/ListMessage'
import Item from './Item'

interface DragItem {
	id: string
}

const GMTurnTracker = () => {
	const { game, dispatch } = useContext(GameContext)
	const { turnOrder, currentRound, currentTurnIndex } = game.turnTracker
	const listRef = useRef<HTMLDivElement>(null)
	const { isGM } = useUser()
	const [hasInitialized, setHasInitialized] = useState(false)

	useEffect(() => {
		requestAnimationFrame(() => {
			setHasInitialized(true)
		})
	}, [])

	const handleDrop = (item: DragItem, monitor) => {
		const itemType = monitor.getItemType()

		if (itemType === ItemTypes.DOCUMENT) {
			const document = game.documents.byId[item.id]
			if (!document?.values) return
			const { name } = document.values
			dispatch({
				type: 'ADD_TO_TURN_ORDER',
				payload: {
					item: {
						id: generateUUID(),
						name: name || 'Unnamed',
						initiative: 0,
						isActive: true,
						visibility: true,
						isTurn: false,
						identified: true,
						documentId: item.id,
						relationship: 'neutral',
					},
					index: turnOrder.allIds.length, // add to end
				},
			})
		}
	}

	const [, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT, ItemTypes.TURN_ORDER_ITEM],
		drop: handleDrop,
	})

	drop(listRef)

	const renderTurnOrder = () => {
		if (turnOrder.allIds.length === 0) {
			return (
				<div className='flex h-full flex-col items-center justify-center'>
					<ListMessage title='Drop Participants Here' />
				</div>
			)
		}

		const items = turnOrder.allIds
			.map((id, index) => {
				const item = turnOrder.byId[id]
				if (!item) {
					console.error(`No item found for id: ${id}`)
					return null
				}

				// Calculate which round this item belongs to
				const itemRound = Math.floor(
					index < currentTurnIndex ? currentRound + 1 : currentRound,
				)

				return {
					component: <Item id={item.id} key={item.id} />,
					round: itemRound,
				}
			})
			.filter(Boolean)

		// Group items by round
		const rounds = items.reduce((acc, item) => {
			if (!item) return acc
			if (!acc[item.round]) {
				acc[item.round] = []
			}
			acc[item.round].push(item.component)
			return acc
		}, {} as Record<number, JSX.Element[]>)

		return (
			<>
				{Object.entries(rounds).map(([round, roundItems]) => (
					<div key={round}>
						<ListDivider text={`Round ${round}`} />
						{roundItems}
					</div>
				))}
			</>
		)
	}

	if (!hasInitialized || !game.turnTracker.show || !isGM)
		false && console.log(hasInitialized, game.turnTracker.show, isGM)

	return (
		<div
			className='turn-tracker-dropzone relative flex h-full flex-col pb-2'
			ref={listRef}
		>
			{renderTurnOrder()}
		</div>
	)
}

export default GMTurnTracker
