import React from 'react'

const PrivacyPolicy: React.FC = () => {
	return (
		<section className='mx-auto max-w-4xl py-16 px-4 text-white'>
			<h1 className='mb-8 text-3xl font-bold'>Privacy Policy</h1>

			<div className='space-y-6'>
				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						1. Information We Collect
					</h2>
					<p>
						We may collect personal information you provide (e.g., account
						details, payment info) and data generated by your activities on the
						platform (e.g., usage logs, preferences).
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						2. How We Use Your Information
					</h2>
					<p>
						We use your information to operate, maintain, and improve the
						platform, process payments, provide support, and communicate updates
						or offers.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						3. Cookies and Tracking
					</h2>
					<p>
						We may use cookies, analytics tools, and similar technologies to
						understand usage patterns, improve user experience, and personalize
						content.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						4. Sharing Your Information
					</h2>
					<p>
						We do not sell your personal information. We may share it with
						service providers who assist us (e.g., payment processors, hosting
						services) and only as needed. We may disclose data if required by
						law or to protect our rights.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>5. Data Security</h2>
					<p>
						We use reasonable measures to protect your data. However, no online
						transmission is completely secure, and we cannot guarantee absolute
						security.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>6. Data Retention</h2>
					<p>
						We retain your data as long as necessary for the purposes described
						in this policy or as required by law.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>7. Your Choices</h2>
					<p>
						You may update or delete your personal information by adjusting your
						account settings or contacting support. You may opt out of certain
						communications at any time.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>8. Children's Privacy</h2>
					<p>
						Our platform is not intended for children under the age of majority
						in their jurisdiction. We do not knowingly collect their
						information.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						9. Changes to This Policy
					</h2>
					<p>
						We may update this policy by posting the revised version. Continuing
						to use the platform after changes become effective means you accept
						them.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>10. Contact Us</h2>
					<p>For questions or concerns, contact our support team.</p>
				</div>
			</div>
		</section>
	)
}

export default PrivacyPolicy
