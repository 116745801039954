import { FC } from 'react'
import { IGameCard } from '../../../../../shared/types/game'
import GameCardContainer from '../elements/GameCardContainer'

interface IProps {
	games: IGameCard[]
	refetch: () => void
	loading: boolean
	createdGameIds?: string[]
}

const Loading = (
	<div className='my-40 text-center text-gray-500'>Loading...</div>
)

const GamesList: FC<IProps> = ({
	games = [],
	refetch,
	loading,
	createdGameIds = [],
}) => {
	if (loading) return Loading
	if (!games || games.length === 0) {
		return <div className='my-40 text-center text-gray-500'>No Games Found</div>
	}

	const gameList = games.map(game => (
		<li key={game._id}>
			<GameCardContainer
				game={game}
				refetch={refetch}
				showCrown={createdGameIds.includes(game._id)}
			/>
		</li>
	))

	return (
		<>
			<ul className='grid grid-cols-1 transition-all duration-300 md:grid-cols-2 md:gap-8 lg:grid-cols-3 lg:gap-4 xl:gap-8'>
				{gameList}
			</ul>
		</>
	)
}

export default GamesList
