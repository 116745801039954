import { Map } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'
import ToolButton from '../interface/toolbar/ToolButton'
import Popover from '../Popover'
import Translucency from '../Translucency'
import MapEditorContent from './MapEditor/MapEditorContent'

export default function EditMapButton() {
	const [isOpen, setIsOpen] = useState(false)
	const buttonRef = useRef<HTMLDivElement>(null)
	const { dispatchTools } = useContext(ToolsContext)
	const activeScene = useGetActiveScene()
	const { isGM } = useUser()
	const hasMap = Boolean(activeScene?.values?.mapId)

	if (!hasMap || !isGM) return null

	const handleToggle = () => {
		const newIsOpen = !isOpen
		setIsOpen(newIsOpen)
		dispatchTools({ type: 'SET_MODE', payload: 'select' })
	}

	return (
		<>
			<div ref={buttonRef}>
				<ToolButton
					isOpen={isOpen}
					tooltip='Map Settings'
					onClick={handleToggle}
				>
					<Map aria-label='Map' />
				</ToolButton>
			</div>

			<Popover
				id='map-editor-popover'
				open={isOpen}
				anchor={buttonRef}
				onClose={() => {
					setIsOpen(false)
					dispatchTools({ type: 'SET_MODE', payload: 'select' })
				}}
				className={`border-none p-0 ${Translucency} pt-4`}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
				}}
				placement='top'
			>
				<MapEditorContent onClose={() => setIsOpen(false)} />
			</Popover>
		</>
	)
}
