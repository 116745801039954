import { Viewport as PixiViewport } from 'pixi-viewport'
import { useCallback, useState } from 'react'
import { IActor } from '../../../../shared/types/actor'
import { GridProvider } from '../../contexts/grid'
import { useMapDrop } from '../../hooks/useMapDrop'
import useStageMenus from '../../hooks/useStageMenus'
import useTokenDeletion from '../../hooks/useTokenDeletion'
import useTokenSelection from '../../hooks/useTokenSelection'
import MapContextMenu from './MapContextMenu/MapContextMenu'
import PixiCover from './PixiCover'
import SceneContextMenu from './SceneContextMenu/SceneContextMenu'
import Stage from './Stage'
import StageContent from './StageContent'
import StageDropZone from './StageDropZone'
import TokenContextMenu from './TokenContextMenu/TokenContextMenu'
import Viewport from './Viewport'

const StageContainer = () => {
	const [viewport, setViewport] = useState<PixiViewport | null>(null)
	const {
		tokenMenu,
		mapMenu,
		sceneMenu,
		setTokenMenu,
		setMapMenu,
		setSceneMenu,
		closeTokenMenu,
		closeMapMenu,
		closeSceneMenu,
	} = useStageMenus()
	const { selectedTokenIds, handleTokenSelect, clearSelection } =
		useTokenSelection()

	useTokenDeletion(selectedTokenIds, clearSelection)

	const handleSetViewport = useCallback((newViewport: PixiViewport | null) => {
		setViewport(newViewport)
	}, [])

	const { dropRef } = useMapDrop({ viewport })

	return (
		<StageDropZone>
			<div ref={dropRef}>
				<Stage>
					<GridProvider>
						<Viewport setViewport={handleSetViewport}>
							{viewport && (
								<StageContent
									viewport={viewport}
									setTokenMenu={setTokenMenu}
									setMapMenu={setMapMenu}
									selectedTokenIds={selectedTokenIds}
									handleTokenSelect={handleTokenSelect}
									clearSelection={clearSelection}
									activeTokenId={tokenMenu?.document._id || null}
								/>
							)}
						</Viewport>
					</GridProvider>
					<PixiCover onShowMenu={setSceneMenu} />
				</Stage>
			</div>
			{tokenMenu && (
				<TokenContextMenu
					position={tokenMenu.position}
					dimensions={tokenMenu.dimensions}
					document={tokenMenu.document as IActor}
					onClose={closeTokenMenu}
					clearSelection={clearSelection}
				/>
			)}
			{mapMenu && <MapContextMenu position={mapMenu} onClose={closeMapMenu} />}
			{sceneMenu && (
				<SceneContextMenu position={sceneMenu} onClose={closeSceneMenu} />
			)}
		</StageDropZone>
	)
}

export default StageContainer
