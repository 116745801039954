import { useFormContext } from 'react-hook-form'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import DocumentTypeListItem from './DocumentTypeListItem'

export default function DocumentTypeList() {
	const collectionTypes = useCollectionTypes()
	const { setValue } = useFormContext()

	const documentTypes = [{ type: 'All', pluralName: 'All' }, ...collectionTypes]

	return (
		<div className='flex flex-wrap justify-center gap-x-0 gap-y-0.5'>
			{documentTypes.map(({ type, pluralName }) => (
				<button key={type} onClick={() => setValue('selection', type)}>
					<DocumentTypeListItem label={pluralName} type={type} />
				</button>
			))}
		</div>
	)
}
