import { useContext } from 'react'
import AuthContext from '../contexts/auth'
import { UserLevel } from '../interfaces/auth'

const useUserLevel = () => {
	const { authState } = useContext(AuthContext)
	const userLevel = authState.userDetails.userLevel || UserLevel.ADVENTURER

	// Base level - must be logged in
	const isAtLeastAdventurer = () => !!authState.userId

	// Adept level - must be logged in and be either ADEPT or ADMIN
	const isAtLeastAdept = () =>
		isAtLeastAdventurer() && (userLevel === UserLevel.ADEPT || isAtLeastAdmin())

	// Admin level - must be logged in and be ADMIN
	const isAtLeastAdmin = () =>
		isAtLeastAdventurer() && userLevel === UserLevel.ADMIN

	const getUserLevel = () => userLevel

	return {
		isAtLeastAdventurer,
		isAtLeastAdept,
		isAtLeastAdmin,
		getUserLevel,
	}
}

export default useUserLevel
