import { Button as HeadlessButton } from '@headlessui/react'
import Color from 'color'
import React, {
	ElementType,
	forwardRef,
	useEffect,
	useRef,
	useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../../hooks/useTheme'
import Tooltip from '../../Tooltip'

type PolymorphicRef<E extends ElementType> =
	React.ComponentPropsWithRef<E>['ref']

interface BaseProps {
	className?: string
	tooltip?: string
	isOpen?: boolean
	disabled?: boolean
	isOnDark?: boolean
	size?: 'base' | 'sm'
	noHover?: boolean
	style?: React.CSSProperties
}

type PolymorphicProps<E extends ElementType> = BaseProps &
	Omit<React.ComponentPropsWithoutRef<E>, keyof BaseProps> & {
		as?: E
	}

export type ToolButtonProps = PolymorphicProps<ElementType>

function ToolButtonInner<E extends ElementType = 'button'>(
	{
		as,
		className,
		tooltip,
		isOpen,
		disabled,
		isOnDark = false,
		size = 'base',
		noHover = false,
		style,
		...rest
	}: PolymorphicProps<E>,
	forwardedRef: PolymorphicRef<E>,
) {
	const [showTooltip, setShowTooltip] = useState(false)
	const [isHovered, setIsHovered] = useState(false)
	const { primary } = useTheme()
	const fadedPrimary = Color(primary).alpha(0.2).toString()
	const elementRef = useRef<HTMLElement | null>(null)
	const [strokeOffset, setStrokeOffset] = useState('100')
	const [circleOpacity, setCircleOpacity] = useState(0)
	const TheComponent = as === 'button' ? HeadlessButton : as || 'button'
	const buttonSize = size === 'base' ? 56 : 32
	const strokeWidth = 3

	useEffect(() => {
		if (isOpen) {
			setCircleOpacity(1)
			setStrokeOffset('100')
			requestAnimationFrame(() => {
				requestAnimationFrame(() => setStrokeOffset('0'))
			})
		} else {
			setStrokeOffset('100')
			const timer = setTimeout(() => setCircleOpacity(0), 300)
			return () => clearTimeout(timer)
		}
	}, [isOpen])

	useEffect(() => {
		if (!forwardedRef) return
		if (typeof forwardedRef === 'function') {
			forwardedRef(elementRef.current)
		} else {
			;(forwardedRef as React.MutableRefObject<HTMLElement | null>).current =
				elementRef.current
		}
	}, [forwardedRef])

	return (
		<>
			<TheComponent
				ref={elementRef as any}
				className={twMerge(
					'pointer-events-auto relative flex items-center justify-center overflow-hidden rounded-full p-1',
					size === 'base' ? 'h-14 w-14' : 'h-8 w-8',
					isOnDark ? 'text-white hover:bg-white/20' : 'text-gray-800',
					disabled && 'cursor-not-allowed opacity-40 hover:bg-transparent',
					className,
				)}
				onMouseEnter={() => {
					setShowTooltip(true)
					setIsHovered(true)
				}}
				onMouseLeave={() => {
					setShowTooltip(false)
					setIsHovered(false)
				}}
				style={{
					backgroundColor:
						!isOnDark && isHovered && !disabled && !noHover ? fadedPrimary : '',
					...style,
				}}
				{...rest}
			>
				{rest.children}
				<svg
					className='pointer-events-none absolute left-0 top-0'
					width={buttonSize}
					height={buttonSize}
					style={{
						transform: 'rotate(-90deg)',
						opacity: circleOpacity,
						transition: 'opacity 0s',
					}}
				>
					<circle
						cx={buttonSize / 2}
						cy={buttonSize / 2}
						r={(buttonSize - strokeWidth) / 2}
						fill='none'
						stroke={primary}
						strokeWidth={strokeWidth}
						strokeLinecap='round'
						pathLength='100'
						strokeDasharray='100'
						strokeDashoffset={strokeOffset}
						style={{
							transition: 'stroke-dashoffset 0.3s ease-in-out',
						}}
					/>
				</svg>
			</TheComponent>
			{tooltip && (
				<Tooltip
					open={showTooltip && !isOpen}
					anchor={elementRef as any}
					placement='bottom'
				>
					{tooltip}
				</Tooltip>
			)}
		</>
	)
}

const ToolButton = forwardRef(ToolButtonInner)
ToolButton.displayName = 'ToolButton'
export default ToolButton
