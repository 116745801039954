import { Bug } from 'lucide-react'
import { useState } from 'react'
import FeedbackModal from './feedback/FeedbackModal'
import ToolButton from './interface/toolbar/ToolButton'

export default function FeedbackButton() {
	const [isModalOpen, setIsModalOpen] = useState(false)

	return (
		<>
			<ToolButton tooltip='Send Feedback' onClick={() => setIsModalOpen(true)}>
				<Bug aria-hidden='true' />
			</ToolButton>
			<FeedbackModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
		</>
	)
}
