import { ReactNode, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ModalWindow, { IModal } from './ModalWindow'
import TabButton from './TabButton'

interface Tab {
	id: string
	label: string
	icon?: ReactNode
	content: ReactNode
	onClick?: () => void
	accessory?: string
}

type SidebarModalSize = 'large' | 'full'

interface ISidebarModal extends Omit<IModal, 'children' | 'size'> {
	tabs: Tab[]
	sidebarWidth?: number
	defaultTabId?: string
	sidebarClassName?: string
	contentClassName?: string
	size?: SidebarModalSize
	onTransitionEnd?: () => void
	sidebarHeader?: ReactNode
}

const SidebarModalWindow = ({
	tabs,
	sidebarWidth = 200,
	defaultTabId,
	sidebarClassName,
	contentClassName,
	size = 'large',
	onTransitionEnd,
	sidebarHeader,
	...modalProps
}: ISidebarModal) => {
	const [activeTabId, setActiveTabId] = useState(defaultTabId || tabs[0].id)
	const activeTab = tabs.find(tab => tab.id === activeTabId)

	const handleTabClick = (tab: Tab) => {
		if (tab.onClick) {
			tab.onClick()
		} else {
			setActiveTabId(tab.id)
		}
	}

	return (
		<ModalWindow
			{...modalProps}
			size={size}
			className='aspect-[3/2]'
			bodyClassName='overflow-hidden p-0'
			innerBodyClassName='p-0'
			onTransitionEnd={onTransitionEnd}
		>
			<div className='flex h-full gap-8 pl-8'>
				{/* Sidebar */}
				<div
					role='tablist'
					className={twMerge(
						'flex h-full flex-shrink-0 flex-col gap-1 pb-4',
						sidebarClassName,
					)}
					style={{ width: sidebarWidth }}
				>
					{sidebarHeader}
					{/* Regular tabs */}
					<div className='flex flex-col gap-1'>
						{tabs
							.filter(tab => !tab.onClick)
							.map(tab => (
								<TabButton
									key={tab.id}
									id={tab.id}
									label={tab.label}
									icon={tab.icon}
									accessory={tab.accessory}
									isActive={tab.id === activeTabId}
									onClick={() => handleTabClick(tab)}
								/>
							))}
					</div>

					{/* Action buttons at bottom */}
					<div className='mt-auto flex flex-col gap-1'>
						{tabs
							.filter(tab => tab.onClick)
							.map(tab => (
								<TabButton
									key={tab.id}
									id={tab.id}
									label={tab.label}
									icon={tab.icon}
									isActionButton
									onClick={() => handleTabClick(tab)}
								/>
							))}
					</div>
				</div>

				{/* Content */}
				<div
					className={twMerge(
						'flex flex-1 flex-col overflow-y-auto pr-8 pb-8 pt-8',
						contentClassName,
					)}
					role='tabpanel'
					id={`tabpanel-${activeTabId}`}
					aria-labelledby={`tab-${activeTabId}`}
				>
					{activeTab?.content}
				</div>
			</div>
		</ModalWindow>
	)
}

export default SidebarModalWindow
