import { ChangeEvent, useCallback, useContext } from 'react'
import { IAsset } from '../../../../../shared/types/asset'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import AssetManager from '../../AssetManager'
import Input from '../../FormComponents/Input'

interface HandoutEditorProps {
	doc: IDocument
}

const HandoutEditor = ({ doc }: HandoutEditorProps) => {
	const { dispatch } = useContext(GameContext)

	const handleNameChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...doc,
						version: doc.version + 1,
						values: {
							...doc.values,
							name: e.target.value,
						},
					},
				},
			})
		},
		[dispatch, doc],
	)

	const handleAssetChange = useCallback(
		(asset?: IAsset) => {
			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...doc,
						version: doc.version + 1,
						values: {
							...doc.values,
							asset: asset?._id,
						},
					},
				},
			})
		},
		[dispatch, doc],
	)

	return (
		<div className='flex flex-col gap-4 p-4'>
			<div>
				<label className='mb-1 block text-black'>Name</label>
				<Input
					type='text'
					value={doc.values.name || ''}
					onChange={handleNameChange}
					placeholder='Untitled Handout'
					isOnDark={false}
				/>
			</div>

			<div>
				<label className='mb-1 block text-black'>Asset</label>
				<AssetManager
					assetId={doc.values.asset}
					onAssetChange={handleAssetChange}
					className='mx-auto max-h-48'
					assetClassName='object-contain'
				/>
			</div>
		</div>
	)
}

export default HandoutEditor
