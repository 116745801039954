import { useCallback, useState } from 'react'

const useApi = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const request = useCallback(
		async (url: RequestInfo | URL, options: RequestInit = {}) => {
			const defaultOptions = {
				method: 'GET',
				credentials: 'include' as RequestCredentials,
				headers: {
					'Content-Type': 'application/json',
				},
			}

			const mergedOptions = {
				...defaultOptions,
				...options,
				headers: {
					...defaultOptions.headers,
					...options.headers,
				},
			}

			setLoading(true)
			setError(null)

			try {
				const response = await fetch(url, mergedOptions)

				// Try to parse as JSON, but handle text responses too
				let data
				const contentType = response.headers.get('content-type')
				if (contentType && contentType.includes('application/json')) {
					data = await response.json()
				} else {
					const text = await response.text()
					data = { error: text }
				}

				if (!response.ok) {
					throw new Error(
						data.error || `HTTP error! status: ${response.status}`,
					)
				}

				return data
			} catch (err) {
				const error = err as Error
				console.error('API Error:', error.message)
				setError(error)
				throw error
			} finally {
				setLoading(false)
			}
		},
		[],
	)

	return { request, loading, error }
}

export default useApi
