import { useMemo } from 'react'
import { TAccess } from '../../../shared/types/document'
import { PdfSheet } from '../../../shared/types/pdfSheet'
import { stringBoolean } from '../../../shared/types/stringBoolean'
import { ICollection } from '../../../shared/types/system'
import { useGame } from './useGame'

export const usePdfCollections = () => {
	const { game } = useGame()

	const collections = useMemo(() => {
		if (!game.pdfSheets?.byId) {
			return []
		}

		const allCollections = Object.values(game.pdfSheets.byId).map(
			(sheet: PdfSheet) => {
				return {
					...sheet.collection,
					singularName: sheet.collection.singularName,
					defaultAccess: 'private' as TAccess,
					allowCreate: 'true' as stringBoolean,
					description: sheet.collection.description || '',
					acceptTypes: sheet.collection.acceptTypes || [],
					hasEditMode: 'false' as stringBoolean,
					thumbnailField: ['token'] || [],
				}
			},
		)

		const uniqueCollections = new Map<string, ICollection>()
		allCollections.forEach(collection => {
			if (collection.type && !uniqueCollections.has(collection.type)) {
				uniqueCollections.set(collection.type, collection as ICollection)
			}
		})

		const result = Array.from(uniqueCollections.values())
		return result
	}, [game.pdfSheets?.byId])

	return collections
}
