import * as Toggle from '@radix-ui/react-toggle'
import { Pencil, PencilOff } from 'lucide-react'
import { TDocumentModes } from '../../../shared/types/document'
import ToolButton from './interface/toolbar/ToolButton'
import TooltipWrapper from './TooltipWrapper'
import { windowButtonStyles } from './window/Button'

export interface IWindowModeToggleProps {
	documentMode: TDocumentModes
	setDocumentMode: (mode: TDocumentModes) => void
}

export default function WindowModeToggle(props: IWindowModeToggleProps) {
	const { documentMode, setDocumentMode } = props

	// Handler for toggle changes
	const handleToggleChange = () => {
		const nextMode = documentMode === 'view' ? 'edit' : 'view'
		setDocumentMode(nextMode)
	}

	return (
		<TooltipWrapper label={documentMode === 'edit' ? 'Done Editing' : 'Edit'}>
			<Toggle.Root
				className={windowButtonStyles + ' text-gray-900'}
				aria-label='Document Mode'
				onClick={handleToggleChange}
				pressed={documentMode === 'edit'}
			>
				<ToolButton>
					{documentMode === 'edit' ? (
						<Pencil className='h-4 w-4 self-center' aria-hidden='true' />
					) : (
						<PencilOff className='h-4 w-4 self-center' aria-hidden='true' />
					)}
				</ToolButton>
			</Toggle.Root>
		</TooltipWrapper>
	)
}
