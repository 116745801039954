import { Point } from 'pixi.js'
import { Square, SquareCoordinates } from './Square'

export interface GridOptions {
	size: number
}

export class SquareGrid {
	private squares: Map<string, Square> = new Map()
	readonly size: number

	constructor(options: GridOptions) {
		this.size = options.size
	}

	pointToSquare(point: Point): Square | undefined {
		const col = Math.floor(point.x / this.size)
		const row = Math.floor(point.y / this.size)
		return this.getSquare({ col, row })
	}

	getSquare(coordinates: SquareCoordinates): Square {
		const key = `${coordinates.col},${coordinates.row}`
		let square = this.squares.get(key)

		if (!square) {
			square = new Square(coordinates, this.size)
			this.squares.set(key, square)
		}

		return square
	}

	rectangle(options: {
		width: number
		height: number
		start?: SquareCoordinates
	}): Square[] {
		const start = options.start || { col: 0, row: 0 }
		const squares: Square[] = []

		for (let row = start.row; row < start.row + options.height; row++) {
			for (let col = start.col; col < start.col + options.width; col++) {
				squares.push(this.getSquare({ col, row }))
			}
		}

		return squares
	}

	neighbors(square: Square): Square[] {
		const directions = [
			{ col: 0, row: -1 }, // top
			{ col: 1, row: 0 }, // right
			{ col: 0, row: 1 }, // bottom
			{ col: -1, row: 0 }, // left
		]

		return directions
			.map(dir => ({
				col: square.col + dir.col,
				row: square.row + dir.row,
			}))
			.map(coords => this.getSquare(coords))
	}

	distance(a: Square, b: Square): number {
		// Manhattan distance for square grid
		return Math.abs(a.col - b.col) + Math.abs(a.row - b.row)
	}
}

export function defineGrid(options?: Partial<GridOptions>): SquareGrid {
	const defaultOptions: GridOptions = {
		size: 50,
	}
	return new SquareGrid({ ...defaultOptions, ...options })
}
