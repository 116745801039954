import { forwardRef, useContext } from 'react'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'
import PenWithColor from './PenWithColor'

const DrawModeButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { toolsState, dispatchTools } = useContext(ToolsContext)
		const { dispatch } = useContext(GameContext)
		const scene = useGetActiveScene()
		const { mode } = toolsState
		const { color } = useUser()

		const handleDrawingModeClick = () => {
			if (mode !== 'draw') {
				console.log('Draw mode activated: Starting in freehand mode')
				dispatchTools({ type: 'SET_MODE', payload: 'draw' })
			} else {
				// Clean up deleted marks when exiting draw mode
				if (scene) {
					const currentMarks = scene.values.marks?.marks || []
					const cleanedMarks = currentMarks.filter(mark => !mark.deleted)

					dispatch({
						type: 'UPDATE_DOCUMENT',
						payload: {
							updatedDocument: {
								...scene,
								version: scene.version + 1,
								values: {
									...scene.values,
									marks: {
										marks: cleanedMarks,
									},
								},
							},
						},
					})
				}
				dispatchTools({ type: 'SET_MODE', payload: 'select' })
			}
		}

		return (
			<ToolButton
				tooltip='Draw on Map'
				isOpen={mode === 'draw'}
				onClick={handleDrawingModeClick}
				ref={ref}
			>
				<PenWithColor aria-hidden='true' lineColor={color} />
			</ToolButton>
		)
	},
)

DrawModeButton.displayName = 'DrawModeButton'

export default DrawModeButton
