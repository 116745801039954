import React from 'react'
import TextareaAutosize, {
	TextareaAutosizeProps,
} from 'react-textarea-autosize'
import { twMerge } from 'tailwind-merge'

interface IProps extends TextareaAutosizeProps {
	className?: string
	isOnDark?: boolean
}

const TextArea = React.forwardRef<HTMLTextAreaElement, IProps>(
	({ className = '', isOnDark = true, ...props }, ref) => {
		return (
			<TextareaAutosize
				ref={ref}
				className={twMerge(
					`leading-16 mt-1 mb-1 block w-full rounded-md border-none p-3 placeholder-gray-400 focus:border-blue-500 focus:ring-blue-500 sm:text-sm`,
					isOnDark ? 'bg-gray-800 text-white' : 'bg-gray-200 text-black',
					className,
				)}
				{...props}
			/>
		)
	},
)

export default TextArea
