import {
	DiceExpressionRoll,
	DiceRollResult,
	DieRoll,
	DieRollBase,
	ExpressionRoll,
	FateDieRoll,
	MathFunctionRoll,
	RollBase,
} from 'dice-roller-parser'
import React, { useContext, useMemo, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import UsersContext from '../../contexts/users'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useGetUserById from '../../hooks/useGetUserById'
import Avatar from '../Avatar'
import SmallUppercase from '../interface/text/SmallUppercase'
import MessageMenu from './MessageMenu'
// Import all dice components
import { DiceMessage } from '../../../../shared/types/log'
import D10Icon from '../dice-icons/D10Icon'
import D12Icon from '../dice-icons/D12Icon'
import D20Icon from '../dice-icons/D20Icon'
import D4Icon from '../dice-icons/D4Icon'
import D6Icon from '../dice-icons/D6Icon'
import D8Icon from '../dice-icons/D8Icon'
import Translucency from '../Translucency'

interface SimpleDiceMessageProps {
	message: DiceMessage
	onDelete: () => void
	showSender?: boolean
	className?: string
	style?: React.CSSProperties
}

const DieDisplay = ({
	roll,
	dieSize,
	color,
	isToggled,
	onToggle,
}: {
	roll: DieRollBase
	dieSize: number
	color: string
	isToggled?: boolean
	onToggle?: () => void
}) => {
	const DieIcon = useMemo(() => {
		switch (dieSize) {
			case 4:
				return D4Icon
			case 6:
				return D6Icon
			case 8:
				return D8Icon
			case 10:
				return D10Icon
			case 12:
				return D12Icon
			case 20:
				return D20Icon
			case 100:
				return D10Icon
			default:
				return D6Icon
		}
	}, [dieSize])

	const isDieRoll = (roll: DieRollBase): roll is DieRoll => {
		return 'critical' in roll
	}

	const isSuccess = roll.success
	const hasCritical = isDieRoll(roll)
	const critical = hasCritical ? roll.critical : null
	const isD100 = dieSize === 100

	return (
		<div
			className='relative mx-1 inline-block cursor-pointer align-baseline'
			onClick={onToggle}
		>
			<div
				className={twMerge(
					'absolute left-1/2 top-1/2 z-10 flex -translate-y-[50%] -translate-x-1/2 items-center justify-center whitespace-nowrap text-lg font-semibold',
					isSuccess ? 'text-green-300' : 'text-white',
					!roll.valid && 'opacity-50',
					isToggled && 'opacity-30',
					'[text-shadow:_-1px_-1px_0_#000,_1px_-1px_0_#000,_-1px_1px_0_#000,_1px_1px_0_#000]',
				)}
			>
				{roll.roll}
			</div>
			{isD100 ? (
				<div className='flex items-center'>
					<DieIcon
						className={twMerge(
							'h-10 w-10 transition-all duration-200',
							hasCritical && 'scale-110',
							!roll.valid && 'opacity-50',
							isToggled && 'opacity-30',
						)}
						fill={color}
						stroke='black'
					/>
					<DieIcon
						className={twMerge(
							'-ml-5 h-10 w-10 transition-all duration-200',
							hasCritical && 'scale-110',
							!roll.valid && 'opacity-50',
							isToggled && 'opacity-30',
						)}
						fill={color}
						stroke='black'
					/>
				</div>
			) : (
				<DieIcon
					className={twMerge(
						'h-10 w-10 transition-all duration-200',
						hasCritical && 'scale-110',
						!roll.valid && 'opacity-50',
						isToggled && 'opacity-30',
					)}
					fill={color}
					stroke='black'
				/>
			)}
			{hasCritical && critical && (
				<div className='absolute -top-2 left-1/2 -translate-x-1/2 text-xs font-bold'>
					{critical === 'success' ? '⭐' : '💀'}
				</div>
			)}
		</div>
	)
}

const DiceGroup = ({
	roll,
	color,
	toggledDice,
	onToggleDie,
	indexOffset = 0,
}: {
	roll: DiceRollResult
	color: string
	toggledDice: Set<number>
	onToggleDie: (index: number) => void
	indexOffset?: number
}) => {
	return (
		<div className='inline-flex flex-wrap items-center gap-1'>
			{roll.rolls.map((r, idx) => (
				<DieDisplay
					key={idx}
					roll={r}
					dieSize={roll.die.value}
					color={color}
					isToggled={toggledDice.has(indexOffset + idx)}
					onToggle={() => onToggleDie(indexOffset + idx)}
				/>
			))}
		</div>
	)
}

const SimpleDiceMessage: React.FC<SimpleDiceMessageProps> = React.memo(
	({ message, onDelete, className, style, showSender }) => {
		const [toggledDice, setToggledDice] = useState<Set<number>>(new Set())
		const { user } = useGetUserById(message.sender)
		const { usersState } = useContext(UsersContext)
		const senderColor =
			usersState.users.find(u => u.userId === message.sender)?.userSettings
				.color || '#ff0000'
		const avatarSrc = useGetAvatarSrc({
			userId: message.sender,
			assumedCharacterId: message.assumedCharacterId,
			fallbackAvatarId: message.fallbackAvatarId,
			disableAssumedCharacterLookup: false,
		})

		const name = useMemo(
			() =>
				user?.userProfile?.name ||
				`${message.fallbackName}${user ? '' : ' (removed)'}`,
			[user, message.fallbackName],
		)

		const containerClassName = useMemo(
			() =>
				twMerge(
					'max-w-4/6 message inline-block transition-all duration-200',
					showSender ? 'my-2' : 'mt-1',
					className,
				),
			[showSender, className],
		)

		const calculateValue = (results: RollBase, toggledDice: Set<number>) => {
			// If no dice are toggled, use the parser's calculated value
			if (toggledDice.size === 0) {
				return results.value
			}

			// Only recalculate if some dice are toggled
			if (results.type === 'die') {
				const diceResult = results as DiceRollResult
				// For single die rolls
				if (diceResult.success !== undefined) {
					// Count successes for success-based rolls
					return diceResult.rolls.reduce(
						(sum, roll, idx) =>
							toggledDice.has(idx) ? sum : (roll.success ? 1 : 0) + sum,
						0,
					)
				}
				// Sum values for regular rolls
				return diceResult.rolls.reduce(
					(sum, roll, idx) => (toggledDice.has(idx) ? sum : sum + roll.roll),
					0,
				)
			} else if (
				results.type === 'diceexpressionroll' ||
				results.type === 'expressionroll'
			) {
				const exprResult = results as DiceExpressionRoll | ExpressionRoll
				let total = 0
				let diceIndex = 0

				// Calculate total based on roll type
				for (let i = 0; i < exprResult.dice.length; i++) {
					const die = exprResult.dice[i]
					// First die is always added, subsequent dice use the operator from ops array
					const operation = i === 0 ? '+' : exprResult.ops[i - 1]

					let value = 0
					if (die.type === 'die' && 'rolls' in die) {
						const diceResult = die as DiceRollResult
						// Sum values for regular rolls, excluding toggled dice
						value = diceResult.rolls.reduce(
							(sum, roll, idx) =>
								toggledDice.has(diceIndex + idx) ? sum : sum + roll.roll,
							0,
						)
						diceIndex += diceResult.rolls.length
					} else if ('value' in die) {
						value = die.value
					}

					// Apply the operation (ops array contains simple strings like "+", "-", etc.)
					switch (operation) {
						case '+':
							total += value
							break
						case '-':
							total -= value
							break
						case '*':
							total *= value
							break
						case '/':
							if (value !== 0) total /= value
							break
						default:
							total += value
					}
				}
				return total
			}

			// For other types (mathfunction, fateroll, etc.), use the parser's value
			return results.value
		}

		const renderDiceResult = (results: RollBase) => {
			switch (results.type) {
				case 'diceexpressionroll':
				case 'expressionroll': {
					const exprResult = results as DiceExpressionRoll | ExpressionRoll
					let diceIndex = 0
					return (
						<div className='flex flex-col items-center gap-2'>
							<div className='flex flex-wrap items-center justify-center gap-2'>
								{exprResult.dice.map((die, idx) => {
									const currentIndex = diceIndex
									if (die.type === 'die' && 'rolls' in die) {
										const diceResult = die as DiceRollResult
										diceIndex += diceResult.rolls.length
										return (
											<React.Fragment key={idx}>
												<DiceGroup
													roll={diceResult}
													color={senderColor}
													toggledDice={toggledDice}
													indexOffset={currentIndex}
													onToggleDie={dieIdx => {
														setToggledDice(prev => {
															const next = new Set(prev)
															if (next.has(dieIdx)) {
																next.delete(dieIdx)
															} else {
																next.add(dieIdx)
															}
															return next
														})
													}}
												/>
											</React.Fragment>
										)
									} else if (die.type === 'mathfunction') {
										const mathResult = die as MathFunctionRoll
										return (
											<span key={idx} className='mx-1 text-white'>
												{mathResult.op}({mathResult.value})
											</span>
										)
									} else if (die.type === 'fateroll') {
										const fateResult = die as FateDieRoll
										return (
											<span key={idx} className='mx-1 text-white'>
												{fateResult.roll > 0
													? '+'
													: fateResult.roll < 0
													? '-'
													: '0'}
											</span>
										)
									}
									return (
										<span key={idx} className='mx-1 text-white'>
											{die.value}
										</span>
									)
								})}
							</div>
							<div className='flex items-center gap-2'>
								<span className='whitespace-nowrap text-xl text-white'>
									{calculateValue(results, toggledDice)}
								</span>
								{exprResult.success && (
									<span className='text-sm font-semibold text-green-400'>
										Success!
									</span>
								)}
							</div>
						</div>
					)
				}

				case 'die': {
					const diceResult = results as DiceRollResult
					return (
						<div className='flex flex-col items-center gap-2'>
							<div className='flex items-center justify-center'>
								<DiceGroup
									roll={diceResult}
									color={senderColor}
									toggledDice={toggledDice}
									onToggleDie={dieIdx => {
										setToggledDice(prev => {
											const next = new Set(prev)
											if (next.has(dieIdx)) {
												next.delete(dieIdx)
											} else {
												next.add(dieIdx)
											}
											return next
										})
									}}
								/>
							</div>
							<div className='flex items-center gap-2'>
								<span className='whitespace-nowrap text-xl text-white'>
									{calculateValue(results, toggledDice)}
								</span>
								{diceResult.success && (
									<span className='text-sm font-semibold text-green-400'>
										Success!
									</span>
								)}
							</div>
						</div>
					)
				}

				case 'mathfunction': {
					const mathResult = results as MathFunctionRoll
					return (
						<div className='flex flex-col items-center gap-2'>
							<span className='text-white'>
								{mathResult.op}({mathResult.expr.value})
							</span>
							<span className='text-xl text-white'>{mathResult.value}</span>
						</div>
					)
				}

				case 'fateroll': {
					const fateResult = results as FateDieRoll
					return (
						<div className='flex flex-col items-center gap-2'>
							<span className='text-white'>
								{fateResult.roll > 0 ? '+' : fateResult.roll < 0 ? '-' : '0'}
							</span>
							<span className='text-xl text-white'>{fateResult.value}</span>
						</div>
					)
				}

				default:
					return <div>Unknown roll type: {results.type}</div>
			}
		}

		return (
			<MessageMenu onDelete={onDelete}>
				<div
					id={message._id}
					className={twMerge('w-full', containerClassName)}
					style={style}
				>
					<div className='flex'>
						{showSender && <Avatar src={avatarSrc} color={senderColor} />}
						<div className={twMerge('w-full', !showSender ? 'ml-9' : '')}>
							{showSender && (
								<SmallUppercase className='ml-2 mb-1'>{name}</SmallUppercase>
							)}
							<div
								className={twMerge('flex-grow rounded-2xl p-3', Translucency)}
							>
								{message.message && (
									<div className='mb-2 whitespace-normal break-words text-center text-white opacity-75'>
										{message.message}
									</div>
								)}
								<div className='flex flex-col items-center font-mono text-sm'>
									{renderDiceResult(message.diceResults)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</MessageMenu>
		)
	},
)

SimpleDiceMessage.displayName = 'SimpleDiceMessage'

export default SimpleDiceMessage
