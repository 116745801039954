import { ActorRelationship } from '../../../../shared/types/actor'
import { LibraryToken, TokenSet } from '../../../../shared/types/tokenLibrary'

const beowulfTokens: LibraryToken[] = [
	'Arnfinn',
	'Bald',
	'Bartas',
	'Bernardas',
	'Braegde',
	'Ejnar',
	'Grimrik',
	'Helge',
	'Hidulf',
	'Hjordis',
	'Ingrunn',
	'Kadaras',
	'Maggan',
	'Moat Snake',
	'Sverra',
	'Vandran',
].map(name => ({
	id: name.toLowerCase().replace(/\s+/g, '-'),
	name,
	imageUrl: `/assets/tokens/beowulf/${name.replace(/\s+/g, '-')}-VTT-token.png`,
	width: 500,
	height: 500,
	relationship: 'neutral' as ActorRelationship,
	setIds: ['beowulf'],
}))

export const beowulfSet: TokenSet = {
	id: 'beowulf',
	name: 'Beowulf',
	description: 'Characters from the Beowulf saga',
	tokenIds: beowulfTokens.map(token => token.id),
}

export { beowulfTokens }
