import { Grid2X2 } from 'lucide-react'
import { useGridMode } from '../../hooks/useGridMode'
import ToolButton from '../interface/toolbar/ToolButton'

export default function GridEditButton() {
	const { activateGridMode } = useGridMode()

	return (
		<ToolButton tooltip='Edit Grid' onClick={activateGridMode}>
			<Grid2X2 aria-label='Edit Grid' />
		</ToolButton>
	)
}
