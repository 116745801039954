import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext } from 'react'
import { useDrop } from 'react-dnd'
import ItemTypes from '../components/draganddrop/ItemTypes'
import GameContext from '../contexts/game'
import useGetActiveScene from '../hooks/useGetActiveScene'
import useDocumentToActor from './useDocumentToActor'
import useTokenToDocument from './useTokenToDocument'

interface UseMapDropProps {
	viewport: PixiViewport | null
}

export const useMapDrop = ({ viewport }: UseMapDropProps) => {
	const { game, dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const { createActor } = useDocumentToActor()
	const { createDocument } = useTokenToDocument()

	const xyToViewport = useCallback(
		(x: number, y: number) => {
			if (!viewport) return { x: 0, y: 0 }
			console.log('xyToViewport', x, y)
			const globalPoint = new PIXI.Point(x, y)
			return viewport.toWorld(globalPoint)
		},
		[viewport],
	)

	const handleDrop = useCallback(
		async (
			item: { id: string; token?: any },
			point: { x: number; y: number },
		) => {
			if (!activeScene) return

			let document
			if (item.token) {
				// Handle token drop
				document = await createDocument(item.token)
				dispatch({
					type: 'ADD_DOCUMENT',
					payload: {
						document,
					},
				})
			} else {
				// Handle document drop
				document = game.documents.byId[item.id]
			}

			const actor = createActor(document)

			// Add the location to the actor
			actor.values.locations = [
				{
					mapId: activeScene._id,
					x: point.x,
					y: point.y,
					visibility: {
						access: document.access,
						accessList: [...document.accessList],
					},
				},
			]

			dispatch({
				type: 'ADD_ACTOR_WITH_SCENE',
				payload: {
					document: actor,
					sceneId: activeScene._id,
				},
			})
		},
		[game.documents.byId, createActor, createDocument, activeScene, dispatch],
	)

	const [{ isOver }, dropRef] = useDrop({
		accept: ItemTypes.DOCUMENT,
		hover: (item: { id: string; token?: any }, monitor) => {
			if (!activeScene) return

			const offset = monitor.getClientOffset()
			if (!offset) return

			const point = xyToViewport(offset.x, offset.y)
			console.log('Hover position:', point)
		},
		drop: (item: { id: string; token?: any }, monitor) => {
			if (!activeScene) return

			const offset = monitor.getClientOffset()
			if (!offset) return

			const point = xyToViewport(offset.x, offset.y)
			handleDrop(item, point)
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	})

	return {
		dropRef,
		isOver,
	}
}
