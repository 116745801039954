import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import AuthContext from '../contexts/auth'
import GameContext from '../contexts/game'
import { ADD_ASSET } from '../graphql/games'
import useFileUpload from './useFileUpload'

interface IDimensions {
	width: number
	height: number
}

// File size limits in bytes
const MAX_FILE_SIZES = {
	image: 50 * 1024 * 1024, // 50MB for images
	video: 500 * 1024 * 1024, // 500MB for videos
	pdf: 100 * 1024 * 1024, // 100MB for PDFs
	font: 10 * 1024 * 1024, // 10MB for fonts
} as const

const MAX_FILENAME_LENGTH = 255 // Common filesystem limit

// Accepted file types with their categories
const FILE_TYPES = {
	'image/jpeg': 'image',
	'image/png': 'image',
	'image/gif': 'image',
	'image/bmp': 'image',
	'image/webp': 'image',
	'video/mp4': 'video',
	'video/webm': 'video',
	'application/pdf': 'pdf',
	'font/ttf': 'font',
	'font/otf': 'font',
	'font/woff': 'font',
	'font/woff2': 'font',
} as const

// Validate file name (allow Unicode letters, numbers, and safe symbols)
const isValidFileName = (filename: string): boolean => {
	if (filename.length > MAX_FILENAME_LENGTH) return false
	// Disallow control characters (Unicode U+0000 to U+001F), path separators, and other unsafe characters
	// eslint-disable-next-line no-control-regex
	return !/[<>:"/\\|?*\u0000-\u001F]/.test(filename)
}

const useAssetUploader = () => {
	const { dispatch } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const uploadFile = useFileUpload()

	const validateFile = (file: File) => {
		// Check if file type is supported
		const fileCategory = FILE_TYPES[file.type as keyof typeof FILE_TYPES]
		if (!fileCategory) {
			const acceptedTypes = Object.keys(FILE_TYPES).join(', ')
			throw new Error(
				`Unsupported file type: ${file.type}. Accepted types are: ${acceptedTypes}`,
			)
		}

		// Check file size
		const maxSize = MAX_FILE_SIZES[fileCategory]
		if (file.size > maxSize) {
			throw new Error(
				`File too large. Maximum size for ${fileCategory} files is ${
					maxSize / (1024 * 1024)
				}MB`,
			)
		}

		// Check file name
		if (!isValidFileName(file.name)) {
			throw new Error(
				'Invalid file name. Use only letters, numbers, spaces, hyphens, underscores, and dots.',
			)
		}
	}

	const getDimensionsFromDataUrl = async (file: File): Promise<IDimensions> => {
		if (file.type.includes('video')) {
			return new Promise(resolve => {
				const url = URL.createObjectURL(file)
				const video = document.createElement('video')
				video.src = url
				video.addEventListener('loadedmetadata', function () {
					URL.revokeObjectURL(url)
					resolve({
						width: this.videoWidth,
						height: this.videoHeight,
					})
				})
				// Handle errors
				video.addEventListener('error', () => {
					URL.revokeObjectURL(url)
					resolve({ width: 0, height: 0 })
				})
			})
		}

		if (file.type.includes('image')) {
			return new Promise(resolve => {
				const url = URL.createObjectURL(file)
				const image = new Image()
				image.src = url
				image.onload = () => {
					URL.revokeObjectURL(url)
					resolve({
						width: image.width,
						height: image.height,
					})
				}
				image.onerror = () => {
					URL.revokeObjectURL(url)
					resolve({ width: 0, height: 0 })
				}
			})
		}

		return { width: 0, height: 0 }
	}

	const [addAsset] = useMutation(ADD_ASSET, {
		onCompleted: data => {
			const { asset } = data.addAsset
			dispatch({ type: 'ADD_ASSET', payload: { asset } })
		},
		onError: error => {
			console.error('Failed to add asset:', error)
			throw error
		},
	})

	const fileHandler = async (
		file: File,
		gameId: string,
		onProgress?: (percent: number) => void,
	) => {
		try {
			// Validate file before proceeding
			validateFile(file)

			// Get dimensions first to catch any media errors early
			const dimensions = await getDimensionsFromDataUrl(file)

			// Upload the file
			const uploadResult = await uploadFile(file, onProgress)

			const asset = {
				_id: uploadResult._id,
				name: file.name,
				creator: authState.userId || 'missing',
				fileurl: uploadResult.fileurl,
				filesize: file.size,
				filetype: file.type,
				width: dimensions.width,
				height: dimensions.height,
			}

			const result = await addAsset({
				variables: {
					asset,
					gameId,
				},
			})

			return result.data.addAsset.asset
		} catch (error) {
			console.error('Asset upload failed:', error)
			throw error
		}
	}

	return fileHandler
}

export default useAssetUploader
