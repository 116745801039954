import { useFormContext } from 'react-hook-form'
import { IDocument } from '../../../../shared/types/document'
import Dropdown from '../FormComponents/Dropdown'
import { videoOptions } from './video/videoData'

type Props = {
	doc: IDocument
}

const EffectsOverlaySelector = ({ doc }: Props) => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Dropdown
				{...register('effectsOverlay')}
				defaultValue={doc.values.effectsOverlay}
				containerClassName='w-2/3'
				className='mt-1 block'
				isOnDark={false}
			>
				<option value=''>No Effect</option>
				{videoOptions.map(option => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</Dropdown>
		</div>
	)
}

export default EffectsOverlaySelector
