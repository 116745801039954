/**
 * Hook that determines which collection types (Books, Maps, etc.) a user can access.
 * GMs can access all types except folders, while regular players only see types
 * that are either public or explicitly shared with them.
 * The Books collection is only available to adept and admin users.
 */
import { useContext, useMemo } from 'react'
import { IDocument, TAccess } from '../../../shared/types/document'
import { stringBoolean } from '../../../shared/types/stringBoolean'
import GameContext from '../contexts/game'
import { usePdfCollections } from './usePdfCollections'
import useUser from './useUser'
import useUserLevel from './useUserLevel'

interface CollectionTypeInfo {
	type: string
	pluralName: string
	singularName: string
	defaultAccess?: TAccess
	allowCreate?: stringBoolean
}

const useCollectionTypes = () => {
	const { game } = useContext(GameContext)
	const { documents, system } = game
	const collections = useMemo(() => system?.collections || [], [system])
	const { userId, isGM } = useUser()
	const { isAtLeastAdept } = useUserLevel()
	const pdfCollections = usePdfCollections()

	const isDocumentAccessible = (document: IDocument) => {
		return (
			document.access === 'public' ||
			(Array.isArray(document.accessList) &&
				document.accessList.includes(userId))
		)
	}

	const collectionTypes = useMemo(() => {
		// Add 'Books' and PDF sheet collections to the initial collection types
		const allTypes: CollectionTypeInfo[] = [
			...collections.map(c => ({
				type: c.type,
				pluralName: c.pluralName,
				singularName: c.singularName,
				defaultAccess: c.defaultAccess as TAccess,
				allowCreate: c.allowCreate,
			})),
			// Only add Books if user is adept or higher
			...(isAtLeastAdept()
				? [
						{
							// this exists only to show the filter in the library
							type: 'Books',
							pluralName: 'Books',
							singularName: 'Book',
							defaultAccess: 'public' as TAccess,
							allowCreate: 'false' as stringBoolean,
						},
				  ]
				: []),
			...pdfCollections.map(c => ({
				type: c.type,
				pluralName: c.pluralName,
				singularName: c.singularName,
				defaultAccess: 'public' as TAccess,
				allowCreate: 'true' as stringBoolean,
			})),
		]

		// GMs see all collections types (except folders)
		if (isGM) {
			return allTypes.filter(({ type }) => type !== 'folder')
		}

		// For non-GM users, we need to show all collection types that have accessible documents
		const { allIds, byId } = documents
		const accessibleTypes: CollectionTypeInfo[] = []

		// Add Books if user is adept
		if (isAtLeastAdept()) {
			const booksType = allTypes.find(t => t.type === 'Books')
			if (booksType) accessibleTypes.push(booksType)
		}

		// Add PDF collections for all users
		pdfCollections.forEach(c => {
			const pdfType = allTypes.find(t => t.type === c.type)
			if (pdfType) accessibleTypes.push(pdfType)
		})

		// Then add any collection type that has at least one accessible document
		allIds.forEach(id => {
			const doc = byId[id]
			if (
				isDocumentAccessible(doc) &&
				!accessibleTypes.some(t => t.type === doc.type)
			) {
				const collectionInfo = allTypes.find(t => t.type === doc.type)
				if (collectionInfo) {
					accessibleTypes.push(collectionInfo)
				}
			}
		})

		return accessibleTypes
	}, [collections, documents, isGM, userId, pdfCollections, isAtLeastAdept])

	// Just sort the collection types
	return collectionTypes.sort((a, b) => a.type.localeCompare(b.type))
}

export default useCollectionTypes
