import { flip, offset, shift, useFloating } from '@floating-ui/react'
import { Menu, Portal, Transition } from '@headlessui/react'
import { ChevronDown, Plus, Search } from 'lucide-react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface FilterDropdownProps {
	title: string
	items: { id: string; label: string }[]
	selectedItems: string[]
	onItemToggle: (item: string) => void
	getItemLabel?: (id: string) => string
	multiple?: boolean
	disabled?: boolean
	placeholder?: string
}

const normalize = (str: string) =>
	str
		.toLowerCase()
		.normalize('NFKD')
		.replace(/[\u0300-\u036f]/g, '')

const FilterDropdown = ({
	title,
	items,
	selectedItems,
	onItemToggle,
	getItemLabel = id => id,
	multiple = true,
	disabled = false,
	placeholder,
}: FilterDropdownProps) => {
	const [search, setSearch] = useState('')
	const [highlightedIndex, setHighlightedIndex] = useState(0)
	const inputRef = useRef<HTMLInputElement>(null)
	const { refs, floatingStyles } = useFloating({
		middleware: [offset(4), flip(), shift()],
	})

	const filteredItems = items.filter(item =>
		normalize(item.label).includes(normalize(search)),
	)

	const allOptions = [
		...(search.trim() && !items.some(item => item.label === search.trim())
			? [{ id: search.trim(), label: search.trim(), isCustom: true }]
			: []),
		...filteredItems,
	]

	useEffect(() => {
		if (highlightedIndex >= allOptions.length) {
			setHighlightedIndex(0)
		}
	}, [allOptions.length, highlightedIndex])

	const handleItemClick = (
		e: React.MouseEvent,
		itemId: string,
		close: () => void,
	) => {
		e.preventDefault()
		if (!multiple && selectedItems.includes(itemId)) {
			return
		}
		onItemToggle(itemId)
		setSearch('')
		if (!multiple) {
			close()
		}
	}

	const handleAddCustomValue = (e: React.MouseEvent, close: () => void) => {
		e.preventDefault()
		if (search.trim()) {
			onItemToggle(search.trim())
			setSearch('')
			if (!multiple) {
				close()
			}
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent, close: () => void) => {
		switch (e.key) {
			case 'ArrowDown':
				e.preventDefault()
				setHighlightedIndex(i => (i + 1) % allOptions.length)
				break
			case 'ArrowUp':
				e.preventDefault()
				setHighlightedIndex(
					i => (i - 1 + allOptions.length) % allOptions.length,
				)
				break
			case 'Enter':
				e.preventDefault()
				if (allOptions.length > 0) {
					const selectedOption = allOptions[highlightedIndex]
					onItemToggle(selectedOption.id)
					setSearch('')
					if (!multiple) {
						close()
					}
				}
				break
		}
	}

	const displayTitle = () => {
		if (selectedItems.length === 0) return placeholder || title

		const selectedLabels = selectedItems
			.map(id => {
				const item = items.find(item => item.id === id)
				return item ? getItemLabel(item.id) : id
			})
			.filter(Boolean)

		if (selectedLabels.length === 0) return title

		if (!multiple) return selectedLabels[0]

		if (selectedLabels.length === 1) return selectedLabels[0]
		if (selectedLabels.length === 2)
			return `${selectedLabels[0]} and ${selectedLabels[1]}`
		return `${selectedLabels[0]} and ${selectedLabels.length - 1} more`
	}

	return (
		<Menu as='div' className='relative'>
			{({ open, close }) => (
				<>
					<Menu.Button
						ref={refs.setReference}
						className={twMerge(
							'flex w-full items-center justify-between rounded-md bg-gray-700 px-4 py-2.5 text-sm font-medium text-white transition-colors hover:bg-gray-600',
							open && 'bg-gray-600',
							disabled && 'cursor-not-allowed opacity-50',
						)}
						disabled={disabled}
					>
						<span className='truncate'>{displayTitle()}</span>
						<ChevronDown className='ml-2 h-4 w-4 flex-none' />
					</Menu.Button>

					<Transition
						as={Fragment}
						enter='transition ease-out duration-100'
						enterFrom='transform opacity-0 scale-95'
						enterTo='transform opacity-100 scale-100'
						leave='transition ease-in duration-75'
						leaveFrom='transform opacity-100 scale-100'
						leaveTo='transform opacity-0 scale-95'
						afterLeave={() => {
							setSearch('')
							setHighlightedIndex(0)
						}}
						afterEnter={() => {
							inputRef.current?.focus()
						}}
					>
						<Portal>
							<Menu.Items
								ref={refs.setFloating}
								style={floatingStyles}
								className='z-[9999] w-[300px] overflow-hidden rounded-md bg-gray-700 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
								static
							>
								<div className='sticky top-0 z-10 px-3 pb-0'>
									<div className='relative mt-2'>
										<Search className='pointer-events-none absolute left-3 top-2.5 h-4 w-4 text-gray-400' />
										<input
											ref={inputRef}
											type='text'
											className='w-full rounded-md border border-gray-600 bg-gray-700 py-2 pl-9 pr-4 text-sm text-white placeholder-gray-400'
											placeholder='Search or enter new value...'
											value={search}
											onChange={e => setSearch(e.target.value)}
											onKeyDown={e => handleKeyDown(e, close)}
										/>
									</div>
								</div>

								<div className='max-h-[300px] overflow-y-auto px-2'>
									{search.trim() &&
										!items.some(item => item.label === search.trim()) && (
											<Menu.Item>
												{({ active }) => (
													<button
														type='button'
														className={twMerge(
															'flex w-full items-start px-4 py-2.5 text-left text-sm text-white transition-colors hover:bg-gray-600',
															(active || highlightedIndex === 0) &&
																'rounded bg-gray-700',
														)}
														onClick={e => handleAddCustomValue(e, close)}
													>
														<div className='mr-3 mt-0.5 flex h-5 w-5 flex-none items-center justify-center'>
															<Plus className='h-4 w-4 text-blue-400' />
														</div>
														<span className='flex-1'>
															Add "{search.trim()}"
														</span>
													</button>
												)}
											</Menu.Item>
										)}

									{filteredItems.map((item, index) => {
										const itemIndex =
											search.trim() &&
											!items.some(item => item.label === search.trim())
												? index + 1
												: index
										return (
											<Menu.Item key={item.id}>
												{({ active }) => (
													<button
														type='button'
														className={twMerge(
															'flex w-full items-start px-4 py-2.5 text-left text-sm text-white transition-colors hover:bg-gray-600',
															(active || highlightedIndex === itemIndex) &&
																'rounded bg-gray-700',
														)}
														onClick={e => handleItemClick(e, item.id, close)}
													>
														<div className='mr-3 mt-0.5 flex h-5 w-5 flex-none items-center justify-center'>
															{selectedItems.includes(item.id) && (
																<div className='h-2 w-2 rounded-full bg-white' />
															)}
														</div>
														<span className='flex-1'>
															{getItemLabel(item.id)}
														</span>
													</button>
												)}
											</Menu.Item>
										)
									})}

									{filteredItems.length === 0 && !search.trim() && (
										<div className='px-4 py-3 text-sm text-gray-400'>
											No matches found
										</div>
									)}
								</div>
							</Menu.Items>
						</Portal>
					</Transition>
				</>
			)}
		</Menu>
	)
}

export default FilterDropdown
