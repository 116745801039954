const TermsOfService = () => {
	return (
		<section className='mx-auto max-w-4xl py-16 px-4 text-white'>
			<h1 className='mb-8 text-3xl font-bold'>Terms of Service</h1>

			<div className='space-y-6'>
				<div>
					<h2 className='mb-2 text-xl font-semibold'>1. Acceptance of Terms</h2>
					<p>
						By using this platform, you agree to these Terms of Service and all
						related policies. If you do not agree, discontinue use immediately.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>2. Eligibility</h2>
					<p>
						You must be at least the age of majority in your jurisdiction and
						have the legal capacity to form a binding contract.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						3. Account Responsibility
					</h2>
					<p>
						You are responsible for all activity on your account. Keep your
						login credentials secure. We may suspend or terminate accounts for
						unauthorized use.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>4. User Content</h2>
					<p>
						You retain rights to your own content. By uploading content, you
						grant us a license to host, display, and distribute it within the
						platform. You warrant that you have the necessary rights and that
						your content does not violate any laws or infringe any third-party
						rights.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						5. Prohibited Activities
					</h2>
					<p>
						Do not use the platform to share illegal, harmful, or infringing
						material. Do not attempt to disrupt, damage, or gain unauthorized
						access to the platform or other users' data.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						6. Intellectual Property
					</h2>
					<p>
						All platform-provided content (e.g., code, design, logos, text) is
						our property or licensed to us. You may not copy, distribute, or
						modify it without permission.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						7. Paid Features and Subscriptions
					</h2>
					<p>
						Some content or features may require payment. All fees are
						non-refundable unless required by law. We reserve the right to
						change pricing or offerings.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>8. Disclaimers</h2>
					<p>
						Use of the platform is at your own risk. We provide no warranty of
						uptime, accuracy, or suitability for any purpose. The platform and
						all content are provided "as is."
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						9. Limitation of Liability
					</h2>
					<p>
						We are not liable for indirect, incidental, or consequential
						damages. Our total liability is limited to the amount you have paid
						us in the last six months, or $50, whichever is greater.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>10. Termination</h2>
					<p>
						We may suspend or terminate your access at any time for any reason.
						You may stop using the platform at any time.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>11. Changes to Terms</h2>
					<p>
						We may update these terms at any time by posting the new version.
						Continued use after changes means you accept the new terms.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>
						12. Governing Law and Disputes
					</h2>
					<p>
						These terms are governed by the laws of our principal place of
						business. All disputes must be resolved in the local courts of that
						jurisdiction.
					</p>
				</div>

				<div>
					<h2 className='mb-2 text-xl font-semibold'>13. Contact</h2>
					<p>
						For questions or concerns, contact us through the provided support
						channels.
					</p>
				</div>
			</div>
		</section>
	)
}

export default TermsOfService
