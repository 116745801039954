import { DiceExpressionRoll, DiceRollResult } from 'dice-roller-parser'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import EventContext from '../../contexts/event'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import useShowMap from '../../hooks/useShowMap'
import useUser from '../../hooks/useUser'
import generateMessage from '../chat/generateMessage'
import DiceTools from '../dice/DiceTools'
import DrawModeButton from '../toolbar/DrawModeButton'
import DrawTools from '../toolbar/DrawTools'
import EditMapButton from '../toolbar/EditMapButton'
import EditSceneButton from '../toolbar/EditSceneButton'
import FogModeButton from '../toolbar/FogModeButton'
import FogTools from '../toolbar/FogTools'
import GridEditButton from '../toolbar/GridEditButton'
import MeasureButton from '../toolbar/MeasureButton'
import PointerButton from '../toolbar/PointerButton'
import TokenLibraryButton from '../toolbar/TokenLibraryButton'
import DiceButton from './DiceButton'
import GuideButton from './GuideButton'
import GuideNotification from './GuideNotification'
import Toolbar from './toolbar/Toolbar'

const mapDiceConfig = (config: any[] | undefined): string[] | undefined => {
	if (!config) return undefined
	return config.map(die => die.notation.replace(/^1/, ''))
}

export default function Tools() {
	const { game, dispatch } = useContext(GameContext)
	const { eventQueue, dispatchEvent } = useContext(EventContext)
	const { userId, color } = useUser()
	const { toolsState } = useContext(ToolsContext)
	const { showMap } = useShowMap()
	const userIdRef = useRef(userId)
	const [currentRollNotation, setCurrentRollNotation] = useState<
		string | undefined
	>(undefined)
	const { isGM, activeSceneId } = useUser()

	useEffect(() => {
		userIdRef.current = userId
	}, [userId])

	const addMessage = useCallback(
		(message: string, diceResults: DiceRollResult | DiceExpressionRoll) => {
			dispatch({
				type: 'ADD_MESSAGE',
				payload: generateMessage({
					sender: userIdRef.current,
					type: 'dice',
					message,
					diceResults,
				}),
			})
		},
		[dispatch],
	)

	const handleEvents = useCallback(() => {
		if (eventQueue.length) {
			const event = eventQueue[0]
			if (event.name === 'roll') {
				setCurrentRollNotation(event.data)
				dispatchEvent({ type: 'DELETE_EVENT', payload: event._id })
			}
		}
	}, [eventQueue, dispatchEvent])

	useEffect(() => {
		handleEvents()
	}, [eventQueue, handleEvents])

	const handleColorChange = useCallback(
		(newColor: string) => {
			dispatch({
				type: 'UPDATE_USER_COLOR',
				payload: { userId: userIdRef.current, color: newColor },
			})
		},
		[dispatch],
	)

	const handleRollResults = useCallback(
		(results: DiceRollResult | DiceExpressionRoll, notation: string) => {
			addMessage(notation, results)
			setCurrentRollNotation(undefined)
		},
		[addMessage],
	)

	return (
		<div className='pointer-events-auto fixed bottom-0 left-1/2 flex -translate-x-1/2 flex-col items-center gap-2'>
			<GuideNotification />
			<div
				className={`${
					toolsState.mode !== 'dice' ? 'pointer-events-none invisible h-0' : ''
				}`}
			>
				<DiceTools
					color={color}
					diceConfig={mapDiceConfig(game.system?.diceConfig)}
					onRollResults={handleRollResults}
					onColorChange={handleColorChange}
					showColorPicker={false}
					rollNotation={currentRollNotation}
				/>
			</div>
			{toolsState.mode === 'draw' && <DrawTools />}
			{toolsState.mode === 'fog' && <FogTools />}

			<div className='flex flex-row justify-end gap-2'>
				<Toolbar>
					<PointerButton />
					<DiceButton />
					{showMap === 'true' && <DrawModeButton />}
					{showMap === 'true' && <MeasureButton />}
					{isGM && activeSceneId && (
						<>
							{showMap !== 'true' && <EditSceneButton />}
							{showMap === 'true' && (
								<>
									<FogModeButton />
									<TokenLibraryButton />
									<GridEditButton />
									<EditMapButton />
								</>
							)}
							<GuideButton />
						</>
					)}
				</Toolbar>
			</div>
		</div>
	)
}
