import { useQuery } from '@apollo/client'
import React, { useReducer } from 'react'
import { ResourceContext } from '../contexts/resources'
import { GET_RESOURCES_BY_USER } from '../graphql/resources'
import { resourceReducer } from '../reducers/resource'

type Props = {
	userId?: string
	children: React.ReactNode
}

const ResourcesProvider = ({ userId, children }: Props) => {
	const [resources, dispatchResource] = useReducer(resourceReducer, {})

	const { loading, error } = useQuery(GET_RESOURCES_BY_USER, {
		variables: { userId },
		skip: !userId,
		fetchPolicy: 'no-cache', // cannot figure out why caching won't work for this query
		onCompleted: data => {
			const resources = data.getResourcesByUser.map((resource: any) => {
				// Ensure metadata fields have default values if they're missing
				if (resource.resourceType === 'image' && resource.metadata) {
					resource.metadata = {
						width: resource.metadata.width || 0,
						height: resource.metadata.height || 0,
						description: resource.metadata.description || '',
						tags: resource.metadata.tags || [],
						...resource.metadata,
					}
				}
				return resource
			})

			dispatchResource({
				type: 'ADD_RESOURCES',
				payload: {
					resources,
				},
			})
		},
		onError: error => {
			console.error('Error loading resources', error)
		},
	})

	if (!userId) {
		return (
			<ResourceContext.Provider value={{ resources: {}, dispatchResource }}>
				{children}
			</ResourceContext.Provider>
		)
	}

	if (loading) {
		return null
	}

	if (error) {
		console.error(error)
		return <div>Error loading resources. Please try again later.</div>
	}

	return (
		<ResourceContext.Provider value={{ resources, dispatchResource }}>
			{children}
		</ResourceContext.Provider>
	)
}

export default ResourcesProvider
