import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import MenuItem from './MenuItem'

type DropdownMenuItemProps = React.ComponentProps<typeof DropdownMenu.Item>

type Props = DropdownMenuItemProps & {
	children: React.ReactNode
	className?: string
}

const DangerMenuItem: React.FC<Props> = ({ children, className, ...rest }) => {
	return (
		<MenuItem
			className={twMerge(
				'text-red-500 hover:bg-red-900/60 data-[disabled]:hover:bg-transparent',
				className,
			)}
			{...rest}
		>
			{children}
		</MenuItem>
	)
}

export default DangerMenuItem
