import ObjectId from 'bson-objectid'
import { Plus } from 'lucide-react'
import { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocument } from '../../../../shared/types/document'
import { stringBoolean } from '../../../../shared/types/stringBoolean'
import { ICollection } from '../../../../shared/types/system'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import { usePdfCollections } from '../../hooks/usePdfCollections'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import BookPicker from '../pages/books/elements/BookPicker'
import ToolbarControlButton from '../toolbar/ControlButton'
import AddMenu from './AddMenu'

export default function AddDocumentButton() {
	const { game, dispatch } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const [isOpen, setIsOpen] = useState(false)
	const addButtonRef = useRef<HTMLButtonElement>(null)
	const { userId, role } = useUser()
	const { control } = useFormContext()
	const isGM = role === 'gm'
	const pdfCollections = usePdfCollections()
	const collections = useMemo(() => {
		const systemCollections = game.system?.collections || []
		return [...systemCollections, ...pdfCollections]
	}, [game.system?.collections, pdfCollections])
	const collectionTypes = useCollectionTypes()
	const [showBookPicker, setShowBookPicker] = useState(false)
	const { primary } = useTheme()

	const selectedType = useWatch({
		control,
		name: 'selection',
		defaultValue: 'all',
	})

	const handleAddDocument = useCallback(
		(type: string) => {
			const _id = ObjectId().toHexString()
			const collection = collections.find(t => t.type === type)
			const name = collection?.singularName
			const access = collection?.defaultAccess

			const document: IDocument = {
				_id,
				type,
				version: 1,
				parentId: 'library',
				creator: userId,
				access: access || 'private',
				accessList: [userId],
				values: {
					name: 'New ' + name,
				},
			}

			if (type === 'map') {
				// bring in default grid settings from the loaded system
				document.values.grid = game.system.grid
			}

			dispatch({
				type: 'ADD_DOCUMENT',
				payload: { document },
			})

			// open the document if type not a folder
			if (type !== 'folder') {
				dispatchDocuments({
					type: 'OPEN_DOCUMENT',
					payload: { documentId: _id },
				})
			}

			setIsOpen(false)
		},
		[collections, dispatch, dispatchDocuments, game.system.grid, userId],
	)

	const handleAddBook = useCallback(() => {
		setShowBookPicker(true)
	}, [])

	const handleDoubleClick = useCallback(() => {
		if (selectedType !== 'all' && selectedType !== 'Books') {
			handleAddDocument(selectedType)
		}
	}, [handleAddDocument, selectedType])

	// user has permissions to add document to collection?
	const hasPermissions = useMemo(() => {
		if (isGM) return true

		// when showing all collections, we should show the add button if any collection allows creation
		if (selectedType === 'all' || selectedType === 'All') {
			return collections.some(c => c.allowCreate === 'true')
		}

		// collection allows players to create documents
		const collection = collections.find(t => t.type === selectedType)
		if (collection?.allowCreate === 'true') return true

		return false
	}, [collections, isGM, selectedType])

	const allCollections = useMemo(() => {
		const systemCollections = collections.map(c => ({
			...c,
			defaultAccess: c.defaultAccess || 'private',
			allowCreate: c.allowCreate || ('false' as stringBoolean),
			hasEditMode: c.hasEditMode || ('false' as stringBoolean),
		}))

		const pdfCollections = collectionTypes
			.filter(c => !collections.find(sc => sc.type === c.type))
			.map(c => ({
				type: c.type,
				singularName: c.singularName || c.pluralName,
				pluralName: c.pluralName,
				description: '',
				acceptTypes: [],
				hasEditMode: 'false' as stringBoolean,
				thumbnailField: [],
				defaultAccess: c.defaultAccess,
				allowCreate: c.allowCreate,
			}))

		return [...systemCollections, ...pdfCollections] as ICollection[]
	}, [collections, collectionTypes])

	if (!hasPermissions) return null
	if (collections.length < 1) return null

	return (
		<>
			<ToolbarControlButton
				ref={addButtonRef}
				label='Add Document'
				onClick={() => {
					setIsOpen(true)
				}}
				onDoubleClick={handleDoubleClick}
			>
				<Plus
					className='h-6 w-6'
					aria-hidden='true'
					style={{
						color: primary,
					}}
					strokeWidth={1.8}
				/>
			</ToolbarControlButton>

			<AddMenu
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onAddDocument={handleAddDocument}
				onAddBook={handleAddBook}
				collections={allCollections}
				anchorRef={addButtonRef}
			/>

			{showBookPicker && (
				<BookPicker onClose={() => setShowBookPicker(false)} />
			)}
		</>
	)
}
