import React, { useContext } from 'react'
import DocumentsContext from '../../../contexts/documents'
import WindowsContext from '../../../contexts/windows'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import MenuItem from '../../dropdownmenu/MenuItem'

interface EditMapMenuItemProps {
	onClose: () => void
}

const EditMapMenuItem: React.FC<EditMapMenuItemProps> = ({ onClose }) => {
	const { dispatchDocuments } = useContext(DocumentsContext)
	const { dispatchWindows } = useContext(WindowsContext)
	const activeScene = useGetActiveScene()

	const handleEditMap = () => {
		if (!activeScene) return

		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: {
				documentId: activeScene._id,
			},
		})

		dispatchWindows({
			type: 'MOVE_WINDOW_TO_FRONT',
			payload: {
				documentId: activeScene._id,
			},
		})

		onClose()
	}

	return <MenuItem onSelect={handleEditMap}>Edit Map</MenuItem>
}

export default EditMapMenuItem
