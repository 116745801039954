/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useMemo } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import useGetCollectionName from '../../../hooks/useGetCollectionName'
import Menu from '../../documentmenu/Menu'
import Window from '../../window/Window'
import DocumentWindowMenuButton from '../MenuButton'
import DocumentWindowTitle from '../Title'
import MapEditor from './MapEditor'

interface MapWindowProps {
	documentId: string
	onClose: () => void
}

const emptyDocument: IDocument = {
	_id: '',
	type: '',
	creator: '',
	access: 'private',
	accessList: [],
	values: {},
} as IDocument

const MapWindow = ({ documentId, onClose = () => {} }: MapWindowProps) => {
	const { game } = useContext(GameContext)
	// const [documentMode, setDocumentMode] = useState<TDocumentModes>('view')
	const document = useMemo(
		() => game.documents.byId[documentId] || emptyDocument,
		[game.documents, game.assets, documentId],
	)
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`

	if (!document._id) return null

	return (
		<Window
			key={documentId}
			documentId={documentId}
			title={DocumentWindowTitle({ title, singularName, document })}
			open={true}
			onClose={onClose}
			bodyClassName='p-0'
			dimensions={{ width: 340, height: 740 }}
			headerIcon={
				<Menu document={document}>
					<DocumentWindowMenuButton />
				</Menu>
			}
			// headerChildren={
			// 	<div className='flex space-x-2 self-center'>
			// 		{collection?.hasEditMode === 'true' && (
			// 			<WindowModeToggle
			// 				documentMode={documentMode}
			// 				setDocumentMode={setDocumentMode}
			// 			/>
			// 		)}
			// 	</div>
			// }
		>
			<MapEditor doc={document} />
		</Window>
	)
}

export default MapWindow
