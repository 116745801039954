import React from 'react'
import { BookResource } from '../../../../../shared/types/resources'
import BookCover from './BookCover'
import { BooksResources } from './BooksPage'

interface BookGridProps {
	resources: BooksResources
	selectMode?: boolean
	selectedIds?: string[]
	onSelect?: (id: string, selected: boolean) => void
	onFilter?: (type: 'game' | 'ruleSystem', value: string) => void
}

const BookGrid: React.FC<BookGridProps> = ({
	resources,
	selectMode,
	selectedIds = [],
	onSelect,
	onFilter,
}) => {
	return (
		<ul
			className='mx-auto grid max-w-[2400px] grid-cols-2 gap-x-px gap-y-px p-3 
			          transition-all duration-500 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5
			          3xl:grid-cols-7 4xl:grid-cols-9'
		>
			{Object.entries(resources).map(([id, resource]) => {
				const isSelected = selectedIds.includes(resource._id)
				return (
					<li
						key={id}
						className='relative flex aspect-[9/12] flex-col justify-end'
					>
						<BookCover
							resource={resource as BookResource}
							selectMode={selectMode}
							selected={isSelected}
							onSelect={() => onSelect?.(resource._id, !isSelected)}
							onFilter={onFilter}
						/>
					</li>
				)
			})}
		</ul>
	)
}

export default BookGrid
