import { useDrag } from 'react-dnd'
import { animated, useSpring } from 'react-spring'
import { LibraryToken } from '../../../../shared/types/tokenLibrary'
import ItemTypes from '../draganddrop/ItemTypes'

interface TokenGridProps {
	tokens: LibraryToken[]
}

const TokenGrid = ({ tokens }: TokenGridProps) => {
	return (
		<div className='grid grid-cols-3 gap-2'>
			{tokens.map(token => (
				<TokenGridItem key={token.id} token={token} />
			))}
		</div>
	)
}

interface TokenGridItemProps {
	token: LibraryToken
}

const TokenGridItem = ({ token }: TokenGridItemProps) => {
	const [{ isDragging }, dragRef] = useDrag(() => ({
		type: ItemTypes.DOCUMENT,
		item: { token },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	}))

	const [springs, api] = useSpring(() => ({
		scale: 1,
		config: { tension: 300, friction: 15 },
	}))

	return (
		<animated.div
			ref={dragRef}
			className='aspect-square cursor-grab rounded-lg p-1 transition-opacity active:cursor-grabbing'
			style={{
				opacity: isDragging ? 0.5 : 1,
				transform: springs.scale.to(s => `scale(${s})`),
			}}
			onMouseEnter={() => api.start({ scale: 1.1 })}
			onMouseLeave={() => api.start({ scale: 1 })}
		>
			<img
				src={token.imageUrl}
				alt={token.name}
				className='h-full w-full rounded-lg object-cover'
				draggable={false}
			/>
		</animated.div>
	)
}

export default TokenGrid
