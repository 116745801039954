import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useApi from '../../../hooks/useApi'
import { UserLevel } from '../../../interfaces/auth'
import SlideoverPanel from '../../SlideoverPanel'

interface UserDetailProps {
	user?: any // Replace with proper type when available
	onClose?: () => void
	open?: boolean
	onUserUpdate?: (userId: string, updates: Partial<UserData>) => void
}

interface UserMetrics {
	totalStorage: number
	monthlyTransfer: number
	requestCounts: {
		puts: number
		gets: number
	}
	estimatedCosts: {
		storage: number
		transfer: number
		requests: {
			puts: number
			gets: number
		}
		total: number
	}
}

interface UserData {
	_id: string
	name: string
	email: string
	avatar: string
	userLevel: UserLevel
	createdAt: string
	createdGames: Array<{ _id: string; title: string }>
	joinedGames: Array<{ _id: string; title: string }>
	resources: Array<{ _id: string; name: string }>
}

export default function AdminUserDetail({
	user,
	onClose,
	open = false,
	onUserUpdate,
}: UserDetailProps) {
	const { id } = useParams()
	const [metrics, setMetrics] = useState<UserMetrics | null>(null)
	const [userData, setUserData] = useState<UserData | null>(null)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const { request } = useApi()

	const fetchUserData = async () => {
		try {
			setLoading(true)
			const userId = user?._id || id
			if (!userId) return

			const data = await request(`/api/users/${userId}`)
			setUserData(data)
			setError(null)
		} catch (err) {
			console.error('Error fetching user details:', err)
			setError('Error loading user details')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (user) {
			setUserData(user)
		} else if (id) {
			fetchUserData()
		}
	}, [user, id])

	useEffect(() => {
		const fetchUserMetrics = async () => {
			try {
				const userId = user?._id || id
				if (!userId) return

				const metricsData = await request(`/api/storage-metrics/${userId}`)
				setMetrics(metricsData)
			} catch (error) {
				console.error('Error fetching user metrics:', error)
			}
		}

		fetchUserMetrics()
	}, [user, id, request])

	const handleUserLevelChange = async (newLevel: UserLevel) => {
		try {
			const userId = user?._id || id
			if (!userId) return

			const updatedUser = await request(`/api/users/${userId}/level`, {
				method: 'PATCH',
				body: JSON.stringify({ level: newLevel }),
				headers: {
					'Content-Type': 'application/json',
				},
			})

			setUserData(prev =>
				prev ? { ...prev, userLevel: updatedUser.userLevel } : null,
			)

			// Notify parent of the update
			onUserUpdate?.(userId, { userLevel: updatedUser.userLevel })
		} catch (error) {
			console.error('Error updating user level:', error)
		}
	}

	if (loading) return <div>Loading...</div>
	if (error && !user) return <div>{error}</div>
	if (!userData) return null

	const formatBytes = (bytes: number) => {
		const gb = bytes / (1024 * 1024 * 1024)
		return `${gb.toFixed(2)} GB`
	}

	const content = (
		<div className='space-y-6'>
			<div>
				<div className='flex items-center gap-4'>
					{userData.avatar ? (
						<img
							src={userData.avatar}
							alt={userData.name}
							className='h-16 w-16 rounded-full object-cover'
						/>
					) : (
						<div className='flex h-16 w-16 items-center justify-center rounded-full bg-gray-200 text-xl text-gray-500'>
							{userData.name.charAt(0).toUpperCase()}
						</div>
					)}
					<div>
						<h3 className='text-lg font-medium leading-6 text-gray-900'>
							{userData.name}
						</h3>
						<p className='mt-1 text-sm text-gray-500'>{userData.email}</p>
					</div>
				</div>
				<div className='mt-4 flex items-center gap-4'>
					<select
						value={userData.userLevel}
						onChange={e => handleUserLevelChange(e.target.value as UserLevel)}
						className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
					>
						{Object.values(UserLevel).map(level => (
							<option key={level} value={level}>
								{level.charAt(0).toUpperCase() + level.slice(1)}
							</option>
						))}
					</select>
				</div>
			</div>

			<div className='border-t border-gray-200 pt-5'>
				<dl className='grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2'>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Storage Used</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{formatBytes(metrics?.totalStorage || 0)}
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>
							Monthly Transfer
						</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{formatBytes(metrics?.monthlyTransfer || 0)}
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>
							Est. Monthly Cost
						</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							${metrics?.estimatedCosts.total.toFixed(2) || '0'}
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>
							Total Requests
						</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{(metrics?.requestCounts?.puts || 0) +
								(metrics?.requestCounts?.gets || 0)}{' '}
							(↑{metrics?.requestCounts?.puts || 0} ↓
							{metrics?.requestCounts?.gets || 0})
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Resources</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{userData.resources?.length || 0} resources
						</dd>
					</div>
					<div>
						<dt className='text-sm font-medium text-gray-500'>Joined</dt>
						<dd className='mt-1 text-sm text-gray-900'>
							{new Date(userData.createdAt).toLocaleDateString()}
						</dd>
					</div>
				</dl>
			</div>

			{userData.createdGames?.length > 0 && (
				<div className='border-t border-gray-200 pt-5'>
					<h4 className='mb-2 text-sm font-medium text-gray-500'>
						Created Games
					</h4>
					<ul className='space-y-2'>
						{userData.createdGames.map(game => (
							<li key={game._id}>
								<a
									href={`/admin/games/${game._id}`}
									className='text-sm text-indigo-600 hover:text-indigo-900'
								>
									{game.title}
								</a>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	)

	return (
		<SlideoverPanel
			open={open}
			onClose={onClose || (() => {})}
			title='User Details'
		>
			{content}
		</SlideoverPanel>
	)
}
