import { ArrowDown } from 'lucide-react'
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import { useLogVisibility } from '../../contexts/logVisibility'
import UsersContext from '../../contexts/users'
import useGetLog from '../../hooks/useGetLog'
import useUser from '../../hooks/useUser'
import Button from '../FormComponents/Button'
import Translucency from '../Translucency'
import Message from './Message'
import useHandleDrop from './useHandleDrop'

const ITEMS_PER_PAGE = 20

const Log = () => {
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId } = useUser()
	const { log } = useGetLog()
	const { isHidden, visibleItems, setVisibleItems } = useLogVisibility()
	const dropRef = useRef<HTMLDivElement>(null)
	const bottomSentinelRef = useRef<HTMLDivElement>(null)
	const [isAtBottom, setIsAtBottom] = useState(true)
	const [unreadCount, setUnreadCount] = useState(0)

	const visibleLog = useMemo(() => {
		if (visibleItems === 0 && !isHidden) return []
		if (isHidden) return []
		return log.slice(Math.max(0, log.length - visibleItems))
	}, [log, visibleItems, isHidden])

	// Set up intersection observer for bottom detection
	useEffect(() => {
		if (!bottomSentinelRef.current) return

		const observer = new IntersectionObserver(
			([entry]) => {
				console.log('Intersection Observer:', {
					isIntersecting: entry.isIntersecting,
					intersectionRatio: entry.intersectionRatio,
					boundingClientRect: entry.boundingClientRect,
					rootBounds: entry.rootBounds,
					time: new Date().toISOString(),
				})
				setIsAtBottom(entry.isIntersecting)
				if (entry.isIntersecting) {
					setUnreadCount(0)
				}
			},
			{
				threshold: 1.0, // Only trigger when fully visible
			},
		)

		observer.observe(bottomSentinelRef.current)
		return () => observer.disconnect()
	}, [])

	// Update unread count when new messages arrive
	useEffect(() => {
		if (!isAtBottom && !isHidden) {
			setUnreadCount(prev => prev + 1)
		}
	}, [log.length, isAtBottom, isHidden])

	const scrollToBottom = useCallback(() => {
		bottomSentinelRef.current?.scrollIntoView({ behavior: 'smooth' })
		setUnreadCount(0)
	}, [])

	// Scroll to bottom when chat is opened
	useEffect(() => {
		if (!isHidden && isAtBottom) {
			requestAnimationFrame(scrollToBottom)
		}
	}, [isHidden, isAtBottom, scrollToBottom])

	const user = useMemo(
		() => usersState.users.find(u => u.userId === userId),
		[usersState.users, userId],
	)

	const drop = useHandleDrop({ userId, userProfile: user?.userProfile })
	drop(dropRef)

	const handleDelete = useCallback(
		(messageId: string) => {
			dispatch({ type: 'DELETE_MESSAGE', payload: messageId })
		},
		[dispatch],
	)

	const loadMore = useCallback(() => {
		if (!bottomSentinelRef.current) return
		const newVisibleItems = Math.min(visibleItems + ITEMS_PER_PAGE, log.length)
		setVisibleItems(newVisibleItems)
	}, [log.length, visibleItems, setVisibleItems])

	// Load more messages if we're at the bottom and new messages arrive
	useEffect(() => {
		if (!isHidden && isAtBottom && log.length > visibleItems) {
			setVisibleItems(log.length)
		}
	}, [log, visibleItems, isAtBottom, isHidden, setVisibleItems])

	const shouldShowLoadMoreButton =
		visibleItems < log.length && visibleItems > 0 && !isHidden

	return (
		<div
			className={twMerge(
				'flex h-full flex-col-reverse',
				(visibleItems > 0 || !isHidden) && 'min-h-0',
			)}
		>
			{!isAtBottom && unreadCount > 0 && (
				<div className='fixed bottom-20 left-1/2 z-10 -translate-x-1/2 transform'>
					<Button
						onClick={scrollToBottom}
						className='flex items-center gap-2 rounded-full px-4 py-2 text-sm'
					>
						<ArrowDown className='h-4 w-4' />
					</Button>
				</div>
			)}
			<div ref={bottomSentinelRef} className='h-px w-full' />
			{shouldShowLoadMoreButton && (
				<div className='mb-4 text-center'>
					<Button
						onClick={loadMore}
						className={twMerge(
							'm-auto inline-block w-auto rounded-full',
							Translucency,
						)}
					>
						Load Older Messages...
					</Button>
				</div>
			)}
			<div ref={dropRef} className='px-6 pt-2'>
				{visibleLog.map((message, index) => {
					const prevMessage = visibleLog[index - 1]
					const newSender =
						!prevMessage || prevMessage.sender !== message.sender
					const newAssumedCharacter =
						!prevMessage ||
						prevMessage.assumedCharacterId !== message.assumedCharacterId
					const showSender = newSender || newAssumedCharacter

					return (
						<Message
							key={message._id}
							message={message}
							onDelete={() => handleDelete(message._id)}
							showSender={showSender}
						/>
					)
				})}
			</div>
		</div>
	)
}

export default React.memo(Log)
