import RealmsLogo from '../assets/logo/realmslogo2.png'

export default function LoadingScreen() {
	return (
		<div className='flex h-screen w-screen flex-col items-center justify-center bg-gray-900'>
			<img
				src={RealmsLogo}
				alt='Realms Logo'
				className='mb-8 w-24 animate-pulse'
			/>
			<div className='h-2 w-24 overflow-hidden rounded-full bg-gray-800'>
				<div className='bg-primary h-full w-full animate-[loading_1s_ease-in-out_infinite]' />
			</div>
		</div>
	)
}
