import { FC, useContext } from 'react'
import GameContext from '../../contexts/game'
import GameCard from '../pages/elements/GameCard'
import SectionDivider from '../SectionDivider'
import CoverImage from './CoverImage'
import Logo from './Logo'
import SettingsGameName from './SettingsGameName'

const SettingsGame: FC = () => {
	const { game } = useContext(GameContext)

	return (
		<div>
			<SectionDivider label='Game' />
			<GameCard className='m-auto mb-8 w-5/6 text-black/80' game={game} />
			<SettingsGameName />
			<CoverImage />
			<Logo />
		</div>
	)
}

export default SettingsGame
