import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'
import ToolButton, { ToolButtonProps } from '../interface/toolbar/ToolButton'

type Props = ToolButtonProps & {
	show?: boolean
}

const DocumentWindowMenuButton: React.FC<Props> = ({
	isOpen,
	className,
	show = true,
	...props
}) => {
	return (
		<ToolButton
			as='div'
			size='sm'
			className={twMerge(
				'z-10 transition-all duration-200',
				className,
				show || isOpen ? 'opacity-100' : 'opacity-0',
			)}
			style={{
				backgroundColor: INTERFACE_BACKGROUND_COLOR,
			}}
			tabIndex={0}
			isOpen={isOpen}
			{...props}
		>
			<EllipsisHorizontalIcon className='h-6 w-6' aria-hidden='true' />
		</ToolButton>
	)
}

export default DocumentWindowMenuButton
