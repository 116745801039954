import { Container } from '@pixi/react'
import { useGrid } from '../../../../contexts/grid'
import GridInteractions from '../GridInteractions'
import { HexGridRenderer } from './renderers'

export const HexGrid = () => {
	const { grid: gridConfig, gridDimensions } = useGrid()
	const { width, height } = gridDimensions
	const containerOffset = {
		x: -width / 3,
		y: -height / 3,
	}

	if (
		!gridConfig.enabled ||
		(gridConfig.type !== 'hhex' && gridConfig.type !== 'vhex')
	) {
		return null
	}

	if (!width || !height) return null

	return (
		<Container x={containerOffset.x} y={containerOffset.y}>
			<HexGridRenderer />
			<GridInteractions />
		</Container>
	)
}

export default HexGrid
