import { NavLink } from 'react-router-dom'
import LogoSVG from './LogoSVG'

const Logo = () => {
	return (
		<NavLink to='/' className='flex items-center text-xl'>
			<LogoSVG className='w-14' />
		</NavLink>
	)
}

export default Logo
