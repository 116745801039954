import { useMutation } from '@apollo/client'
import { RefreshCw } from 'lucide-react'
import { useContext, useEffect } from 'react'
import { animated, config, useSpring } from 'react-spring'
import GameContext from '../../contexts/game'
import { UPDATE_INVITE_TOKEN } from '../../graphql/games'
import useTheme from '../../hooks/useTheme'
import { generateUUID } from '../../utils/generateUUID'
import Button from '../FormComponents/Button'

export default function InviteTokenResetButton() {
	const { game, dispatch } = useContext(GameContext)
	const [updateInviteToken, { loading }] = useMutation(UPDATE_INVITE_TOKEN)
	const { primary } = useTheme()

	const spinning = useSpring({
		to: {
			transform: loading ? 'rotate(360deg)' : 'rotate(0deg)',
		},
		from: {
			transform: 'rotate(0deg)',
		},
		config: loading ? { duration: 1000 } : config.wobbly,
		reset: loading,
	})

	useEffect(() => {
		if (!game.inviteToken) {
			generateNewToken()
		}
	}, [game.inviteToken])

	const handleResetToken = () => {
		if (
			window.confirm(
				'Are you sure you want to reset the invitation link? Outstanding invitations will cease working.',
			)
		) {
			generateNewToken()
		}
	}

	const generateNewToken = async () => {
		const newToken = generateUUID()

		try {
			await updateInviteToken({
				variables: { gameId: game._id, inviteToken: newToken },
			})
		} catch (error) {
			console.error('Failed to update invite token:', error)
		} finally {
			dispatch({ type: 'SET_INVITE_TOKEN', payload: newToken })
		}
	}

	return (
		<Button
			onClick={handleResetToken}
			className='flex-0 item-center mt-0 ml-1 w-12 bg-black/5 p-1 leading-4'
			disabled={loading}
			label='Reset Invitation Link'
			style={{
				color: primary,
			}}
		>
			<animated.div style={spinning} className='self-center'>
				<RefreshCw className='h-5 w-5' />
			</animated.div>
		</Button>
	)
}
