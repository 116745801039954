import { Container } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext } from 'react'
import { useGrid } from '../../contexts/grid'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'
import useLoadTexture from '../../hooks/useLoadTexture'
import { TokenMenuState } from '../../hooks/useStageMenus'
import useUser from '../../hooks/useUser'
import DrawLayer from './drawing/DrawLayer'
import FogEditor from './FogEditor'
import Map from './Map'
import MeasurementTool from './measure/MeasurementTool'
import Pin from './Pin'
import ProgressLoader from './ProgressLoader'
import Tabletop from './Tabletop'
import Tokens from './Tokens'

interface StageContentProps {
	viewport: PixiViewport
	setTokenMenu: (state: TokenMenuState | null) => void
	setMapMenu: (state: { x: number; y: number } | null) => void
	selectedTokenIds: string[]
	handleTokenSelect: (id: string, isShiftKey: boolean) => void
	clearSelection: () => void
	activeTokenId: string | null
}

const StageContent = ({
	viewport,
	setTokenMenu,
	setMapMenu,
	selectedTokenIds,
	handleTokenSelect,
	clearSelection,
	activeTokenId,
}: StageContentProps) => {
	const { isGM } = useUser()
	const scene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(scene?.values?.mapId)
	const { texture, progress } = useLoadTexture(mapAsset?.fileurl)
	const { toolsState } = useContext(ToolsContext)
	const { anchorCorner, hexGrid, gridDimensions, grid } = useGrid()

	// Calculate pin position from anchor corner
	const pinPosition =
		anchorCorner && hexGrid && gridDimensions && grid
			? (() => {
					const hex = hexGrid.getHex({
						col: anchorCorner.col,
						row: anchorCorner.row,
					})
					if (!hex) return null
					const corner = hex.corners[anchorCorner.index]
					if (!corner) return null
					return {
						x: corner.x - gridDimensions.width / 3 + grid.x,
						y: corner.y - gridDimensions.height / 3 + grid.y,
					}
			  })()
			: null

	console.log(pinPosition)

	const handlePointerDown = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			if (toolsState.mode === 'fog' || toolsState.mode === 'measure') return

			if (e.button === 2 && isGM) {
				e.stopPropagation()
				const globalPosition = new PIXI.Point(e.globalX, e.globalY)
				console.log('Showing map menu at', globalPosition)
				setMapMenu({ x: globalPosition.x, y: globalPosition.y })
				return
			}

			if (e.button === 0) {
				clearSelection()
			}
		},
		[isGM, setMapMenu, clearSelection, toolsState.mode],
	)

	return (
		<Container eventMode='static' pointerdown={handlePointerDown}>
			<Tabletop />
			{texture ? (
				<>
					<Map texture={texture} mapAsset={mapAsset} />
					{isGM && toolsState.mode === 'fog' && (
						<FogEditor viewport={viewport} />
					)}
					<Tokens
						onShowMenu={setTokenMenu}
						viewport={viewport}
						activeTokenId={activeTokenId}
						selectedTokenIds={selectedTokenIds}
						onTokenSelect={handleTokenSelect}
					/>
					<DrawLayer />
					<MeasurementTool viewport={viewport} />
					{pinPosition && <Pin x={pinPosition.x} y={pinPosition.y} />}
					{/* <DebugGrid /> */}
				</>
			) : progress > 0 && progress < 100 ? (
				<ProgressLoader progress={progress} />
			) : null}
		</Container>
	)
}

export default StageContent
