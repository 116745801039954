import { useContext, useRef } from 'react'
import GameContext from '../../contexts/game'
import useAssetUploader from '../../hooks/useAssetUploader'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import UploadField from '../FormComponents/UploadField'
import Checkbox from './Checkbox'

const RemoveLogoButton = ({ onClick }) => (
	<Button className='absolute top-0 right-0 mt-0 bg-gray-800' onClick={onClick}>
		Remove Logo
	</Button>
)

const UploadLogoButton = ({ fileRef, onChange }) => (
	<>
		<Button className='pointer-events-none absolute top-0 right-0 mt-0 bg-gray-800'>
			Upload Logo
		</Button>
		<UploadField
			ref={fileRef}
			name='logo'
			className='opacity-0'
			onChange={onChange}
		/>
	</>
)

export default function Logo() {
	const { game, dispatch } = useContext(GameContext)
	const gameId = game._id
	const fileRef = useRef<HTMLInputElement>(null)
	const assetUploader = useAssetUploader()

	const handleChangeImage = async (gameId: string) => {
		try {
			const file = fileRef.current.files[0]
			const asset = await assetUploader(file, gameId)
			console.log('added asset', asset)
			if (!asset) return

			dispatch({
				type: 'UPDATE_LOGO',
				payload: {
					logo: asset._id,
				},
			})
		} catch (error) {
			console.error('Error while handling image:', error.message)
		}
	}

	const handleRemoveLogo = () => {
		dispatch({
			type: 'UPDATE_LOGO',
			payload: {
				logo: null,
			},
		})
	}

	const handleHideLogo = () => {
		dispatch({
			type: 'UPDATE_LOGO_VISIBILITY',
			payload: {
				hideLogo: !game.hideLogo,
			},
		})
	}

	return (
		<>
			<div className='center mt-1.5 flex align-middle'>
				<Label htmlFor='logo' className='mt-3 flex-grow'>
					Logo
				</Label>

				<div className='relative h-9 w-3/4'>
					{game.logo ? (
						<RemoveLogoButton onClick={handleRemoveLogo} />
					) : (
						<UploadLogoButton
							fileRef={fileRef}
							onChange={() => handleChangeImage(gameId)}
						/>
					)}
				</div>
			</div>
			<Checkbox
				id='hideLogo'
				className='mt-1'
				checked={game.hideLogo}
				onChange={handleHideLogo}
				label='Hide Logo'
			/>
		</>
	)
}
