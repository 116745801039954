// Token.tsx
import { Container, Graphics, Sprite } from '@pixi/react'
import * as ReactPixiAnimated from '@pixi/react-animated'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { memo, useCallback, useContext, useEffect, useRef } from 'react'
import { useSpring } from 'react-spring'
import {
	ActorRelationship,
	IActor,
	IActorLocation,
} from '../../../../shared/types/actor'
import DocumentsContext from '../../contexts/documents'
import { useGrid } from '../../contexts/grid'
import WindowsContext from '../../contexts/windows'
import useItemFilter from '../../hooks/UseItemFilter'
import { useGame } from '../../hooks/useGame'
import useGetTokenAsset from '../../hooks/useGetTokenAsset'
import { useSnapToGrid } from '../../hooks/useSnapToGrid'
import { TokenMenuState } from '../../hooks/useStageMenus'
import useUser from '../../hooks/useUser'
import TokenLabel from './TokenLabel'
import useTokenInteractions from './useTokenInteractions'

interface Props {
	document: IActor
	location: IActorLocation
	onShowMenu: (state: TokenMenuState) => void
	viewport: Viewport
	isActive: boolean
	isSelected?: boolean
	onSelect?: (id: string, isShiftKey: boolean) => void
}

/** Constant controlling token size relative to the cell. */
export const TOKEN_DEFAULT_SCALE = 0.75

const ACTIVE_SCALE_FACTOR = 1.08
const SELECTION_COLOR = 0x0088ff // Blue color for selection
const BORDER_WIDTH_FACTOR = 0.06 // Border width as a fraction of token size

const relationshipColors: { [key in ActorRelationship]: number } = {
	friend: 0x57c96d, // Green
	foe: 0xe33f29, // Red
	neutral: 0x808080, // Gray
}

const Token = memo(
	({
		document,
		location,
		onShowMenu,
		viewport,
		isActive,
		isSelected,
		onSelect,
	}: Props) => {
		const { game } = useGame()
		const { isGM } = useUser()
		const { dispatchDocuments } = useContext(DocumentsContext)
		const { dispatchWindows } = useContext(WindowsContext)
		const { tokenAsset } = useGetTokenAsset({ document })
		const { getCellSize } = useGrid()
		const snapToGrid = useSnapToGrid()
		const containerRef = useRef<PIXI.Container>(null)
		const maskContainerRef = useRef<PIXI.Container>(null)
		const maskRef = useRef<PIXI.Graphics>(null)
		const { checkAccess } = useItemFilter()

		if (!document || !document._id) {
			console.warn('Token received invalid document:', document)
			return null
		}

		const handleSelect = useCallback(
			(isShiftKey: boolean) => {
				onSelect?.(document._id, isShiftKey)
			},
			[document._id, onSelect],
		)

		const handleDoubleClick = () => {
			if (!document.parentId) return
			const parentDoc = game.documents.byId[document.parentId]
			if (!parentDoc || !checkAccess(parentDoc)) {
				console.warn('No access to document:', document.parentId)
				return
			}
			dispatchDocuments({
				type: 'OPEN_DOCUMENT',
				payload: {
					documentId: document.parentId,
				},
			})
			dispatchWindows({
				type: 'MOVE_WINDOW_TO_FRONT',
				payload: {
					documentId: document.parentId,
				},
			})
		}

		const { interactionProps, dragPosition } = useTokenInteractions({
			actorId: document._id,
			onRightClick: (position, dimensions) => {
				if (isGM) {
					handleSelect(false)
					onShowMenu({
						position: {
							x: position.x - dimensions.width / 2,
							y: position.y - dimensions.height / 2,
						},
						dimensions,
						document,
					})
				}
			},
			onClick: (isShiftKey: boolean) => handleSelect(isShiftKey),
			onDoubleClick: handleDoubleClick,
			viewport,
			isGM,
		})

		// The base cell size
		const cellSize = getCellSize()

		// Token size is fraction of cell size
		const tokenSize = cellSize * TOKEN_DEFAULT_SCALE

		// Snap to grid
		const snappedPosition = !location
			? { x: 0, y: 0 }
			: snapToGrid(location.x, location.y)

		// Outline color
		const outlineColor = isSelected
			? SELECTION_COLOR
			: isActive
			? 0xffd700
			: relationshipColors[document.values.relationship] || 0x000000

		const strokeWidth = tokenSize * BORDER_WIDTH_FACTOR

		// Circular mask
		const drawMask = (g: PIXI.Graphics) => {
			const radius = tokenSize / 2
			const sides = 60
			const points: number[] = []
			for (let i = 0; i < sides; i++) {
				const angle = (i * 2 * Math.PI) / sides
				points.push(radius * Math.cos(angle), radius * Math.sin(angle))
			}
			g.clear()
			g.beginFill(0xffffff)
			g.drawPolygon(points)
			g.endFill()
		}

		// Grow or shrink if active
		const { scale } = useSpring({
			scale: isActive ? ACTIVE_SCALE_FACTOR : 1,
			config: { mass: 1, tension: 300, friction: 20 },
		})

		// Mask
		useEffect(() => {
			if (maskContainerRef.current && maskRef.current) {
				maskContainerRef.current.mask = maskRef.current
			}
		}, [tokenAsset])

		return (
			<Container ref={containerRef} position={dragPosition || snappedPosition}>
				<ReactPixiAnimated.Container
					// @ts-ignore
					eventMode={'static' as PIXI.EventMode}
					{...interactionProps}
					scale={scale}
					pivot={[0, 0]}
				>
					<Graphics ref={maskRef} draw={drawMask} />
					<Container ref={maskContainerRef}>
						{tokenAsset ? (
							<Sprite
								texture={PIXI.Texture.from(tokenAsset.fileurl)}
								anchor={0.5}
								scale={
									tokenSize / Math.max(tokenAsset.width, tokenAsset.height)
								}
							/>
						) : (
							<Graphics
								draw={(g: PIXI.Graphics) => {
									const radius = tokenSize / 2
									const sides = 60
									const points: number[] = []
									for (let i = 0; i < sides; i++) {
										const angle = (i * 2 * Math.PI) / sides
										points.push(
											radius * Math.cos(angle),
											radius * Math.sin(angle),
										)
									}
									g.clear()
									g.beginFill(0xcccccc)
									g.drawPolygon(points)
									g.endFill()
								}}
							/>
						)}
					</Container>
					<Graphics
						draw={(g: PIXI.Graphics) => {
							const radius = tokenSize / 2
							const sides = 60
							const points: number[] = []
							for (let i = 0; i < sides; i++) {
								const angle = (i * 2 * Math.PI) / sides
								points.push(radius * Math.cos(angle), radius * Math.sin(angle))
							}
							g.clear()
							g.lineStyle(strokeWidth, outlineColor)
							g.drawPolygon(points)
						}}
					/>
				</ReactPixiAnimated.Container>
				{/* label below the token */}
				<TokenLabel text={document.values?.name || 'Unnamed'} />
			</Container>
		)
	},
)

Token.displayName = 'Token'

export default Token
