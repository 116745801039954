import { useContext } from 'react'
import { ResourceContext } from '../contexts/resources'
import useApi from './useApi'
import useDeleteEmbeddings from './useDeleteEmbeddings'

const useDeleteResource = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const { deleteEmbeddings } = useDeleteEmbeddings()
	const { request, loading, error } = useApi()

	const deleteResource = async (resourceId: string) => {
		try {
			const confirmed = window.confirm(
				'Are you sure you want to delete this PDF? This cannot be undone.',
			)
			if (!confirmed) {
				return
			}

			try {
				const deletedResource = await request(`/api/resource/${resourceId}`, {
					method: 'DELETE',
				})

				if (deletedResource) {
					// Delete embeddings after successful resource deletion
					await deleteEmbeddings(resourceId)
				}
			} catch (err) {
				// If we get a 404, the resource is already gone from the server
				// which is fine - we'll just clean up the local state
				if (!(err instanceof Error && err.message === 'Not Found')) {
					throw err
				}
			}

			// Delete the localStorage summary for the book
			const summaryKey = `summary_${resourceId}`
			localStorage.removeItem(summaryKey)

			// Update local state
			dispatchResource({
				type: 'REMOVE_RESOURCE',
				payload: { resourceId },
			})
		} catch (error) {
			console.error('Error deleting resource:', error)
			throw error
		}
	}

	return { deleteResource, loading, error }
}

export default useDeleteResource
