import { twMerge } from 'tailwind-merge'
import { RPGBookCategory } from '../../../../../../shared/types/bookcategories'
import RPGBookCategoryDescriptions from '../../../../interfaces/bookcategories'
import SearchInput, {
	SearchSuggestion,
} from '../../../resources/toolbar/SearchInput'
import FilterDropdown from './FilterDropdown'
import SidebarHeading from './SidebarHeading'
import SortDropdown, { SortMode } from './SortDropdown'
import ViewAsDropdown from './ViewAsDropdown'
import { ViewMode } from './ViewSelector'

interface BookSidebarProps {
	isOpen: boolean
	selectedCategories: RPGBookCategory[]
	onCategoriesChange: (categories: RPGBookCategory[]) => void
	selectedGameSystems: string[]
	onGameSystemsChange: (systems: string[]) => void
	availableGameSystems: string[]
	selectedRuleSystems: string[]
	onRuleSystemsChange: (systems: string[]) => void
	availableRuleSystems: string[]
	currentView: ViewMode
	onViewChange: (view: ViewMode) => void
	currentSort: SortMode
	onSortChange: (sort: SortMode) => void
	searchValue: string
	onSearch: (value: string) => void
	searchTerms: Array<{
		type:
			| 'text'
			| 'title'
			| 'author'
			| 'publisher'
			| 'game'
			| 'ruleSystem'
			| 'tag'
		value: string
	}>
	onSearchTermsChange: (
		terms: Array<{
			type:
				| 'text'
				| 'title'
				| 'author'
				| 'publisher'
				| 'game'
				| 'ruleSystem'
				| 'tag'
			value: string
		}>,
	) => void
	suggestions?: SearchSuggestion[]
}

const BookSidebar = ({
	isOpen,
	selectedCategories,
	onCategoriesChange,
	selectedGameSystems,
	onGameSystemsChange,
	availableGameSystems,
	selectedRuleSystems,
	onRuleSystemsChange,
	availableRuleSystems,
	currentView,
	onViewChange,
	currentSort,
	onSortChange,
	searchValue,
	onSearch,
	searchTerms,
	onSearchTermsChange,
	suggestions = [],
}: BookSidebarProps) => {
	const handleCategoryToggle = (category: string) => {
		onCategoriesChange(
			selectedCategories.includes(category as RPGBookCategory)
				? selectedCategories.filter(c => c !== category)
				: [...selectedCategories, category as RPGBookCategory],
		)
	}

	const handleGameSystemToggle = (system: string) => {
		onGameSystemsChange(
			selectedGameSystems.includes(system)
				? selectedGameSystems.filter(s => s !== system)
				: [...selectedGameSystems, system],
		)
	}

	const handleRuleSystemToggle = (system: string) => {
		onRuleSystemsChange(
			selectedRuleSystems.includes(system)
				? selectedRuleSystems.filter(s => s !== system)
				: [...selectedRuleSystems, system],
		)
	}

	const gameSystemItems = availableGameSystems.map(system => ({
		id: system,
		label: system,
	}))

	const ruleSystemItems = availableRuleSystems.map(system => ({
		id: system,
		label: system,
	}))

	const categoryItems = Object.entries(RPGBookCategoryDescriptions).map(
		([key, value]) => ({
			id: key,
			label: value.name,
		}),
	)

	const getCategoryLabel = (id: string) => {
		return RPGBookCategoryDescriptions[id as RPGBookCategory]?.name || id
	}

	return (
		<div
			className={twMerge(
				'sticky top-0 h-full w-64 p-8 transition-all duration-300',
				isOpen ? 'translate-x-0' : '-translate-x-full',
			)}
		>
			<div className='flex h-full flex-col'>
				<div className='mb-8'>
					<SearchInput
						placeholder='Search books...'
						onChange={e => onSearch(e.target.value)}
						value={searchValue}
						suggestions={suggestions}
						onSuggestionClick={suggestion => {
							const newSearchTerms = [...searchTerms, suggestion]
							onSearchTermsChange(newSearchTerms)
							onSearch('')
						}}
					/>
				</div>

				<div className='mb-8'>
					<SidebarHeading>View</SidebarHeading>
					<ViewAsDropdown
						currentView={currentView}
						onViewChange={onViewChange}
					/>
				</div>

				<div className='mb-8'>
					<SidebarHeading>Sort By</SidebarHeading>
					<SortDropdown currentSort={currentSort} onSortChange={onSortChange} />
				</div>

				<SidebarHeading>Filters</SidebarHeading>
				<div className='space-y-4'>
					<div className='space-y-1'>
						<FilterDropdown
							title='Game Systems'
							items={gameSystemItems}
							selectedItems={selectedGameSystems}
							onItemToggle={handleGameSystemToggle}
						/>
						<FilterDropdown
							title='Rule Systems'
							items={ruleSystemItems}
							selectedItems={selectedRuleSystems}
							onItemToggle={handleRuleSystemToggle}
						/>
						<FilterDropdown
							title='Categories'
							items={categoryItems}
							selectedItems={selectedCategories}
							onItemToggle={handleCategoryToggle}
							getItemLabel={getCategoryLabel}
						/>
					</div>
					{(selectedCategories.length > 0 ||
						selectedGameSystems.length > 0 ||
						selectedRuleSystems.length > 0) && (
						<button
							onClick={() => {
								onCategoriesChange([])
								onGameSystemsChange([])
								onRuleSystemsChange([])
							}}
							className='w-full rounded-md border border-gray-600 px-3 py-2 text-sm text-gray-400 transition-colors hover:border-gray-500 hover:bg-gray-700 hover:text-white'
						>
							Clear All Filters
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default BookSidebar
