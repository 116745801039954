import { FC, useState } from 'react'
import { BookResource } from '../../../../../../shared/types/resources'
import useResourceMetadataOptions from '../../../../hooks/useResourceMetadataOptions'
import useUpdateResource from '../../../../hooks/useUpdateResource'
import ModalWindow from '../../../window/ModalWindow'
import FilterDropdown from './FilterDropdown'

interface Props {
	id: string
	resources: BookResource[]
	isOpen: boolean
	onClose: () => void
}

interface FieldState {
	value: string | string[]
	isUniform: boolean
	isEnabled: boolean
	mode?: 'add' | 'replace'
}

interface BulkEditState {
	game: FieldState
	ruleSystem: FieldState
	publisher: FieldState
	year: FieldState
	edition: FieldState
	tags: FieldState
	contentWarnings: FieldState
}

const BulkEditModal: FC<Props> = ({ id, resources, isOpen, onClose }) => {
	const { updateResource } = useUpdateResource()
	const { games, ruleSystems } = useResourceMetadataOptions(resources[0])
	const [editState, setEditState] = useState<BulkEditState>(() => {
		// Initialize state by checking uniformity of each field
		const state: BulkEditState = {
			game: { value: '', isUniform: true, isEnabled: false },
			ruleSystem: { value: '', isUniform: true, isEnabled: false },
			publisher: { value: '', isUniform: true, isEnabled: false },
			year: { value: '', isUniform: true, isEnabled: false },
			edition: { value: '', isUniform: true, isEnabled: false },
			tags: { value: [], isUniform: true, isEnabled: false, mode: 'add' },
			contentWarnings: {
				value: [],
				isUniform: true,
				isEnabled: false,
				mode: 'add',
			},
		}

		// Check each field for uniformity
		resources.forEach((resource, index) => {
			if (index === 0) {
				// Set initial values from first resource
				state.game.value = resource.metadata?.game || ''
				state.ruleSystem.value = resource.metadata?.ruleSystem || ''
				state.publisher.value = resource.metadata?.publisher || ''
				state.year.value = resource.metadata?.year || ''
				state.edition.value = resource.metadata?.edition || ''
				state.tags.value = resource.metadata?.tags || []
				state.contentWarnings.value = resource.metadata?.contentWarnings || []
			} else {
				// Compare with first resource
				if (state.game.value !== (resource.metadata?.game || '')) {
					state.game.isUniform = false
					state.game.value = ''
				}
				if (state.ruleSystem.value !== (resource.metadata?.ruleSystem || '')) {
					state.ruleSystem.isUniform = false
					state.ruleSystem.value = ''
				}
				if (state.publisher.value !== (resource.metadata?.publisher || '')) {
					state.publisher.isUniform = false
					state.publisher.value = ''
				}
				if (state.year.value !== (resource.metadata?.year || '')) {
					state.year.isUniform = false
					state.year.value = ''
				}
				if (state.edition.value !== (resource.metadata?.edition || '')) {
					state.edition.isUniform = false
					state.edition.value = ''
				}
				// For arrays, check if they have the same elements
				if (
					!arraysEqual(
						state.tags.value as string[],
						resource.metadata?.tags || [],
					)
				) {
					state.tags.isUniform = false
					state.tags.value = []
				}
				if (
					!arraysEqual(
						state.contentWarnings.value as string[],
						resource.metadata?.contentWarnings || [],
					)
				) {
					state.contentWarnings.isUniform = false
					state.contentWarnings.value = []
				}
			}
		})

		return state
	})

	const [isReviewing, setIsReviewing] = useState(false)
	const [isSaving, setIsSaving] = useState(false)
	const [error, setError] = useState<string | null>(null)

	const handleClose = () => {
		if (!isSaving) {
			setIsReviewing(false)
			onClose()
		}
	}

	const handleReview = () => {
		setIsReviewing(true)
	}

	const handleSave = async () => {
		setIsSaving(true)
		setError(null)

		try {
			// Create a list of changes to apply
			const changes: Record<string, any> = {}

			if (editState.game.isEnabled) {
				changes.game = editState.game.value
			}
			if (editState.ruleSystem.isEnabled) {
				changes.ruleSystem = editState.ruleSystem.value
			}
			if (editState.publisher.isEnabled) {
				changes.publisher = editState.publisher.value
			}
			if (editState.year.isEnabled) {
				changes.year = editState.year.value
			}
			if (editState.edition.isEnabled) {
				changes.edition = editState.edition.value
			}

			// Handle array fields
			if (editState.tags.isEnabled) {
				if (editState.tags.mode === 'add') {
					// Combine existing tags with new ones
					changes.tags = (resource: BookResource) => {
						const existingTags = resource.metadata?.tags || []
						const newTags = editState.tags.value as string[]
						return [...new Set([...existingTags, ...newTags])]
					}
				} else {
					changes.tags = editState.tags.value
				}
			}

			if (editState.contentWarnings.isEnabled) {
				if (editState.contentWarnings.mode === 'add') {
					changes.contentWarnings = (resource: BookResource) => {
						const existing = resource.metadata?.contentWarnings || []
						const newWarnings = editState.contentWarnings.value as string[]
						return [...new Set([...existing, ...newWarnings])]
					}
				} else {
					changes.contentWarnings = editState.contentWarnings.value
				}
			}

			// Apply changes to each resource
			for (const resource of resources) {
				const metadata = { ...resource.metadata }

				// Apply each change
				Object.entries(changes).forEach(([key, value]) => {
					metadata[key] = typeof value === 'function' ? value(resource) : value
				})

				await updateResource(resource._id, metadata)
			}

			handleClose()
		} catch (err) {
			console.error('Failed to update resources:', err)
			setError(
				err instanceof Error ? err.message : 'Failed to update resources',
			)
		} finally {
			setIsSaving(false)
		}
	}

	const handleToggleField = (field: keyof BulkEditState) => {
		setEditState(prev => ({
			...prev,
			[field]: {
				...prev[field],
				isEnabled: !prev[field].isEnabled,
			},
		}))
	}

	const handleFieldChange = (
		field: keyof BulkEditState,
		value: string | string[],
	) => {
		setEditState(prev => ({
			...prev,
			[field]: {
				...prev[field],
				value,
			},
		}))
	}

	const handleModeChange = (
		field: keyof BulkEditState,
		mode: 'add' | 'replace',
	) => {
		setEditState(prev => ({
			...prev,
			[field]: {
				...prev[field],
				mode,
			},
		}))
	}

	const getFieldPlaceholder = (field: FieldState) => {
		if (!field.isUniform) return 'Mixed'
		if (Array.isArray(field.value)) return field.value.join(', ') || 'None'
		return field.value || 'None'
	}

	return (
		<ModalWindow
			id={id}
			title={`Edit ${resources.length} Books`}
			isOpen={isOpen}
			onClose={handleClose}
			size='medium'
			enableClickOutside={!isSaving}
			headerChildren={
				<div className='mr-2 flex space-x-4'>
					<button
						onClick={handleClose}
						disabled={isSaving}
						className='text-gray-400 hover:text-gray-300 disabled:opacity-50'
					>
						Cancel
					</button>
					{!isReviewing && (
						<button
							onClick={handleReview}
							disabled={
								isSaving ||
								!Object.values(editState).some(field => field.isEnabled)
							}
							className='text-blue-500 hover:text-blue-400 disabled:opacity-50'
						>
							Review Changes
						</button>
					)}
					{isReviewing && (
						<button
							onClick={handleSave}
							disabled={isSaving}
							className='text-white hover:text-gray-300 disabled:opacity-50'
						>
							{isSaving ? 'Saving...' : 'Save'}
						</button>
					)}
				</div>
			}
		>
			<div className='space-y-6 p-6'>
				{error && (
					<div className='rounded-md bg-red-900/50 p-3 text-sm text-red-200'>
						{error}
					</div>
				)}

				{!isReviewing ? (
					<>
						{/* Game Information */}
						<div className='space-y-4'>
							<h3 className='text-sm font-medium text-gray-300'>
								Game Information
							</h3>
							<div className='grid grid-cols-2 gap-4'>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>Game</label>
										<input
											type='checkbox'
											checked={editState.game.isEnabled}
											onChange={() => handleToggleField('game')}
										/>
									</div>
									<FilterDropdown
										title='Select Game'
										items={games}
										selectedItems={
											editState.game.isEnabled
												? typeof editState.game.value === 'string'
													? [editState.game.value]
													: []
												: []
										}
										onItemToggle={gameId => {
											handleFieldChange('game', gameId)
										}}
										disabled={!editState.game.isEnabled}
										placeholder={getFieldPlaceholder(editState.game)}
									/>
								</div>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>
											Rule System
										</label>
										<input
											type='checkbox'
											checked={editState.ruleSystem.isEnabled}
											onChange={() => handleToggleField('ruleSystem')}
										/>
									</div>
									<FilterDropdown
										title='Select Rule System'
										items={ruleSystems}
										selectedItems={
											editState.ruleSystem.isEnabled
												? typeof editState.ruleSystem.value === 'string'
													? [editState.ruleSystem.value]
													: []
												: []
										}
										onItemToggle={systemId => {
											handleFieldChange('ruleSystem', systemId)
										}}
										disabled={!editState.ruleSystem.isEnabled}
										placeholder={getFieldPlaceholder(editState.ruleSystem)}
									/>
								</div>
							</div>
						</div>

						{/* Publication Information */}
						<div className='space-y-4'>
							<h3 className='text-sm font-medium text-gray-300'>
								Publication Information
							</h3>
							<div className='grid grid-cols-2 gap-4'>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>
											Publisher
										</label>
										<input
											type='checkbox'
											checked={editState.publisher.isEnabled}
											onChange={() => handleToggleField('publisher')}
										/>
									</div>
									<input
										value={editState.publisher.value as string}
										onChange={e =>
											handleFieldChange('publisher', e.target.value)
										}
										disabled={!editState.publisher.isEnabled}
										placeholder={getFieldPlaceholder(editState.publisher)}
										className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400 disabled:opacity-50'
									/>
								</div>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>Year</label>
										<input
											type='checkbox'
											checked={editState.year.isEnabled}
											onChange={() => handleToggleField('year')}
										/>
									</div>
									<input
										value={editState.year.value as string}
										onChange={e => handleFieldChange('year', e.target.value)}
										disabled={!editState.year.isEnabled}
										placeholder={getFieldPlaceholder(editState.year)}
										className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400 disabled:opacity-50'
									/>
								</div>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>
											Edition
										</label>
										<input
											type='checkbox'
											checked={editState.edition.isEnabled}
											onChange={() => handleToggleField('edition')}
										/>
									</div>
									<input
										value={editState.edition.value as string}
										onChange={e => handleFieldChange('edition', e.target.value)}
										disabled={!editState.edition.isEnabled}
										placeholder={getFieldPlaceholder(editState.edition)}
										className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400 disabled:opacity-50'
									/>
								</div>
							</div>
						</div>

						{/* Tags and Warnings */}
						<div className='space-y-4'>
							<h3 className='text-sm font-medium text-gray-300'>
								Tags and Warnings
							</h3>
							<div className='space-y-4'>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>Tags</label>
										<div className='flex items-center space-x-4'>
											{editState.tags.isEnabled && (
												<div className='flex items-center space-x-2 text-sm'>
													<label>
														<input
															type='radio'
															checked={editState.tags.mode === 'add'}
															onChange={() => handleModeChange('tags', 'add')}
															className='mr-1'
														/>
														Add
													</label>
													<label>
														<input
															type='radio'
															checked={editState.tags.mode === 'replace'}
															onChange={() =>
																handleModeChange('tags', 'replace')
															}
															className='mr-1'
														/>
														Replace
													</label>
												</div>
											)}
											<input
												type='checkbox'
												checked={editState.tags.isEnabled}
												onChange={() => handleToggleField('tags')}
											/>
										</div>
									</div>
									<input
										value={(editState.tags.value as string[]).join(', ')}
										onChange={e =>
											handleFieldChange(
												'tags',
												e.target.value
													.split(',')
													.map(s => s.trim())
													.filter(Boolean),
											)
										}
										disabled={!editState.tags.isEnabled}
										placeholder={getFieldPlaceholder(editState.tags)}
										className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400 disabled:opacity-50'
									/>
								</div>
								<div>
									<div className='flex items-center justify-between'>
										<label className='block text-sm text-gray-400'>
											Content Warnings
										</label>
										<div className='flex items-center space-x-4'>
											{editState.contentWarnings.isEnabled && (
												<div className='flex items-center space-x-2 text-sm'>
													<label>
														<input
															type='radio'
															checked={editState.contentWarnings.mode === 'add'}
															onChange={() =>
																handleModeChange('contentWarnings', 'add')
															}
															className='mr-1'
														/>
														Add
													</label>
													<label>
														<input
															type='radio'
															checked={
																editState.contentWarnings.mode === 'replace'
															}
															onChange={() =>
																handleModeChange('contentWarnings', 'replace')
															}
															className='mr-1'
														/>
														Replace
													</label>
												</div>
											)}
											<input
												type='checkbox'
												checked={editState.contentWarnings.isEnabled}
												onChange={() => handleToggleField('contentWarnings')}
											/>
										</div>
									</div>
									<input
										value={(editState.contentWarnings.value as string[]).join(
											', ',
										)}
										onChange={e =>
											handleFieldChange(
												'contentWarnings',
												e.target.value
													.split(',')
													.map(s => s.trim())
													.filter(Boolean),
											)
										}
										disabled={!editState.contentWarnings.isEnabled}
										placeholder={getFieldPlaceholder(editState.contentWarnings)}
										className='mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-sm text-white placeholder-gray-400 disabled:opacity-50'
									/>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className='space-y-4'>
						<h3 className='text-sm font-medium text-gray-300'>
							Review Changes
						</h3>
						<div className='rounded-md bg-gray-700/50 p-4'>
							<div className='space-y-2'>
								{Object.entries(editState).map(([field, state]) => {
									if (!state.isEnabled) return null
									return (
										<div key={field} className='flex justify-between text-sm'>
											<span className='text-gray-400'>{field}:</span>
											<span className='text-white'>
												{Array.isArray(state.value)
													? `${
															state.mode === 'add' ? 'Add' : 'Replace with'
													  } "${(state.value as string[]).join(', ')}"`
													: `Set to "${state.value}"`}
											</span>
										</div>
									)
								})}
							</div>
						</div>
						<div className='space-y-2'>
							<p className='text-sm text-gray-400'>
								These changes will be applied to:
							</p>
							<ul className='max-h-32 overflow-y-auto text-sm text-gray-300'>
								{resources.map(resource => (
									<li key={resource._id} className='truncate py-0.5'>
										• {resource.metadata?.title || resource.name}
									</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>
		</ModalWindow>
	)
}

const arraysEqual = (a: string[], b: string[]) => {
	if (a.length !== b.length) return false
	const setA = new Set(a)
	return b.every(item => setA.has(item))
}

export default BulkEditModal
