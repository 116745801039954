import { ChevronDown } from 'lucide-react'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	children: React.ReactNode
	name: string
	className?: string
	containerClassName?: string
	defaultValue?: string
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
	disabled?: boolean
	isOnDark?: boolean
}

const Dropdown = forwardRef<HTMLSelectElement, IProps>(
	(
		{
			children,
			className = '',
			containerClassName = '',
			defaultValue = '',
			disabled,
			onChange,
			isOnDark = true,
			...props
		},
		ref,
	) => {
		return (
			<div className={twMerge('relative flex-1', containerClassName)}>
				<select
					ref={ref}
					className={twMerge(
						'mt-1 block w-full cursor-pointer appearance-none rounded-lg border-none py-2 pl-3 pr-10 text-base shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm',
						isOnDark ? 'bg-gray-800 text-white' : 'bg-black/5 text-gray-900',
						disabled ? 'cursor-not-allowed opacity-50' : '',
						className,
					)}
					defaultValue={defaultValue}
					onChange={onChange}
					disabled={disabled}
					{...props}
				>
					{children}
				</select>

				<div
					className='pointer-events-none absolute right-3 top-0.5 flex h-full'
					style={{ ...props.style }}
				>
					<ChevronDown
						className={twMerge(
							'h-4 w-4 self-center',
							isOnDark ? 'text-white' : 'text-gray-900',
						)}
					/>
				</div>
			</div>
		)
	},
)

export default Dropdown
