import { useGrid } from '../../contexts/grid'
import HexGrid from './grid/hex/HexGrid'
import SquareGrid from './grid/square/SquareGrid'

const Grid = () => {
	const { grid } = useGrid()

	if (!grid.enabled) return null

	if (grid.type === 'square') {
		return <SquareGrid />
	} else {
		return <HexGrid />
	}
}

export default Grid
