import { useCallback, useContext, useEffect } from 'react'
import EventContext from '../../contexts/event'
import GameContext from '../../contexts/game'
import {
	LogVisibilityProvider,
	useLogVisibility,
} from '../../contexts/logVisibility'
import useMessageHandler from '../../hooks/useMessageHandler'
import ErrorBoundary from '../ErrorBoundary'
import ChatForm from './ChatForm'
import Log from './Log'
import TypingIndicator from './TypingIndicator'

export const ChatContent = () => {
	const { eventQueue, dispatchEvent } = useContext(EventContext)
	const { game } = useContext(GameContext)
	const handleNewMessage = useMessageHandler()
	const { isHidden, setVisibleItems } = useLogVisibility()

	const handleEvents = useCallback(() => {
		if (eventQueue.length > 1)
			console.error('eventQueue is too long!', eventQueue)

		if (eventQueue.length) {
			const event = eventQueue[0]
			if (event.name === 'message') {
				handleNewMessage(event.data)
				dispatchEvent({ type: 'DELETE_EVENT', payload: event._id })
			}
		}
	}, [eventQueue, dispatchEvent, handleNewMessage])

	useEffect(() => {
		handleEvents()
	}, [handleEvents])

	// Initialize visible items when chat is opened
	useEffect(() => {
		if (!isHidden) {
			const messageCount = game.log.allIds.length
			const initialCount = Math.min(20, messageCount)
			setVisibleItems(initialCount)
		}
	}, [isHidden, setVisibleItems, game.log.allIds])

	return (
		<div className='flex min-h-0 flex-1 flex-col'>
			<div className={`min-h-0 flex-1 ${isHidden ? 'hidden' : ''}`}>
				<ErrorBoundary>
					<Log />
				</ErrorBoundary>
			</div>
		</div>
	)
}

export const ChatFooter = () => {
	return (
		<div className='flex flex-col'>
			<TypingIndicator />
			<ChatForm />
		</div>
	)
}

const ChatProviderContent = ({ children }: { children: React.ReactNode }) => {
	const { setIsHidden } = useLogVisibility()

	useEffect(() => {
		setIsHidden(false)
	}, [setIsHidden])

	return <>{children}</>
}

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
	return (
		<LogVisibilityProvider>
			<ChatProviderContent>{children}</ChatProviderContent>
		</LogVisibilityProvider>
	)
}
