import { defineHex, Grid, Hex, Orientation, rectangle } from 'honeycomb-grid'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { PixelPoint } from '../contexts/grid'

export function createHexDefinition(size: number, type: string) {
	return defineHex({
		dimensions: size / 2,
		orientation: type === 'vhex' ? Orientation.POINTY : Orientation.FLAT,
		origin: 'topLeft',
	})
}

export function createHexGrid(
	size: number,
	type: string,
	gridDimensions: { width: number; height: number },
) {
	const hexDefinition = createHexDefinition(size, type)
	const sampleHex = new hexDefinition()
	const hexWidth = sampleHex.width
	const hexHeight = sampleHex.height

	const isPointyTop = type === 'vhex'
	const colCount = isPointyTop
		? Math.floor((gridDimensions.width - hexWidth) / hexWidth) + 1
		: Math.floor((gridDimensions.width - hexWidth) / (0.75 * hexWidth)) + 1

	const rowCount = isPointyTop
		? Math.floor((gridDimensions.height - hexHeight) / (0.75 * hexHeight)) + 1
		: Math.floor((gridDimensions.height - hexHeight) / hexHeight) + 1

	return {
		grid: new Grid(
			hexDefinition,
			rectangle({
				width: colCount + 2,
				height: rowCount + 2,
			}),
		),
		hexWidth,
		hexHeight,
	}
}

export function pickCornerOnHexFarSide(sourceHex: Hex, targetHex: Hex) {
	if (!sourceHex || !targetHex) return null

	// Get center points of both hexes
	const sourceX = sourceHex.x
	const sourceY = sourceHex.y
	const targetX = targetHex.x
	const targetY = targetHex.y

	// Calculate direction vector from source to target hex
	const directionX = targetX - sourceX
	const directionY = targetY - sourceY

	let furthestCorner = sourceHex.corners[0]
	let smallestDotProduct = Infinity

	// Find corner with smallest dot product (most opposite direction)
	for (const corner of sourceHex.corners) {
		const cornerVectorX = corner.x - sourceX
		const cornerVectorY = corner.y - sourceY
		const dotProduct = directionX * cornerVectorX + directionY * cornerVectorY
		if (dotProduct < smallestDotProduct) {
			smallestDotProduct = dotProduct
			furthestCorner = corner
		}
	}
	return furthestCorner
}

export function toWorldCoords(
	e: PIXI.FederatedPointerEvent,
	viewport: Viewport | null,
) {
	if (!viewport) return null
	const worldPos = viewport.toWorld(e.globalX, e.globalY)
	return new PIXI.Point(worldPos.x, worldPos.y)
}

export function distanceBetweenPoints<T extends PIXI.Point | PixelPoint>(
	a: T,
	b: T,
): number {
	const dx = b.x - a.x
	const dy = b.y - a.y
	return Math.sqrt(dx * dx + dy * dy)
}

export function isClickDistance<T extends PIXI.Point | PixelPoint>(
	a: T,
	b: T,
	threshold = 3,
): boolean {
	return distanceBetweenPoints(a, b) < threshold
}

export function clampGridOffset(
	proposedOffset: PIXI.Point,
	mapDimensions: { width: number; height: number },
): PIXI.Point {
	const minX = -(mapDimensions.width / 2)
	const maxX = mapDimensions.width / 2
	const minY = -(mapDimensions.height / 2)
	const maxY = mapDimensions.height / 2

	const clampedX = Math.min(maxX, Math.max(minX, proposedOffset.x))
	const clampedY = Math.min(maxY, Math.max(minY, proposedOffset.y))

	return new PIXI.Point(clampedX, clampedY)
}
