import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useGrid } from '../../../../../contexts/grid'
import { SquareGrid } from '../core/Grid'

const DEFAULT_LINE_WIDTH = 0.05

interface SquareGridRendererProps {
	grid: SquareGrid
}

export const SquareGridRenderer = ({ grid }: SquareGridRendererProps) => {
	const { grid: gridConfig, gridDimensions } = useGrid()
	const { width, height } = gridDimensions

	const drawGrid = (g: PIXI.Graphics) => {
		if (!width || !height) return

		g.clear()
		g.alpha = gridConfig.alpha
		g.lineStyle(
			DEFAULT_LINE_WIDTH,
			parseInt(gridConfig.color.replace('#', ''), 16),
			1,
		)

		const { x: offsetX, y: offsetY } = gridConfig
		const cellSize = grid.size

		// Draw vertical lines
		for (let x = 0; x <= width + cellSize; x += cellSize) {
			g.moveTo(x + offsetX, offsetY)
			g.lineTo(x + offsetX, height + offsetY)
		}

		// Draw horizontal lines
		for (let y = 0; y <= height + cellSize; y += cellSize) {
			g.moveTo(offsetX, y + offsetY)
			g.lineTo(width + offsetX, y + offsetY)
		}
	}

	if (!width || !height) return null

	return <Graphics draw={drawGrid} eventMode='none' />
}
