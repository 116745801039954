import {
	LibraryToken,
	TokenLibrary,
	TokenSet,
} from '../../../../shared/types/tokenLibrary'
import { beowulfSet, beowulfTokens } from './beowulfTokens'

// Add all default sets here
const defaultSets: TokenSet[] = [beowulfSet]

// Create a lookup of all tokens
const allTokens = beowulfTokens.reduce((acc, token) => {
	acc[token.id] = token
	return acc
}, {} as { [id: string]: LibraryToken })

// Create the initial token library state
export const initialTokenLibrary: TokenLibrary = {
	sets: {
		byId: defaultSets.reduce((acc, set) => {
			acc[set.id] = set
			return acc
		}, {} as { [id: string]: TokenSet }),
		allIds: defaultSets.map(set => set.id),
	},
	tokens: {
		byId: allTokens,
		allIds: Object.keys(allTokens),
	},
}
