import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import GameContext from '../../contexts/game'
import { usePanelSystem } from '../../contexts/panelSystem'
import Message from '../chat/Message'
import Button from '../FormComponents/Button'

const NOTIFICATION_TIMEOUT_MS = 10000 // Show notifications for messages in the last 10 seconds

interface NotificationsProps {
	onToggle: (id: string) => Promise<void>
}

const Notifications = ({ onToggle }: NotificationsProps) => {
	const { game } = useContext(GameContext)
	const { panels } = usePanelSystem()
	const notificationsRef = useRef<HTMLDivElement>(null)
	const [isAtBottom, setIsAtBottom] = useState(true)
	const [hiddenMessageIds, setHiddenMessageIds] = useState<Set<string>>(
		new Set(),
	)

	// Hide all messages when chat opens
	useEffect(() => {
		if (panels.chat.isOpen) {
			const newHiddenIds = new Set(game.log.allIds)
			setHiddenMessageIds(newHiddenIds)
		}
	}, [panels, game.log.allIds])

	// Get recent messages from game log
	const recentMessages = useMemo(() => {
		// Don't show if chat panel is open
		if (panels.chat.isOpen) return []

		const now = Date.now()
		const cutoffTime = now - NOTIFICATION_TIMEOUT_MS

		return (
			game.log.allIds
				.map(id => game.log.byId[id])
				.filter(msg => {
					// Only show messages newer than cutoff time
					const isRecent = msg.createdAt > cutoffTime
					// Don't show hidden messages
					const isVisible = !hiddenMessageIds.has(msg._id)
					// Show chat messages and dice rolls
					const isRelevantType = msg.type === 'message' || msg.type === 'dice'
					return isRecent && isVisible && isRelevantType
				})
				// Sort by timestamp, oldest first
				.sort((a, b) => (a.createdAt || 0) - (b.createdAt || 0))
				.reverse()
		)
	}, [game.log, panels.chat.isOpen, hiddenMessageIds])

	const scrollToBottom = useCallback(() => {
		if (notificationsRef.current) {
			const scrollTarget =
				notificationsRef.current.querySelector('#scroll-target')
			scrollTarget?.scrollIntoView({ behavior: 'auto' })
		}
	}, [])

	// Track if user has scrolled up from bottom
	const checkIfAtBottom = useCallback(() => {
		if (notificationsRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = notificationsRef.current
			const atBottom = Math.abs(scrollHeight - clientHeight - scrollTop) < 1
			setIsAtBottom(atBottom)
		}
	}, [])

	// Scroll to bottom when new messages arrive
	useEffect(() => {
		if (isAtBottom) {
			requestAnimationFrame(() => {
				scrollToBottom()
			})
		}
	}, [isAtBottom, scrollToBottom, recentMessages.length])

	// Set up scroll and resize listeners
	useEffect(() => {
		const container = notificationsRef.current
		if (container) {
			container.addEventListener('scroll', checkIfAtBottom)

			// Create resize observer to maintain scroll position when panel resizes
			const resizeObserver = new ResizeObserver(() => {
				if (isAtBottom) {
					requestAnimationFrame(scrollToBottom)
				}
			})
			resizeObserver.observe(container)

			return () => {
				container.removeEventListener('scroll', checkIfAtBottom)
				resizeObserver.disconnect()
			}
		}
	}, [checkIfAtBottom, isAtBottom, scrollToBottom])

	const handleClick = useCallback(() => {
		onToggle('chat')
	}, [onToggle])

	const handleClear = useCallback(() => {
		setHiddenMessageIds(new Set(game.log.allIds))
	}, [game.log.allIds])

	if (!recentMessages.length) return null

	return (
		<div
			ref={notificationsRef}
			className='pointer-events-auto flex h-1/3 flex-col-reverse space-y-2 overflow-y-auto p-2'
		>
			{recentMessages.length > 0 && (
				<Button
					onClick={handleClear}
					className='m-auto mt-2 flex w-48 items-center justify-center gap-1 rounded-full'
				>
					Clear Notifications
				</Button>
			)}
			{recentMessages.map((message, index) => {
				const prevMessage = recentMessages[index - 1]
				const newSender = !prevMessage || prevMessage.sender !== message.sender
				const newAssumedCharacter =
					!prevMessage ||
					prevMessage.assumedCharacterId !== message.assumedCharacterId
				const showSender = newSender || newAssumedCharacter

				return (
					<div key={message._id} onClick={handleClick}>
						<Message
							message={message}
							onDelete={() => {}} // No delete in notifications
							showSender={showSender}
						/>
					</div>
				)
			})}
			<div id='scroll-target' />
		</div>
	)
}

export default Notifications
