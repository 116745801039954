import React from 'react'
import { twMerge } from 'tailwind-merge'
import Checkbox from '../FormComponents/Checkbox'
import Label from '../FormComponents/Label'

interface FlagCheckboxProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string
	isOnDark?: boolean
}

const FlagCheckbox: React.FC<FlagCheckboxProps> = ({
	id,
	className,
	checked,
	onChange,
	label,
	isOnDark,
	...props
}) => {
	const displayLabel = label || (id && id.charAt(0).toUpperCase() + id.slice(1))

	return (
		<div className='my-4 flex items-center'>
			{displayLabel && (
				<Label
					htmlFor={id}
					className={twMerge('flex-grow', className)}
					isOnDark={isOnDark}
				>
					{displayLabel}
				</Label>
			)}
			<div className='w-80'>
				<Checkbox
					id={id}
					className={className}
					checked={checked}
					onChange={onChange}
					{...props}
				/>
			</div>
		</div>
	)
}

export default FlagCheckbox
