import React from 'react'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../../interface/constants'

interface LogoSVGProps {
	className?: string
	color?: string
}

const LogoSVG: React.FC<LogoSVGProps> = ({
	className = '',
	color = INTERFACE_BRIGHT_GOLD_COLOR,
}) => {
	return (
		<svg
			viewBox='0 0 61.3 66.12'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<g>
				<path
					fill={color}
					d='M0,63.39c0,1.51,1.23,2.74,2.74,2.74h57.19c1.41,0,1.66-1.65,1.08-2.69s-11.98-13.93-13.93-16.24-.91-4.19.44-4.96c6.85-3.88,11.47-11.23,11.47-19.66C58.99,10.11,48.88,0,36.42,0H3.12C1.4,0,0,1.4,0,3.12v60.27Z'
				/>
			</g>
		</svg>
	)
}

export default LogoSVG
