import { useEffect, useReducer, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AppContent } from './components/AppContent'
import LoadingScreen from './components/LoadingScreen'
import { ProfileEditProvider } from './contexts/ProfileEditContext'
import useApi from './hooks/useApi'
import { IAuthState, UserLevel } from './interfaces/auth'
import { IUser } from './interfaces/users'
import AuthReducer from './reducers/auth'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

export default function App() {
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const origin = window.location.origin
	const [loading, setLoading] = useState(true)
	const { request } = useApi()

	const loginHandler = (token: string, user: IUser) => {
		authDispatch({
			type: 'UPDATE_ALL',
			payload: {
				userId: user._id,
				token,
				userDetails: {
					name: user.name,
					email: user.email,
					avatar: user.avatar,
					userLevel: user.userLevel,
				},
			},
		})
	}

	const logoutHandler = () => {
		const options: RequestInit = {
			method: 'post',
			credentials: 'include',
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
			},
		}

		fetch(`${origin}/api/logout/`, options)
			.then(res => {
				if (!res.ok) {
					alert('An error occurred while attempting to sign out.')
				}
				return res
			})
			.then(() => {
				authDispatch({ type: 'LOGOUT' })
				localStorage?.removeItem('token')
			})
	}

	const loadedUser = localStorage?.getItem('user')

	const initialAuthState: IAuthState = {
		token: localStorage?.getItem('token') || null,
		userId: loadedUser || '',
		userDetails: {
			name: '',
			avatar: '',
			email: '',
			userLevel: UserLevel.ADVENTURER,
		},
		login: loginHandler,
		logout: logoutHandler,
	}

	const [authState, authDispatch] = useReducer(AuthReducer, initialAuthState)

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const data = await request('/api/users/me')
				const { token, user } = data

				if (!user || !token) {
					authDispatch({ type: 'LOGOUT' })
					localStorage?.removeItem('token')
				} else {
					authDispatch({
						type: 'UPDATE_ALL',
						payload: {
							userId: user._id,
							token: token,
							userDetails: {
								name: user.name,
								avatar: user.avatar,
								email: user.email,
								userLevel: user.userLevel || UserLevel.ADVENTURER,
							},
						},
					})
				}
			} catch (error) {
				console.error('Auth check failed:', error)
				authDispatch({ type: 'LOGOUT' })
				localStorage?.removeItem('token')
			} finally {
				setLoading(false)
			}
		}

		checkAuth()
	}, [request])

	if (loading) {
		return <LoadingScreen />
	}

	const isAuthed = !!authState.token
	const isInvited = !!inviteToken

	const hideNavigation = (pathname: string) => {
		return (
			pathname.startsWith('/admin') ||
			pathname.startsWith('/game/') ||
			pathname.startsWith('/document/')
		)
	}

	return (
		<ProfileEditProvider>
			<AppContent
				authState={authState}
				authDispatch={authDispatch}
				isAuthed={isAuthed}
				isInvited={isInvited}
				hideNavigation={hideNavigation}
			/>
		</ProfileEditProvider>
	)
}
