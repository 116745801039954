import { Container, Text } from '@pixi/react'
import { useCallback, useMemo } from 'react'
import { useGrid } from '../../../contexts/grid'
import useTextStyle from '../../../hooks/useTextStyle'

interface Props {
	points: { x: number; y: number }[]
	isClosed: boolean
}

export default function MeasurementDisplay({ points, isClosed }: Props) {
	const { grid, hexGrid } = useGrid()
	const textStyle = useTextStyle()
	const totalTextStyle = useTextStyle(1.5) // 1.5x scale for total distance

	// Calculate distance between two points
	const calculateDistance = useCallback(
		(p1: { x: number; y: number }, p2: { x: number; y: number }) => {
			if (grid.type === 'square') {
				const dx = p2.x - p1.x
				const dy = p2.y - p1.y
				return Math.sqrt(dx * dx + dy * dy) / grid.size
			}

			// For hex grids, convert to hex coordinates and count steps
			if (!hexGrid) return 0

			const hex1 = hexGrid.pointToHex(p1)
			const hex2 = hexGrid.pointToHex(p2)
			if (!hex1 || !hex2) return 0

			const hexDx = hex2.col - hex1.col
			const hexDy = hex2.row - hex1.row
			return (Math.abs(hexDx) + Math.abs(hexDy) + Math.abs(hexDx + hexDy)) / 2
		},
		[grid.type, grid.size, hexGrid],
	)

	// Memoize segment calculations
	const segments = useMemo(() => {
		const result = []
		for (let i = 0; i < points.length - 1; i++) {
			const start = points[i]
			const end = points[i + 1]
			const distance = calculateDistance(start, end) * grid.unitSize

			// Calculate angle for text rotation
			const angle = Math.atan2(end.y - start.y, end.x - start.x)
			const normalizedAngle =
				((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI)
			const displayAngle =
				normalizedAngle > Math.PI / 2 && normalizedAngle <= (3 * Math.PI) / 2
					? angle + Math.PI
					: angle

			// Calculate offset perpendicular to line
			const perpAngle = angle + Math.PI / 2
			const offset = grid.size * 0.3
			const midpoint = {
				x: (start.x + end.x) / 2 + Math.cos(perpAngle) * offset,
				y: (start.y + end.y) / 2 + Math.sin(perpAngle) * offset,
			}

			result.push({
				distance,
				position: midpoint,
				rotation: (displayAngle * 180) / Math.PI,
			})
		}
		return result
	}, [points, calculateDistance, grid.unitSize, grid.size])

	// Calculate total distance only when needed
	const totalDistance = useMemo(() => {
		if (!isClosed || points.length < 2) return 0
		return segments.reduce((sum, segment) => sum + segment.distance, 0)
	}, [segments, isClosed, points.length])

	if (points.length < 2) return null

	return (
		<Container sortableChildren>
			{/* Render segment distances */}
			{segments.map(({ distance, position, rotation }, i) => (
				<Text
					key={i}
					text={`${Math.round(distance)}${grid.unit}`}
					x={position.x}
					y={position.y}
					anchor={0.5}
					angle={rotation}
					style={textStyle}
					resolution={150}
				/>
			))}

			{/* Render total distance if measurement is closed */}
			{isClosed && points.length >= 2 && (
				<Text
					text={`${Math.round(totalDistance)}${grid.unit}`}
					x={points[points.length - 1].x}
					y={points[points.length - 1].y - grid.size * 0.3}
					anchor={0.5}
					style={totalTextStyle}
					resolution={150}
				/>
			)}
		</Container>
	)
}
