import { Graphics } from '@pixi/react'
import { rectangle } from 'honeycomb-grid'
import * as PIXI from 'pixi.js'
import { useGrid } from '../../../../../contexts/grid'

const DEFAULT_LINE_WIDTH = 0.05

export const HexGridRenderer = () => {
	const { grid, hexGrid, hexDefinition, gridDimensions, mapDimensions } =
		useGrid()
	const { width, height } = gridDimensions
	const originOffset = {
		x: gridDimensions.width / 3,
		y: gridDimensions.height / 3,
	}

	const drawHexGrid = (g: PIXI.Graphics) => {
		if (!hexGrid || !hexDefinition || !width || !height) {
			return
		}

		g.clear()
		g.alpha = grid.alpha
		g.lineStyle(
			DEFAULT_LINE_WIDTH,
			parseInt(grid.color.replace('#', ''), 16),
			1,
		)

		const { x: offsetX, y: offsetY } = grid

		// Calculate the bounds of the map area in hex coordinates
		const mapTopLeft = hexGrid.pointToHex({
			x: -offsetX + originOffset.x,
			y: -offsetY + originOffset.y,
		})
		const mapBottomRight = hexGrid.pointToHex({
			x: mapDimensions.width - offsetX + originOffset.x,
			y: mapDimensions.height - offsetY + originOffset.y,
		})

		if (!mapTopLeft || !mapBottomRight) return

		// Add padding to ensure we draw hexes that partially overlap the edges
		const colStart = Math.floor(mapTopLeft.col - 1)
		const colEnd = Math.ceil(mapBottomRight.col + 1)
		const rowStart = Math.floor(mapTopLeft.row - 1)
		const rowEnd = Math.ceil(mapBottomRight.row + 1)

		// Draw only hexes that could intersect with the map area
		hexGrid
			.traverse(
				rectangle({
					width: colEnd - colStart + 1,
					height: rowEnd - rowStart + 1,
					start: { col: colStart, row: rowStart },
				}),
			)
			.forEach(hex => {
				const translatedCorners = hex.corners.map(corner => ({
					x: corner.x + offsetX,
					y: corner.y + offsetY,
				}))
				g.drawShape(new PIXI.Polygon(translatedCorners))
			})
	}

	if (!width || !height) return null

	return <Graphics draw={drawHexGrid} eventMode='none' />
}
