import { Transition } from '@headlessui/react'
import { useContext } from 'react'
import UsersContext from '../../contexts/users'
import Overlapper from '../Overlapper'
import UserHead from './UserHead'

export default function UserHeads() {
	const { usersState } = useContext(UsersContext)

	if (!usersState || !usersState.users) return null

	return (
		<Overlapper
			className='-mt-2 flex-shrink-0 space-x-2'
			hoverScale={1.2}
			centered
		>
			{[
				...usersState.users.map(user => (
					<Transition
						key={user.userId}
						show={true}
						enter='transition duration-300'
						enterFrom='transform scale-95 opacity-0'
						enterTo='transform scale-100 opacity-100'
						leave='transition duration-300'
						leaveFrom='transform scale-100 opacity-100'
						leaveTo='transform scale-95 opacity-0'
					>
						<div
							className={`transform transition-all duration-500 ${
								user.online
									? 'w-20 scale-100 opacity-100 hover:mx-1.5'
									: 'w-0 scale-0 opacity-40'
							}`}
						>
							<UserHead user={user} />
						</div>
					</Transition>
				)),
			]}
		</Overlapper>
	)
}
