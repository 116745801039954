import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import { IMark } from '../../../../shared/types/draw'
import { MAX_LINE_WIDTH } from '../../utils/drawingConstants'

const MARK_OPACITY = 0.75

interface Props {
	mark: IMark
	color: string
}

const ExistingMark = ({ mark, color }: Props) => {
	const draw = useCallback(
		(g: PIXI.Graphics) => {
			if (!mark.points || mark.points.length === 0) return

			g.clear()

			// Create and apply AlphaFilter
			const alphaFilter = new PIXI.AlphaFilter(MARK_OPACITY)
			g.filters = [alphaFilter]

			// Draw each segment with its stored width and full opacity
			for (let i = 1; i < mark.points.length; i++) {
				const width = mark.points[i].width || MAX_LINE_WIDTH
				g.lineStyle({
					width,
					color: parseInt(color.replace('#', '0x')),
					alpha: 1, // Full opacity for individual lines
					cap: PIXI.LINE_CAP.ROUND,
					join: PIXI.LINE_JOIN.ROUND,
				})
				g.moveTo(mark.points[i - 1].x, mark.points[i - 1].y)
				g.lineTo(mark.points[i].x, mark.points[i].y)
			}
		},
		[mark.points, color],
	)

	return <Graphics draw={draw} />
}

export default ExistingMark
