import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IDocument } from '../../../../../shared/types/document'
import { ISceneValues } from '../../../../../shared/types/scene'
import GameContext from '../../../contexts/game'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import { useGridMode } from '../../../hooks/useGridMode'
import AssetManager from '../../AssetManager'
import ActivateSceneButton from '../../Library/ActivateSceneButton'
import SceneName from '../../Library/SceneEditor/SceneName'
import SectionDivider from '../../SectionDivider'
import EffectsOverlaySelector from '../../interface/EffectsOverlaySelector'

interface Props {
	doc?: IDocument
	onClose?: () => void
}

export default function MapEditorContent({ doc, onClose }: Props) {
	const { dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const scene = doc || activeScene
	const { activateGridMode } = useGridMode()

	const form = useForm<ISceneValues>({
		defaultValues: {
			...scene?.values,
			effectsOverlay: scene?.values?.effectsOverlay || '',
			grid: {
				...defaultGridValues,
				...scene?.values?.grid,
			},
		},
	})

	useEffect(() => {
		form.reset({
			...scene?.values,
			effectsOverlay: scene?.values?.effectsOverlay || '',
			grid: {
				...defaultGridValues,
				...scene?.values?.grid,
			},
		})
	}, [scene?._id])

	const values = useWatch({
		control: form.control,
	})

	const handleUpdate = () => {
		if (!scene) return

		const updatedDocument = {
			...scene,
			version: scene.version + 1,
			values: {
				...scene.values,
				...values,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: { updatedDocument },
		})
	}

	useEffect(() => {
		handleUpdate()
	}, [values])

	if (!scene) return null

	const handleAssetChange = (asset: any) => {
		if (asset) {
			form.setValue('mapId', asset._id)
		}
	}

	return (
		<FormProvider {...form}>
			<div className='mx-auto w-80 p-4 pt-0'>
				<SectionDivider label='Map' />
				<div className='space-y-1'>
					<div className='relative'>
						<AssetManager
							className='mx-auto max-h-40 w-full rounded-lg'
							assetId={scene.values.mapId}
							onAssetChange={handleAssetChange}
						/>
						{scene.values.mapId && (
							<div className='absolute left-4 bottom-4 z-20'>
								<ActivateSceneButton sceneId={scene._id} />
							</div>
						)}
					</div>
					<SceneName doc={scene} />
					<EffectsOverlaySelector doc={scene} />
					<div>
						<button
							onClick={() => {
								activateGridMode()
								onClose?.()
							}}
							className='mt-1 w-full rounded-lg bg-gray-700 px-3 py-2 text-sm text-gray-200 hover:bg-gray-600'
						>
							Edit Grid
						</button>
					</div>
				</div>
			</div>
		</FormProvider>
	)
}

const defaultGridValues = {
	enabled: false,
	type: 'square' as 'square' | 'hhex' | 'vhex',
	size: 1,
	unitSize: 5,
	unit: 'ft',
	color: '#000000',
	alpha: 0.2,
	x: 0,
	y: 0,
}
