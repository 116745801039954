import { createContext, ReactNode, useContext, useState } from 'react'

interface LogVisibilityContextType {
	isHidden: boolean
	setIsHidden: (value: boolean) => void
	visibleItems: number
	setVisibleItems: (value: number) => void
	loadedMessageIds: Set<string>
	setLoadedMessageIds: (
		value: Set<string> | ((prev: Set<string>) => Set<string>),
	) => void
}

const LogVisibilityContext = createContext<LogVisibilityContextType | null>(
	null,
)

export function LogVisibilityProvider({ children }: { children: ReactNode }) {
	const [isHidden, setIsHidden] = useState(true)
	const [visibleItems, setVisibleItems] = useState(0)
	const [loadedMessageIds, setLoadedMessageIds] = useState<Set<string>>(
		new Set(),
	)

	return (
		<LogVisibilityContext.Provider
			value={{
				isHidden,
				setIsHidden,
				visibleItems,
				setVisibleItems,
				loadedMessageIds,
				setLoadedMessageIds,
			}}
		>
			{children}
		</LogVisibilityContext.Provider>
	)
}

export function useLogVisibility() {
	const context = useContext(LogVisibilityContext)
	if (!context) {
		throw new Error(
			'useLogVisibility must be used within a LogVisibilityProvider',
		)
	}
	return context
}

export default LogVisibilityContext
