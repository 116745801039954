import { Viewport } from 'pixi-viewport'
import { useContext, useEffect } from 'react'
import ToolsContext from '../contexts/tools'

export const useViewportControls = (viewport: Viewport | null) => {
	const { toolsState, dispatchTools } = useContext(ToolsContext)

	useEffect(() => {
		if (!viewport) return

		const handleKeyDown = (_e: KeyboardEvent) => {
			// Temporarily disabled spacebar handling
			// if (e.code === 'Space') {
			// 	e.preventDefault() // Prevent page scrolling
			// 	if (toolsState.mode === 'draw') {
			// 		dispatchTools({ type: 'SET_MODE', payload: null })
			// 		viewport.plugins.resume('drag')
			// 	}
			// }
		}

		const handleKeyUp = (_e: KeyboardEvent) => {
			// Temporarily disabled spacebar handling
			// if (e.code === 'Space') {
			// 	if (toolsState.mode === null) {
			// 		dispatchTools({ type: 'SET_MODE', payload: 'draw' })
			// 		viewport.plugins.pause('drag')
			// 	}
			// }
		}

		// Configure viewport drag
		viewport.plugins.remove('drag')
		viewport.drag({
			clampWheel: false,
		})

		// Set initial state after configuring drag
		if (toolsState.mode === 'draw') {
			viewport.plugins.pause('drag')
		} else {
			viewport.plugins.resume('drag')
		}

		window.addEventListener('keydown', handleKeyDown)
		window.addEventListener('keyup', handleKeyUp)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
			window.removeEventListener('keyup', handleKeyUp)
			viewport.plugins.resume('drag')
		}
	}, [viewport, toolsState.mode, dispatchTools])
}
