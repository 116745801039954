import { Dispatch } from 'react'
import { IUser } from './users'

export interface IAuthContext {
	authState: IAuthState
	authDispatch: Dispatch<IAuthAction>
}

export interface ILogin {
	token: string
	user: IUser
}

type TUserDetails = {
	name: string
	email: string
	avatar?: string
	userLevel?: UserLevel
}

export interface IAuthState {
	token: string | null
	userId: string
	userDetails: TUserDetails
	login: (token: string, user: IUser) => void
	logout: () => void
}

export type IAuthAction =
	| {
			type: 'UPDATE_ALL'
			payload: {
				userId: string
				token: string
				userDetails: TUserDetails
			}
	  }
	| {
			type: 'UPDATE_PROFILE'
			payload: {
				userDetails: Partial<TUserDetails>
			}
	  }
	| {
			type: 'UPDATE_PROFILE_PICTURE'
			payload: {
				avatar: string
			}
	  }
	| {
			type: 'LOGOUT'
	  }

export enum UserLevel {
	ADVENTURER = 'ADVENTURER',
	ADEPT = 'ADEPT',
	ADMIN = 'ADMIN',
}

export interface AuthData {
	userId: string
	token: string
	tokenExpiration: number
	email: string
	name: string
	avatar?: string
	userLevel: UserLevel
}
