import { gql, useMutation } from '@apollo/client'
import { FC, useContext } from 'react'
import GameContext from '../../contexts/game'
import Label from '../FormComponents/Label'
import SectionDivider from '../SectionDivider'
import DebugButton from '../toolbar/DebugButton'
import DevelopmentMode from './DevelopmentMode'
import SystemInfo from './SystemInfo'
import SystemLoader from './SystemLoader'
import SystemReset from './SystemReset'

const SET_GAME_TEMPLATE = gql`
	mutation SetGameTemplate($gameId: ID!, $isTemplate: Boolean!) {
		setGameTemplate(gameId: $gameId, isTemplate: $isTemplate) {
			_id
			isTemplate
		}
	}
`

interface AdminProps {
	onClose: () => void
	setEnableClickOutside: (enable: boolean) => void
}

const Admin: FC<AdminProps> = ({ onClose, setEnableClickOutside }) => {
	const { game, dispatch } = useContext(GameContext)
	const [setGameTemplate] = useMutation(SET_GAME_TEMPLATE)

	const handleTemplateToggle = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const newValue = e.target.checked
		const action = newValue
			? 'mark this game as a template'
			: 'remove this game from templates'

		if (window.confirm(`Are you sure you want to ${action}?`)) {
			try {
				const result = await setGameTemplate({
					variables: {
						gameId: game._id,
						isTemplate: newValue,
					},
				})

				if (result.data?.setGameTemplate) {
					dispatch({
						type: 'UPDATE_STATE',
						payload: {
							game: {
								...game,
								isTemplate: newValue,
							},
						},
					})
				}
			} catch (err) {
				console.error('Error toggling template status:', err)
				alert('Failed to update template status')
				// Reset checkbox to original value
				e.target.checked = !newValue
			}
		} else {
			// User cancelled - reset checkbox
			e.target.checked = !newValue
		}
	}

	return (
		<div>
			<SectionDivider label='Game Settings' />
			<div className='mt-4 flex items-center gap-2'>
				<input
					type='checkbox'
					id='isTemplate'
					checked={game.isTemplate}
					onChange={handleTemplateToggle}
					className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
				/>
				<Label htmlFor='isTemplate' className='mt-0'>
					Template Game
				</Label>
			</div>

			<SectionDivider className='mt-4' label='System Loader (Advanced)' />
			<SystemLoader onClose={onClose} />
			<SystemInfo />

			<SectionDivider className='mt-4' label='Development Mode (Advanced)' />
			<DevelopmentMode setEnableClickOutside={setEnableClickOutside} />
			{process.env.NODE_ENV === 'development' && (
				<>
					<SectionDivider className='mt-4' label='Debug Tools' />
					<div className='mt-4'>
						<DebugButton />
					</div>
				</>
			)}

			<SectionDivider className='mt-4 mb-4' label='Danger Zone' />
			<SystemReset />
		</div>
	)
}

export default Admin
