import { useContext } from 'react'
import { TSetDocumentAccessPayload } from '../../../shared/types/game'
import GameContext from '../contexts/game'
import useUser from './useUser'

const useHandleShareDocumentWith = () => {
	const { game, dispatch } = useContext(GameContext)
	const { isGM, userId } = useUser()

	const share = (payload: TSetDocumentAccessPayload) => {
		dispatch({
			type: 'SET_DOCUMENT_ACCESS',
			payload,
		})
	}

	const handleSharePublic = (documentId: string) => {
		const payload: TSetDocumentAccessPayload = {
			documentId: documentId,
			access: 'public',
			accessList: [],
		}

		share(payload)
	}

	const handleSharePrivate = (documentId: string) => {
		const payload: TSetDocumentAccessPayload = {
			documentId: documentId,
			access: 'private',
			accessList: isGM ? [] : [userId],
		}

		share(payload)
	}

	const handleShareWith = (
		documentId: string,
		userId: string,
		toggleState = false,
	) => {
		const document = game.documents.byId[documentId]
		let accessList = document.accessList || []

		const alreadyShared = accessList.includes(userId)

		if (alreadyShared && toggleState) {
			accessList = accessList.filter(id => id !== userId)
		} else if (!alreadyShared) {
			accessList = [...accessList, userId]
		}

		const payload: TSetDocumentAccessPayload = {
			documentId: document._id,
			access: 'private',
			accessList: accessList,
		}

		share(payload)
	}

	return { handleSharePublic, handleSharePrivate, handleShareWith }
}

export default useHandleShareDocumentWith
