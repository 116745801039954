import { useContext } from 'react'
import usePdfThumbnail from '../components/resources/hooks/usePdfThumbnail'
import AuthContext from '../contexts/auth'
import { useToast } from '../contexts/toast'
import useApi from './useApi'
import useFileUpload, { FileUploadResult } from './useFileUpload'

export type ResourceType =
	| 'book'
	| 'sheet'
	| 'image'
	| 'video'
	| 'other'
	| 'token'

const MAX_FILE_SIZES = {
	book: 200 * 1024 * 1024, // 200MB for books
	sheet: 200 * 1024 * 1024, // 200MB for sheets
	image: 20 * 1024 * 1024, // 20MB for images
	video: 500 * 1024 * 1024, // 500MB for videos
	other: 100 * 1024 * 1024, // 100MB for other files
	token: 20 * 1024 * 1024, // 20MB for tokens
}

const useResourceUpload = () => {
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const { request } = useApi()
	const uploadFile = useFileUpload()
	const { createThumbnail } = usePdfThumbnail()
	const { addToast } = useToast()

	const validateFile = (file: File, resourceType: ResourceType) => {
		const maxSize = MAX_FILE_SIZES[resourceType]
		if (file.size > maxSize) {
			const sizeInMB = maxSize / (1024 * 1024)
			throw new Error(
				`File too large. Maximum size for ${resourceType} is ${sizeInMB}MB`,
			)
		}
	}

	const handleFileUpload = async (
		file: File,
		givenResourceType: ResourceType = 'book',
		onProgress?: (percent: number) => void,
		skipDuplicateCheck?: boolean,
	): Promise<FileUploadResult> => {
		try {
			console.log('ResourceUpload: Starting upload process for:', {
				name: file.name,
				size: file.size,
				type: givenResourceType,
			})

			validateFile(file, givenResourceType)

			if (!userId) {
				throw new Error('User not authenticated')
			}

			// Check for duplicates first
			if (!skipDuplicateCheck) {
				const duplicateCheck = await request('/api/resource/check-duplicate', {
					method: 'POST',
					body: JSON.stringify({
						name: file.name,
						size: file.size,
						userId,
					}),
				})

				if (duplicateCheck?.isDuplicate) {
					return new Promise((resolve, reject) => {
						addToast({
							id: `duplicate-${file.name}`,
							title: 'Duplicate File',
							message: `You already have a file named "${file.name}" with the same size.`,
							type: 'warning',
							requireDismiss: true,
							actions: [
								{
									label: 'Upload Anyway',
									onClick: async () => {
										try {
											// Continue with upload
											const uploadResult = await uploadFile(file, onProgress)

											// For PDFs, create and upload a thumbnail
											let thumbnailUpload = uploadResult
											if (
												givenResourceType === 'book' ||
												givenResourceType === 'sheet'
											) {
												const thumbnailFile = await createThumbnail(file)
												if (thumbnailFile) {
													const thumbnailFileName = `thumb_${
														uploadResult._id
													}_${file.name.replace(/\.[^/.]+$/, '')}.jpg`
													const renamedThumbnail = new File(
														[thumbnailFile],
														thumbnailFileName,
														{
															type: thumbnailFile.type,
														},
													)
													thumbnailUpload = await uploadFile(renamedThumbnail)
												}
											}

											const result = {
												...uploadResult,
												thumbnailurl: thumbnailUpload.fileurl,
											}
											resolve(result)
										} catch (error) {
											reject(error)
										}
									},
								},
								{
									label: 'Cancel',
									onClick: () => {
										const error = new Error('Upload cancelled - duplicate file')
										error.name = 'UserCancelled'
										reject(error)
									},
								},
							],
						})
					})
				}
			}

			// Upload the main file
			const uploadResult = await uploadFile(file, onProgress)

			// For PDFs, create and upload a thumbnail
			let thumbnailUpload = uploadResult
			if (givenResourceType === 'book' || givenResourceType === 'sheet') {
				const thumbnailFile = await createThumbnail(file)
				if (thumbnailFile) {
					// Ensure unique name for thumbnail
					const thumbnailFileName = `thumb_${
						uploadResult._id
					}_${file.name.replace(/\.[^/.]+$/, '')}.jpg`
					const renamedThumbnail = new File(
						[thumbnailFile],
						thumbnailFileName,
						{
							type: thumbnailFile.type,
						},
					)
					thumbnailUpload = await uploadFile(renamedThumbnail)
				}
			}

			const result = {
				...uploadResult,
				thumbnailurl: thumbnailUpload.fileurl,
			}

			return result
		} catch (error) {
			console.error('ResourceUpload: Error in upload process:', error)
			// Don't rethrow if it was a user cancellation
			if (error instanceof Error && error.name !== 'UserCancelled') {
				throw error
			}
			throw error
		}
	}

	return handleFileUpload
}

export default useResourceUpload
