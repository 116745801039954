import { X } from 'lucide-react'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { animated, useTransition } from 'react-spring'
import { twMerge } from 'tailwind-merge'
import { useQueue } from '../../contexts/queue'
import Card from '../pages/elements/Card'

interface Toast {
	id: string
	message: string
	type: 'error' | 'warning' | 'success'
}

interface ResourceDropzoneProps {
	children: React.ReactNode
	cardClassName?: string
	cardInnerClassName?: string
	style?: any
}

const ResourceDropzone = ({
	children,
	cardClassName,
	cardInnerClassName,
	style,
}: ResourceDropzoneProps) => {
	const [fileDragOver, setFileDragOver] = useState<boolean>(false)
	const [toasts, setToasts] = useState<Toast[]>([])
	const { addToQueue } = useQueue()

	const transitions = useTransition(toasts, {
		from: { opacity: 0, y: 20 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, x: 100 },
		config: { tension: 300, friction: 20 },
		keys: toast => toast.id,
	})

	const addToast = (message: string, type: Toast['type'] = 'error') => {
		const id = Math.random().toString(36).substring(7)
		setToasts(prev => [...prev, { id, message, type }])
		// Auto-remove after 5 seconds
		setTimeout(() => {
			setToasts(prev => prev.filter(t => t.id !== id))
		}, 5000)
	}

	const removeToast = (id: string) => {
		setToasts(prev => prev.filter(t => t.id !== id))
	}

	const dropHandler = (files: File[]) => {
		setFileDragOver(false)
		try {
			addToQueue(files)
		} catch (error) {
			if (error instanceof Error) {
				addToast(error.message)
			} else {
				addToast('Error processing file(s)')
			}
		}
	}

	return (
		<div className='relative'>
			<Dropzone
				onDragEnter={e => {
					if (e.target === e.currentTarget) {
						setFileDragOver(true)
					}
				}}
				onDragLeave={e => {
					if (e.target === e.currentTarget) {
						setFileDragOver(false)
					}
				}}
				onDrop={dropHandler}
				accept='.pdf'
				noClick
			>
				{({ getRootProps, isDragActive }) => {
					const isOver = isDragActive || fileDragOver
					return (
						<Card
							{...getRootProps()}
							style={{
								...style,
								marginLeft: style?.marginLeft,
								marginRight: style?.marginRight,
								marginTop: style?.marginTop ?? 0,
								marginBottom: style?.marginBottom ?? 0,
							}}
							className={twMerge(
								'mt-0 cursor-default p-0 transition-all duration-500',
								isOver && 'dark:bg-blue-950 bg-blue-100',
								cardClassName,
								'm-0 overflow-visible',
							)}
							innerClassName={twMerge('max-w-none', cardInnerClassName)}
						>
							{children}
						</Card>
					)
				}}
			</Dropzone>

			{/* Toast Container */}
			<div className='fixed bottom-4 right-4 z-50 flex flex-col gap-2'>
				{transitions((style, toast) => (
					<animated.div
						style={style}
						className={twMerge(
							'flex items-center gap-2 rounded-lg px-4 py-3 text-sm text-white shadow-lg',
							toast.type === 'error' && 'bg-red-500',
							toast.type === 'warning' && 'bg-yellow-500',
							toast.type === 'success' && 'bg-green-500',
						)}
					>
						<span>{toast.message}</span>
						<button
							onClick={() => removeToast(toast.id)}
							className='ml-2 rounded-full p-1 hover:bg-black/10'
						>
							<X size={14} />
						</button>
					</animated.div>
				))}
			</div>
		</div>
	)
}

export default ResourceDropzone
