import { CloudFog } from 'lucide-react'
import { useContext } from 'react'
import { IFogPolygon } from '../../../../shared/types/fog'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import ToolButton from '../interface/toolbar/ToolButton'

const FogAllButton = () => {
	const { dispatch } = useContext(GameContext)
	const scene = useGetActiveScene()

	const handleClick = () => {
		if (!scene) return

		const fullFog: IFogPolygon = {
			_id: 'full-fog',
			points: [
				{ x: -10000, y: -10000 },
				{ x: 10000, y: -10000 },
				{ x: 10000, y: 10000 },
				{ x: -10000, y: 10000 },
			],
			type: 'hide',
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...scene,
					version: scene.version + 1,
					values: {
						...scene.values,
						fog: [fullFog],
					},
				},
			},
		})
	}

	return (
		<ToolButton tooltip='Fog All' onClick={handleClick}>
			<CloudFog aria-hidden='true' />
		</ToolButton>
	)
}

export default FogAllButton
