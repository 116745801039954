import { Cloud, CloudFog } from 'lucide-react'
import { forwardRef, useContext } from 'react'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

const FogModeButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { toolsState, dispatchTools } = useContext(ToolsContext)
		const { mode } = toolsState
		const { isGM } = useUser()
		const scene = useGetActiveScene()
		const hasFog = scene?.values?.fog?.some(polygon => !polygon.deleted)

		const handleFogModeClick = () => {
			if (mode !== 'fog') {
				dispatchTools({ type: 'SET_MODE', payload: 'fog' })
			} else {
				dispatchTools({ type: 'SET_MODE', payload: 'select' })
			}
		}

		if (!isGM) return null

		return (
			<ToolButton
				tooltip='Edit Fog'
				isOpen={mode === 'fog'}
				onClick={handleFogModeClick}
				ref={ref}
			>
				{hasFog ? <CloudFog aria-label='Fog' /> : <Cloud aria-label='Fog' />}
			</ToolButton>
		)
	},
)

FogModeButton.displayName = 'FogModeButton'

export default FogModeButton
