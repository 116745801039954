import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import {
	FormButton,
	FormError,
	FormInput,
	FormLabel,
} from '../FormComponents/auth'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	email: string
}

const schema = yup
	.object({
		email: yup
			.string()
			.email('Please enter a valid email address')
			.required('Email is required'),
	})
	.required()

export default function ForgotPassword() {
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const onSubmit = async (data: IFormInputs) => {
		setError('')
		setMessage('')

		try {
			const response = await fetch('/api/password-reset-request', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: data.email }),
			})

			const responseData = await response.json()

			if (response.ok) {
				setMessage(
					'Check your email for password reset instructions. Return to Sign In?',
				)
			} else {
				setError(responseData.message || 'Something went wrong')
			}
		} catch (err) {
			setError('Failed to send reset email')
		}
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Reset Password' className='text-center' />

			<form
				onSubmit={handleSubmit(onSubmit)}
				className='mx-auto max-w-sm space-y-6'
			>
				<div>
					<FormLabel htmlFor='email'>Email</FormLabel>
					<FormInput
						type='email'
						id='email'
						placeholder='Email address...'
						{...register('email', { required: true })}
					/>
					<FormError message={errors.email?.message} />
				</div>

				{message && (
					<div className='space-y-2'>
						<p className='text-sm text-gray-300'>{message}</p>
						<Link
							to='/signin'
							className='text-sm font-medium text-gray-400 hover:text-gray-300'
						>
							Back to Sign In
						</Link>
					</div>
				)}
				{error && <FormError message={error} />}

				<FormButton type='submit'>Send Reset Instructions</FormButton>
			</form>
		</Card>
	)
}
