import { Stage as PixiStage } from '@pixi/react'
import { useEffect, useRef, useState } from 'react'
import { DndContext } from 'react-dnd'
import AuthContext from '../../contexts/auth'
import DocumentsContext from '../../contexts/documents'
import { DragStateContext } from '../../contexts/dragdrop'
import EventContext from '../../contexts/event'
import GameContext from '../../contexts/game'
import { GridContext } from '../../contexts/grid'
import { GuideContext } from '../../contexts/guideContext'
import { SocketContext } from '../../contexts/socket'
import ToolsContext from '../../contexts/tools'
import UsersContext from '../../contexts/users'
import WindowsContext from '../../contexts/windows'
import ContextBridge from '../ContextBridge'
import RaindropsManager from './effects/RaindropsManager'

export const Stage = ({ children }) => {
	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})
	const stageRef = useRef(null)

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return (
		<ContextBridge
			Context={AuthContext}
			render={authChildren => (
				<ContextBridge
					Context={EventContext}
					render={eventChildren => (
						<ContextBridge
							Context={GameContext}
							render={gameChildren => (
								<ContextBridge
									Context={SocketContext}
									render={socketChildren => (
										<ContextBridge
											Context={DragStateContext}
											render={dragAndDropChildren => (
												<ContextBridge
													Context={ToolsContext}
													render={toolsChildren => (
														<ContextBridge
															Context={UsersContext}
															render={usersChildren => (
																<ContextBridge
																	Context={DndContext}
																	render={dndChildren => (
																		<ContextBridge
																			Context={WindowsContext}
																			render={windowsChildren => (
																				<ContextBridge
																					Context={DocumentsContext}
																					render={documentsChildren => (
																						<ContextBridge
																							Context={GuideContext}
																							render={guideChildren => (
																								<ContextBridge
																									Context={GridContext}
																									render={gridChildren => (
																										<PixiStage
																											ref={stageRef}
																											id='pixi-canvas'
																											width={
																												windowDimensions.width
																											}
																											height={
																												windowDimensions.height
																											}
																											options={{
																												backgroundColor: 0x111111,
																												resolution:
																													window.devicePixelRatio ||
																													1,
																												eventMode: 'static',
																												antialias: true,
																												forceCanvas: false,
																												powerPreference:
																													'high-performance',
																											}}
																										>
																											{gridChildren}
																											<RaindropsManager
																												enabled={false}
																											/>
																										</PixiStage>
																									)}
																								>
																									{guideChildren}
																								</ContextBridge>
																							)}
																						>
																							{documentsChildren}
																						</ContextBridge>
																					)}
																				>
																					{windowsChildren}
																				</ContextBridge>
																			)}
																		>
																			{dndChildren}
																		</ContextBridge>
																	)}
																>
																	{usersChildren}
																</ContextBridge>
															)}
														>
															{toolsChildren}
														</ContextBridge>
													)}
												>
													{dragAndDropChildren}
												</ContextBridge>
											)}
										>
											{socketChildren}
										</ContextBridge>
									)}
								>
									{gameChildren}
								</ContextBridge>
							)}
						>
							{eventChildren}
						</ContextBridge>
					)}
				>
					{authChildren}
				</ContextBridge>
			)}
		>
			{children}
		</ContextBridge>
	)
}

export default Stage
