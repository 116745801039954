import { Redo2 } from 'lucide-react'
import { forwardRef, useContext } from 'react'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

const RedoButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { dispatch } = useContext(GameContext)
		const scene = useGetActiveScene()
		const { userId } = useUser()
		const { toolsState } = useContext(ToolsContext)

		// Handle fog mode
		if (toolsState.mode === 'fog') {
			const currentFog = scene?.values?.fog || []
			// Find the first deleted fog operation by current user
			const nextRedoIndex = currentFog.findIndex(
				fog => fog.userId === userId && fog.deleted,
			)
			const canRedo = nextRedoIndex !== -1

			const handleRedo = () => {
				if (!scene || !canRedo) return

				const updatedFog = [...currentFog]
				updatedFog[nextRedoIndex] = {
					...updatedFog[nextRedoIndex],
					deleted: false,
				}

				dispatch({
					type: 'UPDATE_DOCUMENT',
					payload: {
						updatedDocument: {
							...scene,
							version: scene.version + 1,
							values: {
								...scene.values,
								fog: updatedFog,
							},
						},
					},
				})
			}

			return (
				<ToolButton
					tooltip='Redo Fog Operation'
					onClick={handleRedo}
					disabled={!canRedo}
					ref={ref}
				>
					<Redo2 aria-hidden='true' />
				</ToolButton>
			)
		}

		// Handle drawing mode
		const currentMarks = scene?.values?.marks?.marks || []
		// Find the first deleted mark by current user
		const nextRedoIndex = currentMarks.findIndex(
			mark => mark.userId === userId && mark.deleted,
		)
		const canRedo = nextRedoIndex !== -1

		const handleRedo = () => {
			if (!scene || !canRedo) return

			const updatedMarks = [...currentMarks]
			updatedMarks[nextRedoIndex] = {
				...updatedMarks[nextRedoIndex],
				deleted: false,
			}

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...scene,
						version: scene.version + 1,
						values: {
							...scene.values,
							marks: {
								marks: updatedMarks,
							},
						},
					},
				},
			})
		}

		return (
			<ToolButton
				tooltip='Redo Drawing'
				onClick={handleRedo}
				disabled={!canRedo}
				ref={ref}
			>
				<Redo2 className='h-5 w-5' aria-hidden='true' />
			</ToolButton>
		)
	},
)

RedoButton.displayName = 'RedoButton'

export default RedoButton
