import * as React from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import Asset from '../Asset'

export interface IGameLogoProps {
	gameId: string
	assetId: string
	title?: string
	style?: React.CSSProperties
	className?: string
}

export default function GameLogo({
	gameId,
	assetId,
	title,
	style,
	className,
}: IGameLogoProps) {
	const { game } = React.useContext(GameContext)
	const logoAssetId = game?.system?.logoPath
	if (!assetId) assetId = logoAssetId

	if (!gameId || !assetId) return null

	return (
		<Asset
			gameId={gameId}
			assetId={assetId}
			alt={`Logo for the game ${title}`}
			className={twMerge('h-auto max-h-[60%] w-auto object-contain', className)}
			style={{
				maxWidth: '70%',
				margin: 'auto',
				...style,
			}}
		/>
	)
}
