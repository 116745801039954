import { CircleUserRound } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import ToolsContext from '../../contexts/tools'
import useUser from '../../hooks/useUser'
import useUserLevel from '../../hooks/useUserLevel'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'
import ToolButton from '../interface/toolbar/ToolButton'
import Popover from '../Popover'
import TokenLibrary from '../TokenLibrary/TokenLibrary'
import Translucency from '../Translucency'

const TokenLibraryButton = () => {
	const [isOpen, setIsOpen] = useState(false)
	const { isGM } = useUser()
	const buttonRef = useRef<HTMLDivElement>(null)
	const { dispatchTools } = useContext(ToolsContext)
	const { isAtLeastAdept } = useUserLevel()

	if (!isAtLeastAdept()) return null
	if (!isGM) return null

	const handleToggle = () => {
		const newIsOpen = !isOpen
		setIsOpen(newIsOpen)
		dispatchTools({ type: 'SET_MODE', payload: 'select' })
	}

	return (
		<>
			<ToolButton
				ref={buttonRef}
				isOpen={isOpen}
				tooltip={'Tokens'}
				onClick={handleToggle}
				className='text-purple-600'
			>
				<CircleUserRound aria-label='Tokens' />
			</ToolButton>

			<Popover
				id='token-library-popover'
				open={isOpen}
				anchor={buttonRef}
				onClose={() => {
					setIsOpen(false)
					dispatchTools({ type: 'SET_MODE', payload: 'select' })
				}}
				className={`border-none p-0 ${Translucency}`}
				style={{
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
				}}
				placement='top'
			>
				<TokenLibrary />
			</Popover>
		</>
	)
}

export default TokenLibraryButton
