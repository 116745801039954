import { Container, Sprite } from '@pixi/react'
import { Assets, Texture } from 'pixi.js'
import { useContext, useEffect, useRef, useState } from 'react'
import AuthContext from '../../contexts/auth'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'

interface CoverState {
	texture: Texture
	alpha: number
	width: number
	height: number
	x: number
	y: number
}

interface PixiCoverProps {
	onShowMenu?: (position: { x: number; y: number }) => void
}

const PixiCover: React.FC<PixiCoverProps> = ({ onShowMenu }) => {
	const { game } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const { isGM } = useUser()
	const userSettings = game.userSettings[userId]
	const scene = useGetActiveScene()
	const [currentCover, setCurrentCover] = useState<CoverState>()
	const [nextCover, setNextCover] = useState<CoverState>()
	const animationFrame = useRef<number>()

	// Calculate dimensions and position that maintain aspect ratio while covering the screen
	const calculateCoverState = (texture: Texture): CoverState => {
		const screenRatio = window.innerWidth / window.innerHeight
		const imageRatio = texture.width / texture.height
		let width: number, height: number

		if (screenRatio > imageRatio) {
			width = window.innerWidth
			height = window.innerWidth / imageRatio
		} else {
			height = window.innerHeight
			width = window.innerHeight * imageRatio
		}

		const x = (window.innerWidth - width) / 2
		const y = (window.innerHeight - height) / 2

		return { texture, alpha: 0, width, height, x, y }
	}

	// Handle cover texture changes with crossfade
	useEffect(() => {
		// Cleanup function to cancel any ongoing animation
		const cleanup = () => {
			if (animationFrame.current) {
				cancelAnimationFrame(animationFrame.current)
			}
		}

		if (!scene?.values.coverId) {
			if (currentCover) {
				// Fade out the current cover when coverId becomes null
				const startTime = Date.now()
				const duration = 1500 // 1.5 seconds

				const animate = () => {
					const elapsed = Date.now() - startTime
					const progress = Math.min(elapsed / duration, 1)

					if (progress < 1) {
						setCurrentCover(prev =>
							prev ? { ...prev, alpha: 1 - progress } : prev,
						)
						animationFrame.current = requestAnimationFrame(animate)
					} else {
						// Animation complete - clear the cover
						setCurrentCover(undefined)
					}
				}

				cleanup() // Cancel any existing animation
				animationFrame.current = requestAnimationFrame(animate)
			} else {
				setCurrentCover(undefined)
				setNextCover(undefined)
			}
			return cleanup
		}

		const coverAsset = game.assets.byId[scene.values.coverId]
		if (!coverAsset?.fileurl) {
			setCurrentCover(undefined)
			setNextCover(undefined)
			return cleanup
		}

		Assets.load(coverAsset.fileurl)
			.then(texture => {
				const newCoverState = calculateCoverState(texture)

				// If this is the first load, set current cover immediately
				if (!currentCover) {
					setCurrentCover({ ...newCoverState, alpha: 1 })
					return
				}

				// Otherwise, start a crossfade
				setNextCover({ ...newCoverState, alpha: 0 })

				// Start the fade animation
				const startTime = Date.now()
				const duration = 1500 // 1.5 seconds

				const animate = () => {
					const elapsed = Date.now() - startTime
					const progress = Math.min(elapsed / duration, 1)

					if (progress < 1) {
						setCurrentCover(prev =>
							prev ? { ...prev, alpha: 1 - progress } : prev,
						)
						setNextCover(prev => (prev ? { ...prev, alpha: progress } : prev))
						animationFrame.current = requestAnimationFrame(animate)
					} else {
						// Animation complete - set new texture as current
						setCurrentCover({ ...newCoverState, alpha: 1 })
						setNextCover(undefined)
					}
				}

				cleanup() // Cancel any existing animation
				animationFrame.current = requestAnimationFrame(animate)
			})
			.catch(error => {
				console.error('Error loading cover texture:', error)
				setCurrentCover(undefined)
				setNextCover(undefined)
			})

		return cleanup
	}, [scene?.values.coverId, game.assets.byId])

	// Add resize handler
	useEffect(() => {
		const handleResize = () => {
			if (currentCover) {
				const newState = calculateCoverState(currentCover.texture)
				setCurrentCover({ ...newState, alpha: currentCover.alpha })
			}
			if (nextCover) {
				const newState = calculateCoverState(nextCover.texture)
				setNextCover({ ...newState, alpha: nextCover.alpha })
			}
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [currentCover, nextCover])

	if (!userSettings || !currentCover) return null

	const hasMap = scene?.values.mapId
	const showMap = scene?.values.showMap
	const shouldShow = !hasMap || showMap === 'false'

	if (!shouldShow) return null

	const handleRightClick = (e: any) => {
		if (!isGM) return
		e.stopPropagation()
		onShowMenu?.({
			x: e.globalX,
			y: e.globalY,
		})
	}

	return (
		<Container eventMode='static' rightclick={handleRightClick}>
			{/* Current cover */}
			<Sprite
				texture={currentCover.texture}
				width={currentCover.width}
				height={currentCover.height}
				alpha={currentCover.alpha}
				position={[currentCover.x, currentCover.y]}
			/>

			{/* Next cover (during crossfade) */}
			{nextCover && (
				<Sprite
					texture={nextCover.texture}
					width={nextCover.width}
					height={nextCover.height}
					alpha={nextCover.alpha}
					position={[nextCover.x, nextCover.y]}
				/>
			)}
		</Container>
	)
}

export default PixiCover
