import { yupResolver } from '@hookform/resolvers/yup'
import { useContext } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import request from 'superagent'
import * as yup from 'yup'
import AuthContext from '../../contexts/auth'
import {
	FormButton,
	FormError,
	FormInput,
	FormLabel,
} from '../FormComponents/auth'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	name: string
	email: string
	password: string
}

const schema = yup
	.object({
		name: yup.string().required('Name is required'),
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required()

const AuthPage = () => {
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const signUpHandler = (data: FieldValues) => {
		const { name, email, password } = data

		request
			.post('/api/createuser')
			.send({
				name: name,
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					if (res.status === 400) {
						alert('Something went wrong.')
					}
					if (res.status === 409) {
						alert('Email address is already in use.')
					}
				}

				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)
				navigate('/profile')
			})
			.catch(error => {
				alert(error)
				console.log('create user error', error)
			})
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Sign Up' className='text-center' />
			<div className='mb-12 text-center'>
				<NavLink
					to='/signin'
					className='text-gray-400 underline hover:text-gray-300'
				>
					Sign In Instead?
				</NavLink>
			</div>

			<form
				onSubmit={handleSubmit(signUpHandler)}
				className='mx-auto max-w-sm space-y-6'
			>
				<div>
					<FormLabel htmlFor='name'>Name</FormLabel>
					<FormInput
						type='text'
						id='name'
						placeholder='Name...'
						{...register('name', { required: true })}
					/>
					<FormError message={errors.name?.message} />
				</div>

				<div>
					<FormLabel htmlFor='email'>Email</FormLabel>
					<FormInput
						type='email'
						id='email'
						placeholder='Email...'
						{...register('email', { required: true })}
					/>
					<FormError message={errors.email?.message} />
				</div>

				<div>
					<FormLabel htmlFor='password'>Password</FormLabel>
					<FormInput
						type='password'
						id='password'
						placeholder='Password...'
						{...register('password', { required: true })}
					/>
					<FormError message={errors.password?.message} />
				</div>

				<FormButton type='submit'>Sign Up</FormButton>
			</form>
		</Card>
	)
}

export default AuthPage
