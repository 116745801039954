import { ArrowUp } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import UsersContext from '../../contexts/users'
import useGetAvatarSrc from '../../hooks/useGetAvatarSrc'
import useUser from '../../hooks/useUser'
import Avatar from '../Avatar'
import TextArea from '../TextArea'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'

interface InputProps {
	message: string
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onSend: () => void
}

// Function that calculates line info based on actual wrapping
function getLineInfo(textarea: HTMLTextAreaElement) {
	const { selectionStart } = textarea
	const text = textarea.value || '\u200b'

	// Create a hidden div to measure lines
	const div = document.createElement('div')
	const style = window.getComputedStyle(textarea)
	Array.from(style).forEach(prop => {
		div.style.setProperty(
			prop,
			style.getPropertyValue(prop),
			style.getPropertyPriority(prop),
		)
	})

	div.style.position = 'absolute'
	div.style.visibility = 'hidden'
	div.style.whiteSpace = 'pre-wrap'
	div.style.wordWrap = 'break-word'
	div.style.width = style.width
	div.style.overflow = 'auto'
	div.style.height = 'auto'
	div.style.minHeight = '0'
	div.style.maxHeight = 'none'
	div.style.zIndex = '-9999'

	// Measure total lines
	div.textContent = text
	document.body.appendChild(div)
	const totalHeight = div.scrollHeight

	// Measure single line height
	div.textContent = 'X'
	const singleLineHeight = div.scrollHeight

	// Total lines
	const totalLines = Math.max(1, Math.round(totalHeight / singleLineHeight))

	// Now measure how many lines are before the caret
	div.textContent = text.substring(0, selectionStart) || '\u200b'
	const upToCaretHeight = div.scrollHeight
	document.body.removeChild(div)

	const caretLineNumber = Math.max(
		0,
		Math.round(upToCaretHeight / singleLineHeight) - 1,
	)

	return {
		isAtFirstLine: caretLineNumber === 0,
		isAtLastLine: caretLineNumber === totalLines - 1,
	}
}

const Input = ({ message, onChange, onSend }: InputProps) => {
	const { color, userId } = useUser()
	const { usersState } = useContext(UsersContext)
	const user = usersState.users.find(u => u.userId === userId)
	const name = user?.username

	const [history, setHistory] = useState<string[]>([])
	const [historyIndex, setHistoryIndex] = useState<number>(-1)
	const [currentDraft, setCurrentDraft] = useState<string | null>(null)
	const avatarSrc = useGetAvatarSrc({ userId })
	const inputRef = useRef<HTMLTextAreaElement>(null)
	const toolbarRef = useRef<HTMLDivElement>(null)

	const handleSend = () => {
		if (message.trim()) {
			setHistory(prev => [message, ...prev].slice(0, 50))
			setHistoryIndex(-1)
			setCurrentDraft(null)
			onSend()
		}
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		const textarea = e.currentTarget
		if (!textarea) return

		let changedFromHistory = false
		let moveDirection: 'up' | 'down' | null = null

		if (e.key === 'Enter' && !e.altKey && !e.shiftKey && !e.ctrlKey) {
			e.preventDefault()
			handleSend()
			return
		}

		const { isAtFirstLine, isAtLastLine } = getLineInfo(textarea)

		if (e.key === 'ArrowUp' && isAtFirstLine) {
			if (historyIndex + 1 < history.length) {
				e.preventDefault()
				// Save current message as draft if we're just starting to navigate history
				if (historyIndex === -1 && message.trim()) {
					setCurrentDraft(message)
				}
				const newIndex = historyIndex + 1
				setHistoryIndex(newIndex)
				const historicalMessage = history[newIndex]
				const event = {
					target: { value: historicalMessage },
				} as React.ChangeEvent<HTMLTextAreaElement>
				onChange(event)
				changedFromHistory = true
				moveDirection = 'up'
			}
		} else if (e.key === 'ArrowDown' && isAtLastLine) {
			if (historyIndex > -1) {
				e.preventDefault()
				const newIndex = historyIndex - 1
				setHistoryIndex(newIndex)
				// If we're going back to no history selection, restore draft if it exists
				const historicalMessage =
					newIndex === -1 ? currentDraft || '' : history[newIndex]
				const event = {
					target: { value: historicalMessage },
				} as React.ChangeEvent<HTMLTextAreaElement>
				onChange(event)
				changedFromHistory = true
				moveDirection = 'down'
			}
		}

		// After changing the message from history, adjust the caret position.
		if (changedFromHistory && inputRef.current) {
			requestAnimationFrame(() => {
				if (!inputRef.current) return
				const inputEl = inputRef.current
				if (moveDirection === 'up') {
					// When going up in history, put caret at the end
					const length = inputEl.value.length
					inputEl.selectionStart = length
					inputEl.selectionEnd = length
				} else if (moveDirection === 'down') {
					// When going down in history, put caret at the start
					inputEl.selectionStart = 0
					inputEl.selectionEnd = 0
				}
			})
		}
	}

	return (
		<div
			className='w-full flex-1 rounded-full'
			style={{ backgroundColor: INTERFACE_BACKGROUND_COLOR }}
		>
			<div
				ref={toolbarRef}
				className='flex w-full flex-row items-center rounded-full bg-black/10'
			>
				<Avatar color={color} src={avatarSrc} size='lg' userName={name} />
				<TextArea
					ref={inputRef}
					value={message}
					onChange={onChange}
					onKeyDown={handleKeyDown}
					placeholder='Message...'
					className='min-h-10 m-0 w-full resize-none rounded-xl border-0 bg-transparent p-2 py-2.5 text-sm text-black outline-none placeholder:text-gray-500'
					style={{ resize: 'none' }}
				/>
				{message && (
					<button
						type='submit'
						className={twMerge('h-10 rounded-r-full pr-4 text-xl text-black')}
						onClick={handleSend}
					>
						<ArrowUp />
					</button>
				)}
			</div>
		</div>
	)
}

export default Input
