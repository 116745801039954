import { Container, Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import { useGrid } from '../../../contexts/grid'

interface Props {
	index: number
	point: { x: number; y: number }
	isHovered: boolean
	isDragged: boolean
	onPointSelected: (index: number, e: PIXI.FederatedPointerEvent) => void
	onHoverChange: (hovered: boolean) => void
}

export default function MeasurementPoint({
	index,
	point,
	isHovered,
	isDragged,
	onPointSelected,
	onHoverChange,
}: Props) {
	const { grid } = useGrid()

	console.log('MeasurementPoint: render', {
		index,
		point,
		isHovered,
		isDragged,
	})

	const drawPoint = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()

			// Draw hit area (invisible but interactive)
			g.beginFill(0xffffff, 0.001)
			g.drawCircle(point.x, point.y, grid.size / 2)
			g.endFill()

			// Draw visible point if hovered or dragged
			if (isHovered || isDragged) {
				g.lineStyle(0.1, 0xffffff, 1)
				g.beginFill(0xffffff, isDragged ? 1 : 0.5)
				g.drawCircle(point.x, point.y, grid.size / 6)
				g.endFill()
			}
		},
		[point, grid.size, isHovered, isDragged],
	)

	return (
		<Container sortableChildren>
			<Graphics
				draw={drawPoint}
				eventMode='static'
				cursor='move'
				onpointerdown={e => onPointSelected(index, e)}
				onpointerenter={() => onHoverChange(true)}
				onpointerleave={() => onHoverChange(false)}
				zIndex={1001}
			/>
		</Container>
	)
}
