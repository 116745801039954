import { Dialog, Transition } from '@headlessui/react'
import { Fragment, ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import CloseButton from '../FormComponents/CloseButton'
import {
	INTERFACE_BACKGROUND_COLOR,
	INTERFACE_DROPSHADOW,
} from '../interface/constants'
import DialogBody from './Body'
import DialogFooter from './Footer'
import DialogHeader from './Header'

type ModalSize = 'small' | 'medium' | 'large' | 'full'

interface Dimensions {
	width?: string | number
	maxWidth?: string | number
	height?: string | number
	maxHeight?: string | number
	minHeight?: string | number
}

export interface IModal {
	id: string
	title: string
	children: React.ReactNode
	className?: string
	bodyClassName?: string
	innerBodyClassName?: string
	headerChildren?: React.ReactNode
	footerChildren?: React.ReactNode
	size?: ModalSize
	headerIcon?: ReactNode
	enableClickOutside?: boolean
	dynamicHeight?: boolean
	onClose?: () => void
	isOpen: boolean
	onTransitionEnd?: () => void
	hideHeader?: boolean
	dimensions?: Dimensions
	layer?: number
}

const ModalWindow = ({
	id,
	title,
	children,
	className,
	bodyClassName = '',
	innerBodyClassName = '',
	headerChildren,
	footerChildren,
	size = 'small',
	headerIcon = '',
	enableClickOutside = true,
	dynamicHeight = false,
	onClose = () => {},
	isOpen,
	onTransitionEnd,
	hideHeader = false,
	dimensions,
	layer = 0,
}: IModal) => {
	const minW = 320
	const sizeMap: Record<ModalSize, Dimensions> = {
		small: { width: minW },
		medium: { width: minW * 1.5 },
		large: { width: minW * 2.5 },
		full: { width: 'calc(100vw - 50px)' },
	}

	const baseZIndex = 1000 + layer * 10

	const modalRef = useRef<HTMLDivElement>(null)

	const handleClose = () => {
		onClose()
	}

	const passedHeaderChildren = () => {
		if (headerChildren) {
			return headerChildren
		} else {
			return <CloseButton onClick={handleClose} isOnDark={false} />
		}
	}

	const calculateDimensions = () => {
		const sizeDimensions = sizeMap[size]
		return {
			...sizeDimensions,
			maxWidth: '100%',
			maxHeight: 'calc(100vh - 80px)',
			minHeight: dynamicHeight ? 200 : undefined,
			...dimensions,
		}
	}

	return createPortal(
		<Transition appear show={isOpen} as={Fragment} afterLeave={onTransitionEnd}>
			<Dialog
				as='div'
				className='fixed inset-0 overflow-y-auto'
				style={{ zIndex: baseZIndex }}
				onClose={enableClickOutside ? handleClose : () => {}}
			>
				<div className='flex min-h-full items-center justify-center'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div
							className='fixed inset-0 bg-black/50'
							style={{ zIndex: baseZIndex - 1 }}
							onClick={e => e.stopPropagation()}
						/>
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter='transition ease-out duration-700'
						enterFrom='transform opacity-0 scale-95 translate-y-8'
						enterTo='transform opacity-100 scale-100 translate-y-0'
						leave='transition ease-in duration-500'
						leaveFrom='transform opacity-100 scale-100 translate-y-0'
						leaveTo='transform opacity-0 scale-95 translate-y-8'
					>
						<Dialog.Panel
							ref={modalRef}
							className={twMerge(
								'relative flex transform flex-col overflow-hidden rounded-2xl transition-all',
								dynamicHeight ? '' : 'h-full',
								className,
							)}
							style={{
								...calculateDimensions(),
								touchAction: 'none',
								boxShadow: INTERFACE_DROPSHADOW,
								backgroundColor: INTERFACE_BACKGROUND_COLOR,
								zIndex: baseZIndex + 1,
							}}
							onClick={e => e.stopPropagation()}
						>
							{!hideHeader && (
								<DialogHeader
									title={title}
									headerIcon={headerIcon}
									titleId={id}
									className='text-gray-900'
									headerClassName='text-gray-900'
								>
									{passedHeaderChildren()}
								</DialogHeader>
							)}
							<DialogBody
								className={bodyClassName}
								innerClassName={innerBodyClassName}
							>
								{children}
							</DialogBody>
							{footerChildren && <DialogFooter>{footerChildren}</DialogFooter>}
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>,
		document.body,
	)
}

export default ModalWindow
