import React, { createContext, useCallback, useContext, useState } from 'react'
import { usePanelSystem } from './panelSystem'

type NotificationMessage = {
	id: string
	content: React.ReactNode
	timestamp: number
}

interface NotificationsContextValue {
	messages: NotificationMessage[]
	addMessage: (content: React.ReactNode) => void
	clearMessages: () => void
}

const NotificationsContext = createContext<NotificationsContextValue | null>(
	null,
)

export const NotificationsProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [messages, setMessages] = useState<NotificationMessage[]>([])
	const { panelSystemRef } = usePanelSystem()

	const addMessage = useCallback(
		(content: React.ReactNode) => {
			console.log('[NotificationsContext] Adding message:', {
				content,
				currentMessages: messages.length,
				panelSystemRef: panelSystemRef.current ? 'exists' : 'null',
			})

			const isChatOpen = panelSystemRef.current?.isChatOpen?.()
			console.log('[NotificationsContext] Chat panel state:', {
				isChatOpen,
				panelSystemRefExists: !!panelSystemRef.current,
				isChatOpenFnExists: !!panelSystemRef.current?.isChatOpen,
			})

			if (isChatOpen) {
				console.log(
					'[NotificationsContext] Chat is open, skipping notification',
				)
				return
			}

			const message = {
				id: Math.random().toString(36).substring(7),
				content,
				timestamp: Date.now(),
			}

			setMessages(prev => {
				const newMessages = [...prev, message]
				console.log('[NotificationsContext] Updated messages:', {
					previousCount: prev.length,
					newCount: newMessages.length,
					messages: newMessages,
				})
				return newMessages
			})

			// Remove message after 5 seconds
			setTimeout(() => {
				console.log('[NotificationsContext] Removing message:', message)
				setMessages(prev => {
					const updatedMessages = prev.filter(m => m.id !== message.id)
					console.log('[NotificationsContext] Messages after removal:', {
						previousCount: prev.length,
						newCount: updatedMessages.length,
					})
					return updatedMessages
				})
			}, 5000)
		},
		[messages, panelSystemRef],
	)

	const clearMessages = useCallback(() => {
		setMessages([])
	}, [])

	return (
		<NotificationsContext.Provider
			value={{ messages, addMessage, clearMessages }}
		>
			{children}
		</NotificationsContext.Provider>
	)
}

export const useNotifications = () => {
	const context = useContext(NotificationsContext)
	if (!context) {
		throw new Error(
			'useNotifications must be used within a NotificationsProvider',
		)
	}
	return context
}
