import { useMutation, useQuery } from '@apollo/client'
import { FC, useContext, useState } from 'react'
import { IBook } from '../../../../../../shared/types/book'
import { IResource } from '../../../../../../shared/types/resources'
import GameContext from '../../../../contexts/game'
import {
	GET_RESOURCES_BY_USER,
	UPDATE_RESOURCE,
} from '../../../../graphql/resources'
import useBookSelection from '../../../../hooks/useBookSelection'
import ModalWindow from '../../../window/ModalWindow'
import BookGrid from '../BookGrid'

type Props = {
	onClose: () => void
	onBookSelected?: (selectedBookId: string) => void
}

const BookPicker: FC<Props> = ({ onClose, onBookSelected }) => {
	const { game, dispatch } = useContext(GameContext)
	const initialSelection = Object.values(game.books.byId).map(
		(book: IBook) => book.resourceId,
	)
	const { selectedIds, handleSelect, handleDeselectAll } =
		useBookSelection(initialSelection)
	const [creatorResources, setCreatorResources] = useState<IResource[]>([])
	const [isOpen, setIsOpen] = useState(true)

	const { loading, error } = useQuery(GET_RESOURCES_BY_USER, {
		fetchPolicy: 'no-cache',
		variables: {
			// @ts-ignore
			userId: game.creator._id,
		},
		onCompleted: data => {
			setCreatorResources(
				(data.getResourcesByUser || []).filter(
					(resource: IResource) => resource.resourceType === 'book',
				),
			)
		},
	})

	const [updateResource] = useMutation(UPDATE_RESOURCE)

	const handleBookSelected = (id: string, selected: boolean) => {
		handleSelect(id, selected)
		if (selected && onBookSelected) {
			onBookSelected(id)
		}
	}

	false && handleBookSelected

	const handleSelectAll = () => {
		const ids = Array.isArray(creatorResources)
			? creatorResources.map(resource => resource._id)
			: []
		for (const id of ids) {
			handleSelect(id, true)
		}
	}

	const handleCancel = () => {
		setIsOpen(false)
		setTimeout(onClose, 500)
	}

	const resourceToBook = (resource: IResource): IBook | null => {
		if (!resource) {
			console.error('Attempted to convert undefined resource to book')
			return null
		}
		return {
			_id: resource._id,
			title: resource.name,
			creator: resource.creator,
			access: 'private',
			accessList: [],
			resourceId: resource._id,
		}
	}

	const handleDone = async () => {
		console.log('handleDone', selectedIds, creatorResources)
		const booksArray = selectedIds
			.map(id =>
				Array.isArray(creatorResources)
					? creatorResources.find(resource => resource._id === id)
					: undefined,
			)
			.filter((resource): resource is IResource => resource !== undefined)
			.map(resourceToBook)
			.filter((book): book is IBook => book !== null)

		const books = booksArray.reduce((acc, book) => {
			acc[book._id] = book
			return acc
		}, {} as Record<string, IBook>)

		// Update each selected resource
		for (const id of selectedIds) {
			const resource = Array.isArray(creatorResources)
				? creatorResources.find(resource => resource._id === id)
				: undefined
			if (resource) {
				const usedInGames = resource.usedInGames.includes(game._id)
					? resource.usedInGames.filter(gameId => gameId !== game._id)
					: [...resource.usedInGames, game._id]

				const resourceInput = { ...resource, usedInGames }
				// delete createdAt and updatedAt fields
				delete resourceInput.createdAt
				delete resourceInput.updatedAt

				await updateResource({
					fetchPolicy: 'no-cache',
					variables: {
						resourceInput,
					},
				})
			}
		}

		dispatch({
			type: 'SET_BOOKS',
			payload: {
				books,
			},
		})

		onClose()
	}

	const arraysAreEqual = (arr1: string[], arr2: string[]) =>
		arr1.length === arr2.length && arr1.every(value => arr2.includes(value))

	return (
		<ModalWindow
			id='book-picker'
			title='Add Books to Game'
			onClose={handleCancel}
			size='large'
			enableClickOutside={true}
			headerChildren={
				<div className='mr-2 flex space-x-4'>
					<button onClick={handleCancel}>Cancel</button>
					{!arraysAreEqual(selectedIds, initialSelection) && (
						<button onClick={handleDone}>Done</button>
					)}
				</div>
			}
			footerChildren={
				<div className='absolute bottom-0 left-0 z-10 flex w-full items-center justify-between border-t border-gray-800 bg-gray-900 p-4'>
					<div className='text-gray-500'>
						<p>{selectedIds.length} selected</p>
					</div>
					<div className='space-x-4'>
						<button onClick={handleDeselectAll}>Select None</button>
						<button onClick={handleSelectAll}>Select All</button>
					</div>
				</div>
			}
			isOpen={isOpen}
		>
			{loading && <div>Loading...</div>}
			{error && <div>Error...</div>}
			{!loading && !error && (
				<div
					className='overflow-y-auto px-8 pt-4 pb-16'
					style={{ maxHeight: 'calc(100vh - 200px)' }}
				>
					<BookGrid
						resources={creatorResources.reduce((acc, resource) => {
							acc[resource._id] = resource
							return acc
						}, {})}
						selectMode={true}
						selectedIds={selectedIds}
						onSelect={handleBookSelected}
					/>
				</div>
			)}
		</ModalWindow>
	)
}

export default BookPicker
