// Tabs.tsx
import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import AuthContext from '../../../contexts/auth'
import { UserLevel } from '../../../interfaces/auth'

const Tabs = () => {
	const { authState } = useContext(AuthContext)
	const userLevel = authState.userDetails.userLevel || UserLevel.ADVENTURER
	const canAccessBooks = [UserLevel.ADEPT, UserLevel.ADMIN].includes(userLevel)
	const isAdmin = userLevel === UserLevel.ADMIN

	return (
		<>
			{isAdmin && (
				<NavLink
					to='/admin'
					className={({ isActive }) =>
						isActive ? '' : 'opacity-50 hover:opacity-100'
					}
				>
					Admin
				</NavLink>
			)}

			<NavLink
				to='/profile'
				end
				className={({ isActive }) =>
					isActive ? '' : 'opacity-50 hover:opacity-100'
				}
			>
				Games
			</NavLink>
			{canAccessBooks && (
				<NavLink
					to='/profile/books'
					className={({ isActive }) =>
						isActive ? '' : 'opacity-50 hover:opacity-100'
					}
				>
					Books
				</NavLink>
			)}
		</>
	)
}

export default Tabs
