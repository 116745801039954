import {
	Folder,
	FolderOpen,
	MessageSquare,
	MessagesSquare,
	Sword,
	Swords,
} from 'lucide-react'
import Toolbar from '../interface/toolbar/Toolbar'
import ToolButton from '../interface/toolbar/ToolButton'
import { PanelTabsProps } from './types'

const getIconForPanel = (id: string, isOpen: boolean) => {
	switch (id) {
		case 'chat':
			return isOpen ? (
				<MessagesSquare aria-hidden='true' />
			) : (
				<MessageSquare aria-hidden='true' />
			)
		case 'documents':
			return isOpen ? (
				<FolderOpen aria-hidden='true' strokeWidth={2} />
			) : (
				<Folder aria-hidden='true' />
			)
		case 'turnTracker':
			return isOpen ? (
				<Swords aria-hidden='true' strokeWidth={2} />
			) : (
				<Sword aria-hidden='true' strokeWidth={1.8} className='-rotate-90' />
			)
		default:
			return null
	}
}

const getLabelForPanel = (id: string) => {
	switch (id) {
		case 'chat':
			return 'Chat'
		case 'documents':
			return 'Documents'
		case 'turnTracker':
			return 'Turn Tracker'
		default:
			return id
	}
}

const PanelTabs = ({ panels, activePanels, onToggle }: PanelTabsProps) => {
	return (
		<div className='mt-2 flex flex-row justify-end gap-2'>
			<Toolbar>
				{panels
					.filter(panel => !panel.visibilityCheck || panel.visibilityCheck())
					.reverse()
					.map(panel => {
						const isOpen =
							activePanels.find(p => p.id === panel.id)?.state === 'entered'
						return (
							<ToolButton
								key={panel.id}
								tooltip={getLabelForPanel(panel.id)}
								isOpen={isOpen}
								onClick={() => onToggle(panel.id)}
							>
								{getIconForPanel(panel.id, isOpen)}
							</ToolButton>
						)
					})}
			</Toolbar>
		</div>
	)
}

export default PanelTabs
