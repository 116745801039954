import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useSpring } from 'react-spring'
import { twMerge } from 'tailwind-merge'
import { RPGBookCategory } from '../../../../../shared/types/bookcategories'
import { BookResource } from '../../../../../shared/types/resources'
import { ResourceContext } from '../../../contexts/resources'
import useBookSelection from '../../../hooks/useBookSelection'
import RPGBookCategoryDescriptions from '../../../interfaces/bookcategories'
import ResourceDropzone from '../../resources/ResourceDropZone'
import BookEditor from './BookEditor'
import BookHeader from './elements/BookHeader'
import BookSidebar from './elements/BookSidebar'
import BulkEditModal from './elements/BulkEditModal'
import GroupedBookList from './elements/GroupedBookList'
import { SortMode } from './elements/SortDropdown'
import { ViewMode } from './elements/ViewSelector'

export interface BooksResources {
	[key: string]: BookResource
}

const SIDEBAR_STATE_KEY = 'bookListSidebarOpen'

const BooksPage = () => {
	const { resources } = useContext(ResourceContext)
	const [search, setSearch] = useState('')
	const [searchTerms, setSearchTerms] = useState<
		Array<{
			type:
				| 'text'
				| 'title'
				| 'author'
				| 'publisher'
				| 'game'
				| 'ruleSystem'
				| 'tag'
			value: string
		}>
	>([])
	const [selectMode, setSelectMode] = useState(false)
	const [currentView, setCurrentView] = useState<ViewMode>(() => {
		const savedView = localStorage.getItem('bookListView')
		return (savedView as ViewMode) || 'category' // Default to 'category' if no saved view
	})
	const [sidebarOpen, setSidebarOpen] = useState(() => {
		const saved = localStorage.getItem(SIDEBAR_STATE_KEY)
		return saved ? JSON.parse(saved) : true // Default to open
	})
	const [selectedCategories, setSelectedCategories] = useState<
		RPGBookCategory[]
	>([])
	const [selectedGameSystems, setSelectedGameSystems] = useState<string[]>([])
	const [selectedRuleSystems, setSelectedRuleSystems] = useState<string[]>([])
	const { selectedIds, handleSelect } = useBookSelection<string>()
	const [isHeaderStuck, setIsHeaderStuck] = useState(false)
	const headerRef = useRef<HTMLDivElement>(null)
	const [cardMargin, setCardMargin] = useState(32) // Start with full margin (2rem)
	const containerRef = useRef<HTMLDivElement>(null)
	const [showBulkEdit, setShowBulkEdit] = useState(false)
	const [showSingleEdit, setShowSingleEdit] = useState<string | null>(null)
	const [currentSort, setCurrentSort] = useState<SortMode>('name')

	const [springs, api] = useSpring(() => ({
		marginLeft: 32,
		marginRight: 32,
		config: {
			tension: 300,
			friction: 30,
		},
	}))

	useEffect(() => {
		localStorage.setItem(SIDEBAR_STATE_KEY, JSON.stringify(sidebarOpen))
	}, [sidebarOpen])

	useEffect(() => {
		const handleScroll = () => {
			if (!headerRef.current) return

			const rect = headerRef.current.getBoundingClientRect()
			const distanceToTop = rect.top
			const startDistance = 200 // Distance at which we start reducing margin

			if (distanceToTop <= startDistance) {
				// Calculate a proportional margin based on distance to top
				// When distance is startDistance -> margin is 32 (full margin)
				// When distance is 0 -> margin is 0
				const margin = Math.max(0, (distanceToTop / startDistance) * 32)
				setIsHeaderStuck(distanceToTop <= 0)
				setCardMargin(margin)
			} else {
				setIsHeaderStuck(false)
				setCardMargin(32) // Full margin when not near top
			}
		}

		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	useEffect(() => {
		api.start({
			marginLeft: cardMargin,
			marginRight: cardMargin,
		})
	}, [cardMargin, api])

	// Get unique game systems and rule systems from all book resources
	const { availableGameSystems, availableRuleSystems } = useMemo(() => {
		const gameSystems = new Set<string>()
		const ruleSystems = new Set<string>()
		Object.values(resources).forEach(resource => {
			if (resource.resourceType === 'book') {
				if (resource.metadata.game) {
					gameSystems.add(resource.metadata.game)
				}
				if (resource.metadata.ruleSystem) {
					ruleSystems.add(resource.metadata.ruleSystem)
				}
			}
		})
		return {
			availableGameSystems: Array.from(gameSystems).sort(),
			availableRuleSystems: Array.from(ruleSystems).sort(),
		}
	}, [resources])

	const handleFilter = (type: 'game' | 'ruleSystem', value: string) => {
		// Clear any existing search terms of this type
		setSearchTerms(prev => prev.filter(term => term.type !== type))

		// Update the corresponding selected systems
		if (type === 'game') {
			setSelectedGameSystems([value])
			setCurrentView('gameSystem')
		} else if (type === 'ruleSystem') {
			setSelectedRuleSystems([value])
			setCurrentView('ruleSystem')
		}
	}

	const filteredResources = useMemo(() => {
		const filtered = Object.fromEntries(
			Object.entries(resources).filter(([, resource]) => {
				if (resource.resourceType !== 'book') return false

				// Search is always an AND condition
				const searchLower = search.toLowerCase()
				const matchesCurrentSearch =
					searchLower === '' ||
					// Title/name search
					resource.name.toLowerCase().includes(searchLower) ||
					// Description search
					resource.metadata.description?.toLowerCase().includes(searchLower) ||
					// Author search
					resource.metadata.authors?.some(author =>
						author.toLowerCase().includes(searchLower),
					) ||
					// Publisher search
					resource.metadata.publisher?.toLowerCase().includes(searchLower) ||
					// Game system search
					resource.metadata.game?.toLowerCase().includes(searchLower) ||
					// Rule system search
					resource.metadata.ruleSystem?.toLowerCase().includes(searchLower) ||
					// Tags search
					resource.metadata.tags?.some(tag =>
						tag.toLowerCase().includes(searchLower),
					)

				// Check if resource matches search terms (text-based filtering)
				const matchesSearchTerms =
					searchTerms.length === 0 ||
					searchTerms.every(term => {
						const termLower = term.value.toLowerCase()
						switch (term.type) {
							case 'title':
								return resource.name.toLowerCase().includes(termLower)
							case 'author':
								return resource.metadata.authors?.some(author =>
									author.toLowerCase().includes(termLower),
								)
							case 'publisher':
								return resource.metadata.publisher
									?.toLowerCase()
									.includes(termLower)
							case 'tag':
								return resource.metadata.tags?.some(tag =>
									tag.toLowerCase().includes(termLower),
								)
							case 'text':
								return (
									resource.name.toLowerCase().includes(termLower) ||
									resource.metadata.description
										?.toLowerCase()
										.includes(termLower) ||
									resource.metadata.authors?.some(author =>
										author.toLowerCase().includes(termLower),
									) ||
									resource.metadata.publisher
										?.toLowerCase()
										.includes(termLower) ||
									resource.metadata.tags?.some(tag =>
										tag.toLowerCase().includes(termLower),
									)
								)
							default:
								return true
						}
					})

				if (!matchesCurrentSearch || !matchesSearchTerms) return false

				// System-level filtering (categories, game systems, rule systems)
				// A book matches if:
				// 1. No filters are selected in a category, OR
				// 2. The book matches ANY of the selected filters in that category

				// Categories: Match any selected category
				const matchesCategories =
					selectedCategories.length === 0 ||
					resource.metadata.categories?.some(
						category =>
							RPGBookCategoryDescriptions[category] &&
							selectedCategories.includes(category),
					) ||
					false

				// Game Systems: Match any selected game system
				const matchesGameSystems =
					selectedGameSystems.length === 0 ||
					(resource.metadata.game &&
						selectedGameSystems.includes(resource.metadata.game))

				// Rule Systems: Match any selected rule system
				const matchesRuleSystems =
					selectedRuleSystems.length === 0 ||
					(resource.metadata.ruleSystem &&
						selectedRuleSystems.includes(resource.metadata.ruleSystem))

				// Book must match ALL filter types
				return matchesCategories && matchesGameSystems && matchesRuleSystems
			}),
		) as BooksResources

		// Sort the filtered resources
		const sortedEntries = Object.entries(filtered).sort(([, a], [, b]) => {
			switch (currentSort) {
				case 'name':
					return a.name.localeCompare(b.name)
				case 'updatedAt':
					return (
						new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
					)
				case 'createdAt':
					return (
						new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
					)
				default:
					return 0
			}
		})

		return Object.fromEntries(sortedEntries)
	}, [
		resources,
		search,
		searchTerms,
		selectedCategories,
		selectedGameSystems,
		selectedRuleSystems,
		currentSort,
	])

	const totalBooks = useMemo(() => {
		return Object.values(resources).filter(r => r.resourceType === 'book')
			.length
	}, [resources])

	const filteredBooksCount = useMemo(() => {
		return Object.keys(filteredResources).length
	}, [filteredResources])

	const cardClassName = twMerge(
		isHeaderStuck && 'rounded-none',
		'transition-colors duration-300',
	)

	return (
		<ResourceDropzone cardClassName={cardClassName} style={springs}>
			<div className='flex h-full flex-col'>
				<div
					ref={headerRef}
					className={twMerge(
						'sticky top-0 z-50 border-b border-gray-700/30 bg-gray-800/95 px-8 backdrop-blur-xl transition-all duration-300',
						isHeaderStuck ? 'py-4' : 'py-8',
					)}
				>
					<BookHeader
						selectMode={selectMode}
						setSelectMode={setSelectMode}
						onToggleSidebar={() => setSidebarOpen(prev => !prev)}
						sidebarOpen={sidebarOpen}
						containerRef={containerRef}
						totalBooks={totalBooks}
						filteredBooks={filteredBooksCount}
					/>
				</div>

				<div className='flex min-h-0 flex-1'>
					<BookSidebar
						isOpen={sidebarOpen}
						selectedCategories={selectedCategories}
						onCategoriesChange={setSelectedCategories}
						selectedGameSystems={selectedGameSystems}
						onGameSystemsChange={setSelectedGameSystems}
						availableGameSystems={availableGameSystems}
						selectedRuleSystems={selectedRuleSystems}
						onRuleSystemsChange={setSelectedRuleSystems}
						availableRuleSystems={availableRuleSystems}
						currentView={currentView}
						onViewChange={setCurrentView}
						currentSort={currentSort}
						onSortChange={setCurrentSort}
						searchValue={search}
						onSearch={setSearch}
						searchTerms={searchTerms}
						onSearchTermsChange={setSearchTerms}
					/>

					<div
						id='book-list-content'
						className='flex-1 overflow-y-auto'
						ref={containerRef}
					>
						<div
							className='mx-auto max-w-[2400px] transition-all duration-500'
							style={{
								marginLeft: `${springs.marginLeft.get()}px`,
								marginRight: `${springs.marginRight.get()}px`,
							}}
						>
							<GroupedBookList
								resources={filteredResources}
								viewMode={currentView}
								selectMode={selectMode}
								selectedIds={selectedIds}
								onSelect={handleSelect}
								onCategoriesChange={setSelectedCategories}
								onGameSystemsChange={setSelectedGameSystems}
								onRuleSystemsChange={setSelectedRuleSystems}
								onFilter={handleFilter}
							/>
						</div>
					</div>
				</div>

				{showBulkEdit && (
					<BulkEditModal
						id='bulk-edit-modal'
						resources={selectedIds
							.map(id => resources[id])
							.filter((r): r is BookResource => !!r)}
						isOpen={showBulkEdit}
						onClose={() => setShowBulkEdit(false)}
					/>
				)}

				{showSingleEdit && (
					<BookEditor
						id={`book-editor-${showSingleEdit}`}
						resource={resources[showSingleEdit] as BookResource}
						isOpen={true}
						onClose={() => setShowSingleEdit(null)}
					/>
				)}
			</div>
		</ResourceDropzone>
	)
}

export default BooksPage
