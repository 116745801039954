import { gql, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useApi from '../../../hooks/useApi'

const GET_ADMIN_STATS = gql`
	query GetAdminStats {
		adminStats {
			totalUsers
			totalGames
			totalResources
		}
	}
`

interface AdminStats {
	totalUsers: number
	totalGames: number
	totalResources: number
}

interface StorageStats {
	totalStorageGB: number
	monthlyTransferGB: number
	estimatedMonthlyCost: number
	totalRequests: number
}

export default function AdminDashboard() {
	const [storageStats, setStorageStats] = useState<StorageStats | null>(null)
	const { request } = useApi()
	const { data, loading, error } = useQuery<{ adminStats: AdminStats }>(
		GET_ADMIN_STATS,
	)

	useEffect(() => {
		const fetchStorageStats = async () => {
			try {
				const stats = await request('/api/storage-metrics/stats')
				setStorageStats(stats)
			} catch (error) {
				console.error('Error fetching storage stats:', error)
			}
		}

		fetchStorageStats()
	}, [request])

	if (loading) return <div>Loading stats...</div>
	if (error) return <div>Error loading stats</div>

	const stats = data?.adminStats

	return (
		<div>
			<h1 className='mb-8 text-2xl font-bold'>Dashboard</h1>

			<div className='mb-8 grid grid-cols-1 gap-6 md:grid-cols-3'>
				<Link
					to='/admin/users'
					className='rounded-lg bg-white p-6 shadow transition-all hover:bg-gray-50 hover:shadow-md'
				>
					<h2 className='text-lg font-semibold text-gray-700'>Total Users</h2>
					<p className='mt-2 text-3xl font-bold'>{stats?.totalUsers || 0}</p>
				</Link>

				<Link
					to='/admin/games'
					className='rounded-lg bg-white p-6 shadow transition-all hover:bg-gray-50 hover:shadow-md'
				>
					<h2 className='text-lg font-semibold text-gray-700'>Total Games</h2>
					<p className='mt-2 text-3xl font-bold'>{stats?.totalGames || 0}</p>
				</Link>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h2 className='text-lg font-semibold text-gray-700'>
						Total Resources
					</h2>
					<p className='mt-2 text-3xl font-bold'>
						{stats?.totalResources || 0}
					</p>
				</div>
			</div>

			<h2 className='mb-4 text-xl font-bold text-gray-700'>
				Storage Statistics
			</h2>
			<div className='grid grid-cols-1 gap-6 md:grid-cols-4'>
				<div className='rounded-lg bg-white p-6 shadow'>
					<h3 className='text-sm font-semibold text-gray-600'>Total Storage</h3>
					<p className='mt-2 text-2xl font-bold'>
						{storageStats?.totalStorageGB.toFixed(2) || '0'} GB
					</p>
				</div>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h3 className='text-sm font-semibold text-gray-600'>
						Monthly Transfer
					</h3>
					<p className='mt-2 text-2xl font-bold'>
						{storageStats?.monthlyTransferGB.toFixed(2) || '0'} GB
					</p>
				</div>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h3 className='text-sm font-semibold text-gray-600'>
						Est. Monthly Cost
					</h3>
					<p className='mt-2 text-2xl font-bold'>
						${storageStats?.estimatedMonthlyCost.toFixed(2) || '0'}
					</p>
				</div>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h3 className='text-sm font-semibold text-gray-600'>
						Total Requests
					</h3>
					<p className='mt-2 text-2xl font-bold'>
						{storageStats?.totalRequests.toLocaleString() || '0'}
					</p>
				</div>
			</div>
		</div>
	)
}
