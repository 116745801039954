const BookSpineEffect = () => {
	return (
		<div
			className='pointer-events-none absolute inset-[1px] mix-blend-multiply'
			style={{
				background:
					'linear-gradient(90deg, #FFF 0%, #CCC 1%, #DDD 1.5%, #999 2.5%, #FFF 3.5%, #FFF 22%, #FFF 100%)',
			}}
		></div>
	)
}

export default BookSpineEffect
