import { Dices } from 'lucide-react'
import { useContext } from 'react'
import ToolsContext from '../../contexts/tools'
import useTheme from '../../hooks/useTheme'
import ToolButton from './toolbar/ToolButton'

export default function DiceButton() {
	const { primary } = useTheme()
	const { toolsState, dispatchTools } = useContext(ToolsContext)
	const showDiceTools = toolsState.mode === 'dice'

	const handleDiceModeClick = () => {
		dispatchTools({
			type: 'SET_MODE',
			payload: showDiceTools ? null : 'dice',
		})
	}

	return (
		<ToolButton
			tooltip={showDiceTools ? 'Hide Dice' : 'Show Dice'}
			isOpen={showDiceTools}
			onClick={handleDiceModeClick}
		>
			<div
				style={
					{
						'--dice-stroke-color': showDiceTools ? primary : 'white',
					} as React.CSSProperties
				}
			>
				<Dices aria-label='Dice' />
			</div>
		</ToolButton>
	)
}
