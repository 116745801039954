import { X } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import ToolbarButton from '../toolbar/ToolbarButton'

interface BackToBooksButtonProps {
	onClose?: () => void
}

export default function BackToBooksButton({ onClose }: BackToBooksButtonProps) {
	if (onClose) {
		return (
			<ToolbarButton onClick={onClose} tooltip='Back to Books' className='mr-4'>
				<X aria-hidden='true' />
			</ToolbarButton>
		)
	}

	return (
		<NavLink to='/profile/books'>
			<ToolbarButton tooltip='Back to Books' className='mr-4'>
				<X aria-hidden='true' />
			</ToolbarButton>
		</NavLink>
	)
}
