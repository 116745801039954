import { useRef } from 'react'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { twMerge } from 'tailwind-merge'
import { IMessage } from '../../../../shared/types/log'
import Translucency from '../Translucency'
import './bubble.css'
// import remarkGfm from 'remark-gfm'

const paragraphRenderer = ({ children }) => <p className='mb-4'>{children}</p>
const listRenderer = ({ children, ordered }) =>
	ordered ? (
		<ol className='ml-4 mb-4 list-outside list-decimal'>{children}</ol>
	) : (
		<ul className='ml-4 mb-4 list-outside list-disc'>{children}</ul>
	)
const tableRenderer = ({ children }) => (
	<table className='mb-4 border-collapse border-gray-300'>{children}</table>
)
const tableHeadRenderer = ({ children }) => (
	<thead className='border-b border-gray-300'>{children}</thead>
)
const tableBodyRenderer = ({ children }) => <tbody>{children}</tbody>
const tableRowRenderer = ({ children }) => <tr>{children}</tr>
const tableHeaderRenderer = ({ children }) => (
	<th className='px-4 py-2 text-left font-semibold'>{children}</th>
)
const tableCellRenderer = ({ children }) => (
	<td className='border border-gray-300 px-4 py-2'>{children}</td>
)
const strongRenderer = ({ children }) => (
	<strong className='font-semibold'>{children}</strong>
)
const emRenderer = ({ children }) => <em className='italic'>{children}</em>
// const linkRenderer = ({ children, href }) => (
// 	<a className='text-blue-500 hover:underline' href={href}>
// 		{children}
// 	</a>
// )

interface MessageBubbleProps {
	message: IMessage
}

const MessageBubble: React.FC<MessageBubbleProps> = ({ message }) => {
	const messageRef = useRef<HTMLDivElement>(null)

	return (
		<div
			ref={messageRef}
			className={twMerge(
				'ml-2 inline-block rounded-2xl bg-gray-700/40 py-1.5 px-3 text-sm text-gray-100',
				Translucency,
				'border-0',
			)}
			style={{
				boxShadow: '0 2px 5px rgba(0,0,0,0.25), 0 0 0 1px rgba(255,255,255,.1)',
			}}
		>
			<ReactMarkdown
				className='message'
				// remarkPlugins={[remarkGfm]}
				components={{
					p: paragraphRenderer,
					ul: listRenderer,
					ol: listRenderer,
					li: ({ children }) => <li className='mb-2'>{children}</li>,
					table: tableRenderer,
					thead: tableHeadRenderer,
					tbody: tableBodyRenderer,
					tr: tableRowRenderer,
					th: tableHeaderRenderer,
					td: tableCellRenderer,
					strong: strongRenderer,
					em: emRenderer,
					// a: linkRenderer,
				}}
				children={message.message}
			/>
		</div>
	)
}

export default MessageBubble
