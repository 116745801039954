import { flip, offset, shift, useFloating } from '@floating-ui/react'
import { Menu, Portal, Transition } from '@headlessui/react'
import { ChevronDown } from 'lucide-react'
import { Fragment } from 'react'
import { twMerge } from 'tailwind-merge'

export type SortMode = 'name' | 'updatedAt' | 'createdAt'

const sortOptions = [
	{ value: 'name', label: 'Name' },
	{ value: 'updatedAt', label: 'Last Updated' },
	{ value: 'createdAt', label: 'Date Added' },
]

interface SortDropdownProps {
	currentSort: SortMode
	onSortChange: (sort: SortMode) => void
}

const SortDropdown = ({ currentSort, onSortChange }: SortDropdownProps) => {
	const { refs, floatingStyles } = useFloating({
		middleware: [offset(4), flip(), shift()],
	})

	return (
		<Menu as='div' className='relative'>
			{({ open }) => (
				<>
					<Menu.Button
						ref={refs.setReference}
						className={twMerge(
							'flex w-full items-center justify-between rounded-md bg-gray-700 px-4 py-2.5 text-sm font-medium text-white transition-colors hover:bg-gray-600',
							open && 'bg-gray-600',
						)}
					>
						<span className='truncate'>
							{sortOptions.find(option => option.value === currentSort)?.label}
						</span>
						<ChevronDown className='ml-2 h-4 w-4 flex-none' />
					</Menu.Button>

					<Transition
						as={Fragment}
						enter='transition ease-out duration-100'
						enterFrom='transform opacity-0 scale-95'
						enterTo='transform opacity-100 scale-100'
						leave='transition ease-in duration-75'
						leaveFrom='transform opacity-100 scale-100'
						leaveTo='transform opacity-0 scale-95'
					>
						<Portal>
							<Menu.Items
								ref={refs.setFloating}
								style={floatingStyles}
								className='z-[9999] w-[300px] overflow-hidden rounded-md bg-gray-700 py-2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
								static
							>
								<div className='max-h-[300px] overflow-y-auto px-2'>
									{sortOptions.map(option => (
										<Menu.Item key={option.value}>
											{({ active }) => (
												<button
													type='button'
													className={twMerge(
														'flex w-full items-start px-4 py-2.5 text-left text-sm text-white transition-colors hover:bg-gray-600',
														active && 'rounded bg-gray-700',
													)}
													onClick={() => onSortChange(option.value as SortMode)}
												>
													<div className='mr-3 mt-0.5 flex h-5 w-5 flex-none items-center justify-center'>
														{currentSort === option.value && (
															<div className='h-2 w-2 rounded-full bg-white' />
														)}
													</div>
													<span className='flex-1'>{option.label}</span>
												</button>
											)}
										</Menu.Item>
									))}
								</div>
							</Menu.Items>
						</Portal>
					</Transition>
				</>
			)}
		</Menu>
	)
}

export default SortDropdown
