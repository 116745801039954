import { Container } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useEffect, useState } from 'react'
import { AnchorCorner, useGrid } from '../../../contexts/grid'
import { useGridMode } from '../../../hooks/useGridMode'
import { useSnapToGrid } from '../../../hooks/useSnapToGrid'
import {
	distanceBetweenPoints,
	isClickDistance,
} from '../../../utils/gridUtils'
import GridCorner from './GridCorner'
import { areCornersAtSamePosition } from './areCornersAtSamePosition'
import { useOffset } from './useGridOffset'
import { useResize } from './useGridResize'
import { useUpdateGrid } from './useUpdateGrid'

const GridInteractions = () => {
	const {
		grid,
		hexGrid,
		squareGrid,
		hoveredHex,
		setHoveredHex,
		anchorCorner,
		setAnchorCorner,
		mapDimensions,
		gridDimensions,
		interactionMode,
		setInteractionMode,
		pointerDownPos,
		setPointerDownPos,
		viewport,
		pauseViewport,
		resumeViewport,
	} = useGrid()
	const snapToGrid = useSnapToGrid()
	const { isGridMode, deactivateGridMode } = useGridMode()
	const { startOffset, moveOffset } = useOffset()
	const { startResize, moveResize } = useResize()
	const { updateGrid } = useUpdateGrid()

	// Keep corners unclickable when we’re dragging
	const [cornerEventMode, setCornerEventMode] =
		useState<PIXI.EventMode>('static')
	// Which corner the user clicked
	const [currentCorner, setCurrentCorner] = useState<AnchorCorner | null>(null)
	// Are we currently over the map area while dragging?
	const [isOverMap, setIsOverMap] = useState(false)

	// Clear anchor when grid mode is deactivated
	useEffect(() => {
		if (!isGridMode) setAnchorCorner(null)
	}, [isGridMode, setAnchorCorner])

	// Handle Escape key for exiting grid mode
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Escape' && isGridMode) {
				deactivateGridMode()
			}
		}
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [isGridMode, deactivateGridMode])

	// Set center anchor point when entering grid mode
	useEffect(() => {
		if (!isGridMode || anchorCorner) return
		const centerPoint = new PIXI.Point(
			mapDimensions.width / 2,
			mapDimensions.height / 2,
		)

		// Square grid logic
		if (grid.type === 'square' && squareGrid) {
			const centerSquare = squareGrid.pointToSquare(
				new PIXI.Point(
					centerPoint.x + gridDimensions.width / 3 - grid.x,
					centerPoint.y + gridDimensions.height / 3 - grid.y,
				),
			)
			if (!centerSquare) return
			setAnchorCorner({ ...centerSquare, index: 0 })
		}
		// Hex grid logic
		else if (hexGrid) {
			const snappedPoint = snapToGrid(centerPoint.x, centerPoint.y)
			const centerHex = hexGrid.pointToHex({
				x: snappedPoint.x + gridDimensions.width / 3 - grid.x,
				y: snappedPoint.y + gridDimensions.height / 3 - grid.y,
			})
			if (!centerHex) return
			let closestCorner = 0
			let minDistance = Infinity
			centerHex.corners.forEach((corner, index) => {
				const distance = distanceBetweenPoints(
					new PIXI.Point(corner.x, corner.y),
					centerPoint,
				)
				if (distance < minDistance) {
					minDistance = distance
					closestCorner = index
				}
			})
			setAnchorCorner({
				col: centerHex.col,
				row: centerHex.row,
				index: closestCorner,
			})
		}

		viewport.animate({
			position: { x: mapDimensions.width / 2, y: mapDimensions.height / 2 },
			scale: 40,
			time: 1000,
			ease: 'easeInOutCubic',
		})
	}, [
		isGridMode,
		anchorCorner,
		hexGrid,
		squareGrid,
		grid,
		mapDimensions,
		gridDimensions,
		viewport,
		snapToGrid,
	])

	if (!isGridMode) return null

	const initializeDrag = () => {
		if (!anchorCorner) return 'none'

		if (grid.type === 'square') {
			if (!squareGrid || !currentCorner) return 'none'
			const anchorSquare = squareGrid.getSquare({
				col: anchorCorner.col,
				row: anchorCorner.row,
			})
			const currentSquare = squareGrid.getSquare({
				col: currentCorner.col,
				row: currentCorner.row,
			})
			if (!anchorSquare || !currentSquare) return 'none'
			const anchorPoint = anchorSquare.corners[anchorCorner.index]
			const currentPoint = currentSquare.corners[currentCorner.index]

			// Compare positions
			const EPSILON = 1e-7
			const isSamePosition =
				Math.abs(currentPoint.x - anchorPoint.x) < EPSILON &&
				Math.abs(currentPoint.y - anchorPoint.y) < EPSILON
			return isSamePosition ? 'offset' : 'resize'
		} else {
			if (!hexGrid || !currentCorner) return 'none'
			const isAnchorCorner = areCornersAtSamePosition(
				hexGrid,
				anchorCorner,
				currentCorner,
			)
			return isAnchorCorner ? 'offset' : 'resize'
		}
	}

	const cellsToRender = new Set<string>()
	if (anchorCorner) {
		cellsToRender.add(`${anchorCorner.col},${anchorCorner.row}`)
	}
	if (
		hoveredHex &&
		hoveredHex.col !== anchorCorner?.col &&
		hoveredHex.row !== anchorCorner?.row
	) {
		cellsToRender.add(`${hoveredHex.col},${hoveredHex.row}`)
	}

	const renderCorners = () => {
		if (grid.type === 'square' && squareGrid) {
			return Array.from(cellsToRender).map(key => {
				const [col, row] = key.split(',').map(Number)
				const square = squareGrid.getSquare({ col, row })
				if (!square) return null
				const squareCorners = square.corners.map(corner => ({
					x: corner.x + grid.x,
					y: corner.y + grid.y,
				}))
				return squareCorners.map((corner, index) => (
					<GridCorner
						key={`${key}-${index}`}
						x={corner.x}
						y={corner.y}
						index={index}
						col={col}
						row={row}
						eventMode={cornerEventMode}
						onpointerdown={cornerPointerDown}
					/>
				))
			})
		}
		if (hexGrid) {
			return Array.from(cellsToRender).map(key => {
				const [col, row] = key.split(',').map(Number)
				const hex = hexGrid.getHex({ col, row })
				if (!hex) return null
				return hex.corners.map((corner, index) => (
					<GridCorner
						key={`${key}-${index}`}
						x={corner.x + grid.x}
						y={corner.y + grid.y}
						index={index}
						col={col}
						row={row}
						eventMode={cornerEventMode}
						onpointerdown={cornerPointerDown}
					/>
				))
			})
		}
		return null
	}

	const reset = () => {
		setInteractionMode('none')
		setPointerDownPos(null)
		resumeViewport()
		setCornerEventMode('static')
		setIsOverMap(false)
		setCurrentCorner(null)
	}

	const pointerIsOverMap = (e: PIXI.FederatedPointerEvent) => {
		const { x, y } = viewport.toWorld(e.globalX, e.globalY)
		const { width, height } = mapDimensions
		return x >= 0 && x <= width && y >= 0 && y <= height
	}

	const cornerPointerDown = (
		e: PIXI.FederatedPointerEvent,
		corner: AnchorCorner,
	) => {
		if (e.button !== 0) return
		pauseViewport()
		setCornerEventMode('none')
		setPointerDownPos({ x: e.globalX, y: e.globalY })
		setCurrentCorner(corner)
		if (pointerIsOverMap(e)) {
			setIsOverMap(true)
			startOffset(e)
			startResize(e)
		}
	}

	const overlayPointerDown = (e: PIXI.FederatedPointerEvent) => {
		if (e.button !== 0) return
		setPointerDownPos({ x: e.globalX, y: e.globalY })
	}

	const originOffset = {
		x: gridDimensions.width / 3,
		y: gridDimensions.height / 3,
	}

	const calculateGridPosition = (worldX: number, worldY: number) => {
		if (grid.type === 'square' && squareGrid) {
			return squareGrid.pointToSquare(
				new PIXI.Point(
					worldX - grid.x + originOffset.x,
					worldY - grid.y + originOffset.y,
				),
			)
		}
		if (hexGrid) {
			return hexGrid.pointToHex({
				x: worldX - grid.x + originOffset.x,
				y: worldY - grid.y + originOffset.y,
			})
		}
		return null
	}

	const overlayPointerMove = (e: PIXI.FederatedPointerEvent) => {
		if (!viewport) return

		// Update hovered cell
		const { x, y } = viewport.toWorld(e.globalX, e.globalY)
		const gridPosition = calculateGridPosition(x, y)
		if (gridPosition) {
			setHoveredHex({ col: gridPosition.col, row: gridPosition.row })
		}

		if (!isOverMap || !pointerDownPos || !anchorCorner) return

		const currentPos = { x: e.globalX, y: e.globalY }
		if (!isClickDistance(pointerDownPos, currentPos)) {
			if (interactionMode === 'none') {
				setInteractionMode(initializeDrag())
			}
			if (interactionMode === 'offset') {
				const offset = moveOffset(e)
				if (offset) updateGrid({ x: offset.x, y: offset.y })
			} else if (interactionMode === 'resize') {
				const updatedGrid = moveResize(e)
				if (updatedGrid) updateGrid(updatedGrid)
			}
		}
	}

	// Called for pointerup, pointerupoutside, and pointercancel
	const finalizeInteraction = () => {
		if (isOverMap && interactionMode === 'none' && currentCorner) {
			setAnchorCorner(currentCorner)
		}
		reset()
	}

	return (
		<Container>
			<Container
				eventMode='static'
				hitArea={
					new PIXI.Rectangle(
						-gridDimensions.width / 3,
						-gridDimensions.height / 3,
						gridDimensions.width,
						gridDimensions.height,
					)
				}
				cursor='grab'
				pointerdown={overlayPointerDown}
				pointermove={overlayPointerMove}
				pointerup={finalizeInteraction}
				pointerupoutside={finalizeInteraction}
				pointercancel={finalizeInteraction}
			/>
			{renderCorners()}
		</Container>
	)
}

export default GridInteractions
