import { INTERFACE_BRIGHT_GOLD_COLOR } from '../../interface/constants'
import SmallUppercase from '../../interface/text/SmallUppercase'

export default function TemplatePill() {
	return (
		<span
			className='inline-flex items-center gap-1 rounded-md px-1.5 py-1'
			style={{
				background: INTERFACE_BRIGHT_GOLD_COLOR,
			}}
		>
			<SmallUppercase
				style={{
					color: 'black',
				}}
			>
				Template
			</SmallUppercase>
		</span>
	)
}
