import { Container } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useState } from 'react'
import { useGrid } from '../../../contexts/grid'
import { useSnapToGrid } from '../../../hooks/useSnapToGrid'
import MeasurementDisplay from './MeasurementDisplay'
import MeasurementLine from './MeasurementLine'
import MeasurementPoint from './MeasurementPoint'

interface Props {
	points: { x: number; y: number }[]
	viewport: PixiViewport
	isClosed: boolean
	onPointAdded: (point: { x: number; y: number }) => void
	onMeasurementClosed: () => void
	onPointUpdated: (index: number, point: { x: number; y: number }) => void
}

interface DragState {
	pointIndex: number | null
	startPos: { x: number; y: number } | null
	isOverMap: boolean
}

export default function MeasurementContainer({
	points,
	viewport,
	isClosed,
	onPointAdded,
	onMeasurementClosed,
	onPointUpdated,
}: Props) {
	const { grid, mapDimensions, pauseViewport, resumeViewport } = useGrid()
	const snapToGrid = useSnapToGrid()

	const [dragState, setDragState] = useState<DragState>({
		pointIndex: null,
		startPos: null,
		isOverMap: false,
	})

	const [hoveredPointIndex, setHoveredPointIndex] = useState<number | null>(
		null,
	)

	const handlePointSelected = (
		index: number,
		e: PIXI.FederatedPointerEvent,
	) => {
		if (e.button !== 0) return // Only handle left mouse button

		pauseViewport()
		setDragState({
			pointIndex: index,
			startPos: { x: e.globalX, y: e.globalY },
			isOverMap: true,
		})
	}

	const handlePointerMove = (e: PIXI.FederatedPointerEvent) => {
		if (!dragState.pointIndex || !dragState.startPos || !viewport) return

		const worldPos = viewport.toWorld(e.global.x, e.global.y)
		const snappedPos = grid.enabled
			? snapToGrid(worldPos.x, worldPos.y)
			: worldPos

		// Update point position
		onPointUpdated(dragState.pointIndex, snappedPos)
	}

	const handlePointerUp = () => {
		resumeViewport()
		setDragState({
			pointIndex: null,
			startPos: null,
			isOverMap: false,
		})
	}

	const handleClick = (e: PIXI.FederatedPointerEvent) => {
		if (isClosed) return

		const point = viewport?.toWorld(e.global.x, e.global.y)
		if (!point) return

		const snappedPoint = grid.enabled ? snapToGrid(point.x, point.y) : point

		// If there are points and we click near the last point, close the measurement
		if (points.length > 0) {
			const lastPoint = points[points.length - 1]
			const dx = lastPoint.x - snappedPoint.x
			const dy = lastPoint.y - snappedPoint.y
			const distance = Math.sqrt(dx * dx + dy * dy)

			// If double click or clicking near last point
			if (e.detail > 1 || distance < 1) {
				onMeasurementClosed()
				return
			}
		}

		onPointAdded(snappedPoint)
	}

	return (
		<Container
			hitArea={
				new PIXI.Rectangle(0, 0, mapDimensions.width, mapDimensions.height)
			}
			eventMode='static'
			pointermove={handlePointerMove}
			pointerup={handlePointerUp}
			pointerupoutside={handlePointerUp}
			pointertap={handleClick}
		>
			<MeasurementLine points={points} />
			{points.map((point, i) => (
				<MeasurementPoint
					key={i}
					index={i}
					point={point}
					isHovered={hoveredPointIndex === i}
					isDragged={dragState.pointIndex === i}
					onPointSelected={handlePointSelected}
					onHoverChange={hovered => setHoveredPointIndex(hovered ? i : null)}
				/>
			))}
			<MeasurementDisplay points={points} isClosed={isClosed} />
		</Container>
	)
}
