import { IToolsAction, IToolsState } from '../../../shared/types/tools'

export const initialToolsState: IToolsState = {
	isSceneSettingsOpen: false,
	mode: 'select',
	draw: {
		tool: 'pen',
	},
	fog: {
		tool: 'polygonAdd',
	},
	measure: {
		points: [],
		isActive: false,
		isClosed: false,
	},
}

const toolsReducer = (
	state: IToolsState,
	action: IToolsAction,
): IToolsState => {
	switch (action.type) {
		case 'SET_IS_SCENE_SETTINGS_OPEN':
			return { ...state, isSceneSettingsOpen: action.payload }

		case 'SET_MODE':
			if (action.payload !== 'measure' && state.mode === 'measure') {
				return {
					...state,
					mode: action.payload,
					measure: { ...state.measure, points: [], isActive: false },
				}
			}
			return { ...state, mode: action.payload }

		case 'SET_DRAW_TOOL':
			return { ...state, draw: { ...state.draw, tool: action.payload } }

		case 'SET_FOG_TOOL':
			return { ...state, fog: { ...state.fog, tool: action.payload } }

		case 'ADD_MEASURE_POINT':
			return {
				...state,
				measure: {
					...state.measure,
					points: [...state.measure.points, action.payload],
				},
			}

		case 'CLEAR_MEASURE_POINTS':
			return {
				...state,
				measure: { ...state.measure, points: [], isClosed: false },
			}

		case 'SET_MEASURE_ACTIVE':
			return {
				...state,
				measure: { ...state.measure, isActive: action.payload },
			}

		case 'SET_MEASURE_CLOSED':
			return {
				...state,
				measure: { ...state.measure, isClosed: action.payload },
			}

		case 'UPDATE_MEASURE_POINT': {
			const updatedPoints = [...state.measure.points]
			updatedPoints[action.payload.index] = action.payload.point
			return {
				...state,
				measure: {
					...state.measure,
					points: updatedPoints,
				},
			}
		}

		default:
			return state
	}
}

export default toolsReducer
