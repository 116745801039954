import * as ContextMenu from '@radix-ui/react-context-menu'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../../contexts/game'
import UsersContext from '../../../contexts/users'

type Props = {
	userId: string
}

const EditColor = ({ userId }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { users } = usersState
	const currentColor = users.find(user => user.userId === userId)?.userSettings
		.color

	// also update in GameResolvers.mjs if you change this
	const colors = [
		'#ffbe0b', // yellow
		'#fb5607', // orange
		'#ff006e', // pink
		'#8338ec', // purple

		'#3a86ff', // blue
		'#a1c181', // light green
		'#7209b7', // dark purple
		'#3f37c9', // indigo
	]

	const usedColors = users.map(user => user.userSettings.color)
	const availableColors = colors.filter(
		color => color === currentColor || !usedColors.includes(color),
	)

	const handleChange = (value: string) => {
		dispatch({
			type: 'UPDATE_USER_COLOR',
			payload: {
				userId,
				color: value,
			},
		})
	}
	return (
		<ContextMenu.RadioGroup
			value={currentColor}
			onValueChange={handleChange}
			className='grid grid-cols-4 gap-1'
		>
			{availableColors.map((color, index) => (
				<ContextMenu.RadioItem
					key={index}
					value={color}
					className='outline-none'
				>
					<div
						className={twMerge(
							'aspect-square h-8 transform cursor-pointer rounded-full transition duration-200 hover:scale-125',
							currentColor === color ? 'border-2 border-white' : '',
						)}
						style={{ backgroundColor: color }}
					/>
				</ContextMenu.RadioItem>
			))}
		</ContextMenu.RadioGroup>
	)
}

export default EditColor
