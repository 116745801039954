import React from 'react'
import useActorsInScene from '../../../hooks/useActorsInScene'
import { useGame } from '../../../hooks/useGame'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

type Props = {
	onSelect: () => void
}

const RemoveAllTokensMenuItem: React.FC<Props> = ({ onSelect }) => {
	const { dispatch } = useGame()
	const scene = useGetActiveScene()
	const actorsInScene = useActorsInScene(scene?._id)
	const hasTokens = actorsInScene.length > 0

	const handleSelect = () => {
		if (!confirm('Are you sure you want to remove all tokens from this scene?'))
			return

		dispatch({
			type: 'REMOVE_ALL_ACTORS_FROM_SCENE',
			payload: { sceneId: scene._id },
		})
		onSelect()
	}

	return (
		<DangerMenuItem onSelect={handleSelect} disabled={!hasTokens}>
			Remove All Tokens
		</DangerMenuItem>
	)
}

export default RemoveAllTokensMenuItem
