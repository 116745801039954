import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import menuItemClasses from './menuItemClasses'

type DropdownMenuItemProps = React.ComponentProps<typeof DropdownMenu.Item>

type Props = DropdownMenuItemProps & {
	children: React.ReactNode
	className?: string
}

const MenuItem: React.FC<Props> = ({
	children,
	className,
	disabled,
	...rest
}) => {
	return (
		<DropdownMenu.Item
			className={twMerge(
				menuItemClasses,
				disabled ? 'cursor-not-allowed opacity-50' : '',
				className,
			)}
			disabled={disabled}
			{...rest}
		>
			{children}
		</DropdownMenu.Item>
	)
}

export default MenuItem
