import { FC, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ProfileEditContext } from '../../../contexts/ProfileEditContext'
import Avatar from '../../Avatar'
import Tabs from '../elements/Tabs'
import Logo from './Logo'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

interface IProps {
	isAuthed: boolean
	isInvited: boolean
	authState: any // Replace with proper type when available
}

const Navigation: FC<IProps> = ({ isAuthed, isInvited, authState }) => {
	const location = useLocation()
	const isNotFrontsite =
		location.pathname.includes('/game/') ||
		location.pathname.includes('/book/') ||
		location.pathname.includes('/document/') ||
		location.pathname.includes('/resource/') ||
		location.pathname.includes('/sheet/')
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')

	const onInviteScreen = !!inviteToken
	const { setEditProfile } = useContext(ProfileEditContext)

	if (isNotFrontsite && !onInviteScreen) return null

	const handleAccount = () => {
		setEditProfile(true)
	}

	return (
		<header className='flex flex-wrap items-center px-8 py-12 font-semibold text-white md:p-16'>
			<Logo />

			<nav className='flex flex-1 items-center justify-end space-x-6'>
				{!isAuthed && !isInvited && (
					<>
						<NavLink to='/signup'>Get Started</NavLink>
						<NavLink to='/signin'>Sign In</NavLink>
					</>
				)}
				{isAuthed && (
					<>
						<Tabs />
						<button onClick={handleAccount}>
							<Avatar
								src={authState.userDetails.avatar}
								userName={authState.userDetails.name}
								noBorder
								size='md'
							/>
						</button>
					</>
				)}
			</nav>
		</header>
	)
}

export default Navigation
