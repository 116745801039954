import { useCallback, useEffect, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { Books, IBook } from '../../../../shared/types/book'
import { IDocuments } from '../../../../shared/types/document'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import { useDocumentDrag } from '../../hooks/useDocumentDrag'
import { useGame } from '../../hooks/useGame'
import useRemoteRequestToOpenDocument from '../../hooks/useRemoteRequestToOpenDocument'
import useUser from '../../hooks/useUser'
import useUserLevel from '../../hooks/useUserLevel'
import Book from '../books/Book'
import ListDivider from '../ListDivider'
import ListMessage from '../views/ListMessage'
import DocumentList from './DocumentList'
import useUnpop from './useUnpop'

export type THoverItem = (
	dragIndexPath: number[],
	hoverIndexPath: number[],
) => void

export type TDragItem = {
	id: string
	indexPath: number[]
	children?: TDragItem[]
}

interface DocumentGroup {
	[key: string]: any[]
}

const groupDocumentsByType = (
	documents: any,
	collectionTypes: any[],
): DocumentGroup => {
	const groups: DocumentGroup = {}
	collectionTypes.forEach(type => {
		groups[type.type] = []
	})

	documents.allIds.forEach((id: string) => {
		const doc = documents.byId[id]
		if (groups[doc.type]) {
			groups[doc.type].push(doc)
		}
	})

	return groups
}

interface LibraryForm {
	selection: string
}

const Library = () => {
	const { game, dispatch } = useGame()
	const { _id: gameId, documents, books } = game
	const { control, setValue } = useFormContext<LibraryForm>()
	const { dragIndex, hoverIndex, moveItem, hoverItem } = useDocumentDrag({
		documents,
		dispatch,
	})
	const { userId, role } = useUser()
	const { isAtLeastAdept } = useUserLevel()
	const collectionTypes = useCollectionTypes()
	useUnpop()
	useRemoteRequestToOpenDocument()

	const storageKey = `selectedType_${gameId}`
	const storedType = localStorage.getItem(storageKey)

	useEffect(() => {
		if (storedType) {
			setValue('selection', storedType)
		}
	}, [setValue, storedType])

	const selectedType = useWatch<LibraryForm>({
		control,
		name: 'selection',
		defaultValue: storedType || 'All',
	})

	useEffect(() => {
		localStorage.setItem(storageKey, selectedType)
	}, [selectedType, storageKey])

	const hasAccess = useCallback(
		(items: IDocuments | Books) => {
			const isGM = role === 'gm'
			if (items.allIds.length > 0) {
				const firstItem = items.byId[items.allIds[0]]
				if ((firstItem as IBook).resourceId) {
					if (!isAtLeastAdept()) return false
				}
			}
			return items.allIds.some(itemId => {
				const item = items.byId[itemId]
				const { access, accessList } = item
				const isPublic = access === 'public'
				return isGM || isPublic || accessList?.includes(userId)
			})
		},
		[role, userId, isAtLeastAdept],
	)

	const hasAccessToDocuments = useMemo(
		() => hasAccess(documents),
		[documents, hasAccess],
	)

	const hasAccessToBooks = useMemo(() => hasAccess(books), [books, hasAccess])

	const groupedDocuments = useMemo(
		() => groupDocumentsByType(documents, collectionTypes),
		[documents, collectionTypes],
	)

	const booksList = useMemo(
		() => (
			<div style={{ width: '272px' }}>
				<ul
					className='grid grid-cols-2 gap-2 pb-8'
					style={{
						gridAutoRows: '270px',
					}}
				>
					{!hasAccessToBooks ? (
						<ListMessage title='No Books' />
					) : (
						books.allIds.map(bookId => (
							<li key={bookId} className='relative flex flex-col justify-end'>
								<Book bookId={bookId} />
							</li>
						))
					)}
				</ul>
			</div>
		),
		[books.allIds, hasAccessToBooks],
	)

	return (
		<div className='space-y-6 px-6'>
			{/* Books Section - only show if selectedType is 'All' or 'Books' */}
			{hasAccessToBooks &&
				typeof selectedType === 'string' &&
				(selectedType === 'All' || selectedType === 'Books') && (
					<div>
						<ListDivider text='Books' />
						{booksList}
					</div>
				)}

			{/* Documents Section - only show if selectedType is 'All' or not 'Books' */}
			{hasAccessToDocuments &&
				typeof selectedType === 'string' &&
				selectedType !== 'Books' && (
					<div>
						<DocumentList
							selectedType={selectedType}
							groupedDocuments={groupedDocuments}
							collectionTypes={collectionTypes}
							dragIndex={dragIndex}
							hoverIndex={hoverIndex}
							hoverItem={hoverItem}
							moveItem={moveItem}
						/>
					</div>
				)}

			{/* Show message if no content based on filter */}
			{!(
				(hasAccessToBooks &&
					typeof selectedType === 'string' &&
					(selectedType === 'All' || selectedType === 'Books')) ||
				(hasAccessToDocuments &&
					typeof selectedType === 'string' &&
					selectedType !== 'Books')
			) && <ListMessage title='No Content' />}
		</div>
	)
}

export default Library
