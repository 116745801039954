import { Container } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useContext, useEffect, useRef, useState } from 'react'
import { useGrid } from '../../../contexts/grid'
import ToolsContext from '../../../contexts/tools'
import { useSnapToGrid } from '../../../hooks/useSnapToGrid'
import MeasurementContainer from './MeasurementContainer'

interface Props {
	viewport: PixiViewport
}

export default function MeasurementTool({ viewport }: Props) {
	const { toolsState, dispatchTools } = useContext(ToolsContext)
	const { grid, mapDimensions } = useGrid()
	const snapToGrid = useSnapToGrid()
	const containerRef = useRef<PIXI.Container>(null)
	const [cursorPoint, setCursorPoint] = useState<{
		x: number
		y: number
	} | null>(null)

	function handlePointerMove(e: PIXI.FederatedPointerEvent) {
		if (toolsState.measure.isClosed) return

		const point = viewport?.toWorld(e.global.x, e.global.y)
		if (!point) return

		const snappedPoint = grid.enabled ? snapToGrid(point.x, point.y) : point
		setCursorPoint(snappedPoint)
	}

	function handleKeyDown(e: KeyboardEvent) {
		if (e.key === 'Escape') {
			if (!toolsState.measure.isClosed) {
				// Just close/finalize the measurement
				dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: true })
				setCursorPoint(null)
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [])

	if (toolsState.mode !== 'measure') return null

	// Combine measurement points with cursor point for preview
	const allPoints =
		cursorPoint &&
		toolsState.measure.points.length > 0 &&
		!toolsState.measure.isClosed
			? [...toolsState.measure.points, cursorPoint]
			: toolsState.measure.points

	return (
		<Container
			ref={containerRef}
			x={0}
			y={0}
			hitArea={
				new PIXI.Rectangle(0, 0, mapDimensions.width, mapDimensions.height)
			}
			eventMode={toolsState.mode === 'measure' ? 'static' : 'none'}
			pointermove={handlePointerMove}
		>
			<MeasurementContainer
				points={allPoints}
				viewport={viewport}
				isClosed={toolsState.measure.isClosed}
				onPointAdded={point => {
					dispatchTools({
						type: 'ADD_MEASURE_POINT',
						payload: point,
					})
				}}
				onMeasurementClosed={() => {
					dispatchTools({ type: 'SET_MEASURE_CLOSED', payload: true })
					setCursorPoint(null)
				}}
				onPointUpdated={(index, point) => {
					dispatchTools({
						type: 'UPDATE_MEASURE_POINT',
						payload: { index, point },
					})
				}}
			/>
		</Container>
	)
}
