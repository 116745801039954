import { X } from 'lucide-react'
import { createContext, useCallback, useContext, useState } from 'react'
import { animated, useTransition } from 'react-spring'
import { twMerge } from 'tailwind-merge'

export interface Toast {
	id: string
	title?: string
	message: string
	type: 'error' | 'warning' | 'success'
	requireDismiss?: boolean
	actions?: Array<{
		label: string
		onClick: () => void
	}>
}

export interface ToastContextType {
	addToast: (toast: Toast) => void
	removeToast: (id: string) => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export function ToastProvider({ children }: { children: React.ReactNode }) {
	const [toasts, setToasts] = useState<Toast[]>([])

	const transitions = useTransition(toasts, {
		from: { opacity: 0, y: 20 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, x: 100 },
		config: { tension: 300, friction: 20 },
		keys: toast => toast.id,
	})

	const addToast = useCallback((toast: Toast) => {
		setToasts(prev => [...prev, toast])

		// Only auto-remove if not requiring dismissal
		if (!toast.requireDismiss) {
			setTimeout(() => {
				setToasts(prev => prev.filter(t => t.id !== toast.id))
			}, 10000) // 10 seconds
		}
	}, [])

	const removeToast = useCallback((id: string) => {
		setToasts(prev => prev.filter(t => t.id !== id))
	}, [])

	return (
		<ToastContext.Provider value={{ addToast, removeToast }}>
			{children}
			{/* Toast Container */}
			<div className='fixed bottom-4 right-4 z-50 flex flex-col gap-2'>
				{transitions((style, toast) => (
					<animated.div
						style={style}
						className={twMerge(
							'flex flex-col gap-2 rounded-lg px-4 py-3 text-sm shadow-lg',
							toast.type === 'error' && 'bg-red-500/90 text-white',
							toast.type === 'warning' && 'bg-yellow-500/90 text-white',
							toast.type === 'success' && 'bg-zinc-800/90 text-white',
						)}
					>
						<div className='flex items-center justify-between gap-2'>
							<div className='flex flex-col'>
								{toast.title && (
									<span className='font-semibold'>{toast.title}</span>
								)}
								<span>{toast.message}</span>
							</div>
							<button
								onClick={() => removeToast(toast.id)}
								className='ml-2 rounded-full p-1 hover:bg-black/10'
							>
								<X size={14} />
							</button>
						</div>
						{toast.actions && (
							<div className='mt-1 flex gap-2'>
								{toast.actions.map((action, index) => (
									<button
										key={index}
										onClick={() => {
											action.onClick()
											removeToast(toast.id)
										}}
										className='rounded bg-black/10 px-2 py-1 text-xs hover:bg-black/20'
									>
										{action.label}
									</button>
								))}
							</div>
						)}
					</animated.div>
				))}
			</div>
		</ToastContext.Provider>
	)
}

export function useToast() {
	const context = useContext(ToastContext)
	if (!context) {
		throw new Error('useToast must be used within a ToastProvider')
	}
	return context
}

export default ToastProvider
