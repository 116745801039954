import React, {
	createContext,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react'
import { PanelSystemRef } from '../components/panels/types'

interface PanelState {
	id: string
	isOpen: boolean
	size?: number
}

interface NotificationState {
	lastCleared: number
	manuallyCleared: boolean
}

interface PanelSystemContextValue {
	panelSystemRef: React.RefObject<PanelSystemRef>
	panels: Record<string, PanelState>
	notifications: Record<string, NotificationState>
	openPanel: (id: string) => void
	closePanel: (id: string) => void
	resizePanel: (id: string, size: number) => void
	clearNotifications: (panelId: string) => void
}

const PanelSystemContext = createContext<PanelSystemContextValue | null>(null)

export const PanelSystemProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const panelSystemRef = useRef<PanelSystemRef>(null)

	const [panels, setPanels] = useState<Record<string, PanelState>>({
		chat: { id: 'chat', isOpen: false, size: 50 },
		documents: { id: 'documents', isOpen: false, size: 50 },
		turnTracker: { id: 'turnTracker', isOpen: false, size: 50 },
	})

	const [notifications, setNotifications] = useState<
		Record<string, NotificationState>
	>({
		chat: { lastCleared: 0, manuallyCleared: false },
	})

	const openPanel = useCallback((id: string) => {
		setPanels(prev => ({
			...prev,
			[id]: { ...prev[id], isOpen: true },
		}))
	}, [])

	const closePanel = useCallback((id: string) => {
		setPanels(prev => ({
			...prev,
			[id]: { ...prev[id], isOpen: false },
		}))
	}, [])

	const resizePanel = useCallback((id: string, size: number) => {
		setPanels(prev => ({
			...prev,
			[id]: { ...prev[id], size },
		}))
	}, [])

	const clearNotifications = useCallback((panelId: string) => {
		setNotifications(prev => ({
			...prev,
			[panelId]: {
				lastCleared: Date.now(),
				manuallyCleared: false,
			},
		}))
	}, [])

	return (
		<PanelSystemContext.Provider
			value={{
				panelSystemRef,
				panels,
				notifications,
				openPanel,
				closePanel,
				resizePanel,
				clearNotifications,
			}}
		>
			{children}
		</PanelSystemContext.Provider>
	)
}

export const usePanelSystem = () => {
	const context = useContext(PanelSystemContext)
	if (!context) {
		throw new Error('usePanelSystem must be used within a PanelSystemProvider')
	}
	return context
}
