import { Container, Sprite, useApp } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useEffect, useState } from 'react'
import { IFogOperation } from '../../../../shared/types/fog'
import useUser from '../../hooks/useUser'

interface Props {
	width: number
	height: number
	operations: IFogOperation[]
	resolution?: number
}

const Fog = ({ width, height, operations, resolution = 1 }: Props) => {
	const app = useApp()
	const { isGM } = useUser()
	const [fogTexture, setFogTexture] = useState<PIXI.RenderTexture | null>(null)

	useEffect(() => {
		if (!app || width <= 0 || height <= 0) {
			setFogTexture(null)
			return
		}

		// Create the base render texture
		const baseRT = PIXI.RenderTexture.create({
			width,
			height,
			resolution,
		})

		// Process operations in the exact order given
		operations.forEach(op => {
			const g = new PIXI.Graphics()

			if (op.type === 'hide') {
				// Draw black polygon to hide areas
				g.beginFill(0x000000, op.alpha ?? 1)
			} else if (op.type === 'show') {
				// ERASE polygon to reveal areas
				g.blendMode = PIXI.BLEND_MODES.ERASE
				g.beginFill(0xffffff) // color doesn't matter for ERASE
			} else {
				// Unknown operation type (if any) - skip
				return
			}

			const polygonPoints = op.points.flatMap(p => [p.x, p.y])
			g.drawPolygon(polygonPoints)
			g.endFill()

			// Render this operation onto the baseRT
			app.renderer.render(g, { renderTexture: baseRT, clear: false })
		})

		setFogTexture(baseRT)
	}, [app, width, height, operations, resolution, isGM])

	return (
		<Container alpha={isGM ? 0.5 : 1} eventMode='none'>
			{fogTexture && <Sprite texture={fogTexture} eventMode='none' />}
		</Container>
	)
}

export default Fog
