import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ISceneValues } from '../../../../../shared/types/scene'
import GameContext from '../../../contexts/game'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import AssetManager from '../../AssetManager'
import SceneName from '../../Library/SceneEditor/SceneName'
import SectionDivider from '../../SectionDivider'
import EffectsOverlaySelector from '../../interface/EffectsOverlaySelector'

export default function SceneEditorContent() {
	const { dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()

	const form = useForm<ISceneValues>({
		defaultValues: activeScene?.values,
	})

	const values = useWatch({
		control: form.control,
	})

	const handleUpdate = () => {
		if (!activeScene || !values) return

		const updatedDocument = {
			...activeScene,
			version: activeScene.version + 1,
			values: {
				...activeScene.values,
				...values,
				coverId: values.coverId || activeScene.values.coverId,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: { updatedDocument },
		})
	}

	useEffect(() => {
		handleUpdate()
	}, [values])

	const handleAssetChange = (asset?: any) => {
		if (!asset) return
		form.setValue('coverId', asset._id)
	}

	if (!activeScene) return null

	return (
		<FormProvider {...form}>
			<div className='w-80 p-4'>
				<SectionDivider label='Scene' />
				<div className='space-y-2'>
					<SceneName doc={activeScene} />

					<AssetManager
						className='mx-auto rounded-lg'
						assetId={activeScene.values.coverId}
						onAssetChange={handleAssetChange}
					/>

					<EffectsOverlaySelector doc={activeScene} />
				</div>
			</div>
		</FormProvider>
	)
}
