import type { Identifier } from 'dnd-core'
import { useContext, useEffect, useRef, useState } from 'react'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import { IScene } from '../../../../shared/types/scene'
import DocumentsContext from '../../contexts/documents'
import { DragStateContext } from '../../contexts/dragdrop'
import GameContext from '../../contexts/game'
import WindowsContext from '../../contexts/windows'
import useGetDocumentById from '../../hooks/useGetDocumentById'
import useItemFilter from '../../hooks/UseItemFilter'
import useUser from '../../hooks/useUser'
import { hasSpecificClassInEventPath } from '../../utils/hasSpecificClassInEventPath'
import ItemTypes from '../draganddrop/ItemTypes'
import ItemInformation from './ItemInformation'
import { TDragItem } from './Library'
import SceneDocument from './SceneDocument'
import useItemHover from './useItemHover'

type Props = {
	id: string
	dragIndex: number
	hoverIndex: number
	hoverItem: any
	moveItem: any
	selectedType: string
}

export default function Document({
	id,
	dragIndex,
	hoverIndex,
	hoverItem,
	moveItem,
	selectedType,
}: Props) {
	const { game } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const { dispatchWindows } = useContext(WindowsContext)
	const { dragDispatch } = useContext(DragStateContext)
	const { document } = useGetDocumentById(id)
	const ref = useRef<HTMLDivElement>()
	const { checkAccess } = useItemFilter()
	const hasAccess = checkAccess(document)
	const [hoverDirection, setHoverDirection] = useState<'up' | 'down' | null>(
		null,
	)
	const isVisible = selectedType === document.type || selectedType === 'all'
	const { isHovered, ...hoverEvents } = useItemHover()
	const { isGM } = useUser()

	const docIndex = game.documents.allIds.findIndex(
		documentId => documentId === id,
	)
	const shiftUp = dragIndex <= docIndex && docIndex < hoverIndex
	const shiftDown = hoverIndex <= docIndex && docIndex <= dragIndex

	const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
		// If the click is on the dropdown menu trigger, don't open the document
		const targetIsDropdownMenu = hasSpecificClassInEventPath(
			e.nativeEvent,
			'dropdownMenu',
			ref.current,
		)

		if (targetIsDropdownMenu) {
			e.stopPropagation()
			return
		}

		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: {
				documentId: id,
			},
		})

		dispatchWindows({
			type: 'MOVE_WINDOW_TO_FRONT',
			payload: {
				documentId: id,
			},
		})
	}

	const handleHover = (dragItem: TDragItem, monitor: DropTargetMonitor) => {
		if (!ref.current) return

		const dragId = dragItem.id
		// const dropId = id

		// Child is being hovered
		if (!monitor.isOver({ shallow: true })) return

		// Don't replace items with themselves
		// if (dragId === dropId) return

		// Determine rectangle on screen
		const hoverRect = ref.current?.getBoundingClientRect()

		// Determine mouse position
		const clientOffset = monitor.getClientOffset()

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverRect.top

		// Get vertical middle
		const hoverMiddleY = hoverRect.height / 2

		let direction: 'up' | 'down' | null = 'up'

		// When dragging up, only move when the cursor is above 50%
		if (hoverClientY < hoverMiddleY) direction = 'up'

		// When dragging down, only move when the cursor is below 50%
		if (hoverClientY >= hoverMiddleY) direction = 'down'

		setHoverDirection(direction)
		hoverItem(dragId, id, direction)
	}

	const handleDrop = (dragItem: TDragItem) => {
		if (!ref.current) return

		const dragId = dragItem.id
		const dropId = id

		// do not drop on self
		if (dragId === dropId) return

		console.log('drop', hoverDirection)

		moveItem({
			id: dragId,
			targetId: dropId,
			parentId: document.parentId,
			before: hoverDirection === 'up',
		})

		setHoverDirection(null)
	}

	const [{ handlerId }, drop] = useDrop<
		TDragItem,
		void,
		{
			handlerId: Identifier | void
			isOver: boolean
		}
	>(
		() => ({
			accept: [ItemTypes.DOCUMENT, ItemTypes.FOLDER],
			hover: handleHover,
			drop: handleDrop,
			collect: monitor => ({
				isOver: !!monitor.isOver({ shallow: true }),
				handlerId: monitor.getHandlerId(),
			}),
		}),
		[id, hoverDirection],
	)

	const handleEnd = () => {
		setHoverDirection(null)
		hoverItem(null, null)
	}

	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: ItemTypes.DOCUMENT,
			item: () => ({ id }),
			end: handleEnd,
			collect: monitor => ({
				isDragging: !!monitor.isDragging(),
			}),
		}),
		[id],
	)

	useEffect(() => {
		if (dragDispatch === undefined) return
		dragDispatch({
			type: 'SET_DRAGGING',
			payload: isDragging,
		})
	}, [isDragging, dragDispatch])

	if (isGM) drag(drop(ref))

	const RenderContent = () => {
		if (document.type === 'scene' || document.type === 'map')
			return (
				<SceneDocument
					document={document as IScene}
					className={twMerge(
						'px-2',
						!isDragging && 'transition-transform duration-200 ease-in-out',
						isDragging && 'opacity-0',
						shiftDown ? 'translate-y-full' : '',
						shiftUp ? '-translate-y-full' : '',
					)}
					showMenuButton={isHovered}
				/>
			)
		else
			return (
				<ItemInformation
					document={document}
					className={twMerge(
						'px-2',
						!isDragging && 'transition-transform duration-200 ease-in-out',
						isDragging && 'opacity-0',
						shiftDown ? 'translate-y-full' : '',
						shiftUp ? '-translate-y-full' : '',
					)}
					showMenuButton={isHovered}
				/>
			)
	}

	return (
		<div
			ref={ref}
			onClick={handleOpen}
			data-handler-id={handlerId}
			className={twMerge(
				'flex cursor-pointer items-center justify-between rounded-md border-t border-b border-transparent hover:bg-gray-500/10',
				isVisible && hasAccess ? '' : 'hidden',
			)}
			{...hoverEvents}
		>
			{RenderContent()}
		</div>
	)
}
