import { useContext } from 'react'
import { BookResource } from '../../../shared/types/resources'
import { ResourceContext } from '../contexts/resources'
import useApi from './useApi'

const useUpdateResource = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const { request, loading, error } = useApi()

	const updateResource = async (
		resourceId: string,
		metadata: Partial<BookResource['metadata']>,
	) => {
		try {
			const updatedResource = await request(`/api/resource/${resourceId}`, {
				method: 'PUT',
				body: JSON.stringify({
					metadata,
				}),
			})

			// Update local state with the correct payload structure
			dispatchResource({
				type: 'UPDATE_RESOURCE',
				payload: {
					resource: updatedResource,
				},
			})

			return updatedResource
		} catch (error) {
			console.error('Error updating resource:', error)
			throw error
		}
	}

	return { updateResource, loading, error }
}

export default useUpdateResource
