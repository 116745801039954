import { useGame } from '../../../hooks/useGame'
import useGetActiveScene from '../../../hooks/useGetActiveScene'

export function useUpdateGrid() {
	const { dispatch } = useGame()
	const scene = useGetActiveScene()

	const updateGrid = ({
		x,
		y,
		size,
	}: {
		x?: number
		y?: number
		size?: number
	}) => {
		if (!scene) return

		const updatedDocument = {
			...scene,
			version: scene.version + 1,
			values: {
				...scene.values,
				grid: {
					...scene.values.grid,
					...(x !== undefined && { x }),
					...(y !== undefined && { y }),
					...(size !== undefined && { size }),
				},
			},
		}

		dispatch({ type: 'UPDATE_DOCUMENT', payload: { updatedDocument } })
	}

	return { updateGrid }
}
