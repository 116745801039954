const Footer = () => {
	const isNotFrontsite =
		location.pathname.includes('/game/') ||
		location.pathname.includes('/book/') ||
		location.pathname.includes('/document/') ||
		location.pathname.includes('/resource/') ||
		location.pathname.includes('/sheet/')

	if (isNotFrontsite) return null

	const currentYear = new Date().getFullYear()

	return (
		<div className='business-info my-12 text-center text-sm text-gray-600'>
			<p>&copy; {currentYear} Realms Inc. All rights reserved.</p>
			<p>
				<a href='/terms-of-service/' className='underline'>
					Terms of Service
				</a>{' '}
				•{' '}
				<a href='/privacy-policy/' className='underline'>
					Privacy Policy
				</a>
			</p>
		</div>
	)
}

export default Footer
