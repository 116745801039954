/*
 * This component is used to display an asset (image or video)
 * It can be used both in game or out of game.
 * If it has the game context it will use it to find the asset (in game)
 * Otherwise it will query the server for the asset (e.g. on a profile page)
 */
import { useLazyQuery } from '@apollo/client'
import {
	CSSProperties,
	FC,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import { IAsset } from '../../../shared/types/asset'
import GameContext from '../contexts/game'
import { GET_ASSET } from '../graphql/games'

interface AssetProps {
	assetId: string
	gameId?: string
	width?: string
	height?: string
	className?: string
	style?: CSSProperties
	alt?: string
}

const Asset: FC<AssetProps> = ({
	assetId,
	width = 'auto',
	height = 'auto',
	className,
	style,
	alt,
	gameId,
}) => {
	const { game } = useContext(GameContext)
	const [asset, setAsset] = useState<IAsset | null>(null)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)

	const [loadAssetFromServer] = useLazyQuery(GET_ASSET, {
		variables: {
			gameId,
			assetId,
		},
		onCompleted: data => {
			if (data.getAsset) {
				setAsset(data.getAsset)
			} else {
				setErrorMessage(`Asset ID ${assetId} not found for game ID ${gameId}.`)
			}
		},
		onError: () => {
			setErrorMessage('An error occurred while fetching the asset.')
		},
	})

	const findAsset = useCallback(() => {
		const foundAsset = game?.assets?.byId?.[assetId] ?? null

		if (foundAsset) {
			setAsset(foundAsset)
		} else {
			loadAssetFromServer()
		}
	}, [game?.assets, loadAssetFromServer, assetId])

	useEffect(findAsset, [assetId, findAsset, game])

	if (errorMessage) {
		console.log('[Asset] Threw an error:', errorMessage)
	}

	if (!asset) return null

	return (
		<div
			style={{
				position: 'absolute',
				inset: 0,
				overflow: 'hidden',
				width,
				height,
				...style,
			}}
			className={twMerge('flex items-center justify-center', className)}
		>
			{asset.filetype.includes('image') && (
				<img
					alt={alt || ''}
					src={asset.fileurl}
					className='max-h-full max-w-full object-contain'
					style={{ margin: 'auto' }}
				/>
			)}
			{asset.filetype.includes('video') && (
				<video
					autoPlay
					loop
					muted
					playsInline
					src={asset.fileurl}
					className='max-h-full max-w-full object-contain'
					style={{ margin: 'auto' }}
				/>
			)}
		</div>
	)
}

export default Asset
