import { Hexagon, Square } from 'lucide-react'
import Slider from '../../../interface/Slider'

interface MapGridTypeProps {
	type: 'square' | 'vhex' | 'hhex'
	onChange: (type: 'square' | 'vhex' | 'hhex') => void
}

const MapGridType = ({ type, onChange }: MapGridTypeProps) => {
	const options = [
		{
			value: 'square',
			icon: <Square className='h-5 w-5' />,
		},
		{
			value: 'vhex',
			icon: <Hexagon className='h-5 w-5' />,
		},
		{
			value: 'hhex',
			icon: <Hexagon className='h-5 w-5 rotate-90' />,
		},
	] as const

	const currentIndex = options.findIndex(option => option.value === type)

	return (
		<Slider
			value={currentIndex}
			onChange={index => onChange(options[index].value)}
			values={[0, 1, 2]}
			mode='tabs'
			renderValue={index => options[index]?.icon}
		/>
	)
}

export default MapGridType
