import { useContext } from 'react'
import GameContext from '../../../contexts/game'
import useGetUserById from '../../../hooks/useGetUserById'
import MenuItem from './MenuItem'

type Props = {
	userId: string
}

export default function ToggleGMMenuItem({ userId }: Props) {
	const { game, dispatch } = useContext(GameContext)
	const { user } = useGetUserById(userId)
	const isCurrentlyGM = user?.userSettings?.role === 'gm'

	// Check if this user is the game creator
	const isTargetUserCreator =
		typeof game.creator !== 'string' && game.creator._id === userId

	// Don't render the menu item if we're trying to modify the creator's status
	if (isTargetUserCreator) {
		return null
	}

	const handleSelect = () => {
		dispatch({
			type: 'UPDATE_USER_SETTINGS',
			payload: {
				userId,
				userSettings: {
					role: isCurrentlyGM ? 'player' : 'gm',
				},
			},
		})
	}

	return (
		<MenuItem onSelect={handleSelect}>
			{isCurrentlyGM ? 'Remove GM Status' : 'Make GM'}
		</MenuItem>
	)
}
