import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import { ChatContent, ChatFooter } from '../chat/ChatPanel'
import { DocumentsContent, DocumentsHeader } from '../Library/DocumentsPanel'
import SectionDivider from '../SectionDivider'
import AddTurnOrderItemButton from '../turnTracker/AddTurnOrderItemButton'
import GMTurnTracker from '../turnTracker/GMTurnTracker'
import TurnTrackerControls from '../turnTracker/TurnTrackerControls'
import { PanelConfig } from './types'

const TurnTrackerHeader = () => (
	<div className='flex flex-col gap-2'>
		<SectionDivider label='Turn Tracker'>
			<AddTurnOrderItemButton />
		</SectionDivider>
	</div>
)

const TurnTrackerContent = () => <GMTurnTracker />

export const usePanelConfig = () => {
	const { isGM } = useUser()
	const { game, dispatch } = useGame()

	const panels: PanelConfig[] = [
		{
			id: 'turnTracker',
			header: <TurnTrackerHeader />,
			footer: <TurnTrackerControls />,
			content: <TurnTrackerContent />,
			visibilityCheck: () => isGM,
			onToggle: () => {
				const isShowing = game.turnTracker.show
				const isRunning = game.turnTracker.currentRound > 0

				if (isShowing) {
					if (isRunning) {
						const confirmHide = window.confirm(
							'Hiding the turn tracker will stop it and reset the round counter. Are you sure?',
						)
						if (!confirmHide) return false
					}
					dispatch({ type: 'HIDE_TURN_TRACKER' })
					if (isRunning) {
						dispatch({ type: 'STOP_TURN_TRACKER' })
					}
				} else {
					dispatch({ type: 'SHOW_TURN_TRACKER' })
				}
				return true
			},
		},
		{
			id: 'documents',
			header: <DocumentsHeader />,
			footer: null,
			content: <DocumentsContent />,
		},
		{
			id: 'chat',
			header: null,
			footer: <ChatFooter />,
			content: <ChatContent />,
		},
	]

	return panels
}
