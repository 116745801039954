import { Container, Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { FC } from 'react'

interface Props {
	progress: number
}

const ProgressLoader: FC<Props> = ({ progress }) => {
	const drawProgressBar = (g: PIXI.Graphics) => {
		const centerX = -2.5
		const centerY = -2.5
		const radius = 2.5
		const arcWidth = 1
		const startAngle = -Math.PI / 2 // Start from top
		const progressValue = progress / 100
		const segments = 32 // Number of segments in the circle

		// Clear previous drawing
		g.clear()

		// Draw background segments
		for (let i = 0; i < segments; i++) {
			const segmentAngle = (Math.PI * 2) / segments
			const segmentStart = startAngle + i * segmentAngle
			g.lineStyle({
				width: arcWidth,
				color: 0x333333,
				alpha: 0.5,
				cap: PIXI.LINE_CAP.ROUND,
			})
			g.arc(
				centerX,
				centerY,
				radius,
				segmentStart,
				segmentStart + segmentAngle * 0.8,
			)
		}

		// Draw progress segments
		const progressSegments = Math.floor(segments * progressValue)
		for (let i = 0; i < progressSegments; i++) {
			const segmentAngle = (Math.PI * 2) / segments
			const segmentStart = startAngle + i * segmentAngle
			g.lineStyle({
				width: arcWidth,
				color: 0xffffff,
				alpha: 1,
				cap: PIXI.LINE_CAP.ROUND,
			})
			g.arc(
				centerX,
				centerY,
				radius,
				segmentStart,
				segmentStart + segmentAngle * 0.8,
			)
		}
	}

	return (
		<Container position={[0, 0]}>
			<Graphics draw={drawProgressBar} />
		</Container>
	)
}

export default ProgressLoader
