import {
	defineHex,
	Grid as HoneycombGrid,
	OffsetCoordinates,
	Orientation,
} from 'honeycomb-grid'
import { Viewport as PixiViewport } from 'pixi-viewport'
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from 'react'
import { IMapGrid } from '../../../shared/types/grid'
import {
	defineGrid,
	SquareGrid,
} from '../components/canvas/grid/square/core/Grid'
import useGetActiveScene from '../hooks/useGetActiveScene'
import { createHexGrid } from '../utils/gridUtils'
import GameContext from './game'
const DEFAULT_GRID: IMapGrid = {
	type: 'square',
	size: 50,
	unitSize: 5,
	unit: 'ft',
	enabled: false,
	color: '#CCCCCC',
	alpha: 0.5,
	x: 0,
	y: 0,
}

type HexFactory = ReturnType<typeof defineHex>
export type HexInstance = InstanceType<HexFactory>
export type HexGrid = HoneycombGrid<HexInstance>
export type InteractionMode = 'none' | 'offset' | 'resize'
export type PixelPoint = { x: number; y: number }
export type AnchorCorner = {
	col: number
	row: number
	index: number
}

interface GridContextType {
	grid: IMapGrid
	getCellSize: () => number
	hexGrid: HexGrid | null
	hexDefinition: HexFactory | null
	squareGrid: SquareGrid | null
	mapDimensions: { width: number; height: number }
	gridDimensions: { width: number; height: number }
	viewport: PixiViewport | null
	setViewport: (viewport: PixiViewport | null) => void
	pauseViewport: () => void
	resumeViewport: () => void
	anchorHex: OffsetCoordinates | null
	setAnchorHex: (hex: OffsetCoordinates | null) => void
	dragHex: React.MutableRefObject<OffsetCoordinates | null>
	hoveredHex: OffsetCoordinates | null
	setHoveredHex: (hex: OffsetCoordinates | null) => void
	anchorCorner: AnchorCorner | null
	setAnchorCorner: (corner: AnchorCorner | null) => void
	interactionMode: InteractionMode
	setInteractionMode: (mode: InteractionMode) => void
	pointerDownPos: PixelPoint | null
	setPointerDownPos: (pos: PixelPoint | null) => void
}

export const GridContext = createContext<GridContextType | null>(null)

export function GridProvider({ children }: { children: ReactNode }) {
	const { game } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const systemGrid = game?.system?.grid
	const [viewport, setViewport] = useState<PixiViewport | null>(null)
	const [hoveredHex, setHoveredHex] = useState<OffsetCoordinates | null>(null)
	const [anchorCorner, setAnchorCorner] = useState<AnchorCorner | null>(null)
	const [interactionMode, setInteractionMode] =
		useState<InteractionMode>('none')
	const [pointerDownPos, setPointerDownPos] = useState<PixelPoint | null>(null)
	const [anchorHex, setAnchorHex] = useState<OffsetCoordinates | null>(null)
	const dragHexRef = useRef<OffsetCoordinates | null>(null)

	const grid = {
		...DEFAULT_GRID,
		...systemGrid,
		...activeScene?.values?.grid,
	}

	// Calculate map dimensions
	const mapDimensions = useMemo(() => {
		const mapAsset = game?.assets?.byId[activeScene?.values?.mapId]
		if (!mapAsset) return { width: 100, height: 100 } // default fallback

		const aspectRatio = mapAsset.width / mapAsset.height
		if (mapAsset.width >= mapAsset.height) {
			return {
				width: 100,
				height: 100 / aspectRatio,
			}
		} else {
			return {
				width: 100 * aspectRatio,
				height: 100,
			}
		}
	}, [activeScene?.values?.mapId, game?.assets])

	const gridDimensions = useMemo(() => {
		return {
			width: mapDimensions.width * 3,
			height: mapDimensions.height * 3,
		}
	}, [mapDimensions])

	const hexDefinition = useMemo(() => {
		if (grid.type === 'square') return null

		const orientation =
			grid.type === 'vhex' ? Orientation.POINTY : Orientation.FLAT

		return defineHex({
			dimensions: grid.size / 2,
			orientation,
			origin: 'topLeft',
		})
	}, [grid.type, grid.size])

	const hexGrid = useMemo(() => {
		if (!hexDefinition) return null
		const { grid: hexGridResult } = createHexGrid(
			grid.size,
			grid.type,
			gridDimensions,
		)
		return hexGridResult
	}, [hexDefinition, gridDimensions, grid.type, grid.size])

	const getCellSize = useCallback(() => {
		if (grid.type === 'square') {
			return grid.size
		} else {
			return grid.size
		}
	}, [grid.type, grid.size])

	const pauseViewport = useCallback(() => {
		if (viewport) {
			viewport.plugins.pause('drag')
			viewport.plugins.pause('wheel')
			viewport.plugins.pause('pinch')
			viewport.plugins.pause('decelerate')
		}
	}, [viewport])

	const resumeViewport = useCallback(() => {
		if (viewport) {
			viewport.plugins.resume('drag')
			viewport.plugins.resume('wheel')
			viewport.plugins.resume('pinch')
			viewport.plugins.resume('decelerate')
		}
	}, [viewport])

	const squareGrid = useMemo(() => {
		if (grid.type !== 'square') return null
		return defineGrid({ size: grid.size })
	}, [grid.type, grid.size])

	return (
		<GridContext.Provider
			value={{
				grid,
				getCellSize,
				hexGrid,
				hexDefinition,
				squareGrid,
				mapDimensions,
				gridDimensions,
				viewport,
				setViewport,
				pauseViewport,
				resumeViewport,
				anchorHex,
				setAnchorHex,
				dragHex: dragHexRef,
				hoveredHex,
				setHoveredHex,
				anchorCorner,
				setAnchorCorner,
				interactionMode,
				setInteractionMode,
				pointerDownPos,
				setPointerDownPos,
			}}
		>
			{children}
		</GridContext.Provider>
	)
}

export function useGrid() {
	const context = useContext(GridContext)
	if (!context) {
		throw new Error('useGrid must be used within a GridProvider')
	}
	return context
}
