import { forwardRef, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import HeaderTitle from './HeaderTitle'

interface IProps {
	title: React.ReactNode
	children?: React.ReactNode
	ref?: React.Ref<HTMLDivElement>
	className?: string
	headerIcon?: ReactNode
	titleId?: string
	headerClassName?: string
}

const Header = forwardRef<HTMLDivElement, IProps>(
	(
		{ title, children, headerIcon, className, titleId, headerClassName },
		ref,
	) => {
		return (
			<div
				data-dragtype='move'
				className={twMerge(
					'sticky top-0 z-10 flex items-center py-3 px-3 text-sm text-black',
					className,
				)}
				ref={ref}
				style={{
					touchAction: 'none',
					minHeight: '50px',
				}}
			>
				<div
					className={twMerge(
						'mr-2 flex flex-1 truncate',
						headerIcon ? '' : 'pl-2',
						className,
					)}
				>
					{headerIcon && (
						<div className='mr-2 inline-block align-middle'>{headerIcon}</div>
					)}
					<HeaderTitle titleId={titleId} className={headerClassName}>
						{title}
					</HeaderTitle>
				</div>
				<div className='ml-auto flex gap-1'>{children}</div>
			</div>
		)
	},
)

export default Header
