import { useContext } from 'react'
import ToolsContext from '../contexts/tools'

export function useGridMode() {
	const { toolsState, dispatchTools } = useContext(ToolsContext)

	const activateGridMode = () => {
		dispatchTools({ type: 'SET_MODE', payload: 'grid' })
	}

	const deactivateGridMode = () => {
		dispatchTools({ type: 'SET_MODE', payload: 'select' })
	}

	return {
		isGridMode: toolsState.mode === 'grid',
		activateGridMode,
		deactivateGridMode,
	}
}
