import * as PIXI from 'pixi.js'
import { useRef } from 'react'
import { useGrid } from '../../../contexts/grid'
import { clampGridOffset, toWorldCoords } from '../../../utils/gridUtils'

interface OffsetState {
	startX: number
	startY: number
	initialOffsetX: number
	initialOffsetY: number
}

export function useOffset() {
	const { grid, viewport, mapDimensions } = useGrid()
	const offsetRef = useRef<OffsetState>({
		startX: 0,
		startY: 0,
		initialOffsetX: 0,
		initialOffsetY: 0,
	})

	const startOffset = (e: PIXI.FederatedPointerEvent) => {
		const worldPos = toWorldCoords(e, viewport)
		if (!worldPos) return

		offsetRef.current = {
			startX: worldPos.x,
			startY: worldPos.y,
			initialOffsetX: grid.x,
			initialOffsetY: grid.y,
		}
	}

	const moveOffset = (e: PIXI.FederatedPointerEvent) => {
		if (!viewport) return

		const worldPos = toWorldCoords(e, viewport)
		if (!worldPos) return

		const dx = worldPos.x - offsetRef.current.startX
		const dy = worldPos.y - offsetRef.current.startY
		const rawOffset = new PIXI.Point(
			offsetRef.current.initialOffsetX + dx,
			offsetRef.current.initialOffsetY + dy,
		)

		return clampGridOffset(rawOffset, mapDimensions)
	}

	return { startOffset, moveOffset }
}
