import { FC, useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { BookResource } from '../../../../shared/types/resources'
import { useGame } from '../../hooks/useGame'
import useProcessBook from '../../hooks/useProcessBook'
import Button from '../FormComponents/Button'
import XsType from '../interface/text/XsType'
import SectionDivider from '../SectionDivider'
import PDFSheetRow from './PDFSheetRow'

interface Props {
	setEnableClickOutside?: (enable: boolean) => void
}

const SettingsSheets: FC<Props> = ({ setEnableClickOutside }) => {
	const { game, dispatch } = useGame()
	const [configuring, setConfiguring] = useState<string | null>(null)
	const [configuringResource, setConfiguringResource] =
		useState<BookResource | null>(null)
	const [isUploading, setIsUploading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const { processBook } = useProcessBook()
	const [isDragging, setIsDragging] = useState(false)

	useEffect(() => {
		console.log('State changed - configuring:', configuring)
		console.log('State changed - configuringResource:', configuringResource)
	}, [configuring, configuringResource])

	const handleClick = () => {
		setError(null)
		fileInputRef.current?.click()
	}

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0]
		if (!file) return

		if (file.type !== 'application/pdf') {
			setError('Please select a PDF file')
			return
		}

		try {
			setEnableClickOutside?.(false)
			setIsUploading(true)
			setError(null)
			console.log('Processing PDF:', file.name)
			const { resource } = await processBook(file, {
				generateEmbeddings: false,
				generateSummary: false,
				skipAIMetadata: true,
				resourceType: 'sheet',
				skipDuplicateCheck: true,
			})
			console.log('Processed PDF:', {
				resourceId: resource._id,
				metadata: resource.metadata,
				formFields: resource.metadata?.pdf?.formFields,
			})

			const formFields = resource.metadata?.pdf?.formFields
			if (!formFields || formFields.length === 0) {
				alert(
					'This PDF does not contain any form fields. Only form-fillable PDFs can be used as PDF sheets.',
				)
				return
			}

			setConfiguring(resource._id)
			console.log('Setting configuring:', resource._id)
			setConfiguringResource(resource)
			console.log('Setting configuringResource:', resource)
		} catch (error) {
			console.error('Error uploading PDF:', error)
			setError(error.message || 'Failed to upload PDF. Please try again.')
		} finally {
			setIsUploading(false)
			if (fileInputRef.current) {
				fileInputRef.current.value = ''
			}
		}
	}

	const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setIsDragging(true)
	}

	const handleDragLeave = () => {
		setIsDragging(false)
	}

	const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		setIsDragging(false)

		const file = e.dataTransfer.files[0]
		if (!file) return

		if (file.type !== 'application/pdf') {
			setError('Please drop a PDF file')
			return
		}

		try {
			setEnableClickOutside?.(false)
			setIsUploading(true)
			setError(null)
			const { resource } = await processBook(file, {
				generateEmbeddings: false,
				generateSummary: false,
				skipAIMetadata: true,
				resourceType: 'sheet',
				skipDuplicateCheck: true,
			})

			const formFields = resource.metadata?.pdf?.formFields
			if (!formFields || formFields.length === 0) {
				alert(
					'This PDF does not contain any form fields. Only form-fillable PDFs can be used as PDF sheets.',
				)
				return
			}

			setConfiguring(resource._id)
			setConfiguringResource(resource)
		} catch (error) {
			console.error('Error uploading PDF:', error)
			setError(error.message || 'Failed to upload PDF. Please try again.')
		} finally {
			setIsUploading(false)
		}
	}

	const handleDelete = async (id: string) => {
		if (
			window.confirm(
				'Are you sure you want to delete this sheet? This will also delete all instances of this sheet in the game.',
			)
		) {
			try {
				setError(null)
				const sheet = game.pdfSheets?.byId[id]
				if (sheet) {
					// First delete all documents of this collection type
					const documentsToDelete = Object.values(game.documents.byId)
						.filter(doc => doc.type === sheet.collection.type)
						.map(doc => doc._id)

					// Delete the documents first
					documentsToDelete.forEach(docId => {
						dispatch({
							type: 'DELETE_DOCUMENT',
							payload: { documentId: docId },
						})
					})

					// Then delete the PDF sheet
					dispatch({
						type: 'REMOVE_PDF_SHEET',
						payload: { id },
					})
				}
			} catch (error) {
				console.error('Error deleting sheet:', error)
				setError(
					'Failed to delete sheet and associated documents. Please try again.',
				)
			}
		}
	}

	return (
		<div>
			<SectionDivider label='Sheets' />

			<p className='mb-6 text-center text-sm text-gray-400'>
				Add editable PDFs as templates for character sheets, NPCs, etc.
			</p>

			<div className='space-y-4'>
				{error && (
					<div className='rounded bg-red-900/50 p-2 text-sm text-red-200'>
						{error}
					</div>
				)}

				{/* List of existing PDF sheets */}
				{game.pdfSheets && (
					<div className='space-y-2'>
						{Object.entries(game.pdfSheets.byId || {}).map(([id, sheet]) => (
							<PDFSheetRow
								key={id}
								id={id}
								resourceId={sheet.resourceId}
								onDelete={handleDelete}
								collection={sheet.collection}
							/>
						))}
					</div>
				)}

				<div
					className={twMerge(
						'rounded-lg border-2 border-dashed border-black/10 p-8 transition-colors',
						isDragging && 'border-white/50 bg-white/5',
					)}
					onDragOver={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<input
						type='file'
						ref={fileInputRef}
						onChange={handleFileChange}
						accept='.pdf'
						className='hidden'
					/>

					<Button
						onClick={handleClick}
						className='bg-gray-800'
						disabled={isUploading}
					>
						{isUploading ? 'Uploading...' : 'Upload PDF Template'}
					</Button>

					<XsType className='mt-2 text-center text-gray-500'>
						Or drop a PDF file here.
					</XsType>
				</div>
			</div>
		</div>
	)
}

export default SettingsSheets
