import { Copy } from 'lucide-react'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import { copyToClipboard } from '../../utils/clipboard'
import Button from '../FormComponents/Button'
import Input from '../FormComponents/Input'
import InviteTokenResetButton from '../interface/InviteTokenResetButton'
import SectionDivider from '../SectionDivider'

interface InvitePlayersProps {
	minimal?: boolean // When true, hides the refresh button and section header
}

export default function InvitePlayers({ minimal = false }: InvitePlayersProps) {
	const { game } = useContext(GameContext)
	const { primary } = useTheme()

	return (
		<div>
			{!minimal && <SectionDivider label='Invite' className='mt-8' />}

			<div className='flex gap-2'>
				<div className='flex w-full'>
					<Input
						type='text'
						className='w-2/4 flex-1 pt-0'
						inputClassName='rounded-r-none text-gray-500 bg-black/5'
						value={`${window.location.href}?inviteToken=${game.inviteToken}`}
						readOnly
					/>
					<Button
						type='button'
						className='flex-0 mt-0 ml-px w-12 rounded-l-none bg-black/5 p-1 leading-4 active:bg-green-100 active:text-green-700'
						onClick={async () => {
							const success = await copyToClipboard(
								`${window.location.href}?inviteToken=${game.inviteToken}`,
							)
							if (success) {
								alert('Invitation link copied to clipboard!')
							} else {
								alert(
									'Failed to copy invitation link. Please try manually selecting and copying the link.',
								)
							}
						}}
						label='Copy Invitation Link'
						style={{
							color: primary,
						}}
					>
						<Copy className='h-5 w-5 self-center' />
					</Button>
				</div>

				{!minimal && <InviteTokenResetButton />}
			</div>
			<p className='mt-2 mb-4 text-sm text-gray-500'>
				Share this link to invite friends to the game
			</p>
		</div>
	)
}
