import { twMerge } from 'tailwind-merge'
import { IMessage } from '../../../../shared/types/log'
import SmallUppercase from '../interface/text/SmallUppercase'
import MessageMenu from './MessageMenu'

type Props = {
	message: IMessage
	onDelete: () => void
	className?: string
}

export default function SystemMessage({ message, onDelete, className }: Props) {
	return (
		<MessageMenu onDelete={onDelete}>
			<div
				key={message._id}
				className={twMerge(
					'mx-auto my-6 w-4/6 rounded-2xl bg-gray-100 py-2 px-4 text-center text-xs text-gray-400',
					className,
				)}
			>
				<SmallUppercase>{message.message}</SmallUppercase>
			</div>
		</MessageMenu>
	)
}
