import Slider from '../../../interface/Slider'

interface MapGridStyleProps {
	color: string
	alpha: number
	onChange: (style: { color?: string; alpha?: number }) => void
}

const MapGridStyle = ({ color, alpha, onChange }: MapGridStyleProps) => {
	const renderColorValue = (value: string) => (
		<div className='flex h-4 w-4 items-center justify-center'>
			<div
				className={`h-full w-full rounded ${
					value === '#FFFFFF' ? 'bg-white' : 'bg-black'
				}`}
			/>
		</div>
	)

	return (
		<div className='flex gap-2'>
			<Slider
				value={color}
				onChange={value => onChange({ color: value })}
				values={['#000000', '#FFFFFF']}
				mode='tabs'
				className='w-full'
				renderValue={renderColorValue}
			/>

			<Slider
				value={alpha}
				onChange={value => onChange({ alpha: value })}
				label='Opacity'
				min={0}
				max={1}
				step={0.01}
				className='w-full'
			/>
		</div>
	)
}

export default MapGridStyle
