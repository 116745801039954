import {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { usePanelSystem } from '../../contexts/panelSystem'
import { ChatProvider } from '../chat/ChatPanel'
import { DocumentsProvider } from '../Library/DocumentsPanel'
import Notifications from '../notifications/Notifications'
import { HEIGHT_TRANSITION_MS, SLIDE_TRANSITION_MS } from './constants'
import DragHandle from './DragHandle'
import Panel from './Panel'
import PanelTabs from './PanelTabs'
import { PanelState, PanelSystemProps, PanelSystemRef } from './types'

type InternalPanelState = {
	id: string
	state: PanelState
}

const wrapWithProvider = (id: string, children: React.ReactNode) => {
	switch (id) {
		case 'documents':
			return <DocumentsProvider key={id}>{children}</DocumentsProvider>
		case 'chat':
			return <ChatProvider key={id}>{children}</ChatProvider>
		default:
			return children
	}
}

const PanelSystem = forwardRef<PanelSystemRef, PanelSystemProps>(
	({ panels, onTogglePanelRef }, ref) => {
		const { panels: contextPanels, openPanel, closePanel } = usePanelSystem()
		const [panelStates, setPanelStates] = useState<InternalPanelState[]>(() =>
			panels.map(p => ({
				id: p.id,
				state: contextPanels[p.id]?.isOpen ? 'entered' : 'exited',
			})),
		)

		const [visualOrder, setVisualOrder] = useState<string[]>(() =>
			panels.filter(p => contextPanels[p.id]?.isOpen).map(p => p.id),
		)

		const [customHeights, setCustomHeights] = useState<Record<string, number>>(
			() => {
				const initialPanels = panels.filter(p => contextPanels[p.id]?.isOpen)
				if (initialPanels.length === 0) return {}

				const equalHeight = 100 / initialPanels.length
				return Object.fromEntries(initialPanels.map(p => [p.id, equalHeight]))
			},
		)

		const containerRef = useRef<HTMLDivElement>(null)
		const animationRef = useRef<number>()
		const isAnimatingRef = useRef<Record<string, boolean>>({})

		const isChatPanelOpen = useCallback(() => {
			return contextPanels.chat?.isOpen || false
		}, [contextPanels])

		useImperativeHandle(
			ref,
			() => ({
				isChatOpen: isChatPanelOpen,
				openChat: () => {
					openPanel('chat')
					if (!visualOrder.includes('chat')) {
						setVisualOrder(prev => [...prev, 'chat'])
					}
					setPanelStates(prev =>
						prev.map(p => (p.id === 'chat' ? { ...p, state: 'entered' } : p)),
					)
				},
			}),
			[isChatPanelOpen, openPanel],
		)

		const getActivePanels = () => {
			return visualOrder
				.map(id => panelStates.find(p => p.id === id))
				.filter(
					(p): p is InternalPanelState =>
						p !== undefined && p.state !== 'exited',
				)
		}

		const togglePanel = async (id: string) => {
			const panel = panels.find(p => p.id === id)
			const isActive = panelStates.find(p => p.id === id)?.state !== 'exited'

			if (isActive) {
				if (panel?.onToggle) {
					const shouldClose = await panel.onToggle()
					if (!shouldClose) return
				}

				isAnimatingRef.current[id] = true
				setPanelStates(prev =>
					prev.map(p => (p.id === id ? { ...p, state: 'exiting' } : p)),
				)

				await new Promise(r => setTimeout(r, SLIDE_TRANSITION_MS))

				const newVisualOrder = visualOrder.filter(pId => pId !== id)
				setVisualOrder(newVisualOrder)
				resetHeights(newVisualOrder)

				setPanelStates(prev =>
					prev.map(p => (p.id === id ? { ...p, state: 'exited' } : p)),
				)
				closePanel(id)
				isAnimatingRef.current[id] = false
			} else {
				if (panel?.onToggle) {
					const shouldOpen = await panel.onToggle()
					if (!shouldOpen) return
				}

				const originalIndex = panels.findIndex(p => p.id === id)
				let insertIndex = 0
				for (let i = 0; i < originalIndex; i++) {
					if (visualOrder.includes(panels[i].id)) {
						insertIndex++
					}
				}

				isAnimatingRef.current[id] = true
				const newVisualOrder = [...visualOrder]
				newVisualOrder.splice(insertIndex, 0, id)
				setVisualOrder(newVisualOrder)

				setPanelStates(prev =>
					prev.map(p => (p.id === id ? { ...p, state: 'entering' } : p)),
				)

				resetHeights(newVisualOrder)
				openPanel(id)

				await new Promise(r => setTimeout(r, HEIGHT_TRANSITION_MS))

				setPanelStates(prev =>
					prev.map(p => (p.id === id ? { ...p, state: 'entered' } : p)),
				)
				isAnimatingRef.current[id] = false
			}
		}

		// Share togglePanel function with parent
		useEffect(() => {
			onTogglePanelRef?.(togglePanel)
		}, [onTogglePanelRef, togglePanel])

		const resetHeights = (activeIds: string[]) => {
			const totalGapHeight = gapSize * Math.max(0, activeIds.length - 1)
			const availableHeight =
				100 - (totalGapHeight / (containerRef.current?.clientHeight || 1)) * 100
			const equalHeight = availableHeight / activeIds.length
			const newHeights: Record<string, number> = {}
			activeIds.forEach(id => {
				newHeights[id] = equalHeight
			})
			setCustomHeights(newHeights)
		}

		const handleDrag = (index: number, dy: number) => {
			if (!containerRef.current) return

			const activePanels = getActivePanels()
			const containerHeight = containerRef.current.clientHeight
			const deltaPercent = (dy / containerHeight) * 100

			if (animationRef.current) {
				cancelAnimationFrame(animationRef.current)
			}

			animationRef.current = requestAnimationFrame(() => {
				setCustomHeights(prev => {
					const newHeights = { ...prev }
					const currentId = activePanels[index].id
					const nextId = activePanels[index + 1].id

					newHeights[currentId] =
						(prev[currentId] || 100 / activePanels.length) + deltaPercent
					newHeights[nextId] =
						(prev[nextId] || 100 / activePanels.length) - deltaPercent

					if (newHeights[currentId] < 10) {
						newHeights[nextId] += newHeights[currentId] - 10
						newHeights[currentId] = 10
					}
					if (newHeights[nextId] < 10) {
						newHeights[currentId] += newHeights[nextId] - 10
						newHeights[nextId] = 10
					}

					return newHeights
				})
			})
		}

		const activePanels = getActivePanels()
		const gapSize = 8
		const totalGaps = Math.max(0, activePanels.length - 1)
		const totalGapHeight = gapSize * totalGaps

		// Effect to sync with context changes
		useEffect(() => {
			setPanelStates(prev =>
				prev.map(p => {
					// Don't update state if panel is animating
					if (isAnimatingRef.current[p.id]) {
						return p
					}
					const isOpenInContext = contextPanels[p.id]?.isOpen
					const isExited = p.state === 'exited'

					// Only update if there's a mismatch between context and current state
					if (isOpenInContext && isExited) {
						return { ...p, state: 'entered' }
					} else if (!isOpenInContext && !isExited) {
						return { ...p, state: 'exited' }
					}
					return p
				}),
			)

			// Only update visual order for non-animating panels
			setVisualOrder(prev => {
				const newOrder = prev.filter(id => {
					if (isAnimatingRef.current[id]) {
						return true // Keep animating panels in their current position
					}
					return contextPanels[id]?.isOpen
				})

				// Add any newly opened panels that aren't animating
				Object.entries(contextPanels).forEach(([id, panel]) => {
					if (
						panel.isOpen &&
						!prev.includes(id) &&
						!isAnimatingRef.current[id]
					) {
						newOrder.push(id)
					}
				})

				return newOrder
			})
		}, [contextPanels])

		return (
			<div className='flex h-full flex-col'>
				<div className='mb-2'>
					<PanelTabs
						panels={panels}
						activePanels={panelStates}
						onToggle={togglePanel}
					/>
				</div>

				<div className='relative flex-1' ref={containerRef}>
					{panels.map(panel => {
						const panelState = panelStates.find(p => p.id === panel.id)
						const isActive = panelState?.state !== 'exited'
						const index = isActive
							? activePanels.findIndex(p => p.id === panel.id)
							: -1

						// Calculate percentage of available space (total height minus gaps)
						const availableHeight =
							100 -
							(totalGapHeight / containerRef.current?.clientHeight || 1) * 100

						const previousPanelsHeight = isActive
							? activePanels
									.slice(0, index)
									.reduce(
										(sum, p) =>
											sum +
											(customHeights[p.id] ||
												availableHeight / activePanels.length),
										0,
									)
							: 0

						const height = isActive
							? customHeights[panel.id] || availableHeight / activePanels.length
							: 0

						return wrapWithProvider(
							panel.id,
							<Panel
								key={panel.id}
								header={panel.header}
								footer={panel.footer}
								state={panelState?.state || 'exited'}
								height={`${height}%`}
								top={`calc(${previousPanelsHeight}% + ${index * gapSize}px)`}
								noBackground={panel.noBackground}
								fitContent={panel.fitContent}
								onClick={panel.onClick}
							>
								{panel.content}
							</Panel>,
						)
					})}

					{activePanels.slice(0, -1).map((_, index) => {
						const panelsBeforeHeight = activePanels
							.slice(0, index + 1)
							.reduce(
								(sum, p) =>
									sum + (customHeights[p.id] || 100 / activePanels.length),
								0,
							)

						return (
							<DragHandle
								key={`handle-${index}`}
								onDrag={dy => handleDrag(index, dy)}
								onDoubleClick={() => resetHeights(activePanels.map(p => p.id))}
								style={{
									top: `calc(${panelsBeforeHeight}% + ${gapSize * index}px + ${
										gapSize / 2 - 3
									}px)`,
								}}
							/>
						)
					})}
				</div>

				<Notifications onToggle={id => togglePanel(id)} />
			</div>
		)
	},
)

export default PanelSystem
