import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import { RPGBookCategory } from '../../../../../../shared/types/bookcategories'
import RPGBookCategoryDescriptions from '../../../../interfaces/bookcategories'
import BookGrid from '../BookGrid'
import { BooksResources } from '../BooksPage'
import type { ViewMode } from './ViewSelector'

interface GroupedBookListProps {
	resources: BooksResources
	viewMode: ViewMode
	selectMode: boolean
	selectedIds: string[]
	onSelect: (id: string, selected: boolean) => void
	onCategoriesChange?: (categories: RPGBookCategory[]) => void
	onGameSystemsChange?: (systems: string[]) => void
	onRuleSystemsChange?: (systems: string[]) => void
	onFilter?: (type: 'game' | 'ruleSystem', value: string) => void
}

const GroupedBookList = ({
	resources,
	viewMode,
	selectMode,
	selectedIds,
	onSelect,
	onCategoriesChange,
	onGameSystemsChange,
	onRuleSystemsChange,
	onFilter,
}: GroupedBookListProps) => {
	const groupedResources = useMemo(() => {
		const groups: { [key: string]: BooksResources } = {}

		// Handle recentlyOpened view mode
		switch (viewMode) {
			case 'recentlyOpened': {
				// Sort all resources by updatedAt timestamp
				const sortedResources = Object.entries(resources).sort(
					([, a], [, b]) => {
						const dateA = new Date(a.updatedAt || 0)
						const dateB = new Date(b.updatedAt || 0)
						return dateB.getTime() - dateA.getTime()
					},
				)

				// Group by time period
				const now = new Date()
				const oneDay = 24 * 60 * 60 * 1000
				const oneWeek = 7 * oneDay
				const oneMonth = 30 * oneDay

				groups['Today'] = {}
				groups['This Week'] = {}
				groups['This Month'] = {}
				groups['Older'] = {}

				sortedResources.forEach(([id, resource]) => {
					const updatedAt = new Date(resource.updatedAt || 0)
					const timeDiff = now.getTime() - updatedAt.getTime()

					if (timeDiff < oneDay) {
						groups['Today'][id] = resource
					} else if (timeDiff < oneWeek) {
						groups['This Week'][id] = resource
					} else if (timeDiff < oneMonth) {
						groups['This Month'][id] = resource
					} else {
						groups['Older'][id] = resource
					}
				})

				// Remove empty groups
				Object.keys(groups).forEach(key => {
					if (Object.keys(groups[key]).length === 0) {
						delete groups[key]
					}
				})
				break
			}

			default: {
				Object.entries(resources).forEach(([id, resource]) => {
					let groupKey: string

					switch (viewMode) {
						case 'all':
							groupKey = 'all'
							break
						case 'category':
							// A book can be in multiple categories, so we need to handle it differently
							resource.metadata.categories?.forEach(category => {
								if (!groups[category]) {
									groups[category] = {}
								}
								groups[category][id] = resource
							})
							return
						case 'gameSystem':
							groupKey = resource.metadata.game || 'Uncategorized'
							break
						case 'ruleSystem':
							groupKey = resource.metadata.ruleSystem || 'Uncategorized'
							break
						default:
							groupKey = 'Uncategorized'
					}

					if (!groups[groupKey]) {
						groups[groupKey] = {}
					}
					groups[groupKey][id] = resource
				})
			}
		}

		// Sort groups by name, but keep 'Uncategorized' at the end
		return Object.entries(groups)
			.sort(([a], [b]) => {
				if ((viewMode as ViewMode) === 'all') return 0
				if ((viewMode as ViewMode) === 'recentlyOpened') {
					// Custom sort order for time periods
					const order = ['Today', 'This Week', 'This Month', 'Older']
					return order.indexOf(a) - order.indexOf(b)
				}
				if (a === 'Uncategorized') return 1
				if (b === 'Uncategorized') return -1
				if ((viewMode as ViewMode) === 'category') {
					const categoryA = RPGBookCategoryDescriptions[a]?.name || a
					const categoryB = RPGBookCategoryDescriptions[b]?.name || b
					return categoryA.localeCompare(categoryB)
				}
				return a.localeCompare(b)
			})
			.reduce((acc, [key, value]) => {
				acc[key] = value
				return acc
			}, {} as { [key: string]: BooksResources })
	}, [resources, viewMode])

	const getGroupTitle = (groupKey: string) => {
		if (viewMode === 'all') {
			return 'All Books'
		}
		if (viewMode === 'category') {
			return RPGBookCategoryDescriptions[groupKey]?.name || groupKey
		}
		if (viewMode === 'recentlyOpened') {
			return groupKey // Already formatted ('Today', 'This Week', etc.)
		}
		return groupKey === 'Uncategorized' ? 'Uncategorized' : groupKey
	}

	const handleGroupClick = (groupKey: string) => {
		if (
			groupKey === 'Uncategorized' ||
			viewMode === 'all' ||
			viewMode === 'recentlyOpened'
		)
			return

		switch (viewMode) {
			case 'category':
				onCategoriesChange?.([groupKey as RPGBookCategory])
				break
			case 'gameSystem':
				onGameSystemsChange?.([groupKey])
				break
			case 'ruleSystem':
				onRuleSystemsChange?.([groupKey])
				break
		}

		// Scroll the container to the top
		const container = document.getElementById('book-list-content')
		if (container) {
			container.scrollTo({
				top: 0,
				behavior: 'smooth',
			})
		}
	}

	return (
		<div className='space-y-8 pt-8'>
			{Object.entries(groupedResources).map(([groupKey, groupResources]) => (
				<div key={groupKey} className='space-y-4'>
					<h2
						className={twMerge(
							'text-xl font-semibold text-white',
							groupKey !== 'Uncategorized' &&
								viewMode !== 'all' &&
								viewMode !== 'recentlyOpened' &&
								'cursor-pointer transition-colors hover:text-blue-400',
						)}
						onClick={() => handleGroupClick(groupKey)}
					>
						{getGroupTitle(groupKey)}
					</h2>
					<BookGrid
						resources={groupResources}
						selectMode={selectMode}
						selectedIds={selectedIds}
						onSelect={onSelect}
						onFilter={onFilter}
					/>
				</div>
			))}
		</div>
	)
}

export default GroupedBookList
