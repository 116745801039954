import { IGameCard } from '../../../../shared/types/game'
import useUser from '../../hooks/useUser'
import MenuContent from '../dropdownmenu/MenuContent'
import DeleteGame from './menuitems/DeleteGame'
import LeaveGame from './menuitems/LeaveGame'

type Props = { game: IGameCard; refetch?: () => void }

const GameMenuContent = ({ game, refetch }: Props) => {
	const gameId = game._id
	const { userId } = useUser()

	const isCreator =
		typeof game.creator !== 'string' && game.creator._id === userId

	return (
		<MenuContent>
			{isCreator ? (
				<DeleteGame gameId={gameId} />
			) : (
				<LeaveGame gameId={gameId} refetch={refetch} />
			)}
		</MenuContent>
	)
}

export default GameMenuContent
