import { useEffect, useRef, useState } from 'react'
import RainDropEffect from './RainDropEffect'

interface RainDrop {
	id: number
	x: number
	y: number
}

interface RaindropsManagerProps {
	enabled?: boolean
}

export const RaindropsManager = ({
	enabled = false,
}: RaindropsManagerProps) => {
	const [rainDrops, setRainDrops] = useState<RainDrop[]>([])
	const [nextId, setNextId] = useState(0)
	const pressTimer = useRef<number | null>(null)
	const pressPosition = useRef<{ x: number; y: number } | null>(null)

	useEffect(() => {
		if (!enabled) {
			setRainDrops([])
			if (pressTimer.current) {
				clearTimeout(pressTimer.current)
				pressTimer.current = null
			}
			pressPosition.current = null
		}
	}, [enabled])

	const handlePointerDown = (e: React.PointerEvent) => {
		if (!enabled || e.button === 2) return

		pressPosition.current = { x: e.clientX, y: e.clientY }
		pressTimer.current = window.setTimeout(() => {
			if (pressPosition.current) {
				const newRainDrop = {
					id: nextId,
					x: pressPosition.current.x,
					y: pressPosition.current.y,
				}
				setRainDrops(prev => [...prev, newRainDrop])
				setNextId(prev => prev + 1)
			}
		}, 500)
	}

	const handlePointerUp = () => {
		if (pressTimer.current) {
			clearTimeout(pressTimer.current)
			pressTimer.current = null
		}
		pressPosition.current = null
	}

	const handlePointerMove = (e: React.PointerEvent) => {
		if (pressPosition.current) {
			const dx = e.clientX - pressPosition.current.x
			const dy = e.clientY - pressPosition.current.y
			const distance = Math.sqrt(dx * dx + dy * dy)
			if (distance > 5) {
				if (pressTimer.current) {
					clearTimeout(pressTimer.current)
					pressTimer.current = null
				}
				pressPosition.current = null
			}
		}
	}

	const handleEffectComplete = (id: number) => {
		setRainDrops(prev => prev.filter(drop => drop.id !== id))
	}

	useEffect(() => {
		if (enabled) {
			window.addEventListener('pointerdown', handlePointerDown as any)
			window.addEventListener('pointerup', handlePointerUp as any)
			window.addEventListener('pointermove', handlePointerMove as any)

			return () => {
				window.removeEventListener('pointerdown', handlePointerDown as any)
				window.removeEventListener('pointerup', handlePointerUp as any)
				window.removeEventListener('pointermove', handlePointerMove as any)
			}
		}
	}, [enabled])

	if (!enabled) return null

	return (
		<>
			{rainDrops.map(drop => (
				<RainDropEffect
					key={drop.id}
					x={drop.x}
					y={drop.y}
					onComplete={() => handleEffectComplete(drop.id)}
				/>
			))}
		</>
	)
}

export default RaindropsManager
