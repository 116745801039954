import * as Toggle from '@radix-ui/react-toggle'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BRIGHT_GOLD_COLOR } from './constants'

interface ToggleProps {
	pressed: boolean
	onPressedChange: (pressed: boolean) => void
	className?: string
}

export default function CustomToggle({
	pressed,
	onPressedChange,
	className,
}: ToggleProps) {
	return (
		<Toggle.Root
			pressed={pressed}
			onPressedChange={onPressedChange}
			className={twMerge(
				'relative h-6 w-11 rounded-full border-2 transition-colors duration-200',
				className,
			)}
			style={{
				borderColor: INTERFACE_BRIGHT_GOLD_COLOR,
				backgroundColor: pressed ? INTERFACE_BRIGHT_GOLD_COLOR : 'transparent',
			}}
		>
			<div
				className='absolute top-0.5 left-0.5 h-4 w-4 transform rounded-full transition-transform duration-200'
				style={{
					backgroundColor: pressed ? '#222' : INTERFACE_BRIGHT_GOLD_COLOR,
					transform: pressed ? 'translateX(20px)' : 'translateX(0)',
				}}
			/>
		</Toggle.Root>
	)
}
