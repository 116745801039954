import ObjectID from 'bson-objectid'
import { useContext } from 'react'
import {
	TAddUserToDocument,
	TAssumeCharacterPayload,
} from '../../../shared/types/game'
import { IMessage } from '../../../shared/types/log'
import { DocumentOpenMessage } from '../../../shared/types/socket'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'
import UsersContext from '../contexts/users'
import useUser from './useUser'

interface AssumeCharacterResult {
	handleAssumeCharacter: (characterId: string, userId: string) => void
	handleUnassumeCharacter: (characterId: string) => void
}

const useAssumeCharacter = (): AssumeCharacterResult => {
	const { game, dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId: selfId, isGM } = useUser()

	const checkCharacterAvailability = (characterId: string): string | false => {
		for (const [userId, userSetting] of Object.entries(game.userSettings)) {
			if (userSetting.assumedCharacterId === characterId) {
				const user = usersState.users.find(user => user.userId === userId)
				return user?.userProfile?.name || null
			}
		}
		return false
	}

	const getConfirmationMessage = (
		characterId: string,
		userId: string,
	): string | false => {
		const isSelf = userId === selfId
		const assumingUserName = checkCharacterAvailability(characterId)
		const characterIsAssumed = !!assumingUserName
		const userSetting = game.userSettings[userId]
		const assumedCharacterId = userSetting?.assumedCharacterId
		const hasAssumedCharacter = !!assumedCharacterId
		const assumedCharacterName =
			game.documents.byId[assumedCharacterId]?.values?.name ||
			'Unknown Character'
		const characterToAssume =
			game.documents.byId[characterId]?.values?.name || 'Unknown Character'

		// Get the correct user name for the target user
		const targetUser = usersState.users.find(u => u.userId === userId)
		const targetUserName = targetUser?.userProfile?.name || 'Unknown User'

		if (characterIsAssumed && isGM) {
			return `${
				assumingUserName || 'Someone'
			} is assuming this character. If you assume this character, they will be removed from it. (Only Game Masters can do this).`
		} else if (characterIsAssumed) {
			return `${
				assumingUserName || 'Someone'
			} is already assuming this character. You need to unassume the character first, or a GM has to do so before to make it available.`
		} else if (!isSelf && hasAssumedCharacter) {
			return `${targetUserName} is currently playing as ${assumedCharacterName}. Are you sure you want to assign them to ${characterToAssume}?`
		} else if (isSelf && hasAssumedCharacter) {
			return `You are currently playing as ${assumedCharacterName}. Are you sure you want to play as ${characterToAssume}?`
		}
		return false
	}

	const handleUnassumeCharacter = (characterId: string): void => {
		dispatch({
			type: 'UNASSUME_CHARACTER',
			payload: { characterId },
		})
	}

	const handleAssumeCharacter = (characterId: string, userId: string): void => {
		try {
			const confirmationMessage = getConfirmationMessage(characterId, userId)

			if (confirmationMessage) {
				const shouldAssume = window.confirm(confirmationMessage)
				if (!shouldAssume) return
			}

			const assumePayload: TAssumeCharacterPayload = {
				userId,
				characterId,
			}

			const addUserToDocumentPayload: TAddUserToDocument = {
				documentId: characterId,
				userId,
			}

			const messagePayload: IMessage = {
				_id: ObjectID().toHexString(),
				sender: userId,
				type: 'document',
				message: 'Assigning character to you:',
				attachmentId: characterId,
				access: 'private',
				accessList: [userId],
				assumedCharacterId: '',
			}

			dispatch({
				type: 'ADD_USER_TO_DOCUMENT',
				payload: addUserToDocumentPayload,
			})

			dispatch({
				type: 'ASSUME_CHARACTER',
				payload: assumePayload,
			})

			dispatch({
				type: 'ADD_MESSAGE',
				payload: messagePayload,
			})

			const documentOpenMessage: DocumentOpenMessage = {
				documentId: characterId,
				userIds: [userId],
			}

			socket.emit('document:open', documentOpenMessage)
		} catch (error) {
			console.error('Error assuming character:', error)
			alert('Error assuming character: ' + error)
		}
	}

	return { handleAssumeCharacter, handleUnassumeCharacter }
}

export default useAssumeCharacter
