import { IDocument } from '../../../../../shared/types/document'
import MapEditorContent from '../../toolbar/MapEditor/MapEditorContent'

type Props = {
	doc: IDocument
}

const MapEditor = ({ doc }: Props) => {
	return <MapEditorContent doc={doc} />
}

export default MapEditor
