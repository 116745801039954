import { ICollection } from '../../../shared/types/system'

export const BUILT_IN_COLLECTIONS: ICollection[] = [
	{
		type: 'scene',
		singularName: 'Scene',
		pluralName: 'Scenes',
		description: 'Background art.',
		hasEditMode: 'false',
		thumbnailField: ['coverId'],
		windowSize: 'small',
		allowCreate: 'false',
		canAssumeAsCharacter: 'false',
		acceptTypes: [],
	},
	{
		type: 'map',
		singularName: 'Map',
		pluralName: 'Maps',
		description: 'Interactive maps.',
		hasEditMode: 'false',
		thumbnailField: ['mapId'],
		windowSize: 'small',
		allowCreate: 'false',
		canAssumeAsCharacter: 'false',
		acceptTypes: [],
	},
	{
		type: 'handout',
		singularName: 'Handout',
		pluralName: 'Handouts',
		description: 'Shareable images and documents for players.',
		hasEditMode: 'true',
		thumbnailField: ['token'],
		windowSize: 'medium',
		allowCreate: 'false',
		canAssumeAsCharacter: 'false',
		defaultAccess: 'private',
		acceptTypes: ['image/jpeg', 'image/png', 'image/gif', 'application/pdf'],
		isLibraryCollection: 'true',
	},
]
