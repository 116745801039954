import { IDocument } from '../../../../shared/types/document'
import MenuContent from '../dropdownmenu/MenuContent'
import AssignCharacter from './menuitems/AssignCharacter'
import Delete from './menuitems/Delete'
import Permissions from './menuitems/Permissions'
import Rename from './menuitems/Rename'
import SetScene from './menuitems/SetScene'

type Props = { document: IDocument }

const DocumentMenuContent = ({ document }: Props) => {
	const documentId = document._id

	return (
		<MenuContent>
			<SetScene document={document} />
			<AssignCharacter document={document} />
			<Permissions document={document} />
			<Rename document={document} />
			<Delete documentId={documentId} />
			{/* <AddToMap documentId={documentId} /> */}
			{/* <AIGenerator documentId={documentId} /> */}
		</MenuContent>
	)
}

export default DocumentMenuContent
