import { gql } from '@apollo/client'

export const UPDATE_AVATAR = gql`
	mutation UpdateAvatar($avatar: String!, $userId: ID!) {
		updateAvatar(avatarInput: { avatar: $avatar, userId: $userId }) {
			_id
			avatar
		}
	}
`

export const UPDATE_USER_PROFILE = gql`
	mutation UpdateUserProfile($email: String!, $name: String!) {
		updateUserProfile(userProfileInput: { email: $email, name: $name }) {
			email
			name
		}
	}
`

export const UPDATE_USER_PASSWORD = gql`
	mutation UpdateUserPassword(
		$currentPassword: String!
		$newPassword: String!
	) {
		updateUserPassword(
			userPasswordInput: {
				currentPassword: $currentPassword
				newPassword: $newPassword
			}
		) {
			_id
		}
	}
`

export const DELETE_USER = gql`
	mutation DeleteUser {
		deleteUser {
			email
		}
	}
`

export const UPDATE_USER_NAME = gql`
	mutation UpdateUserName($name: String!) {
		updateUserName(name: $name) {
			_id
			name
		}
	}
`

export const UPDATE_USER_EMAIL = gql`
	mutation UpdateUserEmail($email: String!) {
		updateUserEmail(email: $email) {
			_id
			email
		}
	}
`
