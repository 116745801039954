import { useDrag } from '@use-gesture/react'
import { useRef, useState } from 'react'
import { ISavedWindow } from '../../../../shared/types/game'
import { MIN_WINDOW_HEIGHT, MIN_WINDOW_WIDTH } from './windowConfig'
import { clampDraggedWindow, snapWindowToEdges } from './windowPositionUtils'

interface UseWindowDragProps {
	windowRect: ISavedWindow
	setWindowRect: (rect: ISavedWindow) => void
	documentId: string
	dispatchWindows: (action: { type: string; payload: any }) => void
}

export const useWindowDrag = ({
	windowRect,
	setWindowRect,
	documentId,
	dispatchWindows,
}: UseWindowDragProps) => {
	const [isDragging, setIsDragging] = useState(false)
	const dragEndTimeoutRef = useRef<number>()

	// Store initial values on drag start
	const initialX = useRef(0)
	const initialY = useRef(0)
	const initialW = useRef(0)
	const initialH = useRef(0)

	const handleDragEnd = (
		newX: number,
		newY: number,
		newW: number,
		newH: number,
	) => {
		setIsDragging(false)
		document.querySelector('body')?.classList.remove('dragging')

		const updatedRect = {
			...windowRect,
			...clampDraggedWindow(newX, newY, newW, newH),
		}

		setWindowRect(updatedRect)

		if (dragEndTimeoutRef.current) {
			window.clearTimeout(dragEndTimeoutRef.current)
		}
		dragEndTimeoutRef.current = window.setTimeout(() => {
			dispatchWindows({
				type: 'OPEN_WINDOW',
				payload: updatedRect,
			})
		}, 300)
	}

	const bind = useDrag(({ down, movement: [mx, my], target, first, last }) => {
		if (first) {
			initialX.current = windowRect.x
			initialY.current = windowRect.y
			initialW.current = windowRect.width
			initialH.current = windowRect.height
			setIsDragging(true)
		}

		const rect = { ...windowRect }
		const maxW = window.innerWidth
		const maxH = window.innerHeight

		let newX = rect.x
		let newY = rect.y
		let newW = rect.width
		let newH = rect.height

		if (down) {
			document.querySelector('body')?.classList.add('dragging')
			dispatchWindows({
				type: 'MOVE_WINDOW_TO_FRONT',
				payload: { documentId },
			})
		}

		if (!down || last) {
			handleDragEnd(newX, newY, newW, newH)
			return
		}

		// @ts-ignore: target is always a HTMLElement
		const dragType = target.closest('[data-dragtype]')?.dataset?.dragtype
		const cancelDrag = !dragType || dragType[0] === ''
		if (cancelDrag) return

		if (dragType.includes('move')) {
			newX = initialX.current + mx
			newY = initialY.current + my
			const snapped = snapWindowToEdges(newX, newY, newW, 10, true)
			newX = snapped.x
		}

		if (dragType.includes('top')) {
			const h = Math.max(initialH.current - my, MIN_WINDOW_HEIGHT)
			newH = Math.min(h, maxH)
			newY =
				h <= MIN_WINDOW_HEIGHT
					? initialY.current + (initialH.current - MIN_WINDOW_HEIGHT)
					: initialY.current + my
		}

		if (dragType.includes('left')) {
			const w = Math.max(initialW.current - mx, MIN_WINDOW_WIDTH)
			newW = Math.min(w, maxW)
			newX =
				w <= MIN_WINDOW_WIDTH
					? initialX.current + (initialW.current - MIN_WINDOW_WIDTH)
					: initialX.current + mx
		}

		if (dragType.includes('bottom')) {
			newH = Math.min(Math.max(initialH.current + my, MIN_WINDOW_HEIGHT), maxH)
		}

		if (dragType.includes('right')) {
			newW = Math.min(Math.max(initialW.current + mx, MIN_WINDOW_WIDTH), maxW)
		}

		setWindowRect({
			...windowRect,
			x: newX,
			y: Math.max(0, newY),
			width: newW,
			height: newH,
		})
	})

	return {
		isDragging,
		bind,
		dragEndTimeoutRef,
	}
}
