import { useContext, useState } from 'react'
import { FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form'
import AuthContext from '../../../../contexts/auth'
import Button from '../../../FormComponents/Button'
import PasswordEditor from '../../../PasswordEditor'
import AvatarSection from './AvatarSection'
import EditFieldModal from './EditFieldModal'

interface AccountTabProps {
	handleSubmit: (field: string) => Promise<void>
	handleDeleteAccount: () => void
	register: UseFormRegister<FieldValues>
	setValue: UseFormSetValue<FieldValues>
	fileRef: React.RefObject<HTMLInputElement>
	isUploading: boolean
	uploadError: string | null
	previewUrl: string | null
	setPreviewUrl: (url: string | null) => void
	setIsUploading: (isUploading: boolean) => void
	setUploadError: (error: string | null) => void
}

const AccountTab = ({
	handleSubmit,
	handleDeleteAccount,
	register,
	setValue,
	fileRef,
	isUploading,
	uploadError,
	previewUrl,
	setPreviewUrl,
	setIsUploading,
	setUploadError,
}: AccountTabProps) => {
	const { authState } = useContext(AuthContext)
	const [editingField, setEditingField] = useState<'name' | 'email' | null>(
		null,
	)
	const [isPasswordEditorOpen, setIsPasswordEditorOpen] = useState(false)

	const handleFieldSave = async (value: string) => {
		if (editingField) {
			setValue(editingField, value)
			await handleSubmit(editingField)
		}
		setEditingField(null)
	}

	return (
		<div className='flex h-full flex-col'>
			<div className='flex-1 space-y-6'>
				<AvatarSection
					fileRef={fileRef}
					isUploading={isUploading}
					uploadError={uploadError}
					previewUrl={previewUrl}
					setPreviewUrl={setPreviewUrl}
					setIsUploading={setIsUploading}
					setUploadError={setUploadError}
				/>

				<div>
					<label
						htmlFor='name'
						className='block text-sm font-medium text-gray-700'
					>
						Name
					</label>
					<div className='relative mt-1'>
						<input
							{...register('name')}
							id='name'
							name='name'
							type='text'
							readOnly
							className='block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-20 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
						/>
						<button
							type='button'
							onClick={() => setEditingField('name')}
							className='absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500 hover:text-gray-700'
						>
							Edit
						</button>
					</div>
				</div>

				<div>
					<label
						htmlFor='email'
						className='block text-sm font-medium text-gray-700'
					>
						Email
					</label>
					<div className='relative mt-1'>
						<input
							{...register('email')}
							id='email'
							name='email'
							type='email'
							readOnly
							className='block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-20 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
						/>
						<button
							type='button'
							onClick={() => setEditingField('email')}
							className='absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500 hover:text-gray-700'
						>
							Edit
						</button>
					</div>
				</div>

				<div>
					<label
						htmlFor='password'
						className='block text-sm font-medium text-gray-700'
					>
						Password
					</label>
					<div className='relative mt-1'>
						<input
							id='password'
							name='password'
							type='password'
							readOnly
							value='••••••••••••'
							className='block w-full appearance-none rounded-md border border-gray-300 bg-white px-3 py-2 pr-20 text-gray-900 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
						/>
						<button
							type='button'
							onClick={() => setIsPasswordEditorOpen(true)}
							className='absolute right-2 top-1/2 -translate-y-1/2 text-sm text-gray-500 hover:text-gray-700'
						>
							Edit
						</button>
					</div>
				</div>
			</div>

			<div className='mt-6'>
				<div className='flex items-center justify-between'>
					<Button
						type='button'
						onClick={handleDeleteAccount}
						className='!bg-red-600 hover:!bg-red-700'
					>
						Delete Account
					</Button>
				</div>
			</div>

			{editingField && (
				<EditFieldModal
					title={`Change ${editingField}`}
					fieldName={editingField}
					initialValue={authState.userDetails[editingField]}
					onSave={handleFieldSave}
					onClose={() => setEditingField(null)}
				/>
			)}

			{isPasswordEditorOpen && (
				<PasswordEditor onClose={() => setIsPasswordEditorOpen(false)} />
			)}
		</div>
	)
}

export default AccountTab
