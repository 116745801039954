import { useEffect, useRef, useState } from 'react'
import { DragHandleProps } from './types'

const DragHandle = ({ onDrag, onDoubleClick, style }: DragHandleProps) => {
	const [isDragging, setIsDragging] = useState(false)
	const [isHovered, setIsHovered] = useState(false)
	const lastY = useRef<number | null>(null)

	useEffect(() => {
		if (!isDragging) {
			document.body.classList.remove('select-none')
			return
		}

		document.body.classList.add('select-none')

		const handleMouseMove = (e: MouseEvent) => {
			if (lastY.current !== null) {
				const dy = e.clientY - lastY.current
				onDrag(dy)
			}
			lastY.current = e.clientY
		}

		const handleMouseUp = () => {
			setIsDragging(false)
			lastY.current = null
			document.body.classList.remove('select-none')
		}

		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)

		return () => {
			document.removeEventListener('mousemove', handleMouseMove)
			document.removeEventListener('mouseup', handleMouseUp)
			document.body.classList.remove('select-none')
		}
	}, [isDragging, onDrag])

	return (
		<div
			style={{
				...style,
				opacity: isHovered ? 1 : 0,
			}}
			className='pointer-events-auto absolute z-10 flex h-3 w-full -translate-y-1/2 cursor-row-resize select-none items-center justify-center transition-opacity duration-150'
			onMouseDown={e => {
				e.preventDefault()
				setIsDragging(true)
				lastY.current = e.clientY
			}}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onDoubleClick={onDoubleClick}
		>
			<div className='h-1 w-16 rounded-full bg-gray-400' />
		</div>
	)
}

export default DragHandle
