import { Minus } from 'lucide-react'
import { FC, useContext } from 'react'
import ToolsContext from '../../contexts/tools'
import ToolButton from '../interface/toolbar/ToolButton'

const FogPolygonRemoveButton: FC = () => {
	const { toolsState, dispatchTools } = useContext(ToolsContext)
	const { fog } = toolsState

	const handleClick = () => {
		dispatchTools({ type: 'SET_FOG_TOOL', payload: 'polygonRemove' })
	}

	return (
		<ToolButton
			tooltip='Remove Fog'
			isOpen={fog.tool === 'polygonRemove'}
			onClick={handleClick}
		>
			<Minus aria-hidden='true' />
		</ToolButton>
	)
}

export default FogPolygonRemoveButton
