import { IBook } from '../../../shared/types/book'
import { IDocument } from '../../../shared/types/document'
import useUser from './useUser'
import useUserLevel from './useUserLevel'

export default function useItemFilter() {
	const { userId, isGM } = useUser()
	const { isAtLeastAdept } = useUserLevel()

	const checkAccess = (props: IDocument | IBook | null): boolean => {
		if (!props) {
			return false
		}

		const { access, accessList } = props

		if (!access || !accessList) {
			return false
		}

		if (isGM) {
			return true
		}

		// For books, check if user is at least adept level
		if ('resourceId' in props) {
			// This is a book
			if (!isAtLeastAdept()) {
				return false
			}
		}

		if (access === 'public') {
			return true
		}

		// If user ID is included in 'accessList', user has access
		if (accessList.includes(userId)) {
			return true
		}

		return false
	}

	return { checkAccess }
}
