import { Transition } from '@headlessui/react'
import { AnnotationMode } from 'pdfjs-dist'
import React, {
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { createPortal } from 'react-dom'
import { useSearchParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { IBook } from '../../../../../shared/types/book'
import { BookResource } from '../../../../../shared/types/resources'
import address from '../../../config'
import { ResourceContext } from '../../../contexts/resources'
import BookSpineEffect from '../../books/BookSpineEffect'
import BookMenu from '../../books/menu/BookMenu'
import ResourceMenu from '../../books/ResourceMenu'
import { INTERFACE_DROPSHADOW } from '../../interface/constants'
import PDFViewer from '../../resources/PDFViewer'
import { ScaleMode } from '../../resources/types/pdfViewerTypes'
import BookEditor from './BookEditor'

const CELL_ASPECT_RATIO = { width: 9, height: 12 }
const CELL_PADDING = 16

const getScaledDimensions = (
	bookWidthInches: number,
	bookHeightInches: number,
	maxWidth: number,
	maxHeight: number,
) => {
	if (!maxWidth || !maxHeight) return { width: 0, height: 0 }

	const pixelsPerInchWidth = maxWidth / CELL_ASPECT_RATIO.width
	const pixelsPerInchHeight = maxHeight / CELL_ASPECT_RATIO.height
	const pixelsPerInch = Math.min(pixelsPerInchWidth, pixelsPerInchHeight)

	let scaledWidth = bookWidthInches * pixelsPerInch
	let scaledHeight = bookHeightInches * pixelsPerInch

	if (scaledWidth > maxWidth || scaledHeight > maxHeight) {
		const widthRatio = maxWidth / scaledWidth
		const heightRatio = maxHeight / scaledHeight
		const scale = Math.min(widthRatio, heightRatio)
		scaledWidth *= scale
		scaledHeight *= scale
	}

	return { width: scaledWidth, height: scaledHeight }
}

interface BookCoverProps {
	resource: BookResource
	book?: IBook
	onLightBackground?: boolean
	selectMode?: boolean
	selected?: boolean
	onSelect?: (selected: boolean) => void
	className?: string
	onFilter?: (type: 'game' | 'ruleSystem', value: string) => void
}

const BookCover: React.FC<BookCoverProps> = ({
	resource,
	book,
	onLightBackground = false,
	selectMode,
	selected = false,
	onSelect,
	className,
	onFilter,
}) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { dispatchResource } = useContext(ResourceContext)
	const cellRef = useRef<HTMLDivElement | null>(null)
	const textContainerRef = useRef<HTMLDivElement | null>(null)
	const [imageDimensions, setImageDimensions] = useState({
		width: 0,
		height: 0,
	})
	const isGame = window.location.pathname.includes('game')

	// Track whether the PDF viewer is open and mounted
	const [showPdf, setShowPdf] = useState(() => {
		return searchParams.get('book') === resource._id
	})
	const [isPdfMounted, setIsPdfMounted] = useState(showPdf)

	// Track whether the resource editor is open and mounted
	const [showEditor, setShowEditor] = useState(false)
	const [isEditorMounted, setIsEditorMounted] = useState(false)

	// Decide what happens when the user clicks on the book cover
	const handleOpen = async (e: React.MouseEvent) => {
		console.log('handleOpen called', { selectMode, selected })
		if (selectMode) {
			console.log('Calling handleSelect in select mode')
			handleSelect()
			return
		}

		// Update document title
		const bookTitle =
			resource.metadata?.title || resource.name.replace(/\.[^/.]+$/, '')
		document.title = `${bookTitle} - Realms Inc`

		// If cmd/ctrl is pressed, open in new tab
		if (e.metaKey || e.ctrlKey) {
			const params = new URLSearchParams(searchParams)
			params.set('book', resource._id)
			const url = `${window.location.pathname}?${params.toString()}`
			window.open(url, '_blank')
			return
		}

		setSearchParams(params => {
			params.set('book', resource._id)
			return params
		})
		setShowPdf(true)
		setIsPdfMounted(true)

		// Touch the resource to update its timestamp
		try {
			const response = await fetch(`/api/resource/${resource._id}/touch`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			})
			const updatedResource = await response.json()

			// Update the local state with the new timestamp
			dispatchResource({
				type: 'UPDATE_RESOURCE',
				payload: { resource: updatedResource },
			})
		} catch (error) {
			console.error('Failed to touch resource:', error)
		}
	}

	// Handle closing the PDF viewer
	const handleClose = () => {
		setSearchParams(params => {
			params.delete('book')
			return params
		})
		setShowPdf(false)
		document.title = 'Realms Inc'
	}

	// Calculate how the book cover should fit in the container
	const measureAndSetDimensions = useCallback(() => {
		if (!cellRef.current || !textContainerRef.current) return
		const cellWidth = cellRef.current.clientWidth
		const cellHeight = cellRef.current.clientHeight
		const textHeight = textContainerRef.current.clientHeight

		const availableWidth = cellWidth - CELL_PADDING
		const availableHeight = cellHeight - textHeight - CELL_PADDING

		const inchesWidth =
			resource.metadata?.pdf?.dimensions?.widthInches || CELL_ASPECT_RATIO.width
		const inchesHeight =
			resource.metadata?.pdf?.dimensions?.heightInches ||
			CELL_ASPECT_RATIO.height

		const { width, height } = getScaledDimensions(
			inchesWidth,
			inchesHeight,
			availableWidth,
			availableHeight,
		)

		setImageDimensions({ width, height })
	}, [resource.metadata?.pdf?.dimensions])

	useLayoutEffect(() => {
		measureAndSetDimensions()
	}, [resource, measureAndSetDimensions])

	useEffect(() => {
		if (!cellRef.current) return
		const observer = new ResizeObserver(() => {
			measureAndSetDimensions()
		})
		observer.observe(cellRef.current)

		return () => observer.disconnect()
	}, [resource, measureAndSetDimensions])

	// Update document title when URL changes or component mounts
	useEffect(() => {
		const isThisBookOpen = searchParams.get('book') === resource._id
		if (isThisBookOpen) {
			const bookTitle =
				resource.metadata?.title || resource.name.replace(/\.[^/.]+$/, '')
			document.title = `${bookTitle} - Realms Inc`
		} else if (!searchParams.get('book')) {
			document.title = 'Realms Inc'
		}
	}, [searchParams, resource])

	useEffect(() => {
		if (showPdf) {
			document.documentElement.style.overflow = 'hidden'
			document.body.style.overflow = 'hidden'
		} else {
			document.documentElement.style.overflow = ''
			document.body.style.overflow = ''
		}

		return () => {
			document.documentElement.style.overflow = ''
			document.body.style.overflow = ''
		}
	}, [showPdf])

	const displayTitle =
		resource.metadata?.title || resource.name.replace(/\.[^/.]+$/, '')
	const hasManyPages = (resource.metadata?.pdf?.pageCount || 0) > 10

	const handleSelect = () => {
		console.log('handleSelect called, current selected:', selected)
		onSelect?.(!selected)
	}

	const handleEdit = () => {
		setShowEditor(true)
		setIsEditorMounted(true)
	}

	const handleCloseEditor = () => {
		setShowEditor(false)
	}

	const handleFilter = (type: 'game' | 'ruleSystem', value: string) => {
		onFilter?.(type, value)
	}

	return (
		<div
			ref={cellRef}
			className={twMerge(
				'flex h-full w-full max-w-xs flex-col justify-end rounded-xl p-3',
				selectMode && 'cursor-pointer hover:bg-white/5',
				selected && 'bg-white/10 hover:bg-white/20',
			)}
		>
			<div className='flex flex-col items-center'>
				<div
					className={twMerge(
						'relative',
						!selectMode &&
							'transform transition-transform duration-200 hover:scale-105',
					)}
				>
					<div
						onClick={handleOpen}
						className={twMerge(
							'relative flex cursor-pointer items-center justify-center shadow-md',
							selectMode && 'cursor-pointer',
							selected && 'bg-black/5 dark:bg-white/10',
							className,
						)}
						style={{
							width: imageDimensions.width ? `${imageDimensions.width}px` : '0',
							height: imageDimensions.height
								? `${imageDimensions.height}px`
								: '0',
							boxShadow: INTERFACE_DROPSHADOW,
							overflow: 'hidden',
							position: 'relative',
						}}
					>
						<img
							src={`${address}${resource.thumbnailurl}`}
							alt={resource.name}
							className='max-h-full max-w-full object-contain'
							style={{
								width: '100%',
								height: '100%',
								objectFit: 'contain',
							}}
						/>

						{hasManyPages && (
							<>
								<div className='absolute inset-x-0 top-0 h-px bg-gradient-to-r from-white/10 via-white/30 to-white/10' />
								<div className='absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-black/10 via-black/30 to-black/10' />
								<BookSpineEffect />
							</>
						)}
					</div>
				</div>
			</div>

			<div
				ref={textContainerRef}
				className='mt-2 flex w-full flex-col items-center px-1'
			>
				<div className='mx-auto flex w-full justify-between space-x-2'>
					<div className='min-w-0 flex-1'>
						<div
							className={`truncate text-xs ${
								onLightBackground ? 'text-gray-900' : 'text-white'
							}`}
						>
							{displayTitle}
						</div>
						<div className='mt-1 truncate text-xs text-gray-400'>
							{resource.metadata?.game || <span>&nbsp;</span>}
						</div>
					</div>

					<div className='flex-shrink-0'>
						{!isGame && (
							<ResourceMenu
								resource={resource}
								onEdit={handleEdit}
								onFilter={handleFilter}
							/>
						)}
						{isGame && book && <BookMenu book={book} />}
					</div>
				</div>
			</div>

			<Transition
				show={showPdf}
				enter='transition-all transform duration-300 ease-out'
				enterFrom='opacity-0 scale-95 translate-y-4'
				enterTo='opacity-100 scale-100 translate-y-0'
				leave='transition-all transform duration-300 ease-in'
				leaveFrom='opacity-100 scale-100 translate-y-0'
				leaveTo='opacity-0 scale-95 translate-y-4'
				afterLeave={() => setIsPdfMounted(false)}
				as='div'
				className='fixed inset-0 z-50 transform bg-gray-900'
			>
				{isPdfMounted &&
					createPortal(
						<div className='fixed inset-0 z-50 transform bg-gray-900'>
							<PDFViewer
								resource={resource}
								showTools={true}
								isGame={false}
								initialScaleMode={ScaleMode.PAGE_FIT}
								annotationMode={AnnotationMode.ENABLE_STORAGE}
								onClose={handleClose}
							/>
						</div>,
						document.body,
					)}
			</Transition>

			{isEditorMounted && (
				<BookEditor
					id={`book-editor-${resource._id}`}
					resource={resource}
					isOpen={showEditor}
					onClose={handleCloseEditor}
				/>
			)}
		</div>
	)
}

export default BookCover
