import React from 'react'
import { twMerge } from 'tailwind-merge'
import BaseType from './BaseType'

type XsTypeProps = React.HTMLAttributes<HTMLDivElement> & {
	children: React.ReactNode
	className?: string
	isOnDark?: boolean
}

const XsType: React.FC<XsTypeProps> = ({
	children,
	className,
	isOnDark = false,
	...props
}) => {
	return (
		<BaseType
			className={twMerge('text-xs', className)}
			isOnDark={isOnDark}
			{...props}
		>
			{children}
		</BaseType>
	)
}

export default XsType
