import { useContext, useEffect, useRef } from 'react'
import DocumentsContext from '../contexts/documents'
import GameContext from '../contexts/game'
import useItemFilter from '../hooks/UseItemFilter'
import { usePdfCollections } from '../hooks/usePdfCollections'
import useUser from '../hooks/useUser'
import DocumentWindow from './Library/DocumentWindow'
import HandoutWindow from './Library/HandoutEditor/HandoutWindow'
import Library from './Library/Library'
import MapWindow from './Library/MapEditor/MapWindow'
import PdfSheetWindow from './Library/PdfSheetWindow'
import SceneWindow from './Library/SceneEditor/SceneWindow'

const Sidebar = () => {
	const { game } = useContext(GameContext)
	const { openDocuments, dispatchDocuments } = useContext(DocumentsContext)
	const pdfCollections = usePdfCollections()
	const { checkAccess } = useItemFilter()
	const previousDocsRef = useRef(game.documents)
	const { isGM } = useUser()

	const handleDocumentClose = (id: string) => {
		dispatchDocuments({
			type: 'CLOSE_DOCUMENT',
			payload: { documentId: id },
		})
	}

	const handleDocumentOpen = (id: string) => {
		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: { documentId: id },
		})
	}

	const isPdfDocument = (documentId: string) => {
		const document = game.documents.byId[documentId]
		return pdfCollections.some(collection => collection.type === document.type)
	}

	// Monitor document access changes
	useEffect(() => {
		// Close windows for documents that are no longer accessible
		openDocuments.forEach(documentId => {
			const document = game.documents.byId[documentId]
			if (document && !checkAccess(document)) {
				handleDocumentClose(documentId)
			}
		})

		// Check for documents that were just shared
		Object.keys(game.documents.byId).forEach(documentId => {
			const currentDoc = game.documents.byId[documentId]
			const previousDoc = previousDocsRef.current.byId[documentId]

			// Skip if document didn't exist before or doesn't exist now
			if (!previousDoc || !currentDoc) return

			// For GMs, open when a document becomes public
			if (
				isGM &&
				previousDoc.access !== 'public' &&
				currentDoc.access === 'public'
			) {
				handleDocumentOpen(documentId)
				return
			}

			// For all users, open when they gain access
			const hadAccess = checkAccess(previousDoc)
			const hasAccess = checkAccess(currentDoc)

			if (!hadAccess && hasAccess) {
				// Document was just shared with the user
				handleDocumentOpen(documentId)
			}
		})

		// Update our reference
		previousDocsRef.current = game.documents
	}, [game.documents]) // Re-run only when documents change

	return (
		<>
			<Library />

			{openDocuments.map((documentId: string) => {
				const document = game.documents.byId[documentId]
				const { type } = document

				if (type === 'scene') {
					return (
						<SceneWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else if (type === 'map') {
					return (
						<MapWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else if (type === 'handout') {
					return (
						<HandoutWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else if (isPdfDocument(documentId)) {
					return (
						<PdfSheetWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				} else {
					return (
						<DocumentWindow
							key={documentId}
							documentId={documentId}
							onClose={() => handleDocumentClose(documentId)}
						/>
					)
				}
			})}
		</>
	)
}

export default Sidebar
