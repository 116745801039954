import { animated, useSpring } from '@react-spring/web'
import { useEffect, useRef, useState } from 'react'
import { INTERFACE_BACKGROUND_COLOR } from '../interface/constants'
import { PanelProps } from './types'

const Panel = ({
	header,
	footer,
	children,
	state,
	height,
	top,
	noBackground,
	fitContent,
	onClick,
}: PanelProps) => {
	const [contentHeight, setContentHeight] = useState<number | null>(null)
	const contentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!fitContent || !contentRef.current) return

		const observer = new ResizeObserver(() => {
			if (contentRef.current) {
				setContentHeight(contentRef.current.scrollHeight)
			}
		})

		observer.observe(contentRef.current)
		return () => observer.disconnect()
	}, [fitContent])

	const springs = useSpring({
		to: {
			opacity: state === 'entered' ? 1 : 0,
			transform:
				state === 'exiting' || state === 'entering'
					? 'translateX(100%)'
					: 'translateX(0%)',
			height: fitContent && contentHeight ? `${contentHeight}px` : height,
			top,
		},
		config: {
			tension: 280,
			friction: 28,
		},
	})

	return (
		<animated.div
			style={{
				...springs,
				backgroundColor: noBackground
					? 'transparent'
					: INTERFACE_BACKGROUND_COLOR,
				position: 'absolute',
				width: '100%',
				pointerEvents: state === 'entered' ? 'auto' : 'none',
			}}
			className='flex flex-col rounded-3xl shadow-lg'
			onClick={onClick}
		>
			{header && (
				<div className='flex-none rounded-t-xl px-6 py-3'>{header}</div>
			)}
			<div
				ref={contentRef}
				className='min-h-0 flex-1 overflow-y-auto overflow-x-hidden'
			>
				{children}
			</div>
			{footer && (
				<div className='flex-none rounded-b-xl px-3 py-3'>{footer}</div>
			)}
		</animated.div>
	)
}

export default Panel
