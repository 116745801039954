import { X } from 'lucide-react'
import ToolButton from '../interface/toolbar/ToolButton'
import TooltipWrapper from '../TooltipWrapper'

interface CloseButtonProps {
	onClick: () => void
	isOnDark?: boolean
}

const CloseButton: React.FC<CloseButtonProps> = ({
	onClick,
	isOnDark = false,
}) => {
	return (
		<TooltipWrapper label='Close'>
			<ToolButton onClick={onClick} onTouchEnd={onClick} size='sm'>
				<X
					className={`h-5 w-5 ${isOnDark ? 'text-white' : 'text-black'}`}
					aria-hidden='true'
				/>
			</ToolButton>
		</TooltipWrapper>
	)
}

export default CloseButton
