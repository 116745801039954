import { useQuery } from '@apollo/client'
import { Crown } from 'lucide-react'
import { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { IGameCard } from '../../../../../shared/types/game'
import { GAME_USERS } from '../../../graphql/games'
import { IUserProfile } from '../../../interfaces/users'
import Avatar from '../../Avatar'
import GameMenu from '../../gamemenu/GameMenu'
import {
	INTERFACE_BRIGHT_GOLD_COLOR,
	INTERFACE_DROPSHADOW,
} from '../../interface/constants'
import DocumentWindowMenuButton from '../../Library/MenuButton'
import Overlapper from '../../Overlapper'
import Tooltip from '../../Tooltip'
import GameCard from './GameCard'
import TemplatePill from './TemplatePill'

export interface IGameCardContainerProps {
	game: IGameCard
	refetch?: () => void
	className?: string
	showCrown?: boolean
	hideMenu?: boolean
}

export default function GameCardContainer({
	game,
	refetch,
	className,
	showCrown = false,
	hideMenu = false,
}: IGameCardContainerProps) {
	const [isHovered, setIsHovered] = useState(true)
	const [, setIsMenuOpen] = useState(false)

	const { data, loading, error } = useQuery(GAME_USERS, {
		variables: {
			gameId: game._id,
		},
		pollInterval: 5000,
		fetchPolicy: 'cache-and-network',
	})

	const UserAvatarWithTooltip = ({ user }: { user: IUserProfile }) => {
		const avatarRef = useRef<HTMLDivElement>(null)
		const [showTooltip, setShowTooltip] = useState(false)

		return (
			<div
				key={user._id}
				ref={avatarRef}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<Tooltip open={showTooltip} anchor={avatarRef} placement='bottom'>
					{user.name}
				</Tooltip>
				<Avatar
					src={user.avatar}
					userId={user._id}
					userName={user.name}
					className='mr-2'
					noBorder
				/>
			</div>
		)
	}

	return (
		<div className={twMerge('rounded-xl', className)}>
			<div
				className='relative z-10'
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				{!hideMenu && (
					<GameMenu
						game={game}
						refetch={refetch}
						show={isHovered}
						onOpen={() => setIsMenuOpen(true)}
						onClose={() => setIsMenuOpen(false)}
					>
						<DocumentWindowMenuButton show={isHovered} />
					</GameMenu>
				)}
				<Link key={game._id} to={'/game/' + game._id}>
					<div className='relative'>
						<GameCard game={game} style={{ boxShadow: INTERFACE_DROPSHADOW }} />
						{game.isTemplate && (
							<div className='absolute top-4 left-4'>
								<TemplatePill />
							</div>
						)}
					</div>

					<div className='mt-2 flex'>
						<div className='flex flex-[2] items-center'>
							{showCrown && (
								<Crown
									size={16}
									className='mr-2'
									style={{
										color: INTERFACE_BRIGHT_GOLD_COLOR,
									}}
								/>
							)}
							<p className='truncate font-medium'>{game.title}</p>
						</div>
						<div className='flex-1'>
							<Overlapper align='center' className='w-full justify-end'>
								{loading && (
									<div className='flex items-center justify-center'>
										loading
									</div>
								)}
								{error && (
									<div className='flex items-center justify-center'>error</div>
								)}
								{data?.gameUsers?.map((user: IUserProfile) => (
									<UserAvatarWithTooltip key={user._id} user={user} />
								))}
							</Overlapper>
						</div>
					</div>
				</Link>
			</div>
		</div>
	)
}
