import { Point } from 'pixi.js'

export interface SquareCoordinates {
	col: number
	row: number
}

export class Square {
	readonly col: number
	readonly row: number
	readonly size: number
	readonly x: number
	readonly y: number

	constructor(coordinates: SquareCoordinates, size: number) {
		this.col = coordinates.col
		this.row = coordinates.row
		this.size = size

		// Calculate pixel coordinates from grid coordinates (center point)
		this.x = this.col * size + size / 2
		this.y = this.row * size + size / 2
	}

	get center(): Point {
		return new Point(this.x, this.y)
	}

	get corners(): Point[] {
		const halfSize = this.size / 2
		return [
			new Point(this.x - halfSize, this.y - halfSize), // top-left
			new Point(this.x + halfSize, this.y - halfSize), // top-right
			new Point(this.x + halfSize, this.y + halfSize), // bottom-right
			new Point(this.x - halfSize, this.y + halfSize), // bottom-left
		]
	}

	equals(other: Square): boolean {
		return this.col === other.col && this.row === other.row
	}

	toString(): string {
		return `${this.col},${this.row}`
	}
}
