import { useCallback, useContext, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { twMerge } from 'tailwind-merge'
import { ISavedWindow, IWindow } from '../../../../shared/types/game'
import WindowsContext from '../../contexts/windows'
import {
	INTERFACE_BACKGROUND_COLOR,
	INTERFACE_DROPSHADOW,
} from '../interface/constants'
import { useWindowDrag } from './useWindowDrag'
import { WINDOW_SIZE_MAP } from './windowConfig'
import WindowInner from './WindowInner'
import { fitWindowToViewport } from './windowPositionUtils'
import WindowResizeHandles from './WindowResizeHandles'

interface WindowProps extends IWindow {
	dimensions?: {
		width: number
		height: number
	}
}

const Window: React.FC<WindowProps> = ({
	documentId,
	open,
	title,
	children,
	className,
	headerChildren,
	footerChildren,
	bodyClassName = '',
	position = { x: 100, y: 100 },
	size = 'small',
	dimensions,
	headerIcon,
	onClose = () => {},
}) => {
	const { windowsState, dispatchWindows } = useContext(WindowsContext)

	const defaultWindowRect = {
		x: position.x,
		y: position.y,
		width: dimensions?.width ?? WINDOW_SIZE_MAP[size].width,
		height: dimensions?.height ?? WINDOW_SIZE_MAP[size].height,
	}
	const savedWindowRect = windowsState.windows.find(
		w => w?.documentId === documentId,
	) || {
		documentId,
		x: position.x,
		y: position.y,
		width: dimensions?.width ?? WINDOW_SIZE_MAP[size]?.width,
		height: dimensions?.height ?? WINDOW_SIZE_MAP[size]?.height,
	}

	const [windowRect, setWindowRect] = useState<ISavedWindow>({
		...defaultWindowRect,
		...savedWindowRect,
	})

	const [zIndex, setZIndex] = useState(0)

	const { isDragging, bind, dragEndTimeoutRef } = useWindowDrag({
		windowRect,
		setWindowRect,
		documentId,
		dispatchWindows,
	})

	const handleOpenClose = () => {
		if (!open) return
		const newRect = fitWindowToViewport(windowRect)
		setWindowRect(newRect)
		if (newRect.width === 0 || newRect.height === 0) return

		dispatchWindows({
			type: 'OPEN_WINDOW',
			payload: newRect,
		})
	}

	useEffect(handleOpenClose, [open]) // eslint-disable-line react-hooks/exhaustive-deps

	const doZIndex = () => {
		const i = windowsState.windows.findIndex(w => w?.documentId === documentId)
		setZIndex(i > -1 ? i : 0)
	}
	useEffect(doZIndex, [windowsState])

	useEffect(() => {
		return () => {
			if (dragEndTimeoutRef.current) {
				window.clearTimeout(dragEndTimeoutRef.current)
			}
		}
	}, [])

	const clampWindowToViewport = useCallback(() => {
		const newRect = fitWindowToViewport(windowRect)
		if (newRect.x !== windowRect.x || newRect.y !== windowRect.y) {
			setWindowRect(newRect)
		}
	}, [windowRect])

	useEffect(() => {
		window.addEventListener('resize', clampWindowToViewport)
		return () => window.removeEventListener('resize', clampWindowToViewport)
	}, [clampWindowToViewport])

	return createPortal(
		<div
			id={documentId}
			className={twMerge('fixed top-0 left-0 z-30', className)}
			style={{
				zIndex: 30 + zIndex,
				transform: `translate3d(${windowRect.x}px, ${windowRect.y}px, 0)`,
				width: windowRect.width,
				height: windowRect.height,
				transition: isDragging ? 'none' : 'transform 0.3s ease-out',
			}}
		>
			<div
				className={twMerge(
					'relative flex h-full w-full flex-col overflow-hidden rounded-2xl',
					className,
				)}
				style={{
					touchAction: 'none',
					backgroundColor: INTERFACE_BACKGROUND_COLOR,
					boxShadow: INTERFACE_DROPSHADOW,
				}}
				// @ts-ignore: is typed as 'void', but is not; do not remove
				{...bind()}
			>
				<WindowInner
					title={title}
					children={children}
					headerChildren={headerChildren}
					footerChildren={footerChildren}
					bodyClassName={bodyClassName}
					headerIcon={headerIcon}
					zIndex={zIndex}
					onClose={onClose}
				/>
				<WindowResizeHandles />
			</div>
		</div>,
		document.body,
	)
}

export default Window
