import { Fragment } from 'react'
import { useGame } from '../../hooks/useGame'
import Document from './Document'
import Folder from './Folder'

type Props = {
	parentId: string
	dragIndex: number
	hoverIndex: number
	hoverItem: any
	moveItem: any
	selectedType: string
}

export default function List({
	parentId,
	dragIndex,
	hoverIndex,
	hoverItem,
	moveItem,
	selectedType,
}: Props) {
	const { game } = useGame()

	const renderChild = (parentId: string) => {
		const allDocs = game.documents.allIds.map(
			documentId => game.documents.byId[documentId],
		)

		const filtered = allDocs.filter(doc => {
			const parentMatch = doc.parentId === parentId
			const typeMatch = selectedType === 'All' || doc.type === selectedType
			return parentMatch && typeMatch
		})

		return filtered
			.sort((a, b) => {
				const nameA = a.values.name.toUpperCase()
				const nameB = b.values.name.toUpperCase()
				if (nameA < nameB) return -1
				if (nameA > nameB) return 1
				return 0
			})
			.map(doc => {
				const props = {
					id: doc._id,
					dragIndex,
					hoverIndex,
					hoverItem,
					moveItem,
					selectedType,
				}

				let childComponent: JSX.Element

				if (doc.type === 'folder') {
					childComponent = <Folder {...props} />
				} else {
					childComponent = <Document {...props} />
				}

				return <Fragment key={doc._id}>{childComponent}</Fragment>
			})
	}

	return <ul className='overflow-auto pb-4'>{renderChild(parentId)}</ul>
}
