import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext, useRef } from 'react'
import GameContext from '../../contexts/game'
import { useGrid } from '../../contexts/grid'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import { useGridMode } from '../../hooks/useGridMode'
import useItemFilter from '../../hooks/UseItemFilter'
import { useSnapToGrid } from '../../hooks/useSnapToGrid'

type Props = {
	actorId: string
	onRightClick: (
		position: { x: number; y: number },
		dimensions: { width: number; height: number },
	) => void
	onClick?: (isShiftKey: boolean) => void
	onDoubleClick?: () => void
	viewport: Viewport
	isGM: boolean
}

const useTokenInteractions = ({
	actorId,
	onRightClick,
	onClick,
	onDoubleClick,
	viewport,
	isGM,
}: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const { grid } = useGrid()
	const snapToGrid = useSnapToGrid()
	const { checkAccess } = useItemFilter()
	const { isGridMode } = useGridMode()
	const dragPosition = useRef<{
		x: number
		y: number
	} | null>(null)
	const lastSnappedPosition = useRef<{ x: number; y: number } | null>(null)
	const dragOffset = { x: 0, y: 0 }
	let isDragging = false
	let dragData: PIXI.FederatedPointerEvent | null = null
	let tokenElement: PIXI.DisplayObject | null = null
	const dragStartPosition = useRef<{ x: number; y: number } | null>(null)
	const lastClickTime = useRef<number>(0)

	// Get actor and its parent document
	const actor = game.documents.byId[actorId]
	const parentDocument = actor?.values?.parentId
		? game.documents.byId[actor.values.parentId]
		: null

	// Check access against both actor and parent - access to either one grants permission
	const hasParentAccess = parentDocument && checkAccess(parentDocument)
	const hasActorAccess =
		actor && actor.access && actor.accessList && checkAccess(actor)
	const canInteract = (isGM || hasParentAccess || hasActorAccess) && !isGridMode

	// Debug logging for access control
	const logAccess = (interactionType: string) => {
		console.log(
			`Token Interaction (${interactionType}) - %c${
				canInteract ? 'ACCESS GRANTED' : 'ACCESS DENIED'
			}`,
			`color: ${canInteract ? 'green' : 'red'}; font-weight: bold`,
			{
				actorId,
				actorName: actor?.values?.name,
				parentId: parentDocument?._id,
				parentName: parentDocument?.values?.name,
				accessReason: isGM
					? 'GM Status'
					: hasParentAccess
					? 'Parent Document Access'
					: hasActorAccess
					? 'Actor Access'
					: 'No Access',
				details: {
					isGM,
					hasParentAccess,
					hasActorAccess,
					actorAccess: actor?.access,
					actorAccessList: actor?.accessList,
					parentAccess: parentDocument?.access,
					parentAccessList: parentDocument?.accessList,
				},
			},
		)
	}

	const keepInBounds = useCallback(
		(pos: { x: number; y: number }) => {
			const mapAsset = game.assets.byId[activeScene?.values?.mapId]
			if (!mapAsset) return pos

			const aspectRatio = mapAsset.width / mapAsset.height
			const mapWidth =
				mapAsset.width >= mapAsset.height ? 100 : 100 * aspectRatio
			const mapHeight =
				mapAsset.width >= mapAsset.height ? 100 / aspectRatio : 100
			const cellSize = mapWidth / (100 / grid.size)
			const tokenSize = cellSize * 0.8

			return {
				x: Math.max(0, Math.min(mapWidth - tokenSize, pos.x)),
				y: Math.max(0, Math.min(mapHeight - tokenSize, pos.y)),
			}
		},
		[activeScene?.values?.mapId, game.assets, grid.size],
	)

	const handleRightClick = (event: PIXI.FederatedPointerEvent) => {
		logAccess('right-click')
		const target = event.currentTarget as PIXI.DisplayObject
		if (!target) return

		const screenPos = { x: event.globalX, y: event.globalY }
		const bounds = target.getBounds()

		onRightClick(
			{
				x: screenPos.x,
				y: screenPos.y,
			},
			{
				width: bounds.width,
				height: bounds.height,
			},
		)
	}

	const onPointerDown = (event: PIXI.FederatedPointerEvent) => {
		event.stopPropagation()

		const now = Date.now()
		const timeSinceLastClick = now - lastClickTime.current

		// Check for double click (within 300ms)
		if (timeSinceLastClick < 300) {
			if (!canInteract) {
				logAccess('denied')
				return
			}
			logAccess('double-click')
			onDoubleClick?.()
			lastClickTime.current = 0 // Reset to prevent triple-click
			return
		}

		lastClickTime.current = now

		if (!canInteract) {
			logAccess('denied')
			return
		}

		// Handle all interactions after access check
		if (event.button === 2) {
			logAccess('right-click')
			handleRightClick(event)
			return
		}

		if (event.button === 0) {
			// Start drag setup regardless of onClick
			logAccess('drag-start')
			dragData = event
			isDragging = true

			const dragHandle = event.currentTarget as PIXI.DisplayObject
			tokenElement = dragHandle.parent as PIXI.DisplayObject

			dragStartPosition.current = {
				x: event.globalX,
				y: event.globalY,
			}

			// Calculate offset from cursor to token center
			const worldPos = viewport.toWorld(
				new PIXI.Point(event.globalX, event.globalY),
			)
			dragOffset.x = tokenElement.position.x - worldPos.x
			dragOffset.y = tokenElement.position.y - worldPos.y

			window.addEventListener('pointermove', onGlobalPointerMove)
			window.addEventListener('pointerup', onGlobalPointerUp)
		}
	}

	const onGlobalPointerMove = (e: PointerEvent) => {
		if (!isDragging || !dragData || !tokenElement) return

		e.stopPropagation()

		const worldPos = viewport.toWorld(new PIXI.Point(e.clientX, e.clientY))

		const tokenCenter = {
			x: worldPos.x + dragOffset.x,
			y: worldPos.y + dragOffset.y,
		}

		const currentPosition = grid.enabled
			? snapToGrid(tokenCenter.x, tokenCenter.y)
			: keepInBounds(tokenCenter)

		dragPosition.current = currentPosition

		if (
			grid.enabled ||
			!lastSnappedPosition.current ||
			Math.abs(lastSnappedPosition.current.x - currentPosition.x) > 0.01 ||
			Math.abs(lastSnappedPosition.current.y - currentPosition.y) > 0.01
		) {
			lastSnappedPosition.current = currentPosition
			tokenElement.position.x = currentPosition.x
			tokenElement.position.y = currentPosition.y
		}
	}

	const onGlobalPointerUp = (event: PointerEvent) => {
		// Check if this was a click or a drag
		const wasDrag =
			dragStartPosition.current &&
			(Math.abs(event.clientX - dragStartPosition.current.x) > 3 ||
				Math.abs(event.clientY - dragStartPosition.current.y) > 3)

		// If it wasn't a drag, treat it as a click
		if (!wasDrag && onClick) {
			logAccess('click')
			onClick(event.shiftKey)
		} else if (wasDrag) {
			const worldPos = viewport.toWorld(
				new PIXI.Point(event.clientX, event.clientY),
			)
			const rawPosition = {
				x: worldPos.x - dragOffset.x,
				y: worldPos.y - dragOffset.y,
			}

			const finalPosition = snapToGrid(rawPosition.x, rawPosition.y)

			console.log('onGlobalPointerUp', {
				worldPos,
				dragOffset,
				rawPosition,
				finalPosition,
			})

			console.log(
				`Token Drag (${actor?.values?.name}) - %cFINISHED`,
				'color: blue; font-weight: bold',
				{
					from: dragStartPosition.current,
					to: finalPosition,
					grid: grid.enabled ? 'enabled' : 'disabled',
				},
			)

			// Only update position if it was a drag
			const currentActor = game.documents.byId[actorId]
			if (!currentActor || currentActor.type !== 'actor') return

			const locations = [...(currentActor.values.locations || [])]
			const locationIndex = locations.findIndex(
				loc => loc.mapId === activeScene?._id,
			)

			if (locationIndex !== -1) {
				locations[locationIndex] = {
					...locations[locationIndex],
					x: finalPosition.x,
					y: finalPosition.y,
				}

				dispatch({
					type: 'UPDATE_DOCUMENT',
					payload: {
						updatedDocument: {
							...currentActor,
							version: currentActor.version + 1,
							values: {
								...currentActor.values,
								locations,
							},
						},
					},
				})
			}
		}

		// Cleanup
		isDragging = false
		dragData = null
		tokenElement = null
		dragPosition.current = null
		lastSnappedPosition.current = null
		dragStartPosition.current = null

		window.removeEventListener('pointermove', onGlobalPointerMove)
		window.removeEventListener('pointerup', onGlobalPointerUp)
	}

	return {
		interactionProps: {
			pointerdown: onPointerDown,
		},
		dragPosition: dragPosition.current,
	}
}

export default useTokenInteractions
