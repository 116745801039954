import { twMerge } from 'tailwind-merge'
import BaseType from '../interface/text/BaseType'
import SmType from '../interface/text/SmType'
import XsType from '../interface/text/XsType'

interface IProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
	children: React.ReactNode
	className?: string
	isOnDark?: boolean
	size?: 'base' | 'sm' | 'xs'
}

const Label: React.FC<IProps> = ({
	children,
	className = '',
	isOnDark = false,
	size = 'base',
	...props
}) => {
	const TextComponent = {
		base: BaseType,
		sm: SmType,
		xs: XsType,
	}[size]

	return (
		<label className={twMerge('mt-6 block', className)} {...props}>
			<TextComponent isOnDark={isOnDark}>{children}</TextComponent>
		</label>
	)
}

export default Label
