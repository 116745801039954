import Popper from '@popperjs/core'
import { RefObject, useLayoutEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { usePopper } from 'react-popper'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

export interface PopoverProps {
	id: string
	open: boolean
	anchor: RefObject<HTMLElement>
	children?: React.ReactNode
	onClose?: () => void
	placement?: Popper.Placement
	showArrow?: boolean
	className?: string
	style?: React.CSSProperties
	enableTransition?: boolean
	disableOutsideClick?: boolean
}

const Popover: React.FC<PopoverProps> = ({
	id,
	open = false,
	anchor,
	children,
	onClose = () => {},
	placement = 'auto',
	showArrow = true,
	className,
	style,
	enableTransition = true,
	disableOutsideClick = false,
}) => {
	const arrowEl = useRef<HTMLDivElement>(null)
	const popEl = useRef<HTMLDivElement>(null)
	const { styles, attributes, update } = usePopper(
		anchor.current,
		popEl.current,
		{
			placement: placement,
			strategy: 'fixed',
			modifiers: [
				{
					name: 'preventOverflow',
					options: {
						rootBoundary: 'document',
						padding: 10,
					},
				},
				{
					name: 'arrow',
					options: {
						element: arrowEl.current,
					},
				},
				{
					name: 'offset',
					options: {
						offset: [0, 10],
					},
				},
			],
		},
	)

	useLayoutEffect(() => {
		update && update()
	}, [open, update])

	return createPortal(
		<OutsideClickHandler
			onOutsideClick={e => {
				if (!open || disableOutsideClick) return

				// @ts-ignore
				const path = e.path || (e.composedPath && e.composedPath())

				let isOutside = true

				if (path) {
					for (const el of path) {
						if (el === anchor.current) isOutside = false
					}
				}

				if (isOutside) onClose()
			}}
		>
			<PopperContainer
				id={id}
				ref={popEl}
				style={{ ...styles.popper, ...style }}
				className={twMerge(
					'z-40 transform rounded-xl border border-gray-400/10 bg-gray-900 px-3 py-1 text-left text-white shadow-2xl',
					enableTransition && 'transition-all duration-200',
					className,
					open
						? 'scale-100 opacity-100'
						: 'pointer-events-none scale-95 opacity-0',
				)}
				{...attributes.popper}
			>
				{children}
				{showArrow && <div id='arrow' ref={arrowEl} style={styles.arrow} />}
			</PopperContainer>
		</OutsideClickHandler>,
		document.body,
	)
}

const arrowSize = 14

const PopperContainer = styled.div`
	#arrow,
	#arrow::before {
		position: absolute;
		width: ${arrowSize}px;
		height: ${arrowSize}px;
		background: inherit;
		z-index: -1;
	}

	#arrow {
		visibility: hidden;
	}

	#arrow::before {
		visibility: visible;
		content: '';
		transform: rotate(45deg);
	}

	&[data-popper-placement^='top'] > #arrow {
		bottom: -${arrowSize / 2}px;
	}

	&[data-popper-placement^='bottom'] > #arrow {
		top: -${arrowSize / 2}px;
	}

	&[data-popper-placement^='left'] > #arrow {
		right: -${arrowSize / 2}px;
	}

	&[data-popper-placement^='right'] > #arrow {
		left: -${arrowSize / 2}px;
		border-left: 1px solid red;
		border-bottom: 1px solid rgba(168, 162, 158, 1);
	}
`

export default Popover
