import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'

interface Props {
	points: { x: number; y: number }[]
}

export default function MeasurementLine({ points }: Props) {
	const draw = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()

			// Set line style
			g.lineStyle({
				width: 0.2,
				color: 0xffffff,
				alpha: 1,
				cap: PIXI.LINE_CAP.ROUND,
				join: PIXI.LINE_JOIN.ROUND,
			})

			// Draw lines connecting points
			if (points.length > 0) {
				points.forEach((point, i) => {
					if (i === 0) {
						g.moveTo(point.x, point.y)
					} else {
						g.lineTo(point.x, point.y)
					}
				})
			}
		},
		[points],
	)

	return <Graphics draw={draw} zIndex={1000} />
}
