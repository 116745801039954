import { useContext, useRef } from 'react'
import {
	INTERFACE_BACKGROUND_COLOR,
	INTERFACE_DROPSHADOW,
} from '../../../components/interface/constants'
import GameContext from '../../../contexts/game'
import { useGrid } from '../../../contexts/grid'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import { useGridMode } from '../../../hooks/useGridMode'
import MapGridSize from '../../Library/MapEditor/MapGrid/MapGridSize'
import MapGridStyle from '../../Library/MapEditor/MapGrid/MapGridStyle'
import MapGridType from '../../Library/MapEditor/MapGrid/MapGridType'
import MapGridUnit from '../../Library/MapEditor/MapGrid/MapGridUnit'
import Popover from '../../Popover'
import SectionDivider from '../../SectionDivider'
import CustomToggle from '../../interface/Toggle'

interface Props {
	isOpen: boolean
}

const DEFAULT_GRID = {
	enabled: true,
	type: 'square' as const,
	size: 1,
	unitSize: 5,
	unit: 'ft',
	color: '#000000',
	alpha: 0.2,
	x: 0,
	y: 0,
}

export default function GridEditor({ isOpen }: Props) {
	const buttonRef = useRef<HTMLDivElement>(null)
	const { deactivateGridMode } = useGridMode()
	const activeScene = useGetActiveScene()
	const { dispatch } = useContext(GameContext)
	const { interactionMode } = useGrid()

	const gridValues = activeScene?.values?.grid || DEFAULT_GRID

	const handleGridUpdate = (changes: Partial<typeof gridValues>) => {
		if (!activeScene || interactionMode !== 'none') return

		const updatedDocument = {
			...activeScene,
			version: activeScene.version + 1,
			values: {
				...activeScene.values,
				grid: {
					...gridValues,
					...changes,
				},
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: { updatedDocument },
		})
	}

	const handleReset = () => {
		handleGridUpdate(DEFAULT_GRID)
	}

	return (
		<>
			<div ref={buttonRef} className='fixed top-4 left-6 h-0 w-80 ' />

			<Popover
				id='grid-editor'
				open={isOpen}
				anchor={buttonRef}
				placement='bottom'
				showArrow={false}
				onClose={deactivateGridMode}
				disableOutsideClick={true}
				className='border-none p-0 backdrop-blur-lg'
				style={{
					backgroundColor: `${INTERFACE_BACKGROUND_COLOR}E6`,
					boxShadow: INTERFACE_DROPSHADOW,
				}}
			>
				<div className='w-80 space-y-2 p-6 pt-4'>
					<SectionDivider label='Grid'>
						<CustomToggle
							pressed={gridValues.enabled}
							onPressedChange={enabled => handleGridUpdate({ enabled })}
						/>
					</SectionDivider>
					{gridValues.enabled && (
						<MapGridType
							type={gridValues.type}
							onChange={type => handleGridUpdate({ type })}
						/>
					)}
					{!gridValues.enabled && (
						<MapGridSize
							size={gridValues.size}
							onChange={size => handleGridUpdate({ size })}
						/>
					)}
					{gridValues.enabled && (
						<MapGridStyle
							color={gridValues.color}
							alpha={gridValues.alpha}
							onChange={style => handleGridUpdate(style)}
						/>
					)}
					{gridValues.enabled && (
						<MapGridUnit
							unitSize={gridValues.unitSize}
							unit={gridValues.unit}
							onChange={values => handleGridUpdate(values)}
						/>
					)}

					<div className='flex gap-2'>
						<button
							onClick={handleReset}
							className='w-full rounded-lg bg-gray-100 px-3 py-2 text-sm text-gray-800 hover:bg-gray-200'
						>
							Reset
						</button>
						<button
							onClick={deactivateGridMode}
							className='w-full rounded-lg bg-gray-100 px-3 py-2 text-sm text-gray-800 hover:bg-gray-200'
						>
							Done
						</button>
					</div>
				</div>
			</Popover>
		</>
	)
}
