import { useMutation } from '@apollo/client'
import { Crown, Trash2 } from 'lucide-react'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import { REMOVE_PLAYER } from '../../graphql/games'
import useUser from '../../hooks/useUser'
import Avatar from '../Avatar'
import ToolButton from '../interface/toolbar/ToolButton'
import SectionDivider from '../SectionDivider'
import InvitePlayers from './InvitePlayers'

// also update in EditColor.tsx if changing this
const DEFAULT_COLORS = [
	'#ffbe0b', // yellow
	'#fb5607', // orange
	'#ff006e', // pink
	'#8338ec', // purple
	'#3a86ff', // blue
	'#a1c181', // light green
	'#7209b7', // dark purple
	'#3f37c9', // indigo
]

interface UserSettings {
	color?: string
	role?: string
}

export default function SettingsPlayers() {
	const { game, dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId: myUserId, isGM } = useUser()

	const [removePlayer] = useMutation(REMOVE_PLAYER, {
		onCompleted(data) {
			const userId = data.removePlayer
			dispatch({
				type: 'REMOVE_USER',
				payload: {
					userId,
				},
			})
		},
		onError(error) {
			alert(error)
		},
	})

	const handleRemovePlayer = (userId: string, userName: string) => {
		if (
			window.confirm(
				`Are you sure you want to remove ${userName} from the game?`,
			)
		) {
			removePlayer({
				variables: { userId, gameId: game._id },
			})
		}
	}

	const handleToggleGM = (
		userId: string,
		userName: string,
		isCurrentlyGM: boolean,
	) => {
		if (
			window.confirm(
				isCurrentlyGM
					? `Are you sure you want to remove GM status from ${userName}? This will remove their ability to see and edit everything and manage players.`
					: `Are you sure you want to make ${userName} a GM? This will grant them the ability to see and edit everything and manage players.`,
			)
		) {
			dispatch({
				type: 'UPDATE_USER_SETTINGS',
				payload: {
					userId,
					userSettings: {
						role: isCurrentlyGM ? 'player' : 'gm',
					},
				},
			})
		}
	}

	return (
		<div>
			<SectionDivider label='Players' />
			<div>
				{usersState?.users.map((user, index) => {
					const isMe = user.userId === myUserId
					const isCreator =
						typeof game.creator !== 'string' && game.creator._id === user.userId
					const canModify = !isMe && isGM && !isCreator
					const userSettings = (user.userSettings || {}) as UserSettings
					const color = userSettings?.color || DEFAULT_COLORS[0]
					const role = userSettings?.role || 'player'
					const isUserGM = role === 'gm'

					return (
						<div
							key={user.userId}
							className={twMerge(
								'group flex items-center justify-between py-2',
								user.online ? 'opacity-100' : 'opacity-60',
								index === usersState.users.length - 1
									? ''
									: 'border-b border-gray-200',
							)}
						>
							<div className='flex items-center space-x-3'>
								<Avatar
									src={user.userProfile.avatar}
									userName={user.userProfile.name}
									userId={user.userId}
									color={color}
									size='md'
								/>
								<div>
									<div className='font-medium text-gray-900'>
										{user.userProfile.name}
									</div>
									<div className='flex items-center gap-1.5 text-sm text-gray-500'>
										<span>{user.online ? 'Online' : 'Offline'}</span>
										{(isMe || isUserGM || isCreator) && <span>•</span>}
										{isMe && <span className='font-medium'>You</span>}
										{isMe && (isUserGM || isCreator) && <span>•</span>}
										{isUserGM && <span className='font-medium'>GM</span>}
										{isCreator && (
											<>
												<span>•</span>
												<span className='font-medium'>Creator</span>
											</>
										)}
									</div>
								</div>
							</div>
							{canModify && (
								<div className='flex gap-1'>
									{!isCreator && (
										<ToolButton
											className={`h-10 w-10 ${
												isUserGM ? 'text-amber-500' : 'text-gray-400'
											}`}
											onClick={() =>
												handleToggleGM(
													user.userId,
													user.userProfile.name,
													isUserGM,
												)
											}
											tooltip={isUserGM ? 'Remove GM Status' : 'Make GM'}
										>
											<Crown className='h-5 w-5' />
										</ToolButton>
									)}
									<ToolButton
										className='h-10 w-10 text-red-600'
										onClick={() =>
											handleRemovePlayer(user.userId, user.userProfile.name)
										}
										tooltip='Remove Player'
									>
										<Trash2 className='h-5 w-5' />
									</ToolButton>
								</div>
							)}
						</div>
					)
				})}
			</div>

			<InvitePlayers />
		</div>
	)
}
