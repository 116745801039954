import { MapPinned } from 'lucide-react'
import { useContext, useEffect } from 'react'
import GameContext from '../../contexts/game'
import { useGuide } from '../../contexts/guideContext'
import { socket } from '../../contexts/socket'
import ToolsContext from '../../contexts/tools'
import useUser from '../../hooks/useUser'
import ToolButton from './toolbar/ToolButton'

export default function GuideButton() {
	const { guidingUserId, setGuidingUserId } = useGuide()
	const { userId, isGM, activeSceneId } = useUser()
	const { game } = useContext(GameContext)
	const { dispatchTools } = useContext(ToolsContext)
	const isMap = game.documents.byId[activeSceneId]?.type === 'map'

	// Stop guiding when scene changes
	useEffect(() => {
		if (guidingUserId === userId) {
			setGuidingUserId(null)
			socket.emit('guideStatusUpdate', { userId, isGuiding: false })
		}
	}, [activeSceneId])

	const handleClick = () => {
		const isGuiding = !guidingUserId
		setGuidingUserId(isGuiding ? userId : null)
		socket.emit('guideStatusUpdate', { userId, isGuiding })
		if (isGuiding) {
			dispatchTools({ type: 'SET_MODE', payload: 'select' })
		}
	}

	const isGuiding = guidingUserId === userId

	if (!isGM || !isMap) return null

	return (
		<ToolButton
			tooltip='Share Map View'
			isOpen={isGuiding}
			onClick={handleClick}
		>
			<MapPinned aria-hidden='true' />
		</ToolButton>
	)
}
