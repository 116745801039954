import { Text } from '@pixi/react'
import { useGrid } from '../../contexts/grid'
import useTextStyle from '../../hooks/useTextStyle'

interface Props {
	text: string
}

export default function TokenLabel(props: Props) {
	const { grid } = useGrid()
	const textStyle = useTextStyle()

	return (
		<Text
			anchor={[0.5, 0]}
			resolution={150}
			style={textStyle}
			x={0}
			y={0.45 * grid.size}
			{...props}
		/>
	)
}
