import { Highlighter, Scissors, Trash2, Type } from 'lucide-react'
import { EventBus } from 'pdfjs-dist/web/pdf_viewer.mjs'
import {
	INTERFACE_BACKGROUND_COLOR,
	INTERFACE_DROPSHADOW,
} from '../../interface/constants'
import ToolButton from '../../interface/toolbar/ToolButton'
import PDFDrawingToolbar from '../PDFDrawingToolbar'
import { ScaleModeType, SpreadModeType } from '../types/pdfViewerTypes'
import BackToBooksButton from './BackToBooksButton'
import PDFDisplayOptionsButton from './PDFDisplayOptionsButton'
import PDFOutlineButton from './PDFOutlineButton'
import PDFPageControls from './PDFPageControls'
import PDFPageInfo from './PDFPageInfo'

// Toolbar prop types
export interface PDFToolbarProps {
	pageNumber: number
	pagesCount: number
	scale: number
	scaleMode: ScaleModeType
	spreadMode: SpreadModeType
	onPageChange: (page: number) => void
	onZoomIn: () => void
	onZoomOut: () => void
	onScaleModeChange: (mode: ScaleModeType) => void
	onSpreadModeChange: (mode: SpreadModeType) => void
	onOutlineToggle: () => void
	showOutline: boolean
	eventBus: EventBus
	title?: string
	isGame?: boolean
	isLoading?: boolean
	loadingProgress?: number
	onClose?: () => void
	isHighlighting?: boolean
	onHighlightToggle?: () => void
	onClearAnnotations?: () => void
}

const PDFToolbar = ({
	pageNumber,
	pagesCount,
	scale,
	scaleMode,
	spreadMode,
	onPageChange,
	onZoomIn,
	onZoomOut,
	onSpreadModeChange,
	onScaleModeChange,
	onOutlineToggle,
	eventBus,
	showOutline,
	title,
	isGame,
	isLoading,
	loadingProgress,
	onClose,
	isHighlighting,
	onHighlightToggle,
	onClearAnnotations,
}: PDFToolbarProps) => {
	return (
		<div
			className='z-20 flex h-16 items-center justify-between gap-4 px-4 text-black'
			style={{
				backgroundColor: INTERFACE_BACKGROUND_COLOR,
				boxShadow: INTERFACE_DROPSHADOW,
			}}
		>
			<div className='flex items-center gap-2'>
				{!isGame && <BackToBooksButton onClose={onClose} />}
				<PDFOutlineButton
					onOutlineToggle={onOutlineToggle}
					isActive={showOutline}
				/>
				<PDFDisplayOptionsButton
					scale={scale}
					scaleMode={scaleMode}
					spreadMode={spreadMode}
					onZoomIn={onZoomIn}
					onZoomOut={onZoomOut}
					onScaleModeChange={onScaleModeChange}
					onSpreadModeChange={onSpreadModeChange}
				/>
				<PDFPageInfo
					pageNumber={pageNumber}
					pagesCount={pagesCount}
					title={title}
					isLoading={isLoading}
					loadingProgress={loadingProgress}
				/>
			</div>
			<div className='flex items-center justify-center gap-2'>
				{false && (
					<>
						<ToolButton
							onClick={() => {}}
							disabled={pageNumber >= pagesCount}
							tooltip='Clip'
						>
							<Scissors />
						</ToolButton>
						<ToolButton
							onClick={() => onPageChange(pageNumber + 1)}
							disabled={pageNumber >= pagesCount}
							tooltip='Add Text'
						>
							<Type />
						</ToolButton>
						<PDFDrawingToolbar eventBus={eventBus} />
					</>
				)}
				{false && (
					<>
						<ToolButton
							onClick={onHighlightToggle}
							tooltip='Highlighter'
							isOpen={isHighlighting}
						>
							<Highlighter />
						</ToolButton>
						<ToolButton
							onClick={onClearAnnotations}
							tooltip='Clear Annotations'
						>
							<Trash2 />
						</ToolButton>
					</>
				)}
			</div>

			<div className='flex items-center justify-end gap-2'>
				<PDFPageControls
					pageNumber={pageNumber}
					pagesCount={pagesCount}
					onPageChange={onPageChange}
				/>
			</div>
		</div>
	)
}

export default PDFToolbar
