import { twMerge } from 'tailwind-merge'
import useTheme from '../../../hooks/useTheme'

type Props = React.HTMLProps<HTMLDivElement>

const SmallUppercase = ({ children, className, style, ...rest }: Props) => {
	const { secondary } = useTheme()

	return (
		<div
			className={twMerge(`text-xs uppercase tracking-widest`, className)}
			style={{
				...style,
				fontSize: '9px',
				fontWeight: 600,
				color: style?.color || secondary,
			}}
			{...rest}
		>
			{children}
		</div>
	)
}

export default SmallUppercase
