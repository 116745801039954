import * as PIXI from 'pixi.js'
import { useEffect, useState } from 'react'

interface UseLoadTextureResult {
	texture: PIXI.Texture | null
	progress: number
}

const useLoadTexture = (fileUrl: string | undefined): UseLoadTextureResult => {
	const [texture, setTexture] = useState<PIXI.Texture | null>(null)
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		if (!fileUrl) {
			setTexture(null)
			setProgress(0)
			return
		}

		const loadTexture = async () => {
			try {
				const response = await fetch(fileUrl)
				const contentLength = response.headers.get('content-length')
				const total = contentLength ? parseInt(contentLength, 10) : 0
				let loaded = 0

				const reader = response.body?.getReader()
				if (!reader) return

				const chunks: Uint8Array[] = []
				let isDone = false
				while (!isDone) {
					const { done, value } = await reader.read()
					isDone = done
					if (done) break

					chunks.push(value)
					loaded += value.length
					const currentProgress = total ? (loaded / total) * 100 : 0
					setProgress(currentProgress)
				}

				const blob = new Blob(chunks)
				const blobUrl = URL.createObjectURL(blob)

				const newTexture = await PIXI.Texture.fromURL(blobUrl)
				setTexture(newTexture)
				URL.revokeObjectURL(blobUrl)
			} catch (error) {
				console.error('Error loading texture:', error)
				setTexture(null)
			}
		}

		loadTexture()
		return () => {
			setProgress(0)
			setTexture(null)
		}
	}, [fileUrl])

	return { texture, progress }
}

export default useLoadTexture
