import { Container } from '@pixi/react'
import { useGrid } from '../../../../contexts/grid'
import GridInteractions from '../GridInteractions'
import { SquareGridRenderer } from './renderers'

export const SquareGrid = () => {
	const { grid: gridConfig, gridDimensions, squareGrid } = useGrid()
	const { width, height } = gridDimensions
	const containerOffset = {
		x: -width / 3,
		y: -height / 3,
	}

	// Don't render if grid is disabled or not square type
	if (!gridConfig.enabled || gridConfig.type !== 'square') {
		return null
	}

	if (!width || !height || !squareGrid) return null

	return (
		<Container x={containerOffset.x} y={containerOffset.y}>
			<SquareGridRenderer grid={squareGrid} />
			<GridInteractions />
		</Container>
	)
}

export default SquareGrid
