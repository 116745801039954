import { gql, useMutation, useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import useApi from '../../../hooks/useApi'
import { UserLevel } from '../../../interfaces/auth'
import AdminUserDetail from './UserDetail'

const GET_ALL_USERS = gql`
	query GetAllUsers {
		adminUsers {
			_id
			name
			email
			avatar
			userLevel
			createdAt
			createdGames {
				_id
			}
			joinedGames {
				_id
			}
			resources {
				... on BookResource {
					_id
				}
				... on ImageResource {
					_id
				}
				... on VideoResource {
					_id
				}
				... on OtherResource {
					_id
				}
			}
		}
	}
`

const DELETE_USERS = gql`
	mutation DeleteUsers($userIds: [ID!]!) {
		deleteUsers(userIds: $userIds)
	}
`

interface User {
	_id: string
	name: string
	email: string
	avatar: string | null
	userLevel: UserLevel
	createdAt: string
	createdGames: { _id: string }[]
	joinedGames: { _id: string }[]
	resources: { _id: string }[]
}

interface UserMetrics {
	userId: string
	totalStorage: number
	monthlyTransfer: number
	estimatedCosts: {
		storage: number
		transfer: number
		requests: {
			puts: number
			gets: number
		}
		total: number
	}
}

const formatDate = (dateString: string) => {
	try {
		// Try parsing as ISO string first
		const date = new Date(dateString)
		if (isNaN(date.getTime())) {
			// If invalid, try parsing as Unix timestamp (in milliseconds)
			const timestampDate = new Date(parseInt(dateString))
			return timestampDate.toLocaleDateString()
		}
		return date.toLocaleDateString()
	} catch {
		return 'Invalid Date'
	}
}

export default function AdminUsers() {
	const [selectedUsers, setSelectedUsers] = useState<string[]>([])
	const [selectedUser, setSelectedUser] = useState<User | null>(null)
	const [isSlideoverOpen, setIsSlideoverOpen] = useState(false)
	const [userMetrics, setUserMetrics] = useState<{
		[key: string]: UserMetrics
	}>({})

	const { request } = useApi()
	const { data, loading, error, refetch } = useQuery<{ adminUsers: User[] }>(
		GET_ALL_USERS,
	)
	const [deleteUsers] = useMutation(DELETE_USERS, {
		refetchQueries: [{ query: GET_ALL_USERS }],
	})

	useEffect(() => {
		const fetchUserMetrics = async () => {
			try {
				const metrics = await request('/api/storage-metrics/all')
				const metricsMap = metrics.reduce(
					(acc: { [key: string]: UserMetrics }, metric: UserMetrics) => {
						acc[metric.userId] = metric
						return acc
					},
					{},
				)
				setUserMetrics(metricsMap)
			} catch (error) {
				console.error('Error fetching user metrics:', error)
			}
		}

		fetchUserMetrics()
	}, [request])

	const handleUserUpdate = async (_userId: string, _updates: Partial<User>) => {
		await refetch()
	}

	if (loading) {
		return (
			<div className='flex min-h-screen items-center justify-center'>
				<div className='h-32 w-32 animate-spin rounded-full border-t-2 border-b-2 border-gray-900'></div>
			</div>
		)
	}
	if (error) return <div>Error loading users</div>

	const users = data?.adminUsers || []

	const handleSelectUser = (userId: string) => {
		setSelectedUsers(prev =>
			prev.includes(userId)
				? prev.filter(id => id !== userId)
				: [...prev, userId],
		)
	}

	const handleSelectAll = () => {
		if (selectedUsers.length === users.length) {
			setSelectedUsers([])
		} else {
			setSelectedUsers(users.map(user => user._id))
		}
	}

	const handleDeleteSelected = async () => {
		if (!selectedUsers.length) return

		if (
			window.confirm(
				`Are you sure you want to delete ${selectedUsers.length} users?`,
			)
		) {
			try {
				await deleteUsers({
					variables: { userIds: selectedUsers },
				})
				setSelectedUsers([])
			} catch (err) {
				console.error('Error deleting users:', err)
				alert('Failed to delete users')
			}
		}
	}

	const handleUserClick = (user: User) => {
		setSelectedUser(user)
		setIsSlideoverOpen(true)
	}

	const handleCloseSlideOver = () => {
		setIsSlideoverOpen(false)
		setTimeout(() => {
			setSelectedUser(null)
		}, 500)
	}

	const formatBytes = (bytes: number) => {
		const gb = bytes / (1024 * 1024 * 1024)
		return `${gb.toFixed(2)} GB`
	}

	return (
		<div>
			<div className='mb-8 flex items-center justify-between'>
				<h1 className='text-2xl font-bold'>Users</h1>
				{selectedUsers.length > 0 && (
					<button
						onClick={handleDeleteSelected}
						className='rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700'
					>
						Delete Selected ({selectedUsers.length})
					</button>
				)}
			</div>

			<div className='overflow-hidden rounded-lg bg-white shadow'>
				<table className='min-w-full divide-y divide-gray-200'>
					<thead className='bg-gray-50'>
						<tr>
							<th className='px-6 py-3 text-left'>
								<input
									type='checkbox'
									checked={selectedUsers.length === users.length}
									onChange={handleSelectAll}
									className='h-4 w-4 rounded border-gray-300'
								/>
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								User
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Email
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								User Level
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Storage
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Transfer
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Est. Cost
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Resources
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Joined
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 bg-white'>
						{users.map(user => (
							<tr
								key={user._id}
								className='cursor-pointer hover:bg-gray-50'
								onClick={() => handleUserClick(user)}
							>
								<td
									className='whitespace-nowrap px-6 py-4'
									onClick={e => e.stopPropagation()}
								>
									<input
										type='checkbox'
										checked={selectedUsers.includes(user._id)}
										onChange={() => handleSelectUser(user._id)}
										className='h-4 w-4 rounded border-gray-300'
									/>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div className='flex items-center'>
										<div className='h-10 w-10 flex-shrink-0'>
											{user.avatar ? (
												<img
													className='h-10 w-10 rounded-full object-cover'
													src={user.avatar}
													alt={user.name}
												/>
											) : (
												<div className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-gray-500'>
													{user.name.charAt(0).toUpperCase()}
												</div>
											)}
										</div>
										<div className='ml-4'>
											<div className='text-sm font-medium text-gray-900'>
												{user.name}
											</div>
										</div>
									</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div className='text-sm text-gray-500'>{user.email}</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<span
										className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
											user.userLevel === UserLevel.ADMIN
												? 'bg-green-100 text-green-800'
												: user.userLevel === UserLevel.ADEPT
												? 'bg-blue-100 text-blue-800'
												: 'bg-gray-100 text-gray-800'
										}`}
									>
										{user.userLevel}
									</span>
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatBytes(userMetrics[user._id]?.totalStorage || 0)}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatBytes(userMetrics[user._id]?.monthlyTransfer || 0)}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									$
									{userMetrics[user._id]?.estimatedCosts.total.toFixed(2) ||
										'0'}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{user.resources.length}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatDate(user.createdAt)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{/* Slide Over */}
			<AdminUserDetail
				user={selectedUser}
				onClose={handleCloseSlideOver}
				open={isSlideoverOpen}
				onUserUpdate={handleUserUpdate}
			/>
		</div>
	)
}
