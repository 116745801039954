import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useGridMode } from '../../../hooks/useGridMode'

interface Props {
	onSelect: () => void
}

export default function EditGridMenuItem({ onSelect }: Props) {
	const { activateGridMode } = useGridMode()

	const handleSelect = () => {
		activateGridMode()
		onSelect()
	}

	return (
		<DropdownMenu.Item
			className='flex cursor-pointer items-center gap-2 rounded-lg px-2 py-1.5 text-sm outline-none hover:bg-gray-700/50'
			onSelect={handleSelect}
		>
			Edit Grid
		</DropdownMenu.Item>
	)
}
