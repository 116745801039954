import { Reducer } from 'react'
import { IAuthAction, IAuthState } from '../interfaces/auth'

const AuthReducer: Reducer<IAuthState, IAuthAction> = (state, action) => {
	switch (action.type) {
		case 'UPDATE_ALL':
			return {
				...state,
				...action.payload,
			}

		case 'UPDATE_PROFILE':
			return {
				...state,
				userDetails: {
					...state.userDetails,
					...action.payload.userDetails,
				},
			}

		case 'UPDATE_PROFILE_PICTURE': {
			const { avatar } = action.payload

			return {
				...state,
				userDetails: {
					...state.userDetails,
					avatar,
				},
			}
		}

		case 'LOGOUT':
			return {
				token: null,
				userId: '',
				userDetails: {
					name: '',
					avatar: '',
					email: '',
					userLevel: undefined,
				},
				login: state.login,
				logout: state.logout,
			}

		default:
			return state
	}
}

export default AuthReducer
