import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import request from 'superagent'
import * as yup from 'yup'
import AuthContext from '../../contexts/auth'
import { ADD_PLAYER_WITH_TOKEN } from '../../graphql/games'
import {
	FormButton,
	FormError,
	FormInput,
	FormLabel,
} from '../FormComponents/auth'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

interface IFormInputs {
	email: string
	password: string
}

const schema = yup
	.object({
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required()

type Props = {
	setIsSignIn: (isSignIn: boolean) => void
}

const InviteSignIn = ({ setIsSignIn }: Props) => {
	const { gameId } = useParams()
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)
	const [addPlayerWithToken] = useMutation(ADD_PLAYER_WITH_TOKEN)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const handleSigninAndJoin = (data: IFormInputs) => {
		const { email, password } = data

		request
			.post('/api/login')
			.send({
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					alert('Something went wrong.')
				}
				if (res.status === 401) {
					alert('Signing in failed.')
				}
				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)

				addPlayerWithToken({
					variables: {
						gameId,
						email,
						inviteToken,
					},
				})
					.then(() => {
						navigate(`/game/${gameId}`)
					})
					.catch(error => {
						console.log('Error adding player with token', error)
					})
			})
			.catch(error => {
				alert(error)
				console.log('create user error', error)
			})
	}

	return (
		<div className='rounded-lg bg-gray-800 p-6'>
			<h2 className='mb-4 text-center text-xl font-semibold text-white'>
				Sign in to join
			</h2>
			<div className='mb-8 text-center'>
				<button
					onClick={() => setIsSignIn(false)}
					className='text-gray-400 transition-colors hover:text-white'
				>
					Don't have an account? Sign Up
				</button>
			</div>

			<form onSubmit={handleSubmit(handleSigninAndJoin)} className='space-y-4'>
				<div>
					<FormLabel htmlFor='email'>Email</FormLabel>
					<FormInput
						type='email'
						placeholder='Email...'
						{...register('email', { required: true })}
					/>
					{errors.email && <FormError message='Email is required' />}
				</div>

				<div>
					<FormLabel htmlFor='password'>Password</FormLabel>
					<FormInput
						type='password'
						placeholder='Password...'
						{...register('password', { required: true })}
					/>
					{errors.password && <FormError message='Password is required' />}
				</div>

				<FormButton type='submit' className='w-full'>
					Join Game
				</FormButton>
			</form>
		</div>
	)
}

export default InviteSignIn
