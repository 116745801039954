import { useContext } from 'react'
import { IActor } from '../../../../../shared/types/actor'
import GameContext from '../../../contexts/game'
import useItemFilter from '../../../hooks/UseItemFilter'
import useUser from '../../../hooks/useUser'
import MenuItem from '../../dropdownmenu/MenuItem'

interface Props {
	doc: IActor
	onClose: () => void
}

const RenameActorMenuItem = ({ doc, onClose }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { isGM } = useUser()
	const { checkAccess } = useItemFilter()
	const hasAccess = isGM || checkAccess(doc)

	const handleRename = () => {
		const newName = window.prompt('Enter new name:', doc.values?.name || '')
		if (newName !== null && newName !== doc.values?.name) {
			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...doc,
						version: doc.version + 1,
						values: {
							...doc.values,
							name: newName,
						},
					},
				},
			})
		}
		onClose()
	}

	if (!hasAccess) return null

	return <MenuItem onSelect={handleRename}>Rename</MenuItem>
}

export default RenameActorMenuItem
