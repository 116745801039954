import { LabelHTMLAttributes } from 'react'

const FormLabel = ({
	className = '',
	children,
	...props
}: LabelHTMLAttributes<HTMLLabelElement>) => {
	return (
		<label
			className={`mb-1 block text-sm font-medium text-gray-300 ${className}`}
			{...props}
		>
			{children}
		</label>
	)
}

export default FormLabel
