import { gql, useMutation, useQuery } from '@apollo/client'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import { CREATE_GAME } from '../../graphql/games'
import Label from '../FormComponents/Label'
import GameCard from '../pages/elements/GameCard'
import ModalWindow from '../window/ModalWindow'

const GET_GAME_TEMPLATES = gql`
	query GetGameTemplates {
		gameTemplates {
			_id
			title
			description
			coverImage
			logo
			hideLogo
			assets {
				byId
				allIds
			}
			creator {
				_id
				name
				email
			}
			isTemplate
		}
	}
`

interface CreatorShallow {
	_id: string
	name: string
}

interface TemplateGame {
	_id: string
	title: string
	description: string
	coverImage: string | null
	logo: string
	hideLogo: boolean
	assets: {
		byId: { [key: string]: any }
		allIds: string[]
	}
	creator: CreatorShallow
	users: string[]
	createdAt: string
	updatedAt: string
	isTemplate: boolean
}

interface NewGameModalProps {
	isOpen: boolean
	onClose: () => void
}

const NewGameModal = ({ isOpen, onClose }: NewGameModalProps) => {
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)
	const { data: templateData, loading: templatesLoading } = useQuery<{
		gameTemplates: TemplateGame[]
	}>(GET_GAME_TEMPLATES, {
		skip: !authState.token,
	})

	const [createGame] = useMutation(CREATE_GAME, {
		onCompleted: data => {
			onClose()
			navigate(`/game/${data.createGame._id}`)
		},
		onError: error => {
			console.error('Error creating game:', error)
		},
	})

	const handleTemplateSelect = (template: TemplateGame | null) => {
		createGame({
			variables: {
				createGameInput: {
					title: 'Untitled Adventure',
					description: template?.description || '',
					templateId: template?._id || null,
				},
			},
		})
	}

	return (
		<ModalWindow
			id='new-game-modal'
			title='Create New Game'
			isOpen={isOpen}
			onClose={onClose}
			size='large'
		>
			<div className='px-6 pb-6'>
				{!templatesLoading && templateData?.gameTemplates?.length > 0 && (
					<div className='mt-6'>
						<Label>Start from Template (Optional)</Label>
						<div className='mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
							<div
								onClick={() => handleTemplateSelect(null)}
								className='cursor-pointer rounded-xl border-2 border-transparent p-2 transition-all hover:border-indigo-300'
							>
								<div className='flex aspect-[1.65] items-center justify-center rounded-lg bg-gray-100'>
									<div className='text-center'>
										<div className='font-medium text-gray-900'>
											Start from Scratch
										</div>
										<div className='text-sm text-gray-500'>
											Create a new empty game
										</div>
									</div>
								</div>
							</div>

							{templateData.gameTemplates.map(template => (
								<div
									key={template._id}
									onClick={() => handleTemplateSelect(template)}
									className='cursor-pointer rounded-xl border-2 border-transparent p-2 transition-all hover:border-indigo-300'
								>
									<GameCard game={template} />
									<div className='mt-2 text-center text-xs text-gray-500'>
										{template.title}
									</div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</ModalWindow>
	)
}

export default NewGameModal
