interface FormErrorProps {
	message?: string
	className?: string
}

const FormError = ({ message, className = '' }: FormErrorProps) => {
	if (!message) return null

	return <p className={`mt-1 text-sm text-gray-400 ${className}`}>{message}</p>
}

export default FormError
