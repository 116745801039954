import { animated, SpringValue, useSpring } from '@react-spring/web'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { updateGameProgress } from '../../api/games'
import { MemoryProvider } from '../../contexts/MemoryProvider'
import { LogVisibilityProvider } from '../../contexts/logVisibility'
import { NotificationsProvider } from '../../contexts/notifications'
import { usePanelSystem } from '../../contexts/panelSystem'
import { useGame } from '../../hooks/useGame'
import { useGridMode } from '../../hooks/useGridMode'
import GridEditor from '../canvas/grid/GridEditor'
import { PanelSystem } from '../panels'
import { usePanelConfig } from '../panels/panelConfig'
import Settings from '../settings/Settings'
import PlayerTurnTracker from '../turnTracker/PlayerTurnTracker'
import UserHeads from '../users/UserHeads'
import WelcomeCreatorModal from '../welcome/WelcomeCreatorModal'
import GameName from './GameName'
import GuideOverlay from './GuideOverlay'
import LeftTools from './LeftTools'
import Tools from './Tools'

interface SpringStyles {
	transform?: SpringValue<string>
	scale?: SpringValue<number>
	opacity?: SpringValue<number>
}

const Interface = () => {
	const { game } = useGame()
	const { isGridMode } = useGridMode()
	const [showGameName, setShowGameName] = useState(false)
	const [fadeOut, setFadeOut] = useState(false)
	const panels = usePanelConfig()
	const { panelSystemRef } = usePanelSystem()
	const togglePanelRef = useRef<((id: string) => Promise<void>) | null>(null)
	const [isWelcomeOpen, setIsWelcomeOpen] = useState(
		!game.progress?.creatorWelcomeCompleted,
	)
	const [isSettingsOpen, setIsSettingsOpen] = useState(false)
	const [shouldOpenSettings, setShouldOpenSettings] = useState(false)

	const springConfig = {
		mass: 1, // Keep mass at 1 for good weight
		tension: 200, // Middle ground for tension (was 210 and 180)
		friction: 22, // Middle ground for friction (was 16 and 24)
	}

	const topSpring = useSpring<SpringStyles>({
		to: {
			transform: isGridMode ? 'translateY(-100%)' : 'translateY(0%)',
		},
		config: springConfig,
	})

	const leftSpring = useSpring<SpringStyles>({
		to: {
			transform: isGridMode ? 'translateX(-100%)' : 'translateX(0%)',
		},
		config: springConfig,
	})

	const rightSpring = useSpring<SpringStyles>({
		to: {
			transform: isGridMode ? 'translateX(100%)' : 'translateX(0%)',
		},
		config: springConfig,
	})

	const bottomSpring = useSpring<SpringStyles>({
		to: {
			transform: isGridMode ? 'translateY(100%)' : 'translateY(0%)',
		},
		config: springConfig,
	})

	const containerSpring = useSpring<SpringStyles>({
		to: {
			scale: isGridMode ? 1.1 : 1,
			opacity: isGridMode ? 0 : 1,
		},
		config: springConfig,
	})

	useEffect(() => {
		const timer = setTimeout(() => {
			setFadeOut(true)
			const hideTimer = setTimeout(() => {
				setShowGameName(false)
			}, 1000)
			return () => clearTimeout(hideTimer)
		}, 3000)

		return () => clearTimeout(timer)
	}, [])

	const handleWelcomeClose = async (openSettings?: boolean) => {
		try {
			await updateGameProgress(game._id, { creatorWelcomeCompleted: true })
			setIsWelcomeOpen(false)
			if (openSettings) {
				setIsSettingsOpen(true)
			}
		} catch (error) {
			console.error('Failed to update game progress:', error)
		}
	}

	return (
		<MemoryProvider>
			<LogVisibilityProvider>
				<NotificationsProvider>
					<animated.div
						id='interface'
						style={containerSpring}
						className='pointer-events-none absolute inset-0 z-30 flex flex-row justify-between p-6 text-gray-300'
					>
						{/* Left Column - Left Tools */}
						<animated.div
							style={{
								...leftSpring,
								flex: '0 0 320px',
								width: '320px',
							}}
							className='pointer-events-none flex h-full flex-col justify-start'
						>
							<LeftTools />
						</animated.div>

						{/* Middle Column - User Heads and Tools */}
						<div className='flex flex-1 flex-col justify-between'>
							<animated.div
								style={topSpring}
								className='flex w-full flex-row items-center justify-center space-x-2'
							>
								{game.turnTracker.currentRound === 0 ? (
									<UserHeads />
								) : (
									<PlayerTurnTracker />
								)}
							</animated.div>

							<animated.div
								style={bottomSpring}
								className='pointer-events-none flex flex-col justify-end'
							>
								<Tools />
							</animated.div>
						</div>

						{/* Right Column - Panel System */}
						<animated.div
							style={{
								...rightSpring,
								flex: '0 0 320px',
								width: '320px',
							}}
							className='flex min-h-0 flex-col justify-between @container'
						>
							<PanelSystem
								ref={panelSystemRef}
								panels={panels}
								onTogglePanelRef={fn => {
									togglePanelRef.current = fn
								}}
							/>
						</animated.div>
					</animated.div>

					<GuideOverlay />

					{showGameName && (
						<div
							className={`pointer-events-none fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-1000 ${
								fadeOut ? 'opacity-0' : 'opacity-100'
							}`}
						>
							<GameName />
						</div>
					)}

					<GridEditor isOpen={isGridMode} />

					{createPortal(
						<WelcomeCreatorModal
							isOpen={isWelcomeOpen}
							onClose={() => handleWelcomeClose(shouldOpenSettings)}
							onSetup={() => {
								setShouldOpenSettings(true)
								handleWelcomeClose(true)
							}}
						/>,
						document.body,
					)}

					{createPortal(
						<Settings
							isOpen={isSettingsOpen}
							onClose={() => setIsSettingsOpen(false)}
							defaultTabId='game'
						/>,
						document.body,
					)}
				</NotificationsProvider>
			</LogVisibilityProvider>
		</MemoryProvider>
	)
}

export default Interface
